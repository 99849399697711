import { Mercancias } from "../Mercancias"
import * as Checkbox from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import { FaMinus } from "react-icons/fa";
import { MercanciasRead } from "../MercanciasRead";
import { API_URL } from "../../constants";
import { toast } from "react-toastify";


export const CartaPorte = ({
    mercancias,
    handleInputChange,
    handleTranspInt,
    handleEntradaSalida,
    handleDeleteMercancia,
    handleAddMerch,
    handleMaterialPeligroso,
    handleTransportChange,
    handleRemolqueChange,
    handleFiguraChange,
    handleUbiChange,
    handleEnvioInt,
    handleLogInversa,
    transportsData,
    autoTransporte,
    remolque,
    figuraTransporte,
    isTransInt,
    logInversa,
    entradaSalida,
    formaFarmaceutica,
    condicionesEspeciales,
    embalajeData
}) => {

    useEffect(() => {
        fetchPermisoSCT();
        fetchConfVehicular();
        fetchDrivers();
    }, [])


    const [transport, setTransport] = useState("");
    const [permisosSCT, setPermisosSCT] = useState([]);
    const [configuracionVehic, setConfiguracionVehic] = useState([]);
    const [driversData, setDriversData] = useState([]);
    const [driverSelected, setDriverSelected] = useState("");


    const [nameMunicipio, setNameMunicipio] = useState('');
    const [nameColonia, setNameColonia] = useState('');

    const [nameEstado, setEstadoName] = useState('');

    const [estadosOrigen, setEstadosOrigen] = useState([]);
    const [municipiosOrigen, setMunicipiosOrigen] = useState([]);
    const [coloniasOrigen, setColoniasOrigen] = useState([]);


    const [estadosDest, setEstadosDest] = useState([]);
    const [municipiosDest, setMunicipiosDest] = useState([]);
    const [coloniasDest, setColoniasDest] = useState([]);

    const notifyWarning = (message) => {
        toast.warning(message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

    const handleAddMerchCP = (e) => {
        e.preventDefault();
        handleAddMerch();
    } 
    const handleMaterialPeligrosoChange = (isChecked, index)=>{
        handleMaterialPeligroso(isChecked, index)
        if(isChecked){
            setTransport("");
            notifyWarning("Los transportes han sido actualizados, revisa de nuevo");
        }

    }

    const handleDriverSelected = (e) => {

        if (e.target.name === "driver") {
            let selectedDriver = driversData.filter((driver) => driver._id === e.target.value);
            
            console.log("SEleceted driver", selectedDriver);
            handleFiguraChange(e, selectedDriver);
            fetchMunicipioDriver(selectedDriver[0].estado, selectedDriver[0].municipio);
            fetchEstadoDriver(selectedDriver[0].pais, selectedDriver[0].estado);
            fetchColoniaDriver(selectedDriver[0].codigoPostal, selectedDriver[0].colonia);
            setDriverSelected(e.target.value);
        } else {
            handleFiguraChange(e);
        }
    }

    const handleUbicacionesChange = async (e) => {
        if (e.target.name === "distanceDest") {
            handleUbiChange(e);
        }

        if (e.target.name === "fechaHoraOrigen") {
            handleUbiChange(e);
        }
        if (e.target.name === "rfcOrigen") {
            handleUbiChange(e);
        }

        if (e.target.name === "paisOrigen") {
            let dataEstados = await fetchEstados(e.target.value);
            setEstadosOrigen(dataEstados);
            handleUbiChange(e);

        }
        if (e.target.name === "estadoOrigen") {
            let dataMunicipios = await fetchMunicipios(e.target.value);
            setMunicipiosOrigen(dataMunicipios);
            handleUbiChange(e);

        }
        if (e.target.name === "municipioOrigen") {
            handleUbiChange(e);
        }

        if (e.target.name === "cpOrigen") {
            let dataColonias = await fetchColonias(e.target.value);
            setColoniasOrigen(dataColonias);
            handleUbiChange(e);

        }
        if (e.target.name === "coloniaOrigen") {
            handleUbiChange(e);
        }

        if (e.target.name === "fechaHoraDest") {
            handleUbiChange(e);
        }
        if (e.target.name === "rfcDest") {
            handleUbiChange(e);
        }

        if (e.target.name === "paisDest") {
            let dataEstados = await fetchEstados(e.target.value);
            setEstadosDest(dataEstados);
            handleUbiChange(e);

        }
        if (e.target.name === "estadoDest") {
            let dataMunicipios = await fetchMunicipios(e.target.value);
            setMunicipiosDest(dataMunicipios);
            handleUbiChange(e);
        }
        if (e.target.name === "municipioDest") {
            handleUbiChange(e);
        }
        if (e.target.name === "cpDest") {
            let dataColonias = await fetchColonias(e.target.value);
            setColoniasDest(dataColonias);
            handleUbiChange(e);

        }

        if (e.target.name === "coloniaDest") {
            handleUbiChange(e);
        }


    }



    const fetchMunicipios = async (estado) => {
        const response = await fetch(
            `${API_URL}/api/catalogomunicipios?stateCode=${estado}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const data = await response.json();

        console.log("fetchMunicipios", data);
        return data;
    }

    const fetchColonias = async (cp) => {
        const response = await fetch(
            `${API_URL}/api/catalogolocalidades?postalCode=${cp}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        const data = await response.json();
        console.log("fetchColonias", data);
        return data;
    }

    const fetchEstados = async (pais) => {
        try {
            const response = await fetch(
                `${API_URL}/api/catalogoestados?countryCode=${pais}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            const data = await response.json();
            console.log("fetchEstados", data);
            return data;
        } catch (error) {

        }
    }



    const fetchMunicipioDriver = async (estado, municipio) => {
        console.log("Municipio", municipio)

        const response = await fetch(
            `${API_URL}/api/catalogomunicipios?stateCode=${estado}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const data = await response.json();
        const nameMunicipio = data.find((el) => el.Value == municipio);

        console.log("NameMunicipio", nameMunicipio);
        setNameMunicipio(nameMunicipio.Name);
    }

    const fetchColoniaDriver = async (cp, colonia) => {
        const response = await fetch(
            `${API_URL}/api/catalogolocalidades?postalCode=${cp}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        const data = await response.json();
        let coloniaDriver = data.find((el) => el.Value == colonia);
        setNameColonia(coloniaDriver.Name);
    }

    const fetchEstadoDriver = async (pais, estado) => {
        try {
            const response = await fetch(
                `${API_URL}/api/catalogoestados?countryCode=${pais}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            const data = await response.json();

            const nameEstado = data.find((el) => el.Value === estado);

            setEstadoName(nameEstado.Name);
        } catch (error) {

        }
    }


    const handleTransportIdChange = (e) => {
        if (e.target.name === "unidadTransporte") {
            setTransport(e.target.value);
            let selectedTransport = transportsData.filter((transport) => transport._id === e.target.value);
            console.log("selectedTransport", selectedTransport);
            handleTransportChange(e, selectedTransport);
        } else {
            handleTransportChange(e);
        }
    }

    const fetchPermisoSCT = async () => {
        try {
            let response = await fetch(`${API_URL}/api/catalagos/permisoSCT`);
            let data = await response.json();
            console.log("response", data);
            setPermisosSCT(data);
        } catch (error) {
            console.error(error);
        }
    }

    const fetchConfVehicular = async () => {
        try {
            let response = await fetch(`${API_URL}/api/catalagos/confVehicular`);
            let data = await response.json();
            setConfiguracionVehic(data);
            console.log("data configuracion vehicular", data);
        } catch (error) {
            console.error(error);

        }
    }

    const fetchDrivers = async () => {
        try {
            let response = await fetch(`${API_URL}/api/drivers`);
            let data = await response.json();
            console.log("Data de fetchDrivers", data);
            setDriversData(data);
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div>
            <hr></hr>
            <h4 style={{ margin: "2rem 0" }}>Datos de la Carta Porte</h4>
            <h4 style={{ margin: "2rem 0 0rem 0" }}>Ubicaciones</h4>
            <div className="form__group_form-select">
                <div className="form__group form__group_selectField">
                    <label className="FormLabel" style={{ margin: "1rem 0" }}>Distancia Recorrida</label>
                    <input
                        className="Input boxSizing__bb"
                        type="text"
                        name='distanceDest'
                        required
                        onChange={handleUbicacionesChange}
                        placeholder="Distancia Recorrida en Kilómetros"
                    />

                </div>
            </div>


            <h5 style={{ margin: "2rem 0 0rem 0" }}>Origen</h5>

            <div className="form__group_form-select">
                <div className="form__group form__group_selectField">
                    <label className="FormLabel" style={{ margin: "1rem 0" }}>Fecha y Hora de Salida</label>
                    <input
                        className="Input boxSizing__bb"
                        type="datetime-local"
                        name='fechaHoraOrigen'
                        required
                        onChange={handleUbicacionesChange}

                        placeholder="RFC del Origen"
                    />

                </div>

                <div className="form__group form__group_selectField">
                    <label className="FormLabel" style={{ margin: "1rem 0" }}>RFC del Origen</label>
                    <input
                        className="Input boxSizing__bb"
                        type="text"
                        name='rfcOrigen'
                        required
                        onChange={handleUbicacionesChange}

                        placeholder="RFC del Origen"
                    />
                </div>
            </div>

            <div className="form__group_form-select">
                <div className="form__group form__group_selectField">
                    <label className="FormLabel" style={{ margin: "1rem 0" }}>País</label>
                    <select
                        className="SelectTrigger"
                        required
                        placeholder="pais"
                        onChange={handleUbicacionesChange}
                        name="paisOrigen"
                        style={{ width: "100%" }}
                    >
                        <option value="">Selecciona un País</option>
                        <option value="MEX">México</option>
                        <option value="USA">Estados Unidos</option>
                    </select>
                </div>

                <div className="form__group form__group_selectField">
                    <label className="FormLabel" style={{ margin: "1rem 0" }}>Estado</label>
                    <select
                        className="SelectTrigger"
                        required
                        placeholder="estado"
                        onChange={handleUbicacionesChange}
                        name="estadoOrigen"
                        style={{ width: "100%" }}
                    >
                        <option value="">Selecciona un Estado</option>
                        {
                            estadosOrigen && estadosOrigen.length > 0 ?
                                estadosOrigen.map((estado) => (
                                    <option key={estado.Value} value={estado.Value}>{estado.Name}</option>
                                )) : ""
                        }
                    </select>
                </div>
            </div>

            <div className="form__group_form-select">
                <div className="form__group form__group_selectField">
                    <label className="FormLabel" style={{ margin: "1rem 0" }}>Municipio</label>
                    <select
                        className="SelectTrigger"
                        required
                        name="municipioOrigen"
                        onChange={handleUbicacionesChange}
                        placeholder="Razón Social"
                        style={{ width: "100%" }}
                    >
                        <option value="">Selecciona un Municipio</option>
                        {
                            municipiosOrigen && municipiosOrigen.length > 0 ?
                                municipiosOrigen.map((municipio) => (
                                    <option key={municipio.Value} value={municipio.Value}>{municipio.Name}</option>
                                )) : ""
                        }
                    </select>
                </div>

                <div className="form__group form__group_selectField">
                    <label className="FormLabel" style={{ margin: "1rem 0" }}>Código Postal</label>
                    <input
                        className="Input boxSizing__bb"
                        type="text"
                        name='cpOrigen'
                        onChange={handleUbicacionesChange}
                        required
                        placeholder="Codigo Postal"
                    />
                </div>
            </div>

            <div className="form__group_form-select">
                <div className="form__group form__group_selectField">
                    <label className="FormLabel" style={{ margin: "1rem 0" }}>Colonia</label>
                    <select
                        className="SelectTrigger"
                        required
                        placeholder="Razón Social"
                        name="coloniaOrigen"
                        onChange={handleUbicacionesChange}

                        style={{ width: "100%" }}
                    >
                        <option value="">Selecciona una Colonia</option>
                        {
                            coloniasOrigen && coloniasOrigen.length > 0 ?
                                coloniasOrigen.map((colonia) => (
                                    <option key={colonia.Value} value={colonia.Value}>{colonia.Name}</option>
                                )) : ""
                        }
                    </select>
                </div>
            </div>

            <h5 style={{ margin: "2rem 0 0rem 0" }}>Destino</h5>
            <div className="form__group_form-select">
                <div className="form__group form__group_selectField">
                    <label className="FormLabel" style={{ margin: "1rem 0" }}>Fecha y Hora de Salida</label>
                    <input
                        className="Input boxSizing__bb"
                        type="datetime-local"
                        name='fechaHoraDest'
                        onChange={handleUbicacionesChange}

                        required
                        placeholder="Fecha y hora del destino"
                    />

                </div>

                <div className="form__group form__group_selectField">
                    <label className="FormLabel" style={{ margin: "1rem 0" }}>RFC del Destino</label>
                    <input
                        className="Input boxSizing__bb"
                        type="text"
                        name='rfcDest'
                        onChange={handleUbicacionesChange}
                        required
                        placeholder="RFC del Destino"
                    />
                </div>
            </div>

            <div className="form__group_form-select">
                <div className="form__group form__group_selectField">
                    <label className="FormLabel" style={{ margin: "1rem 0" }}>País</label>
                    <select
                        className="SelectTrigger"
                        required
                        placeholder="paisDest"
                        name="paisDest"
                        style={{ width: "100%" }}
                        onChange={handleUbicacionesChange}
                    >
                        <option value="">Selecciona un País</option>
                        <option value="MEX">México</option>
                        <option value="USA">Estados Unidos</option>
                    </select>
                </div>

                <div className="form__group form__group_selectField">
                    <label className="FormLabel" style={{ margin: "1rem 0" }}>Estado</label>
                    <select
                        className="SelectTrigger"
                        required
                        name="estadoDest"
                        style={{ width: "100%" }}
                        onChange={handleUbicacionesChange}

                    >
                        <option value="">Selecciona un Estado</option>
                        {
                            estadosDest && estadosDest.length > 0 ?
                                estadosDest.map((estado) => (
                                    <option key={estado.Value} value={estado.Value}>{estado.Name}</option>
                                )) : ""
                        }
                    </select>
                </div>
            </div>

            <div className="form__group_form-select">
                <div className="form__group form__group_selectField">
                    <label className="FormLabel" style={{ margin: "1rem 0" }}>Municipio</label>
                    <select
                        className="SelectTrigger"
                        required
                        name="municipioDest"
                        onChange={handleUbicacionesChange}

                        style={{ width: "100%" }}
                    >
                        <option value="">Selecciona un Municipio</option>
                        {
                            municipiosDest && municipiosDest.length > 0 ?
                                municipiosDest.map((municipio) => (
                                    <option key={municipio.Value} value={municipio.Value}>{municipio.Name}</option>
                                )) : ""
                        }
                    </select>
                </div>

                <div className="form__group form__group_selectField">
                    <label className="FormLabel" style={{ margin: "1rem 0" }}>Código Postal</label>
                    <input
                        className="Input boxSizing__bb"
                        type="text"
                        name='cpDest'
                        onChange={handleUbicacionesChange}

                        required
                        placeholder="Codigo Postal"
                    />
                </div>
            </div>

            <div className="form__group_form-select" style={{ marginBottom: "2rem" }}>
                <div className="form__group form__group_selectField">
                    <label className="FormLabel" style={{ margin: "1rem 0" }}>Colonia</label>
                    <select
                        className="SelectTrigger"
                        required
                        placeholder="Municipio"
                        name="coloniaDest"
                        onChange={handleUbicacionesChange}

                        style={{ width: "100%" }}
                    >
                        <option value="">Selecciona una Colonia</option>
                        {
                            coloniasDest && coloniasDest.length > 0 ?
                                coloniasDest.map((colonia) => (
                                    <option key={colonia.Value} value={colonia.Value}>{colonia.Name}</option>
                                )) : ""
                        }
                    </select>
                </div>
            </div>
            <hr></hr>
            <h4 style={{ margin: "2rem 0 1rem 0" }}>Mercancías</h4>
            <a
                href="http://pys.sat.gob.mx/PyS/catPyS.aspx"
                target="_blank"
            >
                Click aquí para ver las Claves de Productos y
                Servicios del SAT
            </a>
            <br></br>

            Para conocer las claves de material peligroso y claves de unidad, ve el <br></br>
            <a
                href="http://omawww.sat.gob.mx/tramitesyservicios/Paginas/documentos/CatalogosCartaPorte30.xls"
                target="_blank"
            >
                Catálago de Carta Porte 3.0
            </a>

            <br></br>
            <a
                href="http://omawww.sat.gob.mx/tramitesyservicios/Paginas/documentos/c_FraccionArancelaria_20231219.xls"
                target="_blank"
            >
                Catálogo de Fracciones Arancelarias SAT
            </a>

            <div
                className="cartaPorte__group_elements_checkbox_parent"
            >
                <div className="cartaPorte__group_elements_checkbox">
                    <Checkbox.Root
                        className="CheckboxRoot"
                        checked={isTransInt === "Sí" ? true : false}
                        onCheckedChange={handleTranspInt}

                    >
                        <Checkbox.Indicator className="CheckboxIndicator">
                            <CheckIcon />
                        </Checkbox.Indicator>
                    </Checkbox.Root>

                    <label style={{ marginLeft: "1rem" }} className="FormLabel" htmlFor="c1">
                        Transportación Internacional
                    </label>
                </div>


                <div className="cartaPorte__group_elements_checkbox">
                    <Checkbox.Root
                        className="CheckboxRoot"
                        checked={logInversa === "Sí" ? true : false}
                        onCheckedChange={handleLogInversa}
                    >
                        <Checkbox.Indicator className="CheckboxIndicator">
                            <CheckIcon />
                        </Checkbox.Indicator>
                    </Checkbox.Root>
                    <label style={{ marginLeft: "1rem" }} className="FormLabel" htmlFor="c1">
                        Logística Inversa Recolección Devolución
                    </label>
                </div>
            </div>
            {
                isTransInt === "Sí" ? (
                    <>

                        <div className="form__group_form-select">
                            <div className="form__group form__group_selectField" style={{
                                marginBottom: "2.3rem"
                            }}>
                                <label className="FormLabel" htmlFor="viaEntradaSalida">
                                    Via de Entrada/Salida de Mercancía
                                </label>
                                <select
                                    required
                                    className="SelectTrigger"
                                    onChange={handleEnvioInt}
                                    name="viaEntradaSalida"
                                    style={{ width: "100%" }}
                                >
                                    <option value="" >
                                        Escoge una opción
                                    </option>
                                    <option value="01">Autotransporte</option>
                                    <option value="02">Transporte Marítimo</option>
                                    <option value="03">Transporte Aéreo</option>
                                    <option value="04">
                                        Transporte Ferroviario
                                    </option>
                                </select>
                            </div>


                            <div className="form__group form__group_selectField" style={{
                                marginBottom: "2.3rem"
                            }}>
                                <label className="FormLabel" htmlFor="name">
                                    Entrada/Salida de Mercancía
                                </label>
                                <select
                                    required

                                    className="SelectTrigger"
                                    onChange={handleEntradaSalida}
                                    value={entradaSalida}
                                    style={{ width: "100%" }}
                                >
                                    <option value="" >
                                        Escoge una opción
                                    </option>
                                    <option value="Entrada">Entrada</option>
                                    <option value="Salida">Salida</option>
                                </select>
                            </div>


                        </div>


                        <div className="form__group_form-select">
                            <div className="form__group form__group_selectField" style={{
                                marginBottom: "2.3rem"
                            }}>
                                <label className="FormLabel" htmlFor="regimenAduanero">
                                    Regimen Aduanero
                                </label>
                                <select
                                    required

                                    className="SelectTrigger"
                                    onChange={handleEnvioInt}
                                    name="regimenAduanero"
                                    style={{ width: "100%" }}
                                >
                                    <option value="" >
                                        Escoge una opción
                                    </option>
                                    {
                                        entradaSalida === "Entrada" ? (
                                            <>
                                                <option value="IMD">
                                                    IMD- Definitivo de Importación
                                                </option>
                                                <option value="ITR">
                                                    ITR Temporales de importación para retomar al
                                                    extranjero en el mismo estado.
                                                </option>
                                                <option value="ITE">
                                                    ITE Temporales de importación para elaboración,
                                                    transformación o reparación para empresas con
                                                    programa IMMEX.
                                                </option>

                                            </>


                                        ) : ""
                                    }

                                    {
                                        entradaSalida === "Salida" ? (
                                            <>
                                                <option value="EXD">
                                                    EXD Definitivo de exportación.
                                                </option>

                                                <option value="ETR">
                                                    ETR Temporales de exportación para retornar al
                                                    país en el mismo estado.
                                                </option>
                                                <option value="ETE">
                                                    ETE Temporales de exportación para elaboración,
                                                    transformación o reparación.
                                                </option>
                                            </>
                                        ) : ""

                                    }

                                    <option value="DFI">DFI Depósito Fiscal.</option>
                                    <option value="RFE">
                                        RFE Elaboración, transformación o reparación en
                                        recinto fiscalizado.
                                    </option>
                                    <option value="RFS">
                                        RFS Recinto fiscalizado estratégico.
                                    </option>
                                    <option value="TRA">TRA Tránsitos.</option>
                                </select>
                            </div>


                            <div className="form__group form__group_selectField" style={{
                                marginBottom: "2.3rem"
                            }}>
                                <label className="FormLabel" htmlFor="paisOrigenDestino">
                                    País de Origen del Destino
                                </label>
                                <select
                                    className="SelectTrigger"
                                    onChange={handleEnvioInt}
                                    required

                                    name="paisOrigenDestino"
                                    style={{ width: "100%" }}
                                >
                                    <option value="" >
                                        Escoge una opción
                                    </option>
                                    <option value="MEX" name="México">
                                        México
                                    </option>
                                    <option value="USA" name="Estados Unidos">
                                        Estados Unidos
                                    </option>
                                </select>
                            </div>


                        </div>
                    </>




                ) : ""
            }


            {mercancias.map((mercancia, index) => (

                <div key={index}>
                    {index > 0 ? (<hr></hr>) : ""}

                    <h4 style={{ display: "flex", justifyContent: "space-between" }}> Mercancía {index + 1} {
                        index >= 0 ? (<FaMinus onClick={(e) => handleDeleteMercancia(index)} className="deleteMercancia" style={{ width: "15px" }} />) : ""
                    }</h4>

                    <MercanciasRead
                        index={index}
                        mercancia={mercancia}
                        handleInputChange={handleInputChange}
                        handleMaterialPeligroso={handleMaterialPeligrosoChange}
                        isTransInt={isTransInt}
                        entradaSalida={entradaSalida}
                        formaFarmaceutica={formaFarmaceutica}
                        condicionesEspeciales={condicionesEspeciales}
                        embalajeData={embalajeData}
                    >
                    </MercanciasRead>
                </div>
            ))}


            {/* Botón para agregar más mercancías */}
            <button style={{ margin: "2rem 0" }} className="Button " onClick={handleAddMerch}>
                Agregar mercancía
            </button>

            <hr></hr>
            <h4 style={{ margin: "2rem 0 1rem 0" }}>AutoTransporte</h4>

            <div className="form__group_form-select">
                <div className="form__group form__group_selectField">
                    <label className="FormLabel" htmlFor="unidadTransporte">
                        Unidad de Transporte
                    </label>
                    <select
                        className="SelectTrigger"
                        onChange={handleTransportIdChange}
                        value={transport}
                        name="unidadTransporte"
                        style={{ width: "100%" }}
                    >
                        <option value="" disabled>
                            Selecciona una Unidad
                        </option>
                        {
                            transportsData && transportsData.length > 0 ?
                                transportsData.map((transport) => (
                                    <option key={transport._id} value={transport._id}>{transport.numUnidad}</option>
                                )) : ""
                        }
                    </select>
                </div>
            </div>
            {

                transport && transport !== "" ? (
                    <>
                        {/* autoTransporte */}
                        <div className="form__group_form-select">
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="permisoSCT">
                                    Tipo de Permiso de SCT
                                </label>
                                <select
                                    className="SelectTrigger"
                                    onChange={handleTransportIdChange}
                                    name="permisoSCT"
                                    value={autoTransporte[0]?.permisoSCT}
                                    style={{ width: "100%" }}
                                >
                                    <option value="">
                                        Selecciona una Unidad
                                    </option>
                                    {
                                        permisosSCT && permisosSCT.length > 0 ?
                                            permisosSCT.map((permisoSCT) => (
                                                <option key={permisoSCT._id} value={permisoSCT.Clave}>{permisoSCT.permisoSCT}</option>
                                            )) : ""
                                    }
                                </select>
                            </div>
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="numPermisoSCT">
                                    Número de Permiso SCT
                                </label>
                                <input
                                    className="Input boxSizing__bb"
                                    name="numPermisoSCT"
                                    onChange={handleTransportIdChange}
                                    value={autoTransporte[0]?.numPermisoSCT}
                                    style={{ width: "100%" }}
                                >
                                </input>
                            </div>

                            {/* autoTransporte */}


                        </div>

                        <h5 style={{ margin: "2rem 0 1rem 0" }}>Identificacion Vehicular</h5>

                        <div className="form__group_form-select">
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="confVehicular">
                                    Configuración Vehícular
                                </label>
                                <select
                                    className="SelectTrigger"
                                    name="confVehicular"
                                    onChange={handleTransportIdChange}
                                    value={autoTransporte[0]?.confVehicular}
                                    style={{ width: "100%" }}
                                >
                                    <option value="" disabled>
                                        Selecciona una Configuración Vehicular
                                    </option>
                                    {
                                        configuracionVehic && configuracionVehic.length > 0 ?
                                            configuracionVehic.map((permisoSCT) => (
                                                <option key={permisoSCT._id} value={permisoSCT.Clave}>{permisoSCT.confVehicular}</option>
                                            )) : ""
                                    }
                                </select>
                            </div>
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="name">
                                    Peso Bruto Vehicular (KG)
                                </label>
                                <input
                                    className="Input boxSizing__bb"
                                    name="pesoVehiculo"
                                    onChange={handleTransportIdChange}
                                    value={autoTransporte[0]?.pesoVehiculo}
                                    style={{ width: "100%" }}
                                >
                                </input>
                            </div>
                            {/* autoTransporte */}
                        </div>

                        <div className="form__group_form-select">
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="placaVehiculo">
                                    Placa del vehículo
                                </label>
                                <input
                                    className="Input boxSizing__bb"
                                    minLength={5}
                                    maxLength={7}
                                    name="placaVehiculo"
                                    onChange={handleTransportIdChange}
                                    value={autoTransporte[0]?.placaVehiculo}
                                    style={{ width: "100%" }}
                                >
                                </input>
                            </div>
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="anioVehiculo">
                                    Año del vehículo
                                </label>
                                <input
                                    className="Input boxSizing__bb"
                                    name="anioVehiculo"
                                    onChange={handleTransportIdChange}
                                    value={autoTransporte[0]?.anioVehiculo}
                                    style={{ width: "100%" }}
                                >
                                </input>
                            </div>
                            {/* autoTransporte */}
                        </div>
                        <hr></hr>
                        <h4 style={{ margin: "2rem 0 1rem 0" }}>Seguros</h4>
                        <div className="form__group_form-select">
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="nombreSeguro">
                                    Aseguradora Responsable Civil
                                </label>
                                <input
                                    className="Input boxSizing__bb"
                                    name="nombreSeguro"
                                    onChange={handleTransportIdChange}
                                    value={autoTransporte[0]?.nombreSeguro}
                                    style={{ width: "100%" }}
                                >
                                </input>
                            </div>

                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="numPoliza">
                                Poliza Responsable Civil 
                                </label>
                                <input
                                    className="Input boxSizing__bb"
                                    name="numPoliza"
                                    onChange={handleTransportIdChange}
                                    value={autoTransporte[0]?.numPoliza}
                                    style={{ width: "100%" }}
                                >
                                </input>
                            </div>
                            {/* autoTransporte */}
                        </div>
                        {
                            autoTransporte[0]?.medAmbiente ? (
                                <div className="form__group_form-select">
                                    <div className="form__group form__group_selectField">
                                        <label className="FormLabel" htmlFor="aseguraMedAmbiente">
                                            Aseguradora Medio Ambiente
                                        </label>
                                        <input
                                            className="Input boxSizing__bb"
                                            name="aseguraMedAmbiente"
                                            onChange={handleTransportIdChange}
                                            value={autoTransporte[0]?.aseguraMedAmbiente}
                                            style={{ width: "100%" }}
                                        >
                                        </input>
                                    </div>

                                    <div className="form__group form__group_selectField">
                                        <label className="FormLabel" htmlFor="polizaMedAmbiente">
                                        Poliza Medio Ambiente 
                                        </label>
                                        <input
                                            className="Input boxSizing__bb"
                                            name="polizaMedAmbiente"
                                            onChange={handleTransportIdChange}
                                            value={autoTransporte[0]?.polizaMedAmbiente}
                                            style={{ width: "100%" }}
                                        >
                                        </input>
                                    </div>
                                    {/* autoTransporte */}
                                </div>

                            ) : ""
                        }


                        {
                            autoTransporte[0]?.remolqueRequerido ? (
                                <>
                                    <hr></hr>
                                    <h4 style={{ margin: "2rem 0 1rem 0" }}>Remolques</h4>
                                    <div className="form__group_form-select">
                                        <div className="form__group form__group_selectField">
                                            <label className="FormLabel" htmlFor="SubTipoRem">
                                                SubTipo de Remolque
                                            </label>
                                            {/* <input
                                    className="Input boxSizing__bb"
                                    name="SubTipoRem"
                                    onChange={handleRemolqueChange}
                                    value={remolque.SubTipoRem}
                                    style={{ width: "100%" }}
                                >
                                </input> */}
                                            <select
                                                className="SelectTrigger"
                                                name="SubTipoRem"
                                                onChange={handleRemolqueChange}
                                                value={remolque.SubTipoRem}
                                                required
                                                style={{ width: "100%" }}
                                            >
                                                <option value="">
                                                    Selecciona un remolque
                                                </option>
                                                <option value="CTR001">CTR001 Caballete</option>
                                                <option value="CTR002">CTR002 Caja</option>
                                                <option value="CTR003">
                                                    CTR003 Caja Abierta
                                                </option>
                                                <option value="CTR004">
                                                    CTR004 Caja Cerrada
                                                </option>
                                                <option value="CTR005">
                                                    CTR005 Caja De Recolección Con Cargador Frontal
                                                </option>
                                                <option value="CTR006">
                                                    CTR006 Caja Refrigerada
                                                </option>
                                                <option value="CTR007">CTR007 Caja Seca</option>
                                                <option value="CTR008">
                                                    CTR008 Caja Transferencia
                                                </option>
                                                <option value="CTR009">
                                                    CTR009 Cama Baja o Cuello Ganso
                                                </option>
                                                <option value="CTR010">
                                                    CTR010 Chasis Portacontenedor
                                                </option>
                                                <option value="CTR011">
                                                    CTR011 Convencional De Chasis
                                                </option>
                                                <option value="CTR012">
                                                    CTR012 Equipo Especial
                                                </option>
                                                <option value="CTR013">CTR013 Estacas</option>
                                                <option value="CTR014">
                                                    CTR014 Góndola Madrina
                                                </option>
                                                <option value="CTR015">
                                                    CTR015 Grúa Industrial
                                                </option>
                                                <option value="CTR016">CTR016 Grúa</option>
                                                <option value="CTR017">CTR017 Integral</option>
                                                <option value="CTR018">CTR018 Jaula</option>
                                                <option value="CTR019">
                                                    CTR019 Media Redila
                                                </option>
                                                <option value="CTR020">
                                                    CTR020 Pallet o Celdillas
                                                </option>
                                                <option value="CTR021">CTR021 Plataforma</option>
                                                <option value="CTR022">
                                                    CTR022 Plataforma Con Grúa
                                                </option>
                                                <option value="CTR023">
                                                    CTR023 Plataforma Encortinada
                                                </option>
                                                <option value="CTR024">CTR024 Redilas</option>
                                                <option value="CTR025">
                                                    CTR025 Refrigerador
                                                </option>
                                                <option value="CTR026">CTR026 Revolvedora</option>
                                                <option value="CTR027">CTR027 Semicaja</option>
                                                <option value="CTR028">CTR028 Tanque</option>
                                                <option value="CTR029">CTR029 Tolva</option>
                                                <option value="CTR031">CTR031 Volteo</option>
                                                <option value="CTR032">
                                                    CTR032 Volteo Desmontable
                                                </option>
                                            </select>

                                        </div>

                                        <div className="form__group form__group_selectField">
                                            <label className="FormLabel" htmlFor="Placa">
                                                Placa de la Caja
                                            </label>
                                            <input
                                                className="Input boxSizing__bb"
                                                minLength={5}
                                                maxLength={7}
                                                name="Placa"
                                                onChange={handleRemolqueChange}
                                                value={remolque.Placa}
                                                style={{ width: "100%" }}
                                            >
                                            </input>
                                        </div>
                                        {/* autoTransporte */}
                                    </div>
                                </>
                            ) : ""
                        }


                    </>



                ) : ""

            }


            <hr></hr>
            <h4 style={{ margin: "2rem 0 1rem 0" }}>Figura Transporte</h4>
            <div className="form__group_form-select">
                <div className="form__group form__group_selectField">
                    <label className="FormLabel" htmlFor="driver">
                        Operador
                    </label>
                    <select
                        className="SelectTrigger"
                        name="driver"
                        onChange={handleDriverSelected}
                        style={{ width: "100%" }}
                        value={driverSelected}
                    >
                        <option value="" disabled>
                            Selecciona un Operador
                        </option>
                        {
                            driversData && driversData.length > 0 ?
                                driversData.map((driver) => (
                                    <option key={driver._id} value={driver._id}>{driver.nombreAlternativo}</option>
                                )) : ""
                        }
                    </select>
                </div>
            </div>

            {
                driverSelected && driverSelected !== "" ? (
                    <>
                        <div className="form__group_form-select">
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="driver">
                                    Tipo de Figura
                                </label>
                                <select
                                    className="SelectTrigger"
                                    name="driver"
                                    onChange={handleDriverSelected}
                                    style={{ width: "100%" }}
                                    value="01"
                                    disabled
                                >
                                    <option value="01" disabled>
                                        Operador
                                    </option>
                                    <option value="02">Propietario</option>
                                    <option value="03">Arrendador</option>
                                    <option value="04">Notificado</option>
                                </select>
                            </div>
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="RFCFigura">
                                    RFC Figura
                                </label>
                                <input
                                    className="Input boxSizing__bb"
                                    name="RFCFigura"
                                    onChange={handleDriverSelected}
                                    style={{ width: "100%" }}
                                    value={figuraTransporte[0]?.RFCFigura}
                                />

                            </div>
                        </div>

                        <div className="form__group_form-select">
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="numLicencia">
                                    Número de Licencia
                                </label>
                                <input
                                    className="Input boxSizing__bb"
                                    minLength={6}
                                    maxLength={16}
                                    name="numLicencia"
                                    onChange={handleDriverSelected}
                                    style={{ width: "100%" }}
                                    value={figuraTransporte[0]?.numLicencia}
                                />

                            </div>
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="name">
                                    Nombre de Figura
                                </label>
                                <input
                                    className="Input boxSizing__bb"
                                    name="name"
                                    onChange={handleDriverSelected}
                                    style={{ width: "100%" }}
                                    value={figuraTransporte[0]?.name}
                                />

                            </div>
                        </div>
                        <h5 style={{ margin: "2rem 0" }}>Domicilio de la Figura</h5>

                        <div className="form__group_form-select">
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="numLicencia">
                                    Calle
                                </label>
                                <input
                                    disabled
                                    className="Input boxSizing__bb"
                                    name="numLicencia"
                                    onChange={handleDriverSelected}
                                    style={{ width: "100%" }}
                                    value={figuraTransporte[0]?.calle}
                                />
                            </div>
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="numExt">
                                    Número Exterior
                                </label>
                                <input
                                    disabled
                                    className="Input boxSizing__bb"
                                    name="numExt"
                                    onChange={handleDriverSelected}
                                    style={{ width: "100%" }}
                                    value={figuraTransporte[0]?.numExt}
                                />

                            </div>
                        </div>

                        <div className="form__group_form-select">
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="numInt">
                                    Número Interior
                                </label>
                                <input
                                    disabled
                                    className="Input boxSizing__bb"
                                    name="numInt"
                                    onChange={handleDriverSelected}
                                    style={{ width: "100%" }}
                                    value={figuraTransporte[0]?.numInt}
                                />
                            </div>
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="referencia">
                                    Referencia
                                </label>
                                <input
                                    disabled

                                    className="Input boxSizing__bb"
                                    name="referencia"
                                    onChange={handleDriverSelected}
                                    style={{ width: "100%" }}
                                    value={figuraTransporte[0]?.referencia}
                                />
                            </div>
                        </div>

                        <div className="form__group_form-select">
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="numInt">
                                    Municipio
                                </label>
                                <input
                                    disabled
                                    className="Input boxSizing__bb"
                                    name="numInt"
                                    onChange={handleDriverSelected}
                                    style={{ width: "100%" }}
                                    value={nameMunicipio}
                                />
                            </div>
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="referencia">
                                    Estado
                                </label>
                                <input
                                    disabled

                                    className="Input boxSizing__bb"
                                    name="referencia"
                                    onChange={handleDriverSelected}
                                    style={{ width: "100%" }}
                                    value={nameEstado}
                                />
                            </div>
                        </div>

                        <div className="form__group_form-select">
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="numInt">
                                    País
                                </label>
                                <select
                                    className="SelectTrigger boxSizing__bb"
                                    name="numInt"
                                    disabled
                                    onChange={handleDriverSelected}
                                    style={{ width: "100%" }}
                                    value={figuraTransporte[0]?.pais}
                                >
                                    <option value="">Selecciona un País</option>
                                    <option value="MEX">México</option>
                                    <option value="USA">Estados Unidos</option>
                                </select>
                            </div>
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="codigoPostal">
                                    Código Postal
                                </label>
                                <input
                                    disabled
                                    className="Input boxSizing__bb"
                                    name="codigoPostal"
                                    onChange={handleDriverSelected}
                                    style={{ width: "100%" }}
                                    value={figuraTransporte[0]?.codigoPostal}
                                />
                            </div>
                        </div>

                        <div className="form__group_form-select">
                            <div className="form__group form__group_selectField">
                                <label className="FormLabel" htmlFor="numInt">
                                    Colonia
                                </label>
                                <input
                                    disabled
                                    className="Input boxSizing__bb"
                                    name="numInt"
                                    onChange={handleDriverSelected}
                                    style={{ width: "100%" }}
                                    value={nameColonia}
                                />
                            </div>
                        </div>
                    </>
                ) : ""
            }
        </div>
    )
}
