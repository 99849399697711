import "../../styles/alert.css";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@radix-ui/themes/styles.css";
import * as Tabs from "@radix-ui/react-tabs";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import * as Dialog from "@radix-ui/react-dialog";
import { EyeNoneIcon, EyeOpenIcon, CrossCircledIcon } from "@radix-ui/react-icons"
import SyncLoader from "react-spinners/ClipLoader";
import { Cross2Icon } from "@radix-ui/react-icons";
import * as Checkbox from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";

import {
  Card,
  Text
} from "@radix-ui/themes";
import "../../index.css";
import { ThemeProvider } from "next-themes";
import { API_URL } from "../../constants";
import { PageInfo } from "../PageInfo";

function RegistroUsuarios() {
  const dt = useRef(null);
  const [usuarios, setUsuarios] = useState("");

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [apellido, setApellido] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rol, setRol] = useState("");
  const [estado, setEstado] = useState("")
  const [showPassword, setShowPassword] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [docs, setDocs] = useState("");

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [userToDelete, setuserToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isDialogEditOpen, setIsDialogEditOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState([]);
  const [empresa, setEmpresa] = useState([]);

  const [empresaDattMX,setEmpresaDattMX] = useState(false);
  const [empresaDattImports,setEmpresaDattImports] = useState(false);
  const [empresaDattEmpresa,setEmpresaDattEmpresa] = useState(false);

  const [empresaDattMXEdit,setEmpresaDattMXEdit] = useState(false);
  const [empresaDattImportsEdit,setEmpresaDattImportsEdit] = useState(false);
  const [empresaDattEmpresaEdit,setEmpresaDattEmpresaEdit] = useState(false);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    rol: { value: null, matchMode: FilterMatchMode.IN },
    password: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const [empresasEdit, setEmpresasEdit] = useState([]);


  const notifySuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const validateUsers = () => {
    if (name.trim() === "") {
      notifyError("El nombre del usuario no puede estar vacío");
      return false;
    }
    if (password.trim() === "") {
      notifyError("El rol del usuario no puede estar vacío");
      return false;
    }
    if (email.trim() === "") {
      notifyError("El email no puede estar vacío");
      return false;
    }

    if (rol.trim() === "") {
      notifyError("El rol del usuario no puede estar vacío");
      return false;
    }
    if (estado.trim() === "") {
      notifyError("El estado no puede estar vacío");
      return false;
    }
    if (empresa.length <= 0) {
      notifyError("Selecciona una empresa");
      return false;
    }


  }

  async function registerUser(event) {
    event.preventDefault();
    setIsLoading(true);

    let validatedUsers = validateUsers();
    if (validatedUsers === false) {
      setIsLoading(false);
      return false;
    }

    const form = document.getElementById("formRegister");
    let newName = `${name} ${apellido}`;
    try {
      const response = await fetch(`${API_URL}/api/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          rol,
          name: newName,
          email,
          password,
          status: estado,
          empresa
        }),
      });

      const data = await response.json();

      if (data.status === "ok") {
        notifySuccess("El usuario ha sido registrado exitosamente");
        setRol("");
        setName("");
        setEmail("");
        setPassword("");
        setEmpresaDattEmpresa(false);
        setEmpresaDattMX(false);
        setEmpresaDattImports(false);
        setEmpresa([]);
        generateRandomPassword("");
        setEstado("");
        setApellido("");
        setIsLoading(false);
      } else if (
        data.status === "Error" &&
        data.error === "El email ya existe."
      ) {
        notifyError("El email ya se encuentra registrado");
        setIsLoading(false);
      }
    } catch (error) {
      notifyError("No se pudo registrar al usuario");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    generateRandomPassword();

    fetchUsersData();

  }, []);

  const fetchUsersData = async () => {
    const response = await fetch(`${API_URL}/api/users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    setUsuarios(data.response);
  };

  const generateRandomPassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const passwordLength = 8;
    let newPassword = "";
    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      newPassword += characters.charAt(randomIndex);
    }

    setPassword(newPassword);
  };

  const confirmDeleteUser = (rowData) => {
    setuserToDelete(rowData);
    setIsDialogOpen(true);
  };

  const onRowEditComplete = (e) => {
    let { data, newData } = e;
    updateUserInDatabase(newData);
  }

  const updateUserInDatabase = async (user) => {
    setIsLoading(true);
    user.empresa =  empresa;
    console.log("user a mandar",user);
    try {
      let resp = await fetch(`${API_URL}/api/users/${user.uid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });


      const data = await resp.json();
      setIsLoading(true);

      if (data.status === "ok") {
        notifyUpdate();
        fetchUsersData();
        setIsDialogEditOpen(false);
        setIsLoading(false);
        setEmpresa([]);
        setEmpresaDattEmpresa(false);
        setEmpresaDattMX(false);
        setEmpresaDattImports(false);

      } else {
        setIsLoading(false);
        notifyError("Los datos no pudieron ser actualizados");
      }
      return data;
    } catch (err) {
      setIsLoading(false);
      console.error("Error al actualizar al usuario:", err);
      notifyError("Error al actualizar el usuario");
    }

  }

  const notifyUpdate = () => {
    toast.success("¡Datos actualizados!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };


  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            style={{ marginLeft: "30px" }}
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar"
          />
        </span>
      </div>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        className="Input"
      />
    );
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(usuarios);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "usuarios");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const handleDeleteConfirmation = () => {
    if (userToDelete) {
      deleteUserFromDatabase(userToDelete.uid);
    }
    setIsDialogOpen(false);
  };

  //DELETE user
  const deleteUserFromDatabase = async (userId) => {
    try {
      const response = await fetch(`${API_URL}/api/users/${userId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        notifyDeleteSuccess();
        fetchUsersData();
      } else {
        // La solicitud no fue exitosa, maneja el error adecuadamente
        const errorData = await response.json();
        console.error("Error al eliminar al usuario:", errorData.error);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const notifyDeleteSuccess = () => {
    toast.success("¡El usuario fue eliminado correctamente!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };


  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          className="pi pi-trash btn__pi"
          severity="danger"
          onClick={() => confirmDeleteUser(rowData)}
          style={{ color: "red" }}
        />
      </React.Fragment>
    );
  };

  const bodyEditTemplate = (data) => {
    return (
      <Button
        className="pi pi-pencil btn__pi"
        onClick={() => { 
          setIsDialogEditOpen(true); 
          console.log("data", data); 
          setUserToEdit(data) 
          if(data.empresa.includes("DATT - MX")){
            setEmpresaDattMX(true);
          } 
          if(data.empresa.includes("DATT - Imports")){
            setEmpresaDattImports(true);
          } 
          if(data.empresa.includes("DATT - Empresa")){
            setEmpresaDattEmpresa(true);
          }
          setEmpresa(data.empresa);
        }}
      />
    )
  }

  function getStatusColor(estatus) {
    switch (estatus) {
      case "En revisión":
        return "blue";
      case "Aprobado":
        return "green";
      case "Rechazado":
        return "red";
      default:
        return "gray";
    }
  }

  const selectStatusEditor = (options) => {
    return (
      <select
        className="SelectTrigger"
        defaultValue={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        style={{ width: "130px" }}
      >
        <option value="Activo">Activo</option>
        <option value="Inactivo">Inactivo</option>
      </select>
    );
  };

  const selectArchivos = (options) => {
    {/*Docs form field */ }
    return (
      <input
        className="Input boxSizing__bb"
        type="file"
        required
        multiple
        placeholder="Selecciona un archivo"
        onChange={(e) => setDocs(e.target.files)}
        style={{ flex: "none" }}
      />
    )
  }

  const selectRolEditor = (options) => {

    return (
      <select
        className="SelectTrigger"
        defaultValue={options.value == "Finanzas" ? 1 : options.value == "Operativo" ? 2 : 3}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        style={{ width: "150px" }}
      >

        <option value="1">Finanzas</option>
        <option value="2">Operativo</option>
        <option value="3">Administrador</option>
      </select>
    );


  }

  const handleEditUsuario = (e) => {
    e.preventDefault();
    updateUserInDatabase(userToEdit);
    
  }
  const handleEditChange = (e) => {
    console.log("User to edit",userToEdit);
    console.log("EMpresa",empresa);
  
    setUserToEdit({
      ...userToEdit,
      [e.target.name]: e.target.value
    })
  }

  return (
    <div>
      <div className="content-wrapper ">
        <div className="container__block ">
          <PageInfo heading={"DATT - Tracking | Registrar usuarios"} />
          <Tabs.Root className="TabsRoot" defaultValue="tab1">
            <Tabs.List className="TabsList" aria-label="Registra un chofer">
              <Tabs.Trigger className="TabsTrigger" value="tab1">
                Registrar Usuario
              </Tabs.Trigger>
              <Tabs.Trigger className="TabsTrigger" value="tab2" onClick={async () => await fetchUsersData()}>
                Ver Usuarios
              </Tabs.Trigger>
            </Tabs.List>
            <Tabs.Content
              className="TabsContent"
              value="tab1"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >

              {/* <Card
                style={{
                  width: "100%",
                  margin: "auto",
                  padding: "20px",
                  marginTop: "2rem",
                  backgroundColor: "white",
                  border: "none",
                  borderRadius: "5px",
                  boxShadow: "0 2px 10px var(--black-a4)"
                }}
              > */}
              <form
                onSubmit={registerUser}
                id="formRegister"
                className="FormLabel container__width_70"
              >
                <div className="data__group">
                  <h4>Datos del Usuario</h4>
                  <div className="data__group_form">

                    <div className="form__group_form-select">

                      <div className="data__group_form_content form__group_selectField" style={{ marginBottom: "15px" }}>
                        <label htmlFor="name" className="LabelForm">

                          Nombre:
                        </label>
                        <br />
                        <input
                          className="Input"
                          id="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          placeholder="Nombre"
                        />
                      </div>

                      <div className="data__group_form_content form__group_selectField" style={{ marginBottom: "15px" }}>
                        <label htmlFor="apellido" className="LabelForm">
                          Apellido:
                        </label>
                        <br />
                        <input
                          className="Input"
                          id="apellido"
                          value={apellido}
                          onChange={(e) => setApellido(e.target.value)}
                          type="text"
                          placeholder="Apellido"
                        />
                      </div>
                    </div>

                    <div className="form__group_form-select">
                      <div className="data__group_form_content form__group_selectField" style={{ marginBottom: "15px" }}>
                        <label htmlFor="email" className="LabelForm">
                          Correo electrónico
                        </label>
                        <br />
                        <input
                          className="Input"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          placeholder="Correo eléctronico"
                        />
                      </div>

                      <div className="inputPassword data__group_form_content form__group_selectField">
                        <label htmlFor="password" className="LabelForm">
                          Contraseña
                        </label>
                        <div style={{ boxShadow: "0 0 1px hsl(252, 71.0%, 83.7%)", marginTop: ".33rem", height: "35px" }}>

                          <div className="inputPasswordControl">
                            <input type={showPassword ? "text" : "password"} value={password} onChange={(e) => setPassword(e.target.value)} />
                            <span className="passwordIcon">
                              {
                                showPassword ?
                                  (<EyeNoneIcon style={{ width: "1.3rem", height: "1.3rem" }} onClick={() => setShowPassword(!showPassword)} />) :
                                  (<EyeOpenIcon style={{ width: "1.3rem", height: "1.3rem" }} onClick={() => setShowPassword(!showPassword)} />)
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form__group_form-select">
                      <div className="data__group_form_content form__group_selectField" style={{ marginBottom: "15px" }}>
                        <label htmlFor="rol" className="LabelForm">
                          Rol:
                        </label>
                        <br />
                        <select
                          className="Input"
                          id="rol"
                          value={rol}
                          onChange={(e) => {
                            if (e.target.value === "3") {
                              setEmpresa(["DATT - MX", "DATT - Imports", "DATT - Empresa"]);
                              setEmpresaDattEmpresa(true);
                              setEmpresaDattImports(true);
                              setEmpresaDattMX(true);
                              console.log("empresas seteadas");
                            }else{
                              setEmpresa([]);
                              setEmpresaDattEmpresa(false);
                              setEmpresaDattImports(false);
                              setEmpresaDattMX(false);
                            }
                            setRol(e.target.value)
                          }
                          }
                          style={{ width: "100%", padding: "0 8px" }}
                        >
                          <option value="" disabled>
                            Seleccione un rol
                          </option>
                          <option value="1">Finanzas</option>
                          <option value="2">Operativo</option>
                          <option value="3">Administrador</option>
                        </select>
                      </div>

                      <div className="data__group_form_content form__group_selectField" style={{ marginBottom: "15px" }}>
                        <label htmlFor="email" className="LabelForm">

                          Estado:
                        </label>
                        <br />
                        <select
                          className="Input"
                          id="estado"
                          value={estado}
                          onChange={(e) => { console.log("Empresas", empresa); setEstado(e.target.value) }}
                          style={{ width: "100%", padding: "0 8px" }}
                        >
                          <option value="" disabled>
                            Seleccione un estado
                          </option>
                          <option value="Activo">Activo</option>
                          <option value="Inactivo">Inactivo</option>
                        </select>
                      </div>
                    </div>
                    <div className="form__group_form-select">
                      <div className="data__group_form_content form__group_selectField" style={{ marginBottom: "15px" }}>
                        <label htmlFor="email" className="LabelForm">
                          Empresa
                        </label>
                        <br />
                        <div className="cartaPorte__group_elements_checkbox">
                          <Checkbox.Root
                            className="CheckboxRoot"
                            checked={empresaDattMX}
                            onCheckedChange={(isChecked) => {
                              let empresas = empresa;
                              console.log("empresas", empresa);

                              if (isChecked) {
                                empresas.push("DATT - MX");
                                setEmpresa(empresas);
                                setEmpresaDattMX(true);
                              } else {
                                setEmpresaDattMX(false);
                                let foundEmpresa = empresas.filter((empresa) => empresa !== "DATT - MX");
                                console.log("Found Empresa", foundEmpresa);
                                setEmpresa(foundEmpresa);
                              }

                            }}

                          >
                            <Checkbox.Indicator className="CheckboxIndicator">
                              <CheckIcon />
                            </Checkbox.Indicator>
                          </Checkbox.Root>
                          <label style={{ marginLeft: "1rem", fontSize: "13px" }} className="FormLabel" htmlFor="c1">
                            DATT - MX
                          </label>
                        </div>
                        <div className="cartaPorte__group_elements_checkbox">
                          <Checkbox.Root
                            className="CheckboxRoot"
                            checked={empresaDattImports}
                            onCheckedChange={(isChecked) => {
                              let empresas = empresa;
                              console.log("empresas", empresa);
                              if (isChecked) {
                                empresas.push("DATT - Imports");
                                setEmpresa(empresas);
                                setEmpresaDattImports(true);
                              } else {
                                setEmpresaDattImports(false)
                                let foundEmpresa = empresas.filter((empresa) => empresa !== "DATT - Imports");
                                console.log("Found Empresa", foundEmpresa);
                                setEmpresa(foundEmpresa);

                              }

                            }}

                          >
                            <Checkbox.Indicator className="CheckboxIndicator">
                              <CheckIcon />
                            </Checkbox.Indicator>
                          </Checkbox.Root>
                          <label style={{ marginLeft: "1rem", fontSize: "13px" }} className="FormLabel" htmlFor="c1">
                            DATT - Imports
                          </label>
                        </div>
                        <div className="cartaPorte__group_elements_checkbox">
                          <Checkbox.Root
                            className="CheckboxRoot"
                            checked={empresaDattEmpresa}
                            onCheckedChange={(isChecked) => {
                              let empresas = empresa;
                              console.log("empresas", empresa);
                              if (isChecked) {
                                setEmpresaDattEmpresa(true)
                                empresas.push("DATT - Empresa");
                                setEmpresa(empresas);
                              } else {
                                setEmpresaDattEmpresa(false)
                                let foundEmpresa = empresas.filter((empresa) => empresa !== "DATT - Empresa");
                                console.log("Found Empresa", foundEmpresa);
                                setEmpresa(foundEmpresa);
                              }

                            }}

                          >
                            <Checkbox.Indicator className="CheckboxIndicator">
                              <CheckIcon />
                            </Checkbox.Indicator>
                          </Checkbox.Root>
                          <label style={{ marginLeft: "1rem", fontSize: "13px" }} className="FormLabel" htmlFor="c1">
                            DATT - Empresa
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="btn__container">
                      <button
                        type="submit"

                        disabled={isLoading}
                        className="btn__login"
                        style={{ width: "230px", display: "flex", justifyContent: "center", alignItems: "center" }}
                      >
                        {
                          isLoading ? (
                            <SyncLoader
                              color="white"
                              loading={isLoading}
                              size={20}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                              speedMultiplier={.7}
                            />

                          ) : "Registrar"
                        }
                      </button>

                    </div>
                  </div>


                </div>
              </form>

              <ToastContainer />
            </Tabs.Content>

            <Tabs.Content
              className="TabsContent"
              value="tab2"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h3>Usuarios registrados</h3>
              <header
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Button
                  type="button"
                  icon="pi pi-file-excel"
                  severity="success"
                  rounded
                  onClick={exportExcel}
                  data-pr-tooltip="XLS"
                  style={{ fontSize: "1.5rem", color: "green" }}
                />
              </header>
              <div className="card">
                <DataTable
                  value={usuarios}
                  header={renderHeader}
                  filters={filters}
                  paginator
                  ref={dt}
                  rows={10}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  tableStyle={{ minWidth: "50rem" }}
                  editMode="row"
                  dataKey="_id"
                  onRowEditComplete={onRowEditComplete}

                >
                  <Column field="_id" header="ID" hidden />
                  <Column
                    field="_id"
                    header="id"
                    hidden
                    editor={(options) => textEditor(options)}
                  />
                  <Column
                    field="name"
                    header="Nombre"
                    sortable
                    editor={(options) => textEditor(options)}
                  />
                  <Column
                    field="email"
                    header="Correo"
                    sortable
                    editor={(options) => textEditor(options)}
                  />
                  <Column
                    field="password"
                    header="Contraseña"
                    sortable
                    editor={(options) => textEditor(options)}
                  />
                  {/* <Column
                    field="rol"
                    header="Rol"
                    sortable
                    editor={(options) => textEditor(options)}
                  /> */}

                  {/* <Column
                    field="status"
                    header="Estatus"
                    sortable
                    editor={(options) => textEditor(options)}
                  /> */}

                  <Column
                    field="rol"
                    header="Rol"
                    sortable
                    editor={(options) => selectRolEditor(options)}
                  />


                  <Column
                    field="status"
                    header="Estatus"
                    sortable
                    editor={(options) => selectStatusEditor(options)}
                  />
                  {/*Edit btn */}
                  <Column

                    headerStyle={{ width: "20%", minWidth: "8rem" }}
                    bodyStyle={{ textAlign: "center" }}
                    body={bodyEditTemplate}

                  ></Column>
                  {/*Delete btn */}
                  <Column
                    body={actionBodyTemplate}
                    exportable={false}
                    style={{ minWidth: "12rem" }}
                  ></Column>


                  {/*Delete driver Dialog */}
                </DataTable>
              </div>

              <Dialog.Root
                open={isDialogOpen}
                onOpenChange={() => setIsDialogOpen(false)}
              >
                <Dialog.Portal>
                  <Dialog.Overlay className="DialogOverlay" />
                  <Dialog.Content
                    className="DialogContent"
                    style={{
                      height: "290px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "400px",
                      backgroundColor: "white"
                    }}>
                    <Dialog.Title className="DialogTitle" style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center"
                    }}>
                      <CrossCircledIcon
                        style={{
                          width: "78px",
                          height: "90px",
                          color: "red"
                        }}
                      />
                      Eliminar usuario
                    </Dialog.Title>
                    <Dialog.Description className="DialogDescription">
                      ¿Estás seguro de que deseas eliminar a <b>{userToDelete?.name} </b>?
                    </Dialog.Description>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 25,
                        justifyContent: "center",
                        gap: "20px",
                        marginTop: "14px"

                      }}
                    >
                      <Dialog.Close asChild>
                        <Button
                          className="EliminarButton"
                          onClick={handleDeleteConfirmation}
                        >
                          Eliminar
                        </Button>
                      </Dialog.Close>
                      <Dialog.Close asChild>
                        <Button
                          aria-label="Close"
                          className="CancelarButton"
                          style={{
                            backgroundColor: "gray"
                          }}
                        >
                          Cancelar
                        </Button>
                      </Dialog.Close>
                    </div>
                  </Dialog.Content>
                </Dialog.Portal>
              </Dialog.Root>

              <Dialog.Root
                open={isDialogEditOpen}
                onOpenChange={() => { setIsDialogEditOpen(false); 
                  setEmpresa([]); 
                  setEmpresaDattImports(false);
                  setEmpresaDattMX(false);
                  setEmpresaDattEmpresa(false);
                }}
              >
                <Dialog.Portal>
                  <Dialog.Overlay className="DialogOverlay" />
                  <Dialog.Content className="DialogContentCP dialogEdit" style={{ backgroundColor: "white" }}>
                    <Dialog.Title className="DialogTitle" >
                      Editar Usuario
                    </Dialog.Title>
                    <form onSubmit={(e) => handleEditUsuario(e)}>
                      <div className="dialogEditGroup">
                        <div className="dialogEditGroup_elements">
                          <label className="FormLabel" htmlFor="name">
                            Nombre
                          </label>
                          <input
                            className="InputForm"
                            type="text"
                            name="name"
                            onChange={handleEditChange}
                            required
                            value={userToEdit?.name}
                          ></input>
                        </div>

                        <div className="dialogEditGroup_elements">
                          <label className="FormLabel" htmlFor="name">
                            Email
                          </label>
                          <input
                            className="InputForm"
                            type="email"
                            name="email"
                            onChange={handleEditChange}
                            required
                            value={userToEdit?.email}
                          ></input>
                        </div>

                        <div className="dialogEditGroup_elements">
                          <label className="FormLabel" htmlFor="password">
                            Password
                          </label>
                          <input
                            className="InputForm"
                            type="text"
                            name="password"
                            onChange={handleEditChange}
                            required
                            value={userToEdit?.password}
                          ></input>
                        </div>

                        <div className="dialogEditGroup_elements">
                          <label className="FormLabel" htmlFor="name">
                            Rol
                          </label>
                          <select
                            className="SelectTrigger"
                            defaultValue={userToEdit?.rol == "Finanzas" ? 1 : userToEdit?.rol == "Operativo" ? 2 : 3}
                            onChange={handleEditChange}
                          >
                            <option value="1">Finanzas</option>
                            <option value="2">Operativo</option>
                            <option value="3">Administrador</option>
                          </select>
                        </div>
                        <div className="dialogEditGroup_elements">
                        <div className="data__group_form_content form__group_selectField" style={{ marginBottom: "15px" }}>
                        <label htmlFor="email" className="LabelForm">
                          Empresa
                        </label>
                        <br />
                        <div className="cartaPorte__group_elements_checkbox">
                          <Checkbox.Root
                            className="CheckboxRoot"
                            checked={empresaDattMX}
                            onCheckedChange={(isChecked) => {
                              let empresas = empresa;
                              console.log("empresas", empresa);

                              if (isChecked) {
                                empresas.push("DATT - MX");
                                setEmpresa(empresas);
                                setEmpresaDattMX(true);
                              } else {
                                setEmpresaDattMX(false);
                                let foundEmpresa = empresas.filter((empresa) => empresa !== "DATT - MX");
                                console.log("Found Empresa", foundEmpresa);
                                setEmpresa(foundEmpresa);
                              }

                            }}

                          >
                            <Checkbox.Indicator className="CheckboxIndicator">
                              <CheckIcon />
                            </Checkbox.Indicator>
                          </Checkbox.Root>
                          <label style={{ marginLeft: "1rem", fontSize: "13px" }} className="FormLabel" htmlFor="c1">
                            DATT - MX
                          </label>
                        </div>
                        <div className="cartaPorte__group_elements_checkbox">
                          <Checkbox.Root
                            className="CheckboxRoot"
                            checked={empresaDattImports}
                            onCheckedChange={(isChecked) => {
                              let empresas = empresa;
                              console.log("empresas", empresa);
                              if (isChecked) {
                                empresas.push("DATT - Imports");
                                setEmpresa(empresas);
                                setEmpresaDattImports(true);
                              } else {
                                setEmpresaDattImports(false)
                                let foundEmpresa = empresas.filter((empresa) => empresa !== "DATT - Imports");
                                console.log("Found Empresa", foundEmpresa);
                                setEmpresa(foundEmpresa);

                              }

                            }}

                          >
                            <Checkbox.Indicator className="CheckboxIndicator">
                              <CheckIcon />
                            </Checkbox.Indicator>
                          </Checkbox.Root>
                          <label style={{ marginLeft: "1rem", fontSize: "13px" }} className="FormLabel" htmlFor="c1">
                            DATT - Imports
                          </label>
                        </div>
                        <div className="cartaPorte__group_elements_checkbox">
                          <Checkbox.Root
                            className="CheckboxRoot"
                            checked={empresaDattEmpresa}
                            onCheckedChange={(isChecked) => {
                              let empresas = empresa;
                              console.log("empresas", empresa);
                              if (isChecked) {
                                setEmpresaDattEmpresa(true)
                                empresas.push("DATT - Empresa");
                                setEmpresa(empresas);
                              } else {
                                setEmpresaDattEmpresa(false)
                                let foundEmpresa = empresas.filter((empresa) => empresa !== "DATT - Empresa");
                                console.log("Found Empresa", foundEmpresa);
                                setEmpresa(foundEmpresa);
                              }

                            }}

                          >
                            <Checkbox.Indicator className="CheckboxIndicator">
                              <CheckIcon />
                            </Checkbox.Indicator>
                          </Checkbox.Root>
                          <label style={{ marginLeft: "1rem", fontSize: "13px" }} className="FormLabel" htmlFor="c1">
                            DATT - Empresa
                          </label>
                        </div>
                      </div>
                        </div>

                        <div className="dialogEditGroup_elements">
                          <label className="FormLabel" htmlFor="name">
                            Estatus
                          </label>
                          <select
                            name="status"
                            className="SelectTrigger"
                            onChange={handleEditChange}
                            value={userToEdit?.status}
                          >
                            <option value="Activo">Activo</option>
                            <option value="Inactivo">Inactivo</option>
                          </select>
                        </div>




                      </div>

                      <div className="btn__container">

                        <button className="btn__login"
                          disabled={isLoading}
                          style={{ marginTop: "2rem", width: "250px", display: "flex", justifyContent: "center", alignItems: "center" }}
                          type="submit">
                          {
                            isLoading ? (
                              <SyncLoader
                                color="white"
                                loading={isLoading}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                speedMultiplier={.7}
                              />

                            ) : "Editar Cliente"
                          }
                        </button>
                      </div>
                    </form>
                    <Dialog.Close asChild></Dialog.Close>
                    <Dialog.Close asChild>
                      <button className="IconButton" aria-label="Close">
                        <Cross2Icon />
                      </button>
                    </Dialog.Close>
                  </Dialog.Content>
                </Dialog.Portal>
              </Dialog.Root>

            </Tabs.Content>


          </Tabs.Root>
        </div>
      </div>
    </div >
  );
}

export default RegistroUsuarios;
