import React from 'react'
import { MdTripOrigin } from "react-icons/md";
import { FaLocationPin } from "react-icons/fa6";
import { BsCalendar2DateFill } from "react-icons/bs";
import { TbClockHour3 } from "react-icons/tb";
import "../styles/CardTravel.css"


export const CardTravel = ({ data }) => {

    const newDate = data.fechaInicio.split(' ');
    const date = new Date(newDate[0]);

    let days = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
    let months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    let day = days[date.getDay()];
    let month = months[date.getMonth()];
    let dayNumber = newDate[0].split('-')[2];
    let year = date.getFullYear();
    let actualDate = `${day}, ${dayNumber} de ${month}, ${year} `

    let horas = Number(newDate[1].substring(0,2));
    let minutos = newDate[1].substring(2,5)


    let horaSalida = horas >= 12 ? `${horas}${minutos} pm` : `${horas}${minutos} am`;
    


    // case "Pendiente Chofer":
    //     return "warning";
    //   case "Pendiente Gastos Diesel":
    //     return "warning";
    //   case "Pendiente Gastos Viaje":
    //     return "warning";
    //   case "Pendiente Precio Venta":
    //     return "warning";
    //   case "Pendiente Vehículo":
    //     return "warning";
    //   case "Pendiente Referencia":
    //     return "warning";
    //   case "Pendiente Facturación":
    //     return "info";
    //   case "Pendiente Pago":
    //     return "info";
    //   case "Viaje en curso":
    //     return "success";
    //   default:
    //     return "info";

    const getStatusColor = (status) => {
        let colors = [
            {
                status: "Pendiente Chofer",
                color: "#ffc107"
            },

            {
                status: "Pendiente Gastos Diesel",
                color: "#ffc107"
            },

            {
                status: "Pendiente Gastos Viaje",
                color: "#ffc107"
            },
            {
                status: "Pendiente Precio Venta",
                color: "#ffc107"
            },
            {
                status: "Pendiente Vehículo",
                color: "#ffc107"
            },
            {
                status: "Pendiente Referencia",
                color: "#ffc107"
            },
            {
                status: "Pendiente Facturación",
                color: "#17a2b8"
            },
            {
                status: "Pendiente Pago",
                color: "#cf9b00"
                // color: "#ffc107"
            },
            {
                status: "Viaje en curso",
                color: "#28a745"
            }];

        let color = colors.find(value => {
            if (value.status == status) return value.color;
        })
        return color ? color.color : "#dc3545";

    }

    return (
        <div className="card__travel">
            {/* <div className="card__travel_header" style={{backgroundColor:`${data.estatus === "Viaje en curso" ? "#28a745" :"#17a2b8"} `}}> */}
            <div className="card__travel_header" style={{ backgroundColor: getStatusColor(data.estatus) }}>
                <h3>{data.estatus}</h3>
            </div>
            <div className="card__travel_content">
                <div className="card__travel_info">

                    <div className="card__travel_location">
                        <div className="card__travel_location_label">
                            <MdTripOrigin />
                            <label>Origen</label>
                        </div>
                        <p>{data.origen}</p>
                    </div>

                    <div className="card__travel_location">
                        <div className="card__travel_location_label">
                            <FaLocationPin />
                            <label>Destino</label>
                        </div>
                        <p>{data.destino}</p>
                    </div>

                    <div className="card__travel_location">
                        <div className="card__travel_location_label">
                            <BsCalendar2DateFill />
                            <label>Fecha de Inicio</label>
                        </div>
                        <p>{actualDate}</p>
                    </div>
                    <div className="card__travel_location">
                        <div className="card__travel_location_label">
                            <TbClockHour3 style={{marginTop:".13rem"}}/>
                            <label>Hora de Registro</label>
                        </div>
                        <p>{horaSalida}</p>
                    </div>

                </div>

            </div>

            <div className="card__travel_footer">
                <div className="card__travel_footer_element">
                    <p>Asignado por <span>{data.asignadoPor}</span></p>
                </div>

                <div className="card__travel_footer_element">
                    <p>Operador <span>{data.usuario}</span></p>
                </div>
            </div>
        </div>


    )
}
