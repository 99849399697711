import { useState } from "react";
import React from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
  
import "@radix-ui/themes/styles.css";

import "../index.css";
// import dattImports from "../../public/images/dattImports.png"


// import dattImports from ".../assets/dattImports.png"
import DATTMXLogo from '../assets/DATTMXLogo.png'

import { ThemeProvider } from "next-themes";
import { API_URL } from "../constants";
import SyncLoader from "react-spinners/ClipLoader";



function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  let initialUser = { email: "", password: "" };
  const [user, setUser] = useState(initialUser)
  const [isLoading, setIsLoading] = useState(false);

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
});
  };

const handleInputChange = ({ target }) => {
  setUser({
    ...user,
    [target.name]: target.value
  })
}

async function loginUser(event) {
  event.preventDefault();
  setIsLoading(true)
  const response = await fetch(`${API_URL}/api/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
    credentials: "include",
  });
  const data = await response.json();

  setIsLoading(false);
  console.log("Data",data);
  if (data.status === "ok" && data.user) {
    localStorage.setItem("token", data.user);
    localStorage.setItem("permisos",JSON.stringify(data?.empresa));
    // Decodificar el token para obtener el rol
    const decodedToken = jwtDecode(data.user);
    const userRole = decodedToken.rol;

    // Redirigir según el rol
    if (userRole === 1) {
      navigate("/finanzas");
    } else if (userRole === 2) {
      navigate("/dashboard");
    } else if (userRole === 3) {
      navigate("/admin");
    } else {
      console.error("Rol no reconocido:", userRole);
    }
  } else {
    notify(data.error);
  }
}

return (
  <div className="login__background"
  // style={{
  //   width: "100%",
  //   height: "100vh",
  //   backgroundImage: `url(https://i.imgur.com/ASXyHBt.jpg)`,
  //   backgroundSize: "cover",
  //   backgroundPosition: "center 0",
  //   backgroundRepeat: "no-repeat",
  //   backgroundColor: "rgba(0, 0, 0, 0.9)",
  // }}
  >
    <div className="loginContainer">
      <header className="login__header">
        <img src={DATTMXLogo} alt="dattImportsLogo"></img>
      </header>

      <div className="login__Card">

        <form onSubmit={loginUser} className="login__form">
          <h3 className="login__form_header">Iniciar sesión</h3>
          <div className="login__form_input">
            <label htmlFor="email">Correo</label>
            <input
              id="email"
              type="email"
              name="email"
              placeholder="Ingresa tu correo"
              onChange={handleInputChange}
              value={user.email}
            />
          </div>

          <div className="login__form_input">
            <label htmlFor="password">Contraseña</label>
            <input
              id="password"
              type="password"
              name="password"
              placeholder="Ingresa tu contraseña"
              value={user.password}
              onChange={handleInputChange}

            />
          </div>

          <button
            disabled={isLoading}
            style={{ width: "100%" }}
            type="submit"
            className="btn__login"
          >
            {
              isLoading ? (
                <SyncLoader
                  color="white"
                  loading={isLoading}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={.7}
                />

              ) : "Inciar Sesión"

            }
          </button>
          <ToastContainer />
        </form>
      </div>

    </div>


  </div >
);
}

export default Login;
