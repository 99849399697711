import { useState } from "react";
import * as Checkbox from "@radix-ui/react-checkbox";
import {
    CheckIcon,

} from "@radix-ui/react-icons";
import { API_URL, RFC_Orig } from "../constants";

export const Mercancias = ({ index, mercancia, handleInputChange, handleMaterialPeligroso, isTransInt, entradaSalida, formaFarmaceutica, condicionesEspeciales, embalajeData }) => {
    return (
        <div>
            <div className="cartaPorte__group">
                {
                    isTransInt == "Sí" ? (
                        <>

                            <div className="cartaPorte__group_elements">

                                <label className="FormLabel" htmlFor="name">
                                    Fracción Arancelaria
                                </label>
                                <input
                                    placeholder="0101290200"
                                    className="InputForm"
                                    type="number"
                                    value={mercancia.fraccAranc}
                                    min={0}
                                    name="fraccAranc"
                                    onChange={(e) => handleInputChange(e.target, index)}
                                ></input>

                            </div>



                            <div className="cartaPorte__group_elements">

                                <label className="FormLabel" htmlFor="name">
                                    Sector COFEPRIS
                                </label>
                                <select
                                    className="SelectTrigger"
                                    value={mercancia.sectorCofepris}
                                    onChange={
                                        (e) => handleInputChange(e.target, index)
                                    }
                                    name="sectorCofepris"
                                >
                                    <option value="">
                                        Escoge una opción
                                    </option>
                                    <option value="01">01 Medicamento</option>
                                    <option value="02">
                                        02 Precursores y químicos de uso dual
                                    </option>
                                    <option value="03">
                                        03 Psicotrópicos y estupefacientes
                                    </option>
                                    <option value="04">04 Sustancias tóxicas</option>
                                    <option value="05">
                                        05 Plaguicidas y fertilizantes
                                    </option>
                                </select>

                            </div>

                            {
                                entradaSalida !== "Entrada" ? "" : mercancia.sectorCofepris === "01" || mercancia.sectorCofepris === "03" ? (

                                    <>
                                        <div className="cartaPorte__group_elements">
                                            <label className="FormLabel" htmlFor="name">
                                                Nombre Genérico
                                            </label>
                                            <input
                                                placeholder="Escribe el nombre generico del producto"
                                                value={mercancia.denominacionGenProd}
                                                className="InputForm"
                                                type="text"
                                                name="denominacionGenProd"
                                                onChange={(e) => handleInputChange(e.target, index)}
                                            ></input>
                                        </div>
                                    </>

                                ) : ""

                            }

                            {
                                entradaSalida !== "Entrada" ? "" : mercancia.sectorCofepris === "01" || mercancia.sectorCofepris === "03" ? (
                                    <>
                                        <div className="cartaPorte__group_elements">
                                            <label className="FormLabel" htmlFor="name">
                                                Marca
                                            </label>
                                            <input
                                                value={mercancia.denominacionDistProd}
                                                placeholder="Escribe la marca del producto"
                                                className="InputForm"
                                                type="text"
                                                name="denominacionDistProd"
                                                onChange={(e) => handleInputChange(e.target, index)}
                                            ></input>
                                        </div>
                                    </>
                                ) : ""

                            }

                            {
                                entradaSalida !== "Entrada" ? "" : mercancia.sectorCofepris === "01" || mercancia.sectorCofepris === "02" || mercancia.sectorCofepris === "03" ? (
                                    <>
                                        <div className="cartaPorte__group_elements">
                                            <label className="FormLabel" htmlFor="name">
                                                Fabricante
                                            </label>
                                            <input
                                                value={mercancia.fabricante}

                                                placeholder="Escribe el fabricante del producto"
                                                className="InputForm"
                                                type="text"
                                                name="fabricante"
                                                onChange={(e) => handleInputChange(e.target, index)}
                                            ></input>
                                        </div>
                                    </>
                                ) : ""

                            }

                            {
                                entradaSalida !== "Entrada" ? "" : mercancia.sectorCofepris === "01" || mercancia.sectorCofepris === "02" || mercancia.sectorCofepris === "03" ? (
                                    <>
                                        <div className="cartaPorte__group_elements">
                                            <label className="FormLabel" htmlFor="name">
                                                Fecha de caducidad
                                            </label>
                                            <input
                                                value={mercancia.fechaCaducidad}

                                                placeholder="Escribe el fabricante del producto"
                                                className="InputForm"
                                                type="date"
                                                name="fechaCaducidad"
                                                onChange={(e) => handleInputChange(e.target, index)}
                                            ></input>
                                        </div>

                                    </>
                                ) : ""

                            }

                            {
                                entradaSalida !== "Entrada" ? "" : mercancia.sectorCofepris === "01" || mercancia.sectorCofepris === "02" || mercancia.sectorCofepris === "03" ? (
                                    <>
                                        <div className="cartaPorte__group_elements">
                                            <label className="FormLabel" htmlFor="name">
                                                Lote de Medicamento
                                            </label>
                                            <input
                                                value={mercancia.loteMed}

                                                placeholder="Escribe el lote del producto"
                                                className="InputForm"
                                                type="text"
                                                name="loteMed"
                                                onChange={(e) => handleInputChange(e.target, index)}
                                            ></input>
                                        </div>
                                    </>
                                ) : ""
                            }

                            {
                                entradaSalida !== "Entrada" ? "" : mercancia.sectorCofepris === "01" || mercancia.sectorCofepris === "02" || mercancia.sectorCofepris === "03" ? (
                                    <>
                                        <div className="cartaPorte__group_elements">
                                            <label className="FormLabel" htmlFor="name">
                                                Forma Farmaceutica
                                            </label>
                                            <select
                                                value={mercancia.formaFarmaceutica}
                                                className="SelectTrigger"
                                                name="formaFarmaceutica"
                                                onChange={(e) => handleInputChange(e.target, index)}
                                            >
                                                <option value="">Escoge una opción</option>
                                                {
                                                    formaFarmaceutica.length > 0 && formaFarmaceutica ? (
                                                        formaFarmaceutica.map((el, index) => (
                                                            <option key={index} v value={el.Clave}>{el.Descripcion}</option>
                                                        ))
                                                    ) : ""
                                                }

                                            </select>
                                        </div>
                                    </>
                                ) : ""
                            }

                            {
                                entradaSalida !== "Entrada" ? "" : mercancia.sectorCofepris === "01" || mercancia.sectorCofepris === "02" || mercancia.sectorCofepris === "03" ? (
                                    <>
                                        <div className="cartaPorte__group_elements">
                                            <label className="FormLabel" htmlFor="name">
                                                Condiciones de transporte
                                            </label>
                                            <select
                                                value={mercancia.condTransporte}
                                                className="SelectTrigger"
                                                name="condTransporte"
                                                onChange={(e) => handleInputChange(e.target, index)}
                                            >
                                                <option value="">Escoge una opción</option>
                                                {
                                                    condicionesEspeciales.length > 0 && condicionesEspeciales ? (
                                                        condicionesEspeciales.map((el) => (
                                                            <option key={el._id} value={el.Clave}>{el.Descripcion}</option>
                                                        ))

                                                    ) : ""
                                                }
                                            </select>
                                        </div>
                                    </>
                                ) : ""

                            }

                            {
                                entradaSalida !== "Entrada" ? "" : mercancia.sectorCofepris === "02" || mercancia.sectorCofepris === "05" ?
                                    (
                                        <>
                                            <div className="cartaPorte__group_elements">
                                                <label className="FormLabel" htmlFor="name">
                                                    Ingrediente activo
                                                </label>
                                                <input
                                                    value={mercancia.ingActivo}
                                                    placeholder="IMIDACLOPRID"
                                                    className="InputForm"
                                                    type="text"
                                                    name="ingActivo"
                                                    onChange={(e) => handleInputChange(e.target, index)}
                                                ></input>
                                            </div>

                                        </>
                                    ) : ""

                            }


                            {
                                entradaSalida != "Entrada" ? "" : mercancia.sectorCofepris === "05" ?
                                    (


                                        <>
                                            <div className="cartaPorte__group_elements">
                                                <label className="FormLabel" htmlFor="name">
                                                    Registro Sanitario de Plaguicidas
                                                </label>
                                                <input
                                                    value={mercancia.regSanPlag}

                                                    placeholder="RSCO-DOM-INAC-009-323-382-0.030"
                                                    className="InputForm"
                                                    type="texts"
                                                    name="regSanPlag"
                                                    onChange={(e) => handleInputChange(e.target, index)}
                                                ></input>
                                            </div>

                                            <div className="cartaPorte__group_elements">
                                                <label className="FormLabel" htmlFor="name">
                                                    Datos del Fabricante
                                                </label>
                                                <input
                                                    value={mercancia.datosFabricante}

                                                    placeholder="Escribe el nombre del fabricante"
                                                    className="InputForm"
                                                    type="text"
                                                    name="datosFabricante"
                                                    onChange={(e) => handleInputChange(e.target, index)}
                                                ></input>
                                            </div>

                                            <div className="cartaPorte__group_elements">
                                                <label className="FormLabel" htmlFor="name">
                                                    Datos Formulador
                                                </label>
                                                <input
                                                    value={mercancia.datosFormulador}

                                                    placeholder="Describe las condiciones para transportar"
                                                    className="InputForm"
                                                    type="text"
                                                    name="datosFormulador"
                                                    onChange={(e) => handleInputChange(e.target, index)}
                                                ></input>
                                            </div>

                                            <div className="cartaPorte__group_elements">
                                                <label className="FormLabel" htmlFor="name">
                                                    Datos del Maquilador
                                                </label>
                                                <input
                                                    value={mercancia.datosMaquilador}

                                                    placeholder="Describe las condiciones para transportar"
                                                    className="InputForm"
                                                    type="text"
                                                    name="datosMaquilador"
                                                    onChange={(e) => handleInputChange(e.target, index)}
                                                ></input>
                                            </div>

                                            <div className="cartaPorte__group_elements">
                                                <label className="FormLabel" htmlFor="name">
                                                    Uso autorizado
                                                </label>
                                                <input
                                                    value={mercancia.usoAutorizado}

                                                    placeholder="Describe las condiciones para transportar"
                                                    className="InputForm"
                                                    type="text"
                                                    name="usoAutorizado"
                                                    onChange={(e) => handleInputChange(e.target, index)}
                                                ></input>
                                            </div>

                                        </>

                                    ) : ""


                            }

                            {
                                entradaSalida !== "Entrada" ? "" : mercancia.sectorCofepris === "04" ? (
                                    <div className="cartaPorte__group_elements">
                                        <label className="FormLabel" htmlFor="name">
                                            Empresa Importadora
                                        </label>
                                        <input
                                            value={mercancia.razonSocialEmpImp}
                                            placeholder="Escribe el nombre de la empresa importadora"
                                            className="InputForm"
                                            type="text"
                                            name="razonSocialEmpImp"
                                            onChange={(e) => handleInputChange(e.target, index)}
                                        ></input>
                                    </div>


                                ) : ""
                            }
                            {
                                entradaSalida !== "Entrada" ? "" : mercancia.sectorCofepris === "04" || mercancia.sectorCofepris === "02" ?
                                    (
                                        <>
                                            <div className="cartaPorte__group_elements">
                                                <label className="FormLabel" htmlFor="name">
                                                    Nombre Químico
                                                </label>
                                                <input
                                                    value={mercancia.nomQuimico}

                                                    placeholder="Escribe el nombre de la sustancia activa"
                                                    className="InputForm"
                                                    type="text"
                                                    name="nomQuimico"
                                                    onChange={(e) => handleInputChange(e.target, index)}
                                                ></input>
                                            </div>
                                        </>
                                    ) : ""
                            }

                            {
                                entradaSalida !== "Entrada" ? "" : mercancia.sectorCofepris === "04" ? (

                                    <div className="cartaPorte__group_elements">
                                        <label className="FormLabel" htmlFor="name">
                                            Número de CAS
                                        </label>
                                        <input
                                            value={mercancia.numCAS}

                                            placeholder="Número de Chemical Abstracts Service"
                                            className="InputForm"
                                            type="text"
                                            name="numCAS"
                                            onChange={(e) => handleInputChange(e.target, index)}
                                        ></input>
                                    </div>


                                ) : ""

                            }








                            {
                                entradaSalida !== "Entrada" ? "" : mercancia.sectorCofepris === "01" || mercancia.sectorCofepris === "03" ? (
                                    <>
                                        <div className="cartaPorte__group_elements">
                                            <label className="FormLabel" htmlFor="name">
                                                Registro Sanitario
                                            </label>
                                            <input
                                                value={mercancia.registroSanitario}

                                                placeholder="305M2018 SSA"
                                                className="InputForm"
                                                type="text"
                                                name="registroSanitario"
                                                onChange={(e) => handleInputChange(e.target, index)}
                                            ></input>
                                        </div>
                                    </>
                                ) : ""

                            }

                            {
                                mercancia.sectorCofepris === "" || mercancia.sectorCofepris === "03" ? "" :
                                    entradaSalida !== "Entrada" ? ""
                                        :
                                        (
                                            <>
                                                <div className="cartaPorte__group_elements">
                                                    <label className="FormLabel" htmlFor="name">
                                                        Folio Impo VUCEM
                                                    </label>
                                                    <input
                                                        value={mercancia.impoVucem}
                                                        placeholder="021203923"
                                                        className="InputForm"
                                                        type="text"
                                                        maxLength="25"
                                                        name="impoVucem"
                                                        onChange={(e) => handleInputChange(e.target, index)}
                                                    ></input>
                                                </div>
                                            </>
                                        )

                            }

                            {
                                mercancia.sectorCofepris == "" || mercancia.sectorCofepris == "04" || mercancia.sectorCofepris == "05" ? "" :
                                    entradaSalida !== "Entrada" ? "" :
                                        (
                                            <>
                                                <div className="cartaPorte__group_elements">
                                                    <label className="FormLabel" htmlFor="name">
                                                        Permiso de Importación
                                                    </label>
                                                    <input
                                                        value={mercancia.permImpo}
                                                        placeholder="Ingrese el permiso de importación"
                                                        className="InputForm"
                                                        type="text"
                                                        maxLength="6"
                                                        min={0}
                                                        name="permImpo"
                                                        onChange={(e) => handleInputChange(e.target, index)}
                                                    ></input>
                                                </div>
                                            </>
                                        )
                            }



                            <div className="cartaPorte__group_elements">

                                <label className="FormLabel" htmlFor="name">
                                    Tipo de Materia
                                </label>
                                <select
                                    className="SelectTrigger"
                                    onChange={(e) => handleInputChange(e.target, index)}
                                    name="tipoMateria"
                                    value={mercancia.tipoMateria}

                                >
                                    <option value="">
                                        Escoge una opción
                                    </option>
                                    <option value="01">01 - Materia Prima</option>
                                    <option value="02">02 Materia procesada</option>
                                    <option value="03">
                                        03 Materia terminada(producto terminado)
                                    </option>
                                    <option value="04">
                                        04 Materia para la industria manufacturera
                                    </option>
                                    <option value="05">05 Otra</option>
                                </select>
                            </div>

                            {
                                mercancia.tipoMateria === "05" ?
                                    (

                                        <div className="cartaPorte__group_elements">
                                            <label className="FormLabel" htmlFor="name">
                                                Descripción de Materia
                                            </label>
                                            <input
                                                value={mercancia.descripcionMateria}

                                                className="InputForm"
                                                placeholder="Describe la materia"
                                                type="text"
                                                name="descripcionMateria"
                                                onChange={(e) => handleInputChange(e.target, index)}
                                            ></input>

                                        </div>

                                    ) : ""


                            }





                        </>
                    ) : ""

                }

                <div className="cartaPorte__group_elements">
                    <label className="FormLabel" htmlFor="name">
                        Bienes Transportados *
                    </label>
                    <input
                        className="InputForm"
                        type="number"
                        min={0}
                        value={mercancia.bienesTransp}
                        name="bienesTransp"
                        placeholder="Código de producto del SAT"
                        onChange={(e) => handleInputChange(e.target, index)}
                        required
                    ></input>
                </div>

                <div className="cartaPorte__group_elements">
                    <label className="FormLabel" htmlFor="name">
                        Descripción *
                    </label>
                    <input
                        className="InputForm"
                        placeholder="Describe los bienes"
                        type="text"
                        value={mercancia.descripcion}
                        name="descripcion"
                        onChange={(e) => handleInputChange(e.target, index)}
                        required
                    ></input>
                </div>

                <div className="cartaPorte__group_elements">
                    <label className="FormLabel" htmlFor="name">
                        Cantidad *
                    </label>
                    <input
                        placeholder="Cantidad de mercancia"
                        className="InputForm"
                        type="number"
                        value={mercancia.cantidad}
                        min={0}
                        name="cantidad"
                        onChange={(e) => handleInputChange(e.target, index)}
                        required
                    ></input>
                </div>

                <div className="cartaPorte__group_elements">
                    <label className="FormLabel" htmlFor="name">
                        Clave de Unidad *
                    </label>
                    <input
                        value={mercancia.claveUnidad}
                        placeholder="XBX"
                        className="InputForm"
                        style={{ textTransform: "uppercase" }}
                        type="text"
                        onChange={(e) => handleInputChange(e.target, index)}
                        required
                        name="claveUnidad"
                    ></input>
                </div>




            </div>

            <div className="cartaPorte__group">
                <div
                    className="cartaPorte__group_elements"
                    style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "270px" }}
                >
                    <Checkbox.Root
                        className="CheckboxRoot"
                        checked={mercancia.matPeligroso === "Sí" ? true : false}
                        onCheckedChange={(isChecked) => handleMaterialPeligroso(isChecked, index)}
                    >
                        <Checkbox.Indicator className="CheckboxIndicator">
                            <CheckIcon />
                        </Checkbox.Indicator>
                    </Checkbox.Root>
                    <label className="FormLabel" htmlFor="c1" style={{ marginLeft: "1rem" }}>
                        Material Peligroso
                    </label>
                </div>

                {
                    mercancia.matPeligroso == "Sí" ?
                        (
                            <>
                                <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                        Clave de Material Peligroso
                                    </label>
                                    <input
                                        value={mercancia.cveMatPeligroso}
                                        placeholder="0000"
                                        className="InputForm"
                                        type="number"
                                        min={0}
                                        onChange={(e) => handleInputChange(e.target, index)}
                                        max="2024"
                                        required
                                        name="cveMatPeligroso"
                                    ></input>
                                </div>

                                <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                        Embalaje
                                    </label>
                                    <select
                                        value={mercancia.embalaje}
                                        className="SelectTrigger"
                                        name="embalaje"
                                        onChange={(e) => handleInputChange(e.target, index)}
                                        style={{ border: "none" }}
                                        required
                                    >
                                        <option value="">Selecciona un embalaje</option>
                                        {
                                            embalajeData && embalajeData.length > 0 ? embalajeData.map((el) => (
                                                <option key={el._id} value={el.ClaveDesignacion}>{el.Descripcion}</option>
                                            )) : <option value="noValue">No se encuentran los embalajes</option>
                                        }
                                    </select>
                                </div>


                            </>

                        )
                        : ""

                }

            </div>






            <div className="cartaPorte__group_elements">
                <label className="FormLabel" htmlFor="name">
                    Peso en Kilogramos *
                </label>
                <input
                    value={mercancia.pesoMateria}
                    className="InputForm"
                    placeholder="Peso de la mercancia"
                    type="number"
                    min={0}
                    name="pesoMateria"
                    onChange={(e) => handleInputChange(e.target, index)}
                    required
                ></input>

            </div>

            {
                isTransInt === "Sí" ? (

                    <section className="documentacionAduanera">
                        <h5 style={{ margin: "2rem 0 1rem 0" }}>Documentación Aduanera</h5>

                        <div className="cartaPorte__group">

                            <div className="cartaPorte__group_elements">

                                <label className="FormLabel" htmlFor="name">
                                    Tipo de Documento
                                </label>
                                <select
                                    value={mercancia.tipoDoc}

                                    className="SelectTrigger"
                                    onChange={(e) => handleInputChange(e.target, index)}
                                    name="tipoDoc"
                                >
                                    <option value="" disabled>
                                        Escoge una opción
                                    </option>
                                    <option value="">
                                        Sin opción
                                    </option>

                                    {
                                        entradaSalida === "Entrada" ? (
                                            <option value="01">01 Pedimento</option>
                                        ) : ""
                                    }

                                    <option value="02">
                                        02 Autorización de importación temporal
                                    </option>
                                    <option value="03">
                                        03 Autorización de importación temporal de
                                        embarcaciones
                                    </option>
                                    <option value="04">
                                        04 Autorización de importación temporal de
                                        mercancías, destinadas al mantenimiento y
                                        reparación de las mercancías importadas
                                        temporalmente
                                    </option>
                                    <option value="05">
                                        05 Autorización para la importación de vehículos
                                        especialmente construidos o transformados,
                                        equipados con dispositivos o aparatos diversos
                                        para cumplir con contrato derivado de licitación
                                        pública
                                    </option>
                                    <option value="06">
                                        06 Aviso de exportación temporal
                                    </option>
                                    <option value="07">
                                        07 Aviso de traslado de mercancías de empresas
                                        con Programa IMMEX, RFE u Operador Económico
                                        Autorizado
                                    </option>
                                    <option value="08">
                                        08 Aviso para el traslado de autopartes ubicadas
                                        en la franja o región fronteriza a la industria
                                        terminal automotriz o manufacturera de vehículos
                                        de autotransporte en el resto del territorio
                                        nacional
                                    </option>
                                    <option value="09">
                                        09 Constancia de importación temporal, retorno o
                                        transferencia de contenedores
                                    </option>
                                    <option value="10">
                                        10 Constancia de transferencia de mercancías
                                    </option>
                                    <option value="11">
                                        11 Autorización de donación de mercancías al
                                        Fisco Federal que se encuentren en el extranjero
                                    </option>
                                    <option value="12">12 Cuaderno ATA</option>
                                    <option value="13">
                                        13 Listas de intercambio
                                    </option>
                                    <option value="14">
                                        14 Permiso de Importación Temporal
                                    </option>
                                    <option value="15">
                                        15 Permiso de importación temporal de casa
                                        rodante
                                    </option>
                                    <option value="16">
                                        16 Permiso de importación temporal
                                        de embarcaciones
                                    </option>
                                    <option value="17">
                                        17 Solicitud de donación de mercancías en casos
                                        de emergencias o desastres naturales
                                    </option>
                                    <option value="18">
                                        18 Aviso de consolidado
                                    </option>
                                    <option value="19">
                                        19 Aviso de cruce de mercancias
                                    </option>
                                    <option value="20">20 Otro</option>
                                </select>


                            </div>
                            {
                                mercancia.tipoDoc === "01" && entradaSalida==="Entrada" ? (
                                    <>

                                        <div className="cartaPorte__group_elements">
                                            <label className="FormLabel" htmlFor="name">
                                                Número de Pedimento
                                            </label>
                                            <input
                                                className="InputForm"
                                                value={mercancia.numPedimento}
                                                type="text"
                                                name="numPedimento"
                                                placeholder="23  43  0472  8000448"
                                                onChange={(e) => handleInputChange(e.target, index)}
                                            ></input>
                                        </div>

                                    </>

                                ) : ""

                            }
                            {
                                mercancia.tipoDoc!=="" ? (
                                        <div className="cartaPorte__group_elements">
                                            <label className="FormLabel" htmlFor="name">
                                                RFC del Importador
                                            </label>
                                            <input
                                                placeholder="XXXX1902163X0"
                                                className="InputForm"
                                                type="text"
                                                name="RFCImportador"
                                                onChange={(e) => handleInputChange(e.target, index)}
                                            ></input>
                                        </div>
                                ):""
                            }


                            {
                                mercancia.tipoDoc !== "01" && mercancia.tipoDoc !== "" ? (

                                    <div className="cartaPorte__group_elements">
                                        <label className="FormLabel" htmlFor="name">
                                            Documento Aduanero
                                        </label>
                                        <input
                                            placeholder="Folio de documento aduanero"
                                            className="InputForm"
                                            maxLength="150"
                                            type="text"
                                            name="identDocAduanero"
                                            value={mercancia.identDocAduanero}
                                            onChange={(e) => handleInputChange(e.target, index)}
                                        ></input>
                                    </div>
                                ) : ""
                            }


                            {
                                mercancia.tipoDoc !== "" ? (


                                    <div className="cartaPorte__group_elements">
                                        <label className="FormLabel" htmlFor="name">
                                            Cantidad que Transporta
                                        </label>
                                        <input
                                            value={mercancia.cantidadMaterialTransp}
                                            placeholder="Cantidad transportada"
                                            className="InputForm"
                                            type="number"
                                            min={0}
                                            name="cantidadMaterialTransp"
                                            onChange={(e) => handleInputChange(e.target, index)}
                                        ></input>
                                    </div>
                                ) : ""
                            }
                        </div>

                    </section>
                ) : ""


            }




        </div >
    )
}
