import React from 'react'

export const PageInfo = ({heading}) => {
    return (
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="header">
                        <h1 style={{width:"100%",textAlign:"center"}}>{heading}</h1>
                    </div>

                </div>
            </div>
        </div>
    )
}
