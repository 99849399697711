import * as Form from "@radix-ui/react-form";
import React, { useState, useEffect, useRef } from "react";
import "@radix-ui/themes/styles.css";
import "../../styles/adminchof.css";

import * as Tabs from "@radix-ui/react-tabs";
import "../../styles/select.css";
import "../../styles/tabs.css";
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css"; // Elige un tema que prefieras
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../../index.css";
import * as Dialog from "@radix-ui/react-dialog";
import { API_URL } from "../../constants";
import { FilterMatchMode } from "primereact/api";
import { PageInfo } from "../PageInfo";
import { CrossCircledIcon, CheckIcon } from "@radix-ui/react-icons"
import * as Checkbox from "@radix-ui/react-checkbox";
import SyncLoader from "react-spinners/ClipLoader";
import { Cross2Icon } from "@radix-ui/react-icons";



export const AdminTransportes = () => {
    //transports data in a datatable
    const [transports, setTransportData] = useState("");
    const dt = useRef(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [transportToDelete, setTransportToDelete] = useState(null);
    const [transportToEdit, setTransportToEdit] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        company: { value: null, matchMode: FilterMatchMode.IN },
        phone: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });

    const [medioAmbiente, setMedioAmbiente] = useState(false);
    const [confVehicularData, setConfVehicularData] = useState([]);
    const [permisoSCTData, setPermisoSCTData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

    const [permisos, setPermisos] = useState([]);

    let initialState = {
        empresa: "",
        placaVehiculo: "",
        anioVehiculo: "",
        nombreSeguro: "",
        numPoliza: "",
        aseguraMedAmbiente: "",
        polizaMedAmbiente: "",
        pesoVehiculo: "",
        numUnidad: "",
        confVehicular: "",
        permisoSCT: "",
        numPermisoSCT: "",
        remolqueRequerido: false
    }
    const [transport, setTransport] = useState(initialState);

    let filterTransportObj = {
        empresa: "",
        numUnidad: "",
        placaVehiculo: "",
        confVehicular: "",
        permisoSCT: "",
        remolqueRequerido: "",
        medAmbiente: ""
    };

    const [isLoadingSearch, setIsLoadingSearch] = useState(false);

    const [filterTransports, setFilterTransports] = useState(filterTransportObj);


    useEffect(() => {

        // fetchTransportsData();
        fetchConfVehicular();
        fetchPermisoSCT();
        let perms = JSON.parse(localStorage.getItem("permisos"));
        setPermisos(perms);
        if (perms.length <= 1) {
            setTransport({
                ...transport,
                empresa: perms[0]
            });
            setFilterTransports({
                ...filterTransports,
                empresa: perms[0]
            });
        }

    }, []);



    const handleInputChange = (e) => {
        const confVehicular = ["C2", "C3", "OTROSG", "GPLUTA", "GPLUTB", "GPLUTD", "GPLATA", "GPLATB", "GPLATC", "GPLUTC", "GPLATD"];
        if (e.target.name === "confVehicular") {
            if (confVehicular.includes(e.target.value)) setTransport({ ...transport, confVehicular: e.target.value, remolqueRequerido: false });
            if (!confVehicular.includes(e.target.value)) setTransport({ ...transport, confVehicular: e.target.value, remolqueRequerido: true });
        }

        else {
            setTransport({
                ...transport,
                [e.target.name]: e.target.value
            });
        }

    }
    const notifySuccess = (message) => {
        toast.success(message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    };

    const notifyWarning = (message) => {
        toast.warning(message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    };

    const notifyDeleteSuccess = () => {
        toast.success("¡El usuario fue eliminado correctamente!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    };

    // const notifyEmptyForm = () => {
    //     toast.warning("¡Hay datos faltantes, revisa nuevamente!", {
    //         position: "bottom-center",
    //         autoClose: 5000,
    //         hideProgressBar: true,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "dark",
    //     });
    // };

    const notifyErrorForm = (message) => {
        toast.warning(message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    };

    const notifyUpdate = () => {
        toast.success("¡Datos actualizados!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    };

    //UPDATE transports in BD
    async function updateTransportInDB(updateTransport) {
        try {
            const response = await fetch(`${API_URL}/api/transports/${updateTransport._id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updateTransport),
            });

            const data = await response.json();
            if (data.status === "Ok") {
                notifyUpdate();
                fetchTransportsData();
            } else {
                notifyErrorForm("Error al actualizar los datos");
            }
            return data;
        } catch (error) {
            console.error("Error al actualizar el conductor:", error);
            return {
                status: "error",
                error: "Ocurrió un error al actualizar el conductor.",
            };
        }
    }

    //FETCH transports
    const fetchTransportsData = async (e) => {
        e.preventDefault();
        setIsLoadingSearch(true);
        try {
            let queryString = "?";
            Object.entries(filterTransports).forEach(([key, value], index) => {
                if (value !== "") {
                    queryString += `${key}=${value}&`
                }
            })

            if (!queryString.includes("empresa")) {
                queryString += `empresa=${permisos}&`
            }
            queryString = queryString.substring(0, queryString.length - 1);

            const response = await fetch(`${API_URL}/api/transports${queryString}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const data = await response.json();
            setIsLoadingSearch(false);
            if (data.status === "Ok") {
                setTransportData(data.transports);
            } else {
                notifyWarning(data.error);
                setTransportData([]);
            }

        } catch (error) {
            setIsLoadingSearch(false);
            notifyErrorForm(error);
        }

    };

    //DELETE driver
    const deleteTransportFromDB = async (e, transportId) => {
        try {
            const response = await fetch(`${API_URL}/api/transports/${transportId}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                notifyDeleteSuccess();
                fetchTransportsData(e);
            } else {
                // La solicitud no fue exitosa, maneja el error adecuadamente
                const errorData = await response.json();
                console.error("Error al eliminar el conductor:", errorData.message);
            }
        } catch (error) {
            console.error("Error", error);
        }
    };



    const fetchPermisoSCT = async () => {
        try {
            let response = await fetch(`${API_URL}/api/catalagos/permisoSCT/`);
            let data = await response.json();

            setPermisoSCTData(data);
        } catch (error) {
            console.error("Error en fetchPermisoSCT", error);
        }
    }

    const fetchConfVehicular = async () => {
        try {
            let response = await fetch(`${API_URL}/api/catalagos/confVehicular/`);
            let data = await response.json();
            setConfVehicularData(data);
        } catch (error) {
            console.error("Error en fetchConfVehicular", error);
        }
    }


    async function registerTransport(event) {
        event.preventDefault();

        if (transport.numUnidad.trim() === "") {
            notifyErrorForm("El numero de unidad no puede estar vacío");
            return false;
        }

        if (transport.pesoVehiculo.trim() === "" || transport.pesoVehiculo.trim().length <= 0) {
            notifyErrorForm("El peso del vehiculo no puede ser igual a 0");
            return false;
        }
        if (transport.confVehicular === "") {
            notifyErrorForm("La configuración vehicular no puede estar vacía");
            return false;
        }


        if (transport.placaVehiculo.trim() == "") { notifyErrorForm("La placa del vehiculo no puede estar vacía"); return false };
        if (transport.placaVehiculo.trim().length < 5) { notifyErrorForm("La placa del vehiculo no puede ser menor a 6 caracteres"); return false; }
        if (transport.placaVehiculo.trim().length > 7) { notifyErrorForm("La placa del vehiculo no puede ser mayor a 7 caracteres"); return false; }


        if (transport.anioVehiculo.trim() === "") { notifyErrorForm("El año del vehiculo no puede estar vacío"); return false; }

        if (transport.anioVehiculo.trim().length < 4) {
            notifyErrorForm("El año del vehículo debe cumplir el formato de año. Ej: '2024'");
            return false;
        }

        if (transport.nombreSeguro.trim() === "") { notifyErrorForm("El nombre de la aseguradora no puede estar vacío"); return false; }

        if (transport.nombreSeguro.trim().length < 3) { notifyErrorForm("El nombre de la aseguradora no puede ser menor a 3 caracteres"); return false; }

        if (transport.nombreSeguro.trim().length > 50) { notifyErrorForm("El nombre de la aseguradora no puedo ser mayor a 3 caracteres"); return false; }

        if (transport.numPoliza.trim() === "" || transport.numPoliza.trim().length < 3 || transport.numPoliza.trim().length > 30) {
            notifyErrorForm("El numero de póliza no puede estar vacía ni ser menor a 3 ni mayor a 30 caracteres");
            return false;
        }
        if (medioAmbiente) {
            if (transport.aseguraMedAmbiente.trim() === "" || transport.aseguraMedAmbiente.trim().length < 3) {
                notifyErrorForm("El Nombre de la aseguradora no puede estar vacío ni ser menor a 3 caracteres");
                return false;
            }
            if (transport.polizaMedAmbiente.trim() === "" || transport.polizaMedAmbiente.trim().length < 3) {
                notifyErrorForm("La Poliza Medio ambiente no puede estar vacía ni ser menor a 3 caracteres");
                return false;
            }
        }


        if (transport.numPermisoSCT.trim() === "" || transport.numPermisoSCT.trim().length > 50) {
            notifyErrorForm("El numero de permiso SCT no puede estar vacío ni ser mayor a 50 caracteres");
            return false;
        }

        if (transport.permisoSCT.trim() === "") {
            notifyErrorForm("El tipo de permiso SCT no puede estar vacío");
            return false;
        }

        setIsLoading(true);
        try {

            transport.medAmbiente = medioAmbiente;
            const response = await fetch(`${API_URL}/api/transports/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(transport),
            });
            const data = await response.json();
            setIsLoading(false);

            if (data.status === "Ok") {
                document.getElementById("DriverForm").reset();
                notifySuccess(data.message);
                fetchTransportsData(event);
                setTransport(initialState);
            }
            if (data.status === "Failed") {
                // notifyEmail();
                notifyErrorForm(data.error);
            }
        } catch (error) {
            notifyErrorForm("No se pudo realizar el registro del transporte");
            setIsLoading(false);
        }
    }

    const handleEditTransport = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const response = await fetch(`${API_URL}/api/transports/${transportToEdit._id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(transportToEdit),
            });
            let data = await response.json();
            setIsLoading(false);
            if (data.status === "Ok") {
                notifySuccess(data.message)
                fetchTransportsData(e);
                setIsEditDialogOpen(false);
            } else {
                notifyErrorForm("No se pudo realizar la actualización de datos");
            }
        } catch (error) {
            setIsLoading(false);
            notifyErrorForm("No se pudo realizar la actualización de datos");
        }
    }

    const handleEditTransportChange = (e) => {
        console.log("Transtoedit",transportToEdit);
        setTransportToEdit({
            ...transportToEdit,
            [e.target.name]: e.target.value
        });
    }

    //UPDATE driver
    const onRowEditComplete = (e) => {
        updateTransportInDB(e.newData);
    };
    const getMaxLength = (field) => {
        let maxLengthValue = [
            {
                description: "placaVehiculo",
                maxLength: 7
            }, {
                description: "numUnidad",
                maxLength: 100
            }, {
                description: "numPermisoSCT",
                maxLength: 50
            }
            , {
                description: "pesoVehiculo",
                maxLength: 4
            },
            {
                description: "nombreSeguro",
                maxLength: 50
            }
            , {
                description: "numPoliza",
                maxLength: 30
            }, {
                description: "aseguraMedAmbiente",
                maxLength: 50
            },
            {
                description: "polizaMedAmbiente",
                maxLength: 30
            },
            {
                description: "anioVehiculo",
                maxLength: 4
            },
        ]

        let maxLen = maxLengthValue.find((el) => el.description === field);
        return maxLen.maxLength;
    }

    const textEditor = (options) => {
        let maxLengthValue = getMaxLength(options.field);
        return (
            <InputText
                type="text"
                maxLength={maxLengthValue}
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
                className="Input"
            />
        );
    };

    const selectPermisoEditor = (options) => {
        return (
            <select
                className="SelectTrigger"
                value={options.value}
                onChange={(e) => {
                    options.editorCallback(e.target.value);
                }}
            >
                {
                    permisoSCTData.length > 0 ?
                        permisoSCTData.map((permiso) => (
                            <option value={permiso.Clave}>{permiso.Clave} - {permiso.permisoSCT}</option>
                        )) : ""
                }

            </select>
        );
    };

    const selectConfVehiEditor = (options) => {
        return (
            <select
                className="SelectTrigger"
                value={options.value}
                onChange={(e) => {
                    options.editorCallback(e.target.value);
                }}
            >
                {
                    confVehicularData.length > 0 ? confVehicularData.map((el) => (
                        <option value={el.Clave} >{el.Clave} - {el.confVehicular} </option>
                    )) : ""
                }
            </select>

        )
    }

    //DELETE driver
    const confirmDeleteDriver = (rowData) => {
        setTransportToDelete(rowData);
        setIsDialogOpen(true);
    };

    const handleDeleteConfirmation = (e) => {
        if (transportToDelete) {
            deleteTransportFromDB(e, transportToDelete._id);
        }
        setIsDialogOpen(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    rounded
                    className="pi pi-trash btn__pi"
                    outlined
                    severity="danger"
                    onClick={() => confirmDeleteDriver(rowData)}
                    style={{ color: "red" }}
                />
            </React.Fragment>
        );
    };
    const bodyEditTransport = (data) => {
        return (
            <Button
                className="pi pi-pencil btn__pi"
                onClick={() => { setIsEditDialogOpen(true); setTransportToEdit(data) }}
            />
        )
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };



    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        style={{ marginLeft: "30px" }}
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        placeholder="Buscar"
                    />
                </span>
            </div>
        );
    };


    const handleChangeFilter = (e) => {
        setFilterTransports({
            ...filterTransports,
            [e.target.name]: e.target.value
        });

    }



    return (
        <div className="content-wrapper container__block" style={{ paddingBottom: "1.4rem" }}>
            <PageInfo heading="DATT - Tracking | Administrar Transportes" />

            <section className="content">
                <div className="container-fluid">
                    <Tabs.Root className="TabsRoot" defaultValue="tab1">
                        <Tabs.List className="TabsList" aria-label="Registra un chofer">
                            <Tabs.Trigger className="TabsTrigger" value="tab1">
                                Registrar Transporte
                            </Tabs.Trigger>
                            <Tabs.Trigger className="TabsTrigger" value="tab2">
                                Ver Transportes
                            </Tabs.Trigger>
                        </Tabs.List>

                        <Tabs.Content
                            className="TabsContent"
                            value="tab1"
                            style={{
                                display: "flex", justifyContent: "center"
                            }}
                        >
                            <div
                                className="container__width_70"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "20px",
                                }}
                            >
                                <div style={{ flex: 1 }}>
                                    <Form.Root
                                        className="FormRoot"
                                        onSubmit={registerTransport}
                                        id="DriverForm"
                                    >
                                        <div className="data__group">
                                            <h4>Datos del Transporte</h4>
                                            <div className="data__group_form">
                                                <div
                                                    className="cartaPorte__group_elements"
                                                    style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "300px", marginTop: "1.3rem" }}
                                                >
                                                    <Checkbox.Root
                                                        className="CheckboxRoot"
                                                        onCheckedChange={(isChecked) => { setMedioAmbiente(isChecked) }}
                                                    >
                                                        <Checkbox.Indicator className="CheckboxIndicator">
                                                            <CheckIcon />
                                                        </Checkbox.Indicator>
                                                    </Checkbox.Root>
                                                    <label className="FormLabel" htmlFor="c1" style={{ marginLeft: "1rem" }}>
                                                        Asegurado medio ambiente
                                                    </label>
                                                </div>

                                                {
                                                    permisos && permisos.length > 1 ?
                                                        (
                                                            <div className="form__group_form-select">
                                                                <Form.Field
                                                                    className="FormField boxSizing__bb selectTriggerCustom form__group_selectField"

                                                                >
                                                                    <Form.Label style={{ margin: 0 }} className="FormLabel">
                                                                        Empresa
                                                                    </Form.Label>
                                                                    <Form.Control asChild>
                                                                        <select
                                                                            className="SelectTrigger"
                                                                            required
                                                                            name="empresa"
                                                                            onChange={handleInputChange}
                                                                            style={{ width: "100%", padding: ".5rem", margin: 0, height: "100%" }}
                                                                        >
                                                                            <option value="">
                                                                                Escoge una empresa
                                                                            </option>
                                                                            {
                                                                                permisos.length > 0 && permisos ?
                                                                                    permisos.map((permiso, index) => (
                                                                                        <option key={index} value={permiso}>{permiso}</option>
                                                                                    )) : ""
                                                                            }
                                                                        </select>
                                                                    </Form.Control>
                                                                </Form.Field>

                                                            </div>
                                                        ) : ""


                                                }

                                                {/* NumeroUnidad */}
                                                <Form.Field
                                                    className="FormField"
                                                    name="numUnidad"
                                                    onChange={handleInputChange}
                                                >
                                                    <Form.Label style={{ margin: 0 }} className="FormLabel">
                                                        Número de la unidad
                                                    </Form.Label>
                                                    <Form.Control asChild>
                                                        <input
                                                            className="Input boxSizing__bb"
                                                            type="text"
                                                            required
                                                            placeholder="Unidad"

                                                        />
                                                    </Form.Control>
                                                </Form.Field>



                                                <div className="form__group_form-select">
                                                    <Form.Field
                                                        className="FormField boxSizing__bb selectTriggerCustom form__group_selectField"

                                                    >
                                                        <Form.Label style={{ margin: 0 }} className="FormLabel">
                                                            Configuración vehícular
                                                        </Form.Label>
                                                        <Form.Control asChild>
                                                            <select
                                                                className="SelectTrigger"
                                                                required
                                                                name="confVehicular"
                                                                onChange={handleInputChange}
                                                                style={{ width: "100%", padding: ".5rem", margin: 0, height: "100%" }}
                                                            >
                                                                <option value="">
                                                                    Escoge una opción
                                                                </option>
                                                                {
                                                                    confVehicularData.length > 0 && confVehicularData ?
                                                                        confVehicularData.map((confVehicular) => (
                                                                            <option value={confVehicular.Clave}>{`${confVehicular.Clave} - ${confVehicular.confVehicular}`} </option>
                                                                        )) : ""
                                                                }
                                                            </select>
                                                        </Form.Control>
                                                    </Form.Field>



                                                    {/* Peso */}
                                                    <Form.Field
                                                        className="FormField form__group_selectField"
                                                        name="pesoVehiculo"
                                                        onChange={handleInputChange}
                                                    >
                                                        <Form.Label style={{ margin: 0 }} className="FormLabel">
                                                            Peso del vehículo
                                                        </Form.Label>
                                                        <Form.Control asChild>
                                                            <input
                                                                className="Input boxSizing__bb"
                                                                type="number"
                                                                required
                                                                placeholder="Peso en Toneladas"
                                                            />
                                                        </Form.Control>
                                                    </Form.Field>
                                                </div>


                                                <div className="form__group_form-select">
                                                    {/* Assurance name */}
                                                    <Form.Field
                                                        className="FormField form__group_selectField"
                                                        name="placaVehiculo"
                                                        onChange={handleInputChange}
                                                    >
                                                        <Form.Label style={{ margin: 0 }} className="FormLabel">
                                                            Placa del vehiculo
                                                        </Form.Label>
                                                        <Form.Control asChild>
                                                            <input
                                                                className="Input boxSizing__bb"
                                                                type="text"
                                                                maxLength="7"
                                                                minLength="5"
                                                                required
                                                                placeholder="AAA0223"
                                                            />
                                                        </Form.Control>
                                                    </Form.Field>

                                                    <Form.Field
                                                        className="FormField form__group_selectField"
                                                        name="anioVehiculo"
                                                        onChange={handleInputChange}
                                                    >
                                                        <Form.Label style={{ margin: 0 }} className="FormLabel">
                                                            Año de vehiculo
                                                        </Form.Label>
                                                        <Form.Control asChild>
                                                            <input
                                                                className="Input boxSizing__bb"
                                                                type="number"
                                                                maxLength="4"
                                                                required
                                                                placeholder="2024"
                                                            />
                                                        </Form.Control>
                                                    </Form.Field>
                                                </div>




                                                <div className="form__group_form-select">
                                                    {/* Assurance name */}
                                                    <Form.Field
                                                        className="FormField form__group_selectField"
                                                        name="nombreSeguro"
                                                        onChange={handleInputChange}
                                                    >
                                                        <Form.Label style={{ margin: 0 }} className="FormLabel">
                                                            Nombre de la aseguradora
                                                        </Form.Label>
                                                        <Form.Control asChild>
                                                            <input
                                                                className="Input boxSizing__bb"
                                                                maxLength="50"
                                                                type="text"
                                                                required
                                                                placeholder="Escribe el nombre de la aseguradora"
                                                            />
                                                        </Form.Control>
                                                    </Form.Field>

                                                    <Form.Field
                                                        className="FormField form__group_selectField"
                                                        name="numPoliza"
                                                        onChange={handleInputChange}
                                                    >
                                                        <Form.Label style={{ margin: 0 }} className="FormLabel">
                                                            Número de poliza
                                                        </Form.Label>
                                                        <Form.Control asChild>
                                                            <input
                                                                maxLength="30"
                                                                className="Input boxSizing__bb"
                                                                type="text"
                                                                required
                                                                placeholder="00000000"
                                                            />
                                                        </Form.Control>
                                                    </Form.Field>
                                                </div>

                                                {
                                                    medioAmbiente ? (

                                                        <div className="form__group_form-select">
                                                            {/* Assurance name */}
                                                            <Form.Field
                                                                className="FormField form__group_selectField"
                                                                name="aseguraMedAmbiente"
                                                                onChange={handleInputChange}
                                                            >
                                                                <Form.Label style={{ margin: 0 }} className="FormLabel">
                                                                    Aseguradora medio ambiente
                                                                </Form.Label>
                                                                <Form.Control asChild>
                                                                    <input
                                                                        className="Input boxSizing__bb"
                                                                        maxLength="50"
                                                                        type="text"
                                                                        required

                                                                        placeholder="Aseguradora de daños al medio ambiente"
                                                                    />
                                                                </Form.Control>
                                                            </Form.Field>

                                                            <Form.Field
                                                                className="FormField form__group_selectField"
                                                                name="polizaMedAmbiente"
                                                                onChange={handleInputChange}
                                                            >
                                                                <Form.Label style={{ margin: 0 }} className="FormLabel">
                                                                    Poliza medio ambiente
                                                                </Form.Label>
                                                                <Form.Control asChild>
                                                                    <input
                                                                        maxLength="30"
                                                                        className="Input boxSizing__bb"
                                                                        type="text"
                                                                        required
                                                                        placeholder="00000000"
                                                                    />
                                                                </Form.Control>
                                                            </Form.Field>
                                                        </div>
                                                    ) : ""
                                                }


                                                <div className="form__group_form-select">
                                                    <Form.Field
                                                        className="FormField boxSizing__bb form__group_selectField"
                                                        name="permisoSCT"
                                                        value={transport.permisoSCT}
                                                    >
                                                        <Form.Label style={{ margin: 0 }} className="FormLabel">
                                                            Tipo de Permiso SCT
                                                        </Form.Label>
                                                        <Form.Control asChild>
                                                            <select
                                                                className="SelectTrigger"
                                                                required
                                                                onChange={handleInputChange}
                                                                style={{ width: "100%", padding: ".5rem", margin: 0, height: "100%" }}
                                                            >
                                                                <option value="">
                                                                    Escoge una opción
                                                                </option>
                                                                {
                                                                    permisoSCTData && permisoSCTData.length > 0 ?
                                                                        permisoSCTData.map((permiso) => (
                                                                            <option value={permiso.Clave} >{`${permiso.Clave} - ${permiso.permisoSCT}`}</option >
                                                                        )) : ""
                                                                }

                                                            </select>
                                                        </Form.Control>
                                                    </Form.Field>
                                                    <Form.Field
                                                        name="numPermisoSCT"
                                                        className="FormField form__group_selectField"
                                                    >
                                                        <Form.Label style={{ margin: 0 }} className="FormLabel">
                                                            Número de permiso SCT
                                                        </Form.Label>
                                                        <Form.Control asChild>
                                                            <input
                                                                className="Input boxSizing__bb"
                                                                maxLength="50"
                                                                type="text"
                                                                placeholder="Número de permiso SCT"
                                                                onChange={handleInputChange}
                                                                value={transport.numPermisoSCT}
                                                                required
                                                            ></input>
                                                        </Form.Control>
                                                    </Form.Field>
                                                </div>

                                                <div className="btn__container">
                                                    {/*Register driver btn in BD*/}

                                                    <Form.Submit asChild>
                                                        <button
                                                            type="submit"
                                                            className="btn__login"
                                                            style={{ marginTop: 10, width: "230px", display: "flex", justifyContent: "center", alignItems: "center" }}
                                                            onClick={registerTransport}
                                                        >
                                                            {
                                                                isLoading ? (
                                                                    <SyncLoader
                                                                        color="white"
                                                                        loading={isLoading}
                                                                        size={20}
                                                                        aria-label="Loading Spinner"
                                                                        data-testid="loader"
                                                                        speedMultiplier={.7}
                                                                    />

                                                                ) : "Registrar Transporte"
                                                            }

                                                        </button>
                                                    </Form.Submit>
                                                </div>
                                            </div>
                                        </div>

                                    </Form.Root>
                                </div>
                            </div>
                        </Tabs.Content>

                        <Tabs.Content
                            className="TabsContent"
                            value="tab2">
                            {/* Transports registered in a table */}
                            <h3>Transportes registrados</h3>

                            <section className=''>
                                <div className='cartaPorte__group'>
                                    <div className="cartaPorte__group_elements">
                                        <label className='FormLabel'>Placa del Vehiculo</label>
                                        <input
                                            className='InputForm'
                                            name="placaVehiculo"
                                            value={filterTransports.placaVehiculo}
                                            onChange={handleChangeFilter}
                                        >

                                        </input>
                                    </div>
                                    <div className="cartaPorte__group_elements">
                                        <label className='FormLabel'>Número de Unidad</label>
                                        <input
                                            className='InputForm'
                                            style={{ width: "100%" }}
                                            name="numUnidad"
                                            value={filterTransports.numUnidad}
                                            onChange={handleChangeFilter}
                                        >
                                        </input>
                                    </div>
                                    <div className="cartaPorte__group_elements">
                                        <label className='FormLabel'>Asegurado Medio Ambiente</label>
                                        <select
                                            className='SelectTrigger'
                                            style={{ width: "100%" }}
                                            name="medAmbiente"
                                            value={filterTransports.medAmbiente}
                                            onChange={handleChangeFilter}
                                        >
                                            <option value="">Selecciona una opción</option>
                                            <option value={true}>Sí</option>
                                            <option value={false}>No</option>

                                        </select>
                                    </div>

                                    <div className="cartaPorte__group_elements">
                                        <label className='FormLabel'>Permiso SCT</label>
                                        <select
                                            className='SelectTrigger'
                                            style={{ width: "100%" }}
                                            name="permisoSCT"
                                            value={filterTransports.permisoSCT}
                                            onChange={handleChangeFilter}
                                        >
                                            <option value="">Selecciona un permiso</option>
                                            {
                                                permisoSCTData && permisoSCTData.length > 0 ?
                                                    permisoSCTData.map((permisoSCT) => (
                                                        <option key={permisoSCT.Clave} value={permisoSCT.Clave}>{permisoSCT.Clave} - {permisoSCT.permisoSCT}</option>
                                                    )) : ""
                                            }
                                        </select>
                                    </div>

                                    <div className="cartaPorte__group_elements">
                                        <label className='FormLabel'>Configuración Vehicular</label>
                                        <select
                                            className='SelectTrigger'
                                            style={{ width: "100%" }}
                                            name="confVehicular"
                                            value={filterTransports.confVehicular}
                                            onChange={handleChangeFilter}
                                        >
                                            <option value="">Selecciona una configuración vehicular</option>
                                            {
                                                confVehicularData && confVehicularData.length > 0 ?
                                                    confVehicularData.map((confVehicular, index) => (
                                                        <option key={index} value={confVehicular.Clave}>{confVehicular.Clave} -{confVehicular.confVehicular}</option>
                                                    )) : ""
                                            }
                                        </select>
                                    </div>

                                    {
                                        permisos && permisos.length > 1 ? (
                                            <div className="cartaPorte__group_elements">
                                                <label className='FormLabel'>Empresa</label>
                                                <select
                                                    className='SelectTrigger'
                                                    style={{ width: "100%" }}
                                                    name="empresa"
                                                    value={filterTransports.empresa}
                                                    onChange={handleChangeFilter}
                                                >
                                                    <option value="">Selecciona una empresa</option>
                                                    {
                                                        permisos.map((permiso, index) => (
                                                            <option key={index} value={permiso} >{permiso}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        ) : ""
                                    }




                                    <div className="cartaPorte__group_elements" style={{ justifyContent: "flex-end" }} >
                                        <div className='btn__container_search'>
                                            <button
                                                type="submit"
                                                disabled={isLoadingSearch}
                                                className="btn__login"
                                                style={{ marginTop: 10, width: "240px", display: "flex", justifyContent: "center", alignItems: "center" }}
                                                onClick={fetchTransportsData}
                                            >
                                                {
                                                    isLoadingSearch ? (
                                                        <SyncLoader
                                                            color="white"
                                                            loading={isLoadingSearch}
                                                            size={20}
                                                            aria-label="Loading Spinner"
                                                            data-testid="loader"
                                                            speedMultiplier={.7}
                                                        />

                                                    ) : "Buscar Transportes"
                                                    // <FaSearch />
                                                }

                                            </button>
                                        </div>

                                    </div>


                                </div>

                            </section>
                            <header
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    gap: "1rem",
                                }}
                            ></header>
                            <div className="card">
                                <DataTable
                                    value={transports}
                                    paginator
                                    header={renderHeader}
                                    ref={dt}
                                    filters={filters}
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    tableStyle={{ minWidth: "50rem" }}
                                    editMode="row"
                                    dataKey="_id"
                                    onRowEditComplete={onRowEditComplete}
                                >
                                    <Column field="_id" header="ID" hidden />
                                    <Column
                                        field="empresa"
                                        header="Empresa"
                                        sortable
                                        editor={(options) => textEditor(options)}
                                    />
                                    <Column
                                        field="placaVehiculo"
                                        header="Placa del Vehículo"
                                        sortable
                                        editor={(options) => textEditor(options)}
                                    />
                                    <Column
                                        field="numUnidad"
                                        header="Número de Unidad"
                                        sortable
                                        editor={(options) => textEditor(options)}
                                    />
                                    <Column
                                        field="anioVehiculo"
                                        header="Año del Vehículo"
                                        sortable
                                        editor={(options) => textEditor(options)}
                                    />
                                    <Column
                                        field="nombreSeguro"
                                        header="Aseguradora"
                                        sortable
                                        editor={(options) => textEditor(options)}
                                    />

                                    <Column
                                        field="numPoliza"
                                        header="Númeor de Poliza"
                                        sortable
                                        editor={(options) => textEditor(options)}
                                    />
                                    <Column
                                        field="aseguraMedAmbiente"
                                        header="Aseguradora Med Ambiente"
                                        sortable
                                        editor={(options) => textEditor(options)}
                                    />

                                    <Column
                                        field="polizaMedAmbiente"
                                        header="Poliza Med Ambiente"
                                        sortable
                                        editor={(options) => textEditor(options)}
                                    />

                                    <Column
                                        field="pesoVehiculo"
                                        header="Peso del Vehículo"
                                        sortable
                                        editor={(options) => textEditor(options)}
                                    />

                                    <Column
                                        field="confVehicular"
                                        header="Configuración Vehícular"
                                        sortable
                                        editor={(options) => selectConfVehiEditor(options)}
                                    />
                                    <Column
                                        field="permisoSCT"
                                        header="Permiso SCT"
                                        sortable
                                        editor={(options) => selectPermisoEditor(options)}
                                    />
                                    <Column
                                        field="numPermisoSCT"
                                        header="Número de perm SCT"
                                        sortable
                                        editor={(options) => textEditor(options)}
                                    />
                                    {/*Edit btn */}
                                    <Column

                                        headerStyle={{ width: "10%", minWidth: "8rem" }}
                                        bodyStyle={{ textAlign: "center" }}
                                        body={bodyEditTransport}
                                    ></Column>
                                    {/*Delete btn */}
                                    <Column
                                        body={actionBodyTemplate}
                                        exportable={false}
                                        style={{ minWidth: "12rem" }}
                                    ></Column>
                                </DataTable>
                                {/*Delete driver Dialog */}
                                <Dialog.Root
                                    open={isDialogOpen}
                                    onOpenChange={() => setIsDialogOpen(false)}
                                >
                                    <Dialog.Portal>
                                        <Dialog.Overlay className="DialogOverlay" />
                                        <Dialog.Content
                                            className="DialogContent"
                                            style={{
                                                height: "290px",
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                width: "400px",
                                                backgroundColor: "white"
                                            }}>
                                            <Dialog.Title className="DialogTitle" style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",

                                            }}>
                                                <CrossCircledIcon
                                                    style={{
                                                        width: "78px",
                                                        height: "90px",
                                                        color: "red",
                                                        marginBottom: "10px"
                                                    }}
                                                />
                                                Eliminar Transporte
                                            </Dialog.Title>
                                            <Dialog.Description className="DialogDescription">
                                                ¿Estás seguro de que deseas eliminar el transporte con placa: <b>{transportToDelete?.placaVehiculo}</b>?
                                            </Dialog.Description>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    gap: "20px",
                                                    marginTop: "14px"
                                                }}
                                            >
                                                <Dialog.Close asChild>
                                                    <Button
                                                        className="EliminarButton"
                                                        onClick={handleDeleteConfirmation}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </Dialog.Close>
                                                <Dialog.Close asChild>
                                                    <Button
                                                        aria-label="Close"
                                                        className="CancelarButton"
                                                    >
                                                        Cancelar
                                                    </Button>
                                                </Dialog.Close>
                                            </div>
                                        </Dialog.Content>
                                    </Dialog.Portal>
                                </Dialog.Root>
                                <Dialog.Root
                                    open={isEditDialogOpen}
                                    onOpenChange={() => { setIsEditDialogOpen(false) }}
                                >
                                    <Dialog.Portal>
                                        <Dialog.Overlay className="DialogOverlay" />
                                        <Dialog.Content className="DialogContentCP dialogEdit" style={{ backgroundColor: "white" }}>
                                            <Dialog.Title className="DialogTitle">
                                                Editar Transporte
                                            </Dialog.Title>

                                            <form onSubmit={handleEditTransport}>

                                                <div
                                                    className="cartaPorte__group_elements"
                                                    style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "300px", marginTop: "1.3rem" }}
                                                >
                                                    <Checkbox.Root
                                                        className="CheckboxRoot"
                                                        checked={transportToEdit?.medAmbiente}
                                                        onCheckedChange={(isChecked) => setTransportToEdit({ ...transportToEdit, medAmbiente: isChecked })}
                                                    >
                                                        <Checkbox.Indicator className="CheckboxIndicator">
                                                            <CheckIcon />
                                                        </Checkbox.Indicator>
                                                    </Checkbox.Root>
                                                    <label className="FormLabel" htmlFor="c1" style={{ marginLeft: "1rem" }}>
                                                        Asegurado medio ambiente
                                                    </label>
                                                </div>
                                                <br></br>
                                                {
                                                    permisos && permisos.length > 1 ?
                                                        (
                                                            <div className="dialogEditGroup_elements">
                                                                <label className="FormLabel" htmlFor="name">
                                                                    Empresa
                                                                </label>

                                                                <select
                                                                    className="SelectTrigger"
                                                                    type="text"
                                                                    name="empresa"
                                                                    onChange={handleEditTransportChange}
                                                                    required
                                                                    value={transportToEdit?.empresa}
                                                                >
                                                                    <option value="">Selecciona una empresa</option>
                                                                    {
                                                                        permisos.map((permiso, index) => (
                                                                            <option key={index} value={permiso}>{permiso}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        ) : ""

                                                }


                                                <div className="dialogEditGroup">

                                                    <div className="dialogEditGroup_elements">
                                                        <label className="FormLabel" htmlFor="name">
                                                            Número de la unidad
                                                        </label>

                                                        <input
                                                            className="InputForm"
                                                            type="text"
                                                            name="numUnidad"
                                                            onChange={handleEditTransportChange}
                                                            placeholder="Número de Unidad"
                                                            required
                                                            value={transportToEdit?.numUnidad}
                                                        ></input>
                                                    </div>

                                                    <div className="dialogEditGroup_elements">
                                                        <label className="FormLabel" htmlFor="name">
                                                            Configuración Vehicular
                                                        </label>
                                                        <select
                                                            className="SelectTrigger"
                                                            required
                                                            value={transportToEdit?.confVehicular}
                                                            name="confVehicular"
                                                            onChange={handleEditTransportChange}
                                                            style={{ width: "100%", padding: ".5rem", margin: 0, height: "100%" }}
                                                        >
                                                            <option value="">
                                                                Escoge una opción
                                                            </option>
                                                            {
                                                                confVehicularData.length > 0 && confVehicularData ?
                                                                    confVehicularData.map((confVehicular) => (
                                                                        <option value={confVehicular.Clave}>{`${confVehicular.Clave} - ${confVehicular.confVehicular}`} </option>
                                                                    )) : ""
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="dialogEditGroup_elements">
                                                        <label className="FormLabel" htmlFor="name">
                                                            Peso del Vehículo
                                                        </label>
                                                        <input
                                                            className="InputForm"
                                                            type="number"
                                                            name="pesoVehiculo"
                                                            onChange={handleEditTransportChange}
                                                            placeholder="Peso en Toneladas"
                                                            required
                                                            value={transportToEdit?.pesoVehiculo}
                                                        ></input>
                                                    </div>

                                                    <div className="dialogEditGroup_elements">
                                                        <label className="FormLabel" htmlFor="name">
                                                            Placa del Vehículo
                                                        </label>

                                                        <input
                                                            className="InputForm"
                                                            type="text"
                                                            maxLength="7"
                                                            minLength="5"
                                                            name="placaVehiculo"
                                                            onChange={handleEditTransportChange}
                                                            placeholder="AAA0223"
                                                            required
                                                            value={transportToEdit?.placaVehiculo}
                                                        ></input>
                                                    </div>


                                                    <div className="dialogEditGroup_elements">
                                                        <label className="FormLabel" htmlFor="name">
                                                            Año de vehiculo
                                                        </label>

                                                        <input
                                                            className="InputForm"
                                                            type="text"
                                                            minLength="4"
                                                            name="anioVehiculo"
                                                            onChange={handleEditTransportChange}
                                                            placeholder="2024"
                                                            required
                                                            value={transportToEdit?.anioVehiculo}
                                                        ></input>
                                                    </div>


                                                    <div className="dialogEditGroup_elements">
                                                        <label className="FormLabel" htmlFor="name">
                                                            Nombre de la aseguradora
                                                        </label>

                                                        <input
                                                            className="InputForm"
                                                            type="text"
                                                            minLength="4"
                                                            maxLength="50"
                                                            name="nombreSeguro"
                                                            onChange={handleEditTransportChange}
                                                            placeholder="Escribe el nombre de la aseguradora"
                                                            required
                                                            value={transportToEdit?.nombreSeguro}
                                                        ></input>
                                                    </div>

                                                    <div className="dialogEditGroup_elements">
                                                        <label className="FormLabel" htmlFor="name">
                                                            Número de poliza
                                                        </label>

                                                        <input
                                                            className="InputForm"
                                                            type="text"
                                                            minLength="3"
                                                            maxLength="30"
                                                            name="numPoliza"
                                                            onChange={handleEditTransportChange}
                                                            placeholder="00000000"
                                                            required
                                                            value={transportToEdit?.numPoliza}
                                                        ></input>
                                                    </div>

                                                    {
                                                        transportToEdit?.medAmbiente ? (
                                                            <>
                                                                <div className="dialogEditGroup_elements">
                                                                    <label className="FormLabel" htmlFor="name">
                                                                        Aseguradora medio ambiente
                                                                    </label>

                                                                    <input
                                                                        className="InputForm"
                                                                        type="text"
                                                                        minLength="3"
                                                                        maxLength="50"
                                                                        name="aseguraMedAmbiente"
                                                                        onChange={handleEditTransportChange}
                                                                        placeholder="Aseguradora de daños al medio ambiente"
                                                                        required
                                                                        value={transportToEdit?.aseguraMedAmbiente}
                                                                    ></input>
                                                                </div>

                                                                <div className="dialogEditGroup_elements">
                                                                    <label className="FormLabel" htmlFor="name">
                                                                        Poliza medio ambiente
                                                                    </label>
                                                                    <input
                                                                        className="InputForm"
                                                                        type="text"
                                                                        minLength="3"
                                                                        maxLength="30"
                                                                        name="polizaMedAmbiente"
                                                                        onChange={handleEditTransportChange}
                                                                        placeholder="00000000"
                                                                        required
                                                                        value={transportToEdit?.polizaMedAmbiente}
                                                                    ></input>
                                                                </div>

                                                            </>

                                                        ) : ""
                                                    }

                                                    <div className="dialogEditGroup_elements">
                                                        <label className="FormLabel" htmlFor="name">
                                                            Tipo de Permiso SCT
                                                        </label>
                                                        <select
                                                            className="SelectTrigger"
                                                            required
                                                            name="permisoSCT"
                                                            value={transportToEdit?.permisoSCT}
                                                            onChange={handleEditTransportChange}
                                                            style={{ width: "100%", padding: ".5rem", margin: 0, height: "100%" }}
                                                        >
                                                            <option value="">
                                                                Escoge una opción
                                                            </option>
                                                            {
                                                                permisoSCTData && permisoSCTData.length > 0 ?
                                                                    permisoSCTData.map((permiso) => (
                                                                        <option value={permiso.Clave} >{`${permiso.Clave} - ${permiso.permisoSCT}`}</option >
                                                                    )) : ""
                                                            }

                                                        </select>
                                                    </div>



                                                    <div className="dialogEditGroup_elements">
                                                        <label className="FormLabel" htmlFor="name">
                                                            Número de permiso SCT
                                                        </label>
                                                        <input
                                                            name="numPermisoSCT"
                                                            className="InputForm"
                                                            maxLength="50"
                                                            type="text"
                                                            placeholder="Número de permiso SCT"
                                                            onChange={handleEditTransportChange}
                                                            value={transportToEdit?.numPermisoSCT}
                                                            required
                                                        ></input>
                                                    </div>

                                                </div>

                                                <div className="btn__container">


                                                    <button className="btn__login" style={{ marginTop: "2rem", width: "240px", display: "flex", justifyContent: "center", alignItems: "center" }} type="submit" disabled={isLoading}>

                                                        {
                                                            isLoading ? (
                                                                <SyncLoader
                                                                    color="white"
                                                                    loading={isLoading}
                                                                    size={20}
                                                                    aria-label="Loading Spinner"
                                                                    data-testid="loader"
                                                                    speedMultiplier={.7}
                                                                />

                                                            ) : "Actualizar Transporte"

                                                        }

                                                    </button>
                                                </div>


                                            </form>
                                            <Dialog.Close asChild></Dialog.Close>
                                            <Dialog.Close asChild>
                                                <button className="IconButton" aria-label="Close">
                                                    <Cross2Icon />
                                                </button>
                                            </Dialog.Close>
                                        </Dialog.Content>
                                    </Dialog.Portal>
                                </Dialog.Root>
                            </div>
                        </Tabs.Content>
                    </Tabs.Root>
                </div>
            </section>
        </div>
    )
}
