import React, { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Header from "../components/Header";
import Dashboard from "../components/Dashboard";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import AdminChoferes from "../components/subcomponents/AdminChoferes";
import AsignarRuta from "../components/subcomponents/AsignarRuta";
import ReporteRutas from "../components/subcomponents/ReporteRutas";
import RutasAsignadas from "../components/subcomponents/RutasAsignadas";
import RegistrarClientes from "../components/subcomponents/RegistrarCliente";
import { NotificationProvider } from "./NotificationContext";
import { AdminTransportes } from "../components/subcomponents/AdminTransportes";
import { AdminRemolques } from "../components/subcomponents/AdminRemolques";

function Main() {
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();
  const [showAdminChoferes, setShowAdminChoferes] = useState(false);
  const [showRutas, setShowRutas] = useState(false);
  const [showReporte, setShowReporte] = useState(false);
  const [showRutasAsignadas, setShowRutasAsignadas] = useState(false);
  const [showRegistroClientes, setShowRegistroClientes] = useState(false);
  const [showAdminTransp, setAdminTransp] = useState(false);
  const [showRemolques, setShowRemolques] = useState(false);

  const handleAdminChoferesClick = () => {
    setShowAdminChoferes(true);
    setShowRutas(false);
    setShowReporte(false);
    setShowRutasAsignadas(false);
    setShowRegistroClientes(false);
    setShowRemolques(false);
    setAdminTransp(false)
  };

  const handleRutasClick = () => {
    setShowAdminChoferes(false);
    setShowRutas(true);
    setShowReporte(false);
    setShowRutasAsignadas(false);
    setShowRegistroClientes(false);
    setShowRemolques(false);
    setAdminTransp(false)
  };

  const handleReporteClick = () => {
    setShowAdminChoferes(false);
    setShowRutas(false);
    setShowReporte(true);
    setShowRutasAsignadas(false);
    setShowRegistroClientes(false);
    setShowRemolques(false);
    setAdminTransp(false)
  };

  const handleRutasAsignadasClick = () => {
    setShowAdminChoferes(false);
    setShowRutas(false);
    setShowReporte(false);
    setShowRutasAsignadas(true);
    setShowRegistroClientes(false);
    setShowRemolques(false);
    setAdminTransp(false)
  };

  const handleRegistroClientes = () => {
    setShowAdminChoferes(false);
    setShowRutas(false);
    setShowReporte(false);
    setShowRutasAsignadas(false);
    setShowRegistroClientes(true);
    setAdminTransp(false)
    setShowRemolques(false);

  };

  const handleAdminTranspClientes = () => {
    setShowAdminChoferes(false);
    setShowRutas(false);
    setShowReporte(false);
    setShowRutasAsignadas(false);
    setShowRegistroClientes(false);
    setAdminTransp(true)
    setShowRemolques(false);
  };
  const handleAdminRemolques = () => {
    setShowAdminChoferes(false);
    setShowRutas(false);
    setShowReporte(false);
    setShowRutasAsignadas(false);
    setShowRegistroClientes(false);
    setAdminTransp(false);
    setShowRemolques(true);
  }

  const notify = () => {
    toast.success("¡Inicio de sesión exitoso!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const previousToken = localStorage.getItem("previousToken");
    if (!token) {
      navigate("/", { replace: true });
    } else {
      const user = jwtDecode(token);
      const decodedUser = jwtDecode(token);
      if (decodedUser) {
        setUserName(decodedUser.name);
      }

      if (token !== previousToken) {
        notify();
        localStorage.setItem("previousToken", token);
      }

      if (!user) {
        localStorage.removeItem("token");
        navigate("/", { replace: true });
      } else {
      }
    }
  }, [navigate, notify]);

  const isTokenExpired = (token) => {
    if (!token) {
      return true;
    }

    const decodedToken = jwtDecode(token);

    if (!decodedToken.exp) {
      return true;
    }

    const expirationTime = decodedToken.exp * 1000;
    const currentTime = Date.now();

    return currentTime > expirationTime;
  };

  const token = localStorage.getItem("token");
  const isExpired = isTokenExpired(token);
  if (isExpired) {
    //console.log("El token ha caducado.");
    localStorage.removeItem("token");
  } else {
    //console.log("Token vigente");
  }

  return (
    <NotificationProvider>
      <div>
        <Header />
        <Menu
          onAdminChoferesClick={handleAdminChoferesClick}
          onAsignarRutasClick={handleRutasClick}
          onReporteRutasClick={handleReporteClick}
          onRutasAsignadasClick={handleRutasAsignadasClick}
          onRegistroClienteClick={handleRegistroClientes}
          onAdminTranspClienteClick={handleAdminTranspClientes}
          onAdminRemolquesClick={handleAdminRemolques}
        />
        {showAdminChoferes ? <AdminChoferes /> : null}
        {showRutas ? <AsignarRuta /> : null}
        {showReporte ? <ReporteRutas /> : null}
        {showRutasAsignadas ? <RutasAsignadas /> : null}
        {showRegistroClientes ? <RegistrarClientes /> : null}
        {showAdminTransp ? <AdminTransportes /> : null}
        {showRemolques ? <AdminRemolques /> : null}
        {!showAdminChoferes &&
          !showRutas &&
          !showReporte &&
          !showRutasAsignadas &&
          !showRegistroClientes &&
          !showAdminTransp &&
          !showRemolques ? (
          <Dashboard />
        ) : null}
        <Footer />
        <ToastContainer />
      </div>
    </NotificationProvider>
  );
}
export default Main;
