import "../../styles/alert.css";
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { API_URL } from "../../constants";
import * as Dialog from "@radix-ui/react-dialog";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { toast } from "react-toastify";
import xmljs from "xml-js";
import xml2js from "xml2js";
import XMLParser from "react-xml-parser";
import { PageInfo } from "../PageInfo";

function ReporteRutas() {
  const [route, setRoute] = useState("");
  const [factura, setFactura] = useState("");
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    username: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    origin: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    destination: { value: null, matchMode: FilterMatchMode.IN },
    distance: { value: null, matchMode: FilterMatchMode.EQUALS },
    duration: { value: null, matchMode: FilterMatchMode.EQUALS },
    customerFlete: { value: null, matchMode: FilterMatchMode.EQUALS },
    customerFactura: { value: null, matchMode: FilterMatchMode.EQUALS },
    vehicle: { value: null, matchMode: FilterMatchMode.EQUALS },
    diesel: { value: null, matchMode: FilterMatchMode.EQUALS },
    assignedDate: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const dt = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogOpen2, setIsDialogOpen2] = useState(false);
  const [isDialogOpen3, setIsDialogOpen3] = useState(false);
  const [isDialogOpen4, setIsDialogOpen4] = useState(false);
  const [monto, setMonto] = useState("");
  const [numeroFactura, setNumero] = useState("");
  const [comprobante, setComprobante] = useState("");
  const [xml, setXML] = useState("");
  const [facturaToSave, setFacturaToSave] = useState(null);
  const [xmlObject, setXmlObject] = useState("");
  const parseString = require("xml2js").parseString;

  useEffect(() => {
    fetchRoutesData();
  }, []);

  //FETCH routes
  const fetchRoutesData = async () => {
    const responseRoutes = await fetch(`${API_URL}/api/routes`);
    const responseDrivers = await fetch(`${API_URL}/api/drivers-username`);

    if (!responseRoutes.ok || !responseDrivers.ok) {
      // Manejar errores de la solicitud si es necesario
      return;
    }

    const dataRoutes = await responseRoutes.json();
    const dataDrivers = await responseDrivers.json();

    // Obtener los usernames de los drivers con company "Power-Only"
    const powerOnlyUsernames = dataDrivers
      .filter((driver) => driver.company === "Power-Only")
      .map((driver) => driver.nombreAlternativo);

    // Filtrar las rutas basándote en los usernames obtenidos
    const powerOnlyRoutes = dataRoutes.routes.filter((route) =>
      powerOnlyUsernames.includes(route.usuario)
    );

    setRoute(powerOnlyRoutes);
  };

  const showPdf = (factura) => {
    const urlFile = factura;
    window.open(`${API_URL}/files/facturas/${urlFile}`);
  };

  const showPdfComprobante = (factura) => {
    const urlFile = factura;
    window.open(`${API_URL}/files/comprobantes/${urlFile}`);
  };

  const showPdfConfirmacion = (factura) => {
    const urlFile = factura;
    window.open(`${API_URL}/files/hoja-finalizacion/${urlFile}`);
  };

  function getStatusColor(estatus) {
    switch (estatus) {
      case "Pendiente Chofer":
        return "orange";
      case "Pendiente Gastos Diesel":
        return "orange";
      case "Pendiente Gastos Viaje":
        return "orange";
      case "Pendiente Precio Venta":
        return "orange";
      case "Pendiente Vehículo":
        return "orange";
      case "Pendiente Referencia":
        return "orange";
      case "Pendiente Facturación":
        return "blue";
      case "Pendiente Pago":
        return "blue";
      case "Viaje en curso":
        return "green";
      case "Finalizado":
        return "green";
      default:
        return "gray";
    }
  }

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const headers = [
        "_id",
        "usuario",
        "origen",
        "intermedio",
        "destino",
        "duracion",
        "distancia",
        "distanciaRecorrida",
        "movimiento",
        "contenedor",
        "referencia",
        "clienteFlete",
        "clienteFactura",
        "documentos",
        "vehiculo",
        "estatus",
        "fechaInicio",
        "asignadoPor",
        "fechaAsignado",
        "fechaFinal",
        "DescripcionConcepto",
        "Fecha",
        "Folio",
        "Moneda",
        "NombreEmisor",
        "RfcEmisor",
        "SubTotal",
        "Total",
        "TotalImpuestosRetenidos",
        "TotalImpuestosTrasladados",
      ];

      const trimmedRoute = route.map((row) => {
        const trimmedRow = {};
        headers.forEach((header) => {
          trimmedRow[header] = row[header];
        });
        return trimmedRow;
      });

      const worksheet = xlsx.utils.json_to_sheet(trimmedRoute);

      const headerStyle = {
        fill: {
          fgColor: { rgb: "FF0000" },
        },
        font: {
          color: { rgb: "FFFFFF" },
          bold: true,
        },
      };

      // Obtener rango de celdas de encabezado
      const headerRange = xlsx.utils.decode_range(worksheet["!ref"]);
      for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
        const headerCellRef = xlsx.utils.encode_cell({
          r: headerRange.s.r,
          c: col,
        });
        worksheet[headerCellRef].s = headerStyle;
      }

      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "viajes");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  // Upload factura on route assigned:
  const handleFileUpload = async () => {
    const nombreArchivo = `factura_${numeroFactura}.pdf`;
    const formData = new FormData();
    formData.append("monto", monto);
    formData.append("factura", factura, nombreArchivo);
    formData.append("_id", facturaToSave._id);
    try {
      const response = await fetch(`${API_URL}/api/uploadfactura`, {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        fetchRoutesData();
      } else {
        console.error("Failed to upload factura");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleComprobanteUpload = async () => {
    const formData = new FormData();
    formData.append("estatus", facturaToSave.estatus);
    formData.append("comprobante", comprobante);
    formData.append("_id", facturaToSave._id);
    //console.log(facturaToSave);
    try {
      const response = await fetch(`${API_URL}/api/uploadcomprobante`, {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        fetchRoutesData();
        notifyComprobante();
      } else {
        console.error("Failed to upload comprobante");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleXMLUpload = async (e) => {
    if (e && e.target && e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        const xmlString = event.target.result;
        setXML(xmlString);
        parseXmlToObject(xmlString);
      };
      reader.readAsText(file);
    }
    //console.log(xmlObject);

    if (xmlObject && xmlObject.attributes && xmlObject.attributes.Fecha) {
      const {
        attributes: { Fecha, Folio, SubTotal, Total, Moneda },
        children: [emisor, receptor, conceptos],
      } = xmlObject;
      const impuestos = xmlObject.children.find(
        (child) => child.name === "cfdi:Impuestos"
      );
      // Emisor
      const nombreEmisor = emisor.attributes.Nombre;
      const rfcEmisor = emisor.attributes.Rfc;

      // Concepto
      const concepto = conceptos.children[0];
      const descripcionConcepto = concepto.attributes.Descripcion;

      //Impuestos
      const totalImpuestosRetenidos =
        impuestos.attributes.TotalImpuestosRetenidos;
      const totalImpuestosTrasladados =
        impuestos.attributes.TotalImpuestosTrasladados;

      const formData = new FormData();
      formData.append("Fecha", Fecha || 0);
      formData.append("Folio", Folio || 0);
      formData.append("SubTotal", SubTotal || 0);
      formData.append("Total", Total || 0);
      formData.append("Moneda", Moneda || 0);
      formData.append("nombreEmisor", nombreEmisor || 0);
      formData.append("rfcEmisor", rfcEmisor || 0);
      formData.append("descripcionConcepto", descripcionConcepto || 0);
      formData.append("totalImpuestosRetenidos", totalImpuestosRetenidos || 0);
      formData.append(
        "totalImpuestosTrasladados",
        totalImpuestosTrasladados || 0
      );

      formData.append("_id", facturaToSave._id);
      formData.append("driver", facturaToSave.usuario);

      try {
        const response = await fetch(`${API_URL}/api/uploadxml`, {
          method: "POST",
          body: formData,
        });
        if (response.ok) {
          const data = await response.json();
          fetchRoutesData();
          notifyXML();
        } else {
          console.error("Failed to upload comprobante");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const parseXmlToObject = (xmlString) => {
    try {
      const xml = new XMLParser().parseFromString(xmlString);
      const xmlObject = xml;
      setXmlObject(xmlObject);
    } catch (error) {
      console.error("Error parsing XML to object:", error);
    }
  };

  const confirmDeleteDriver = (rowData) => {
    setFacturaToSave(rowData);
    setIsDialogOpen(true);
  };

  const handleSaveConfirmation = (e) => {
    e.preventDefault();
    if (facturaToSave) {
      handleFileUpload(facturaToSave._id);
    }
    setIsDialogOpen(false);
  };

  const handleSaveComprobante = (e) => {
    e.preventDefault();
    if (facturaToSave) {
      handleComprobanteUpload(facturaToSave._id);
    }
    setIsDialogOpen3(false);
  };

  const handleSaveXML = (e) => {
    e.preventDefault();
    //console.log(facturaToSave);
    if (facturaToSave) {
      handleXMLUpload(facturaToSave._id);
    }
    setIsDialogOpen4(false);
  };

  const actionBodyTemplate = (rowData) => {
    if (rowData.estatus != "Pendiente Facturación") {
      return null;
    }
    return (
      <React.Fragment>
        <Button
          icon="pi pi-save"
          rounded
          outlined
          severity="primary"
          placeholder="Guardar"
          className="ButtonPDF"
          onClick={() => confirmDeleteDriver(rowData)}
          style={{ fontSize: "1.5rem", color: "black" }}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate4 = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-save"
          rounded
          outlined
          severity="primary"
          placeholder="Guardar"
          className="ButtonPDF"
          onClick={() => confirmDeleteDriver4(rowData)}
          style={{ fontSize: "1.5rem", color: "black" }}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate3 = (rowData) => {
    if (rowData.estatus != "Pendiente Pago") {
      return null;
    }
    return (
      <React.Fragment>
        <Button
          icon="pi pi-save"
          rounded
          outlined
          severity="primary"
          placeholder="Guardar"
          className="ButtonPDF"
          onClick={() => confirmDeleteDriver3(rowData)}
          style={{ fontSize: "1.5rem", color: "black" }}
        />
      </React.Fragment>
    );
  };

  const confirmDeleteDriver2 = (rowData) => {
    setFacturaToSave(rowData);
    setIsDialogOpen2(true);
  };

  const confirmDeleteDriver3 = (rowData) => {
    setFacturaToSave(rowData);
    setIsDialogOpen3(true);
  };

  const confirmDeleteDriver4 = (rowData) => {
    setFacturaToSave(rowData);
    setIsDialogOpen4(true);
  };

  const actionBodyTemplate2 = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          rounded
          outlined
          severity="primary"
          placeholder="Guardar"
          className="ButtonPDF"
          onClick={() => confirmDeleteDriver2(rowData)}
          style={{ fontSize: "1.5rem", color: "black" }}
        />
      </React.Fragment>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            style={{ marginLeft: "30px" }}
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar"
          />
        </span>
      </div>
    );
  };

  const notifyComprobante = () => {
    toast.success("¡Comprobante subido con éxito!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const notifyXML = () => {
    toast.success("¡Datos subidos con éxito!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  return (
    <div>
      <div className="content-wrapper container__block">
        <PageInfo heading={"DATT - Tracking | Power Only"}/>
        
        <section className="content">
          <div className="container-fluid">
            <section className="content">
              <div className="">
                <h3>Viajes asignados</h3>
                <header
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Button
                    severity="success"
                    type="button"
                    icon="pi pi-file-excel"
                    rounded
                    onClick={exportExcel}
                    data-pr-tooltip="XLS"
                    style={{ fontSize: "1.5rem", color: "green" }}
                  />
                </header>
                <div className="card">
                  <DataTable
                    value={route}
                    header={renderHeader}
                    filters={filters}
                    paginator
                    ref={dt}
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: "50rem" }}
                    editMode="row"
                    dataKey="_id"
                  >
                    <Column field="_id" header="ID" hidden />
                    <Column
                      field="usuario"
                      header="Usuario"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="origen"
                      header="Origen"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="destino"
                      header="Destino"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="distancia"
                      header="Distancia (km)"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="duracion"
                      header="Duración (horas)"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="diesel"
                      header="Gastos Diesel"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="vehiculo"
                      header="Vehículo"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="fechaAsignado"
                      header="Fecha asignado"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      header="Documentos de viaje"
                      body={actionBodyTemplate2}
                      exportable={false}
                      style={{ minWidth: "12rem" }}
                    />
                    <Column
                      field="estatus"
                      header="Estatus"
                      sortable
                      style={{ width: "25%" }}
                      body={(rowData) => (
                        <div
                          style={{
                            background: getStatusColor(rowData.estatus),
                            padding: "5px",
                            borderRadius: "5px",
                            color: "white",
                            boxShadow: "0 0 5px rgba(0, 0, 0, 0.8)",
                          }}
                        >
                          {rowData.estatus}
                        </div>
                      )}
                    />
                    <Column
                      field="confirmacion"
                      header="Confirmación"
                      body={(rowData) => (
                        <button
                          onClick={() => {
                            if (rowData.documentoFinalizado) {
                              showPdfConfirmacion(rowData.documentoFinalizado);
                            }
                          }}
                          className="ButtonPDF"
                          disabled={!rowData.documentoFinalizado}
                        >
                          {rowData.documentoFinalizado
                            ? "Ver PDF"
                            : "No hay archivo"}
                        </button>
                      )}
                    />
                    <Column
                      field="facturacion"
                      header="Factura"
                      body={(rowData) => (
                        <button
                          onClick={() => {
                            if (rowData.factura) {
                              showPdf(rowData.factura);
                            }
                          }}
                          className="ButtonPDF"
                          disabled={!rowData.factura}
                        >
                          {rowData.factura ? "Ver PDF" : "No hay archivo"}
                        </button>
                      )}
                    />

                    <Column
                      field="facturacion"
                      header="Subir Factura"
                      style={{ minWidth: "12rem", marginLeft: "5px" }}
                      body={actionBodyTemplate}
                    />

                    <Column
                      field="xml"
                      header="Subir XML"
                      style={{ minWidth: "12rem", marginLeft: "5px" }}
                      body={actionBodyTemplate4}
                    />
                    <Column
                      field="comprobantePago"
                      header="Comprobante"
                      body={(rowData) => (
                        <button
                          onClick={() => {
                            if (rowData.comprobantePago) {
                              showPdfComprobante(rowData.comprobantePago);
                            }
                          }}
                          className="ButtonPDF"
                          disabled={!rowData.comprobantePago}
                        >
                          {rowData.comprobantePago
                            ? "Ver PDF"
                            : "No hay archivo"}
                        </button>
                      )}
                    />

                    <Column
                      field="comprobante"
                      header="Subir Comprobante"
                      style={{ minWidth: "12rem", marginLeft: "5px" }}
                      body={actionBodyTemplate3}
                    />
                  </DataTable>
                  <Dialog.Root
                    open={isDialogOpen}
                    onOpenChange={() => setIsDialogOpen(false)}
                  >
                    <Dialog.Portal>
                      <Dialog.Overlay className="DialogOverlay" />
                      <Dialog.Content className="DialogContentFactura">
                        <Dialog.Title className="DialogTitle">
                          Subir factura
                        </Dialog.Title>
                        <Dialog.Description className="DialogDescription">
                          Selecciona el archivo PDF de la factura y completa la
                          información.
                        </Dialog.Description>

                        <form
                          id="formRoute"
                          encType="multipart/form-data"
                          onSubmit={(e) => handleSaveConfirmation(e)}
                        >
                          <label className="FormLabel">Número de factura</label>
                          <input
                            className="Input"
                            type="text"
                            required
                            name="numero"
                            placeholder="Número de factura"
                            onChange={(e) => setNumero(e.target.value)}
                          />
                          <label className="FormLabel">
                            Monto de la factura
                          </label>
                          <input
                            className="Input"
                            type="text"
                            required
                            name="monto"
                            placeholder="Monto"
                            onChange={(e) => setMonto(e.target.value)}
                          />
                          <label className="FormLabel">
                            Selecciona el archivo
                          </label>
                          <input
                            className="Input"
                            type="file"
                            required
                            accept=".pdf"
                            name="factura"
                            onChange={(e) => setFactura(e.target.files[0])}
                          />
                          <div style={{ margin: "30px" }}>
                            <input
                              className="CancelarButton"
                              type="submit"
                              value="Subir Factura"
                            />
                            <Dialog.Close asChild>
                              <Button
                                aria-label="Close"
                                className="EliminarButton"
                              >
                                Cancelar
                              </Button>
                            </Dialog.Close>
                          </div>
                        </form>
                      </Dialog.Content>
                    </Dialog.Portal>
                  </Dialog.Root>
                  <Dialog.Root
                    open={isDialogOpen2}
                    onOpenChange={() => setIsDialogOpen2(false)}
                  >
                    <Dialog.Portal>
                      <Dialog.Overlay className="DialogOverlay" />
                      <Dialog.Content className="DialogContentFactura">
                        <Dialog.Title className="DialogTitle">
                          Documentos
                        </Dialog.Title>
                        <Dialog.Description className="DialogDescription">
                          Abajo se muestran los documentos que se anexaron por
                          cliente.
                        </Dialog.Description>

                        {facturaToSave && (
                          <div>
                            <h3>Documentos de Viaje</h3>
                            {facturaToSave.documentos.map((documento) => (
                              <div key={documento._id}>
                                <a
                                  className="btnArchivos"
                                  href={`${API_URL}${documento.path}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {documento.originalname}
                                </a>
                              </div>
                            ))}
                          </div>
                        )}

                        <Dialog.Close asChild>
                          <Button aria-label="Close" className="EliminarButton">
                            Cancelar
                          </Button>
                        </Dialog.Close>
                      </Dialog.Content>
                    </Dialog.Portal>
                  </Dialog.Root>

                  <Dialog.Root
                    open={isDialogOpen3}
                    onOpenChange={() => setIsDialogOpen3(false)}
                  >
                    <Dialog.Portal>
                      <Dialog.Overlay className="DialogOverlay" />
                      <Dialog.Content className="DialogContentFactura">
                        <Dialog.Title className="DialogTitle">
                          Subir comprobante de pago
                        </Dialog.Title>
                        <Dialog.Description className="DialogDescription">
                          Selecciona el archivo PDF del comprobante.
                        </Dialog.Description>

                        <form
                          id="formRoute"
                          encType="multipart/form-data"
                          onSubmit={(e) => handleSaveComprobante(e)}
                        >
                          <label className="FormLabel">
                            Selecciona el archivo
                          </label>
                          <input
                            className="Input"
                            type="file"
                            required
                            accept=".pdf"
                            name="comprobante"
                            onChange={(e) => setComprobante(e.target.files[0])}
                          />
                          <div style={{ margin: "30px" }}>
                            <input
                              className="CancelarButton"
                              type="submit"
                              value="Subir Comprobante"
                            />
                            <Dialog.Close asChild>
                              <Button
                                aria-label="Close"
                                className="EliminarButton"
                              >
                                Cancelar
                              </Button>
                            </Dialog.Close>
                          </div>
                        </form>
                      </Dialog.Content>
                    </Dialog.Portal>
                  </Dialog.Root>

                  <Dialog.Root
                    open={isDialogOpen4}
                    onOpenChange={() => setIsDialogOpen4(false)}
                  >
                    <Dialog.Portal>
                      <Dialog.Overlay className="DialogOverlay" />
                      <Dialog.Content className="DialogContentFactura">
                        <Dialog.Title className="DialogTitle">
                          Subir archivo XML
                        </Dialog.Title>
                        <Dialog.Description className="DialogDescription">
                          Selecciona el archivo XML.
                        </Dialog.Description>

                        <form
                          id="formRouteXML"
                          encType="multipart/form-data"
                          onSubmit={(e) => handleSaveXML(e)}
                        >
                          <label className="FormLabel">
                            Selecciona el archivo
                          </label>
                          <input
                            className="Input"
                            type="file"
                            required
                            accept=".xml"
                            name="comprobante"
                            onChange={handleXMLUpload}
                          />
                          <div style={{ margin: "30px" }}>
                            <input
                              className="CancelarButton"
                              type="submit"
                              value="Subir XML"
                            />
                            <Dialog.Close asChild>
                              <Button
                                aria-label="Close"
                                className="EliminarButton"
                              >
                                Cancelar
                              </Button>
                            </Dialog.Close>
                          </div>
                        </form>
                      </Dialog.Content>
                    </Dialog.Portal>
                  </Dialog.Root>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ReporteRutas;
