import * as Form from "@radix-ui/react-form";
import React, { useState, useEffect, useRef } from "react";
import "@radix-ui/themes/styles.css";
import "../../styles/adminchof.css";
import * as Select from "@radix-ui/react-select";
import classnames from "classnames";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import * as Tabs from "@radix-ui/react-tabs";
import "../../styles/select.css";
import "../../styles/tabs.css";
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css"; // Elige un tema que prefieras
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../../index.css";
import * as Dialog from "@radix-ui/react-dialog";
import { API_URL } from "../../constants";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { PageInfo } from "../PageInfo";
import { CrossCircledIcon } from "@radix-ui/react-icons"
import SyncLoader from "react-spinners/ClipLoader";
import { Cross2Icon } from "@radix-ui/react-icons";



function AdminChoferes() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  //Drivers data in a datatable
  const [drivers, setDriverData] = useState("");
  const dt = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [driverToDelete, setDriverToDelete] = useState(null);
  const cols = [
    { field: "name", header: "Nombre" },
    { field: "lastname", header: "Apellido(s)" },
    { field: "email", header: "Apellido(s)" },
    { field: "company", header: "Empresa" },
    { field: "phone", header: "Teléfono" },
    { field: "username", header: "Usuario" },
    { field: "password", header: "Contraseña" },
  ];
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    company: { value: null, matchMode: FilterMatchMode.IN },
    phone: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [nombreAlternativo, setNombreAlternativo] = useState("");
  const [RFCFigura, setRFCFigura] = useState("");
  const [numLicencia, setNumLicencia] = useState("");
  const [calle, setCalle] = useState("");
  const [numExt, setNumExt] = useState("");
  const [numInt, setNumInt] = useState("");
  const [referencia, setReferencia] = useState("");
  const [colonia, setColonia] = useState("");
  const [pais, setPais] = useState("");
  const [estado, setEstado] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [choferToEdit, setChoferToEdit] = useState([]);

  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [colonias, setColonias] = useState([]);
  const [permisos, setPermisos] = useState([]);

  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  let filterDriversObj = {
    RFCFigura: "",
    company: "",
    numLicencia: "",
    pais: "",
    nombreAlternativo: ""

  }
  const [filterDrivers, setFilterDrivers] = useState(filterDriversObj);

  const handleChangeFilter = (e) => {
    setFilterDrivers({
      ...filterDrivers,
      [e.target.name]: e.target.value
    })
  }

  const notifyError = (message) => {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const notifySuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };


  const notifyWarning = (message) => {
    toast.warning(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };


  const handleEditChoferChange = async (e) => {

    setChoferToEdit({
      ...choferToEdit,
      [e.target.name]: e.target.value
    });

    if (e.target.name === "pais") {
      let dataEstados = await fetchEstados(e.target.value);
      setEstados(dataEstados);
    }

    if (e.target.name === "estado") {
      let dataMunicipios = await fetchMunicipios(e.target.value);
      setMunicipios(dataMunicipios);
    }

    if (e.target.name === "codigoPostal") {
      let dataColonias = await fetchColonias(e.target.value);
      setColonias(dataColonias);
    }

  }

  //UPDATE drivers in BD
  async function updateDriverInDatabase(updatedDriver) {
    try {
      const response = await fetch(`${API_URL}/api/update-driver`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedDriver),
      });

      const data = await response.json();
      if (data.status === "ok") {
        notifySuccess("Los datos del operados han sido actualizados exitosamente");
      }
      return data;
    } catch (error) {
      console.error("Error al actualizar el conductor:", error);
      return {
        status: "error",
        error: "Ocurrió un error al actualizar el conductor.",
      };
    }
  }

  //FETCH drivers
  const fetchDriverData = async () => {
    try {
      setIsLoadingSearch(true);
      let queryString = "?";
      Object.entries(filterDrivers).forEach(([key, value], index) => {

        if (value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (!queryString.includes("company")) {
        queryString += `company=${permisos}&`
      }
      queryString = queryString.substring(0, queryString.length - 1);

      const response = await fetch(`${API_URL}/api/drivers${queryString}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setIsLoadingSearch(false);
      if (data.status === "Ok") {
        setDriverData(data.drivers);
      } else {
        setDriverData([]);
        notifyWarning(data.message);
      }
    } catch (error) {
      setIsLoadingSearch(false);
      notifyError(error);
    }

  };

  //DELETE driver
  const deleteDriverFromDatabase = async (driverId) => {
    try {
      const response = await fetch(`${API_URL}/api/delete-driver/${driverId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        notifySuccess("El operador fue eliminado exitosamente");
        fetchDriverData();
      } else {
        // La solicitud no fue exitosa, maneja el error adecuadamente
        const errorData = await response.json();
        console.error("Error al eliminar el conductor:", errorData.message);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    // fetchDriverData();
    let perms = JSON.parse(localStorage.getItem("permisos"));
    setPermisos(perms);

    if (perms.length <= 1) {
      setCompany(perms[0]);
      
      setFilterDrivers({
        ...filterDrivers,
        company: perms[0]
      })
    }


  }, []);

  const validateDriver = () => {
    if (name.trim() === "") {
      notifyWarning("El nombre del operador no puede estar vacío");
      return false;
    }

    if (lastname.trim() === "") {
      notifyWarning("El apellido del operador no puede estar vacío");
      return false;
    }

    if (nombreAlternativo.trim() === "") {
      notifyWarning("El nombre alternativo del operador no puede estar vacío");
      return false;
    }

    if (RFCFigura.trim() === "") {
      notifyWarning("El RFC del operador no puede estar vacío");
      return false;
    }

    if (phone.trim() === "") {
      notifyWarning("El telefono no puede estar vacío");
      return false;
    }

    if (company.trim() === "") {
      notifyWarning("La empresa no puede estar vacía");
      return false;
    }

    if (numLicencia.trim() === "") {
      notifyWarning("El numero de licencia no puede estar vacío");
      return false;
    }

    if (numLicencia.trim().length < 6) {
      notifyWarning("El número de licencia no puede ser menor a 6 caracteres");
      return false;
    }

    if (numLicencia.trim().length > 16) {
      notifyWarning("El número de licencia no puede ser mayor a 16 caracteres");
      return false;
    }
    // if()
    if (calle.trim() === "") {
      notifyWarning("La calle en dirección del operador no puede estar vacía");
      return false;
    }
    if (numExt.trim() === "") {
      notifyWarning("El numero exterior en dirección del operador no puede estar vacío");
      return false;
    }
    if (pais.trim() === "") {
      notifyWarning("El país en dirección del operador no puede estar vacío");
      return false;
    }
    if (estado.trim() === "") {
      notifyWarning("El estado en dirección del operador no puede estar vacío");
      return false;
    }

    if (municipio.trim() === "") {
      notifyWarning("El Municipio en dirección del operador no puede estar vacío");
      return false;
    }
    if (codigoPostal.trim() === "") {
      notifyWarning("El Código Postal en dirección del operador no puede estar vacío");
      return false;
    }


  }
  async function registerDriver(event) {
    event.preventDefault();
    // if(RFCFigura.trim().length < 5 )

    setIsLoading(true);
    let validation = validateDriver();

    if (validation === false) {
      setIsLoading(false);
      return false;
    }

    try {

      const response = await fetch(`${API_URL}/api/register-driver`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          lastname,
          company,
          phone,
          nombreAlternativo,
          RFCFigura,
          numLicencia,
          calle,
          numExt,
          numInt,
          pais,
          estado,
          colonia,
          municipio,
          codigoPostal,
          referencia
        }),
      });
      const data = await response.json();
      setIsLoading(false);
      if (data.status === "ok") {
        document.getElementById("DriverForm").reset();
        notifySuccess("El operador fue creado exitosamente");
        setRFCFigura("");
        setEstado("");
        setMunicipio("");
        setCompany("");
      }
      if (data.status === "401") {
        notifyError("El email ya está registrado");
      }
    } catch (error) {
      notifyWarning("No se pudo realizar el registro del operador")
      setIsLoading(false);
    }
  }

  const SelectItem = React.forwardRef(
    ({ children, className, ...props }, forwardedRef) => {
      return (
        <Select.Item
          className={classnames("SelectItem", className)}
          {...props}
          ref={forwardedRef}
        >
          <Select.ItemText>{children}</Select.ItemText>
          <Select.ItemIndicator className="SelectItemIndicator">
            <CheckIcon />
          </Select.ItemIndicator>
        </Select.Item>
      );
    }
  );

  //UPDATE driver
  const onRowEditComplete = (e) => {
    let _drivers = [...drivers];
    let { newData, index } = e;

    _drivers[index] = newData;
    setDriverData(_drivers);
    updateDriverInDatabase(newData);
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        className="Input"
      />
    );
  };

  const selectEmpresaEditor = (options) => {
    return (
      <select
        className="SelectTrigger"
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
      >
        <option value="Datt-Express">DATT Express</option>
        <option value="Datt-Mx">DATT MX</option>
        <option value="Datt-Imports">DATT Imports</option>
        <option value="Datt-Freight">DATT Freight</option>
        <option value="Power-Only">Power Only</option>
      </select>
    );
  };

  const handleRFCChange = (e) => {
    setRFCFigura(e.target.value.toUpperCase());
  };

  const fetchEstados = async (e) => {
    setPais(e);
    try {
      const response = await fetch(
        `${API_URL}/api/catalogoestados?countryCode=${e}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        // const selectElement = document.getElementById("selectEstado");
        // selectElement.innerHTML = "";
        // const defaultOption = document.createElement("option");
        // defaultOption.value = "";
        // defaultOption.text = "Escoge un estado";
        // selectElement.add(defaultOption);
        // data.forEach((country) => {
        //   const option = document.createElement("option");
        //   option.value = country.Value;
        //   option.text = country.Name;
        //   selectElement.add(option);
        // });
        return data;
      } else {
        console.error("Error en la solicitud:", response.statusText);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMunicipios = async (e) => {
    try {
      const response = await fetch(
        `${API_URL}/api/catalogomunicipios?stateCode=${e}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        // const selectElement = document.getElementById("selectMunicipio");
        // selectElement.innerHTML = "";
        // const defaultOption = document.createElement("option");
        // defaultOption.value = "";
        // defaultOption.text = "Escoge un municipio";
        // selectElement.add(defaultOption);
        // data.forEach((country) => {
        //   const option = document.createElement("option");
        //   option.value = country.Value;
        //   option.text = country.Name;
        //   selectElement.add(option);
        // });
        return data;
      } else {
        console.error("Error en la solicitud:", response.statusText);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchColonias = async (e) => {
    if (e.length !== 5) {
      return false;
    }
    try {
      const response = await fetch(
        `${API_URL}/api/catalogolocalidades?postalCode=${e}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        // const selectElement = document.getElementById("selectColonia");
        // selectElement.innerHTML = "";
        // const defaultOption = document.createElement("option");
        // defaultOption.value = "";
        // defaultOption.text = "Escoge una colonia";
        // selectElement.add(defaultOption);
        // if (data.length === 1) {
        //   const defaultOption = document.createElement("option");
        //   defaultOption.value = "";
        //   defaultOption.text = "Escoge una colonia";
        //   selectElement.add(defaultOption);
        // }
        // data.forEach((country) => {
        //   const option = document.createElement("option");
        //   option.value = country.Value;
        //   option.text = country.Name;
        //   selectElement.add(option);
        // });
        return data;
      } else {
        console.error("Error en la solicitud:", response.statusText);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //DELETE driver
  const confirmDeleteDriver = (rowData) => {
    setDriverToDelete(rowData);
    setIsDialogOpen(true);
  };

  const handleDeleteConfirmation = () => {
    if (driverToDelete) {
      deleteDriverFromDatabase(driverToDelete._id);
    }
    setIsDialogOpen(false);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          className="pi pi-trash btn__pi"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteDriver(rowData)}
          style={{ color: "red" }}
        />
      </React.Fragment>
    );
  };
  const bodyEditChofer = (data) => {

    return (
      <Button
        className="pi pi-pencil btn__pi"
        onClick={() => { handleEditDialogOpenData(data) }}
      />
    )
  }

  const handleEditDialogOpenData = async (data) => {
    setIsEditDialogOpen(true);
    setChoferToEdit(data);

    let dataEstados = await fetchEstados(data.pais);
    setEstados(dataEstados);

    let dataMuncipios = await fetchMunicipios(data.estado);
    setMunicipios(dataMuncipios);

    let dataColonias = await fetchColonias(data.codigoPostal);
    setColonias(dataColonias)

  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            style={{ marginLeft: "30px" }}
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar"
          />
        </span>
      </div>
    );
  };

  const handleEditChofer = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await fetch(`${API_URL}/api/update-driver`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(choferToEdit),
      });

      const data = await response.json();
      setIsLoading(false);

      if (data.status === "ok") {
        notifySuccess();
        fetchDriverData();
        setIsEditDialogOpen(false);
      } else {
        notifyWarning("No se pudo realizar la actualización del operador");
      }
      return data;
    } catch (error) {
      notifyWarning("No se pudo realizar la actualización del operador");
      setIsLoading(false);
    }

  }

  return (
    <div className="content-wrapper container__block" style={{ paddingBottom: "1.4rem" }}>
      <PageInfo heading="DATT - Tracking | Administrar Operadores" />

      <section className="content">
        <div className="container-fluid">
          <Tabs.Root className="TabsRoot" defaultValue="tab1">
            <Tabs.List className="TabsList" aria-label="Registra un chofer">
              <Tabs.Trigger className="TabsTrigger" value="tab1">
                Registrar Operador
              </Tabs.Trigger>
              <Tabs.Trigger className="TabsTrigger" value="tab2">
                Ver Operadores
              </Tabs.Trigger>
            </Tabs.List>

            <Tabs.Content
              className="TabsContent"
              value="tab1"
              style={{
                display: "flex", justifyContent: "center"
              }}
            >
              <div
                className="container__width_70"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px",
                }}
              >
                <div style={{ flex: 1 }}>
                  {/* Register driver form */}


                  <Form.Root
                    className="FormRoot"
                    onSubmit={registerDriver}
                    id="DriverForm"
                  >
                    <div className="data__group">
                      <h4>Datos del Operador</h4>
                      <div className="data__group_form">

                        {
                          permisos && permisos.length > 1 ? (
                            <div className="form__group_form-select">
                              <div className="form__group form__group_selectField">
                                <label className="FormLabel" style={{ margin: "0" }}>Empresa</label>
                                <select
                                  className="SelectTrigger"
                                  required
                                  style={{ width: "100%", margin: "1rem 0" }}
                                  name="empresa"
                                  value={company}
                                  onChange={(e) => setCompany(e.target.value)}
                                >
                                  <option value="">Selecciona una Empresa</option>
                                  {
                                    permisos.map((permiso, index) => (
                                      <option key={index} value={permiso} >{permiso}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            </div>

                          ) : ""

                        }


                        <div className="form__group_form-select">
                          {/*Name form field */}
                          <Form.Field
                            className="FormField form__group_selectField"
                            name="name"
                            onChange={(e) => setName(e.target.value)}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                                justifyContent: "space-between",
                              }}
                            >
                              <Form.Label className="FormLabel" style={{ margin: 0 }}>Nombre</Form.Label>
                              <Form.Message
                                className="FormMessage"
                                match="valueMissing"
                              >
                                Ingresa un nombre
                              </Form.Message>
                            </div>
                            <Form.Control asChild>
                              <input
                                className="Input boxSizing__bb"
                                type="text"
                                required
                                placeholder="Nombre"

                              />
                            </Form.Control>
                          </Form.Field>

                          {/*Lastnames form field */}
                          <Form.Field
                            className="FormField form__group_selectField"
                            name="lastname"
                            onChange={(e) => setLastname(e.target.value)}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                                justifyContent: "space-between",
                              }}
                            >
                              <Form.Label className="FormLabel" style={{ margin: 0 }}>
                                Apellido
                              </Form.Label>
                              <Form.Message
                                className="FormMessage"
                                match="valueMissing"
                              >
                                Ingresa un apellido
                              </Form.Message>
                            </div>
                            <Form.Control asChild>
                              <input
                                className="Input boxSizing__bb"
                                type="text"
                                required
                                placeholder="Apellido"
                              />
                            </Form.Control>
                          </Form.Field>
                        </div>

                        <div className="form__group_form-select">

                          {/*Apodo form field */}
                          <Form.Field
                            className="FormField form__group_selectField"
                            name="name"
                            onChange={(e) => setNombreAlternativo(e.target.value)}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                                justifyContent: "space-between",
                              }}
                            >
                              <Form.Label style={{ margin: 0 }} className="FormLabel">
                                Nombre alternativo
                              </Form.Label>
                              <Form.Message
                                className="FormMessage"
                                match="valueMissing"
                              >
                                Ingresa un nombre
                              </Form.Message>
                            </div>
                            <Form.Control asChild>
                              <input
                                className="Input boxSizing__bb"
                                type="text"
                                required
                                placeholder="Nombre/Apodo"

                              />
                            </Form.Control>
                          </Form.Field>

                          <Form.Field
                            className="FormField form__group_selectField"
                            name="phone"
                            onChange={(e) => setRFCFigura(e.target.value)}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                                justifyContent: "space-between",
                              }}
                            >
                              <Form.Label style={{ margin: 0 }} className="FormLabel">RFC</Form.Label>
                              <Form.Message
                                className="FormMessage"
                                match="valueMissing"
                              >
                                Ingresa un RFC
                              </Form.Message>
                            </div>
                            <Form.Control asChild>
                              <input
                                className="Input boxSizing__bb"
                                type="text"
                                required
                                placeholder="RFC"
                                value={RFCFigura}
                                onChange={handleRFCChange}
                              />
                            </Form.Control>
                          </Form.Field>
                        </div>


                        <div className="form__group_form-select">
                          {/*Phone form field */}
                          <Form.Field
                            className="FormField form__group_selectField"
                            name="phone"
                            onChange={(e) => setPhone(e.target.value)}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                                justifyContent: "space-between",
                              }}
                            >
                              <Form.Label style={{ margin: 0 }} className="FormLabel">
                                Teléfono
                              </Form.Label>
                              <Form.Message
                                className="FormMessage"
                                match="valueMissing"
                              >
                                Ingresa un teléfono
                              </Form.Message>
                            </div>
                            <Form.Control asChild>
                              <input
                                className="Input boxSizing__bb"
                                type="number"
                                required
                                placeholder="+00 00 00 00"
                              />
                            </Form.Control>
                          </Form.Field>

                          {/*Company form field */}


                          {/*Num licencia */}
                          <Form.Field
                            className="FormField form__group_selectField"
                            name="numLicencia"
                            onChange={(e) => setNumLicencia(e.target.value)}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                                justifyContent: "space-between",
                              }}
                            >
                              <Form.Label style={{ margin: 0 }} className="FormLabel">
                                Número de Licencia
                              </Form.Label>
                              <Form.Message
                                className="FormMessage"
                                match="valueMissing"
                              >
                                Ingresa un número de licencia
                              </Form.Message>
                            </div>
                            <Form.Control asChild>
                              <input
                                className="Input boxSizing__bb"
                                type="text"
                                minLength={6}
                                maxLength={16}
                                required
                              />
                            </Form.Control>
                          </Form.Field>
                        </div>


                      </div>
                    </div>
                    <hr></hr>
                    <div className="data__group">
                      <h4>Dirección del operador</h4>
                      <div className="data__group_form">
                        {/* Calle */}
                        <Form.Field
                          className="FormField"
                          name="calle"
                          onChange={(e) => setCalle(e.target.value)}
                        >
                          <Form.Label style={{ margin: 0 }} className="FormLabel">Calle</Form.Label>
                          <Form.Control asChild>
                            <input
                              className="Input boxSizing__bb"
                              type="text"
                              required
                              placeholder="Calle"
                            />
                          </Form.Control>
                        </Form.Field>

                        <div className="form__group_form-select">
                          {/* Num ext */}
                          <Form.Field
                            className="FormField form__group_selectField"
                            name="phone"
                            onChange={(e) => setNumExt(e.target.value)}
                          >
                            <Form.Label style={{ margin: 0 }} className="FormLabel">
                              Número Exterior
                            </Form.Label>
                            <Form.Control asChild>
                              <input
                                className="Input boxSizing__bb"
                                type="number"
                                required
                                placeholder="Número exterior"
                              />
                            </Form.Control>
                          </Form.Field>
                          {/* Num int */}
                          <Form.Field
                            className="FormField form__group_selectField"
                            name="phone"
                            onChange={(e) => setNumInt(e.target.value)}
                          >
                            <Form.Label style={{ margin: 0 }} className="FormLabel">
                              Número Interior
                            </Form.Label>
                            <Form.Control asChild>
                              <input
                                className="Input boxSizing__bb"
                                type="number"
                                required
                                placeholder="Número Interior"
                              />
                            </Form.Control>
                          </Form.Field>
                        </div>

                        {/* Referencia */}
                        <Form.Field
                          className="FormField"
                          name="phone"
                          onChange={(e) => setReferencia(e.target.value)}
                        >
                          <Form.Label style={{ margin: 0 }} className="FormLabel">
                            Referencia
                          </Form.Label>
                          <Form.Control asChild>
                            <input
                              className="Input boxSizing__bb"
                              type="text"
                              required
                              placeholder="Referencia"
                            />
                          </Form.Control>
                        </Form.Field>

                        <div className="form__group_form-select">
                          {/* Pais */}
                          <Form.Field
                            className="FormField boxSizing__bb selectTriggerCustom form__group_selectField"
                            id="selectPaises"
                            onChange={async (e) => {
                              setPais(e.target.value);

                              let estadosData = await fetchEstados(e.target.value);
                              setEstados(estadosData)
                            }}
                            value={pais}
                          >
                            <Form.Label style={{ margin: 0 }} className="FormLabel">País</Form.Label>
                            <Form.Control asChild>

                              <select
                                className="SelectTrigger  "
                                style={{ width: "100%", padding: ".5rem", margin: 0, height: "100%" }}
                              >
                                <option value="">Escoge un país</option>
                                <option value="MEX">México</option>
                                <option value="USA">Estados Unidos</option>
                              </select>
                            </Form.Control>
                          </Form.Field>
                          {/* Estado */}



                          <Form.Field className="FormField boxSizing__bb selectTriggerCustom form__group_selectField" name="phone">
                            <Form.Label style={{ margin: 0 }} className="FormLabel">Estado</Form.Label>
                            <Form.Control asChild>
                              <select
                                className="SelectTrigger"
                                id="selectEstado"
                                onChange={async (e) => {
                                  setEstado(e.target.value);
                                  let municipiosData = await fetchMunicipios(e.target.value);
                                  setMunicipios(municipiosData);
                                }}
                                style={{ width: "100%", padding: ".5rem", margin: 0, height: "100%" }}
                                required
                              >
                                <option value="">Escoge un estado</option>
                                {
                                  estados.length > 0 && estados ?
                                    estados.map((estado, index) => (
                                      <option key={index} value={estado.Value}>{estado.Name}</option>
                                    )) : (<option value="">No hay estados disponibles</option>)
                                }
                              </select>
                            </Form.Control>
                          </Form.Field>
                        </div>
                        <div className="form__group_form-select">

                          {/* Municipio */}
                          <Form.Field
                            className="FormField boxSizing__bb selectTriggerCustom form__group_selectField"
                            name="phone"
                            onChange={(e) => setMunicipio(e.target.value)}
                          >
                            <Form.Label style={{ margin: 0 }} className="FormLabel">Municipio</Form.Label>
                            <Form.Control asChild>
                              <select
                                className="SelectTrigger "
                                id="selectMunicipio"
                                onChange={(e) => {
                                  setMunicipio(e.target.value);
                                }}
                                required
                                style={{ width: "100%", padding: ".5rem", margin: 0, height: "100%" }}
                              ><option value="">Escoge un municipio</option>
                                {
                                  municipios.length > 0 && municipios ?
                                    municipios.map((municipio, index) => (
                                      <option key={index} value={municipio.Value}>{municipio.Name}</option>

                                    )) : (<option>No hay municipios disponibles</option>)
                                }
                              </select>
                            </Form.Control>
                          </Form.Field>

                          {/* CP */}
                          <Form.Field
                            className="FormField  form__group_selectField"
                            name="phone"
                            onChange={(e) => {
                              setCodigoPostal(e.target.value);
                            }}
                          >
                            <Form.Label style={{ margin: 0 }} className="FormLabel">
                              Código Postal
                            </Form.Label>
                            <Form.Control asChild>
                              <input
                                onChange={async (e) => {
                                  setCodigoPostal(e.target.value);
                                  let coloniasData = await fetchColonias(e.target.value);
                                  setColonias(coloniasData);
                                }}
                                required
                                className="Input boxSizing__bb"
                                placeholder="00000"
                                maxLength="12"
                              ></input>
                            </Form.Control>
                          </Form.Field>
                        </div>

                        <div className="form__group_form-select">
                          {/* Colonia */}
                          <Form.Field
                            className="FormField boxSizing__bb form__group_selectField"
                            name="phone"
                            onChange={(e) => setColonia(e.target.value)}
                          >
                            <Form.Label style={{ margin: 0 }} className="FormLabel">Colonia</Form.Label>
                            <Form.Control asChild>
                              <select
                                className="SelectTrigger"
                                id="selectColonia"
                                onChange={(e) => {
                                  setColonia(e.target.value);
                                }}
                                required
                                style={{ width: "100%", padding: ".5rem", margin: 0, height: "100%" }}
                              ><option value="">Escoge una colonia</option>
                                {
                                  colonias.length > 0 && colonias ?
                                    colonias.map((colonia, index) => (
                                      <option key={index} value={colonia.Value}>{colonia.Name}</option>
                                    )) : (<option value="">No hay colonias disponibles</option>)
                                }
                              </select>
                            </Form.Control>
                          </Form.Field>
                        </div>

                        <div className="btn__container">
                          {/*Register driver btn in BD*/}

                          <Form.Submit asChild>
                            <button
                              type="submit"
                              className="btn__login"
                              disabled={isLoading}
                              style={{ marginTop: 10, width: "230px", display: "flex", justifyContent: "center", alignItems: "center" }}
                              onClick={registerDriver}
                            >
                              {
                                isLoading ? (
                                  <SyncLoader
                                    color="white"
                                    loading={isLoading}
                                    size={20}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                    speedMultiplier={.7}
                                  />

                                ) : "Registrar Operador"
                              }

                            </button>
                          </Form.Submit>
                        </div>
                      </div>
                    </div>




                  </Form.Root>
                </div>
              </div>
            </Tabs.Content>

            <Tabs.Content className="TabsContent" value="tab2">
              {/* Drivers registered in a table */}
              <h3>Operadores registrados</h3>
              <section className=''>
                <div className='cartaPorte__group'>
                  <div className="cartaPorte__group_elements">
                    <label className='FormLabel'>Nombre Alternativo</label>
                    <input
                      className='InputForm'
                      name="nombreAlternativo"
                      value={filterDrivers.nombreAlternativo}
                      onChange={handleChangeFilter}
                    >
                    </input>
                  </div>
                  
                  <div className="cartaPorte__group_elements">
                    <label className='FormLabel'>Número de Licencia</label>
                    <input
                      className='InputForm'
                      name="numLicencia"
                      value={filterDrivers.numLicencia}
                      onChange={handleChangeFilter}
                    >
                    </input>
                  </div>

                  <div className="cartaPorte__group_elements">
                    <label className='FormLabel'>RFC del Operador</label>
                    <input
                      className='InputForm'
                      name="RFCFigura"
                      value={filterDrivers.RFCFigura}
                      onChange={handleChangeFilter}
                    >
                    </input>
                  </div>

                  {
                    permisos && permisos.length > 1 ? (
                      <div className="cartaPorte__group_elements">
                        <label className='FormLabel'>Empresa</label>
                        <select
                          className='SelectTrigger'
                          name="company"
                          value={filterDrivers.company}
                          onChange={handleChangeFilter}
                        >
                          <option>Selecciona una empresa</option>
                          {
                            permisos.map((permiso, index) => (
                              <option key={index} value={permiso}>{permiso}</option>
                            ))
                          }
                        </select>
                      </div>
                    ) : ""
                  }

                  <div className="cartaPorte__group_elements">
                    <label className='FormLabel'>País</label>
                    <select
                      className='SelectTrigger'
                      name="pais"
                      value={filterDrivers.pais}
                      onChange={handleChangeFilter}
                    >
                      <option>Selecciona un País</option>
                      <option value="MEX">México</option>
                      <option value="USA">Estados Unidos</option>
                    </select>
                  </div>





                  <div className="cartaPorte__group_elements" style={{ justifyContent: "flex-end" }} >
                    <div className='btn__container_search'>
                      <button
                        type="submit"
                        disabled={isLoadingSearch}
                        className="btn__login"
                        style={{ marginTop: 10, width: "240px", display: "flex", justifyContent: "center", alignItems: "center" }}
                        onClick={fetchDriverData}
                      >
                        {
                          isLoadingSearch ? (
                            <SyncLoader
                              color="white"
                              loading={isLoadingSearch}
                              size={20}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                              speedMultiplier={.7}
                            />

                          ) : "Buscar Operadores"
                          // <FaSearch />
                        }

                      </button>
                    </div>

                  </div>


                </div>

              </section>
              <header
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "1rem",
                }}
              ></header>
              <div className="card">
                <DataTable
                  value={drivers}
                  paginator
                  header={renderHeader}
                  ref={dt}
                  filters={filters}
                  rows={10}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  tableStyle={{ minWidth: "50rem" }}
                  editMode="row"
                  dataKey="_id"
                  onRowEditComplete={onRowEditComplete}
                >
                  <Column field="_id" header="ID" hidden />
                  <Column
                    field="company"
                    header="Empresa"
                    sortable
                    editor={(options) => selectEmpresaEditor(options)}
                  />
                  <Column
                    field="username"
                    header="Usuario"
                    sortable
                    editor={(options) => textEditor(options)}
                  />
                  {/* <Column
                    field="password"
                    header="Contraseña"
                    sortable
                    editor={(options) => textEditor(options)}
                  /> */}
                  <Column
                    field="nombreAlternativo"
                    header="Nombre Alternativo"
                    sortable
                    editor={(options) => textEditor(options)}
                  />
                  <Column
                    field="name"
                    header="Nombre"
                    sortable
                    editor={(options) => textEditor(options)}
                  />
                  <Column
                    field="lastname"
                    header="Apellido(s)"
                    sortable
                    editor={(options) => textEditor(options)}
                  />
                  <Column
                    field="RFCFigura"
                    header="RFC"
                    sortable
                    editor={(options) => textEditor(options)}
                  />

                  <Column
                    field="numLicencia"
                    header="Número de Licencia"
                    sortable
                    editor={(options) => textEditor(options)}
                  />
                  <Column
                    field="pais"
                    header="País"
                    sortable
                    editor={(options) => textEditor(options)}
                  />
                  <Column
                    field="phone"
                    header="Teléfono"
                    sortable
                    editor={(options) => textEditor(options)}
                  />

                  {/*Edit btn */}
                  <Column
                    body={bodyEditChofer}
                    headerStyle={{ width: "10%", minWidth: "8rem" }}
                    bodyStyle={{ textAlign: "center" }}
                  ></Column>
                  {/*Delete btn */}
                  <Column
                    body={actionBodyTemplate}
                    exportable={false}
                    style={{ minWidth: "12rem" }}
                  ></Column>
                </DataTable>
                {/*Delete driver Dialog */}
                <Dialog.Root
                  open={isDialogOpen}
                  onOpenChange={() => setIsDialogOpen(false)}
                >
                  <Dialog.Portal>
                    <Dialog.Overlay className="DialogOverlay" />
                    <Dialog.Content
                      className="DialogContent"
                      style={{
                        height: "290px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "400px",
                        backgroundColor: "white"
                      }}>
                      <Dialog.Title className="DialogTitle" style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",

                      }}>
                        <CrossCircledIcon
                          style={{
                            width: "78px",
                            height: "90px",
                            color: "red",
                            marginBottom: "10px"
                          }}
                        />
                        Eliminar Operador
                      </Dialog.Title>
                      <Dialog.Description className="DialogDescription">
                        ¿Estás seguro de que deseas eliminar a <b>{driverToDelete?.name}</b>?
                      </Dialog.Description>
                      <div
                        style={{
                          display: "flex",
                          marginTop: 25,
                          justifyContent: "center",
                          gap: "20px",
                          marginTop: "14px"
                        }}
                      >
                        <Dialog.Close asChild>
                          <Button
                            className="EliminarButton"
                            onClick={handleDeleteConfirmation}
                          >
                            Eliminar
                          </Button>
                        </Dialog.Close>
                        <Dialog.Close asChild>
                          <Button
                            aria-label="Close"
                            className="CancelarButton"
                          >
                            Cancelar
                          </Button>
                        </Dialog.Close>
                      </div>
                    </Dialog.Content>
                  </Dialog.Portal>
                </Dialog.Root>

                <Dialog.Root
                  open={isEditDialogOpen}
                  onOpenChange={() => { setIsEditDialogOpen(false) }}
                >
                  <Dialog.Portal>
                    <Dialog.Overlay className="DialogOverlay" />
                    <Dialog.Content className="DialogContentCP dialogEdit" style={{ backgroundColor: "white" }}>
                      <Dialog.Title className="DialogTitle">
                        Editar Operador
                      </Dialog.Title>

                      <form onSubmit={(e) => handleEditChofer(e)}>

                        <div className="dialogEditGroup">
                          <div className="dialogEditGroup_elements">

                            <label className="FormLabel" htmlFor="name">
                              Nombre
                            </label>
                            <input
                              className="InputForm"
                              type="text"
                              maxLength={254}
                              name="name"
                              onChange={handleEditChoferChange}
                              required
                              value={choferToEdit?.name}
                            ></input>
                          </div>


                          <div className="dialogEditGroup_elements">

                            <label className="FormLabel" htmlFor="name">
                              Apellido
                            </label>
                            <input
                              className="InputForm"
                              type="text"
                              name="lastname"
                              maxLength={100}
                              onChange={handleEditChoferChange}
                              required
                              value={choferToEdit?.lastname}
                            ></input>
                          </div>

                          <div className="dialogEditGroup_elements">

                            <label className="FormLabel" htmlFor="name">
                              Nombre alternativo
                            </label>
                            <input
                              className="InputForm"
                              type="text"
                              name="nombreAlternativo"
                              maxLength={100}
                              onChange={handleEditChoferChange}
                              required
                              value={choferToEdit?.nombreAlternativo}
                            ></input>
                          </div>
                          <div className="dialogEditGroup_elements">

                            <label className="FormLabel" htmlFor="name">
                              RFC
                            </label>
                            <input
                              className="InputForm"
                              type="text"
                              name="RFCFigura"
                              maxLength={100}
                              onChange={handleEditChoferChange}
                              required
                              value={choferToEdit?.RFCFigura}
                            ></input>
                          </div>
                          <div className="dialogEditGroup_elements">

                            <label className="FormLabel" htmlFor="name">
                              Teléfono
                            </label>
                            <input
                              className="InputForm"
                              type="number"
                              name="phone"
                              onChange={handleEditChoferChange}
                              required
                              value={choferToEdit?.phone}
                            ></input>
                          </div>
                          {
                            permisos && permisos.length > 1 ?
                              (
                                <div className="dialogEditGroup_elements">
                                  <label className="FormLabel" htmlFor="name">
                                    Empresa
                                  </label>
                                  <select
                                    className="SelectTrigger"
                                    name="company"
                                    onChange={handleEditChoferChange}
                                    value={choferToEdit?.company}
                                    required
                                  >
                                    <option value="">Selecciona una empresa</option>
                                    {
                                      permisos.map((permiso, index) => (
                                        <option key={index} value={permiso}>{permiso}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              ) : ""
                          }

                          <div className="dialogEditGroup_elements">
                            <label className="FormLabel" htmlFor="name">
                              Número de Licencia
                            </label>
                            <input
                              className="InputForm"
                              type="text"
                              name="numLicencia"
                              onChange={handleEditChoferChange}
                              minLength={6}
                              maxLength={16}
                              required
                              value={choferToEdit?.numLicencia}
                            ></input>
                          </div>

                          <div className="dialogEditGroup_elements">

                            <label className="FormLabel" htmlFor="name">
                              Calle
                            </label>
                            <input
                              className="InputForm"
                              type="text"
                              name="calle"
                              onChange={handleEditChoferChange}
                              required
                              value={choferToEdit?.calle}
                            ></input>
                          </div>

                          <div className="dialogEditGroup_elements">

                            <label className="FormLabel" htmlFor="name">
                              Número Exterior
                            </label>
                            <input
                              className="InputForm"
                              type="text"
                              name="numExt"
                              onChange={handleEditChoferChange}
                              required
                              value={choferToEdit?.numExt}
                            ></input>
                          </div>

                          <div className="dialogEditGroup_elements">

                            <label className="FormLabel" htmlFor="name">
                              Número Interior
                            </label>
                            <input
                              className="InputForm"
                              type="text"
                              name="numInt"
                              onChange={handleEditChoferChange}
                              value={choferToEdit?.numInt}
                            ></input>
                          </div>
                          <div className="dialogEditGroup_elements">

                            <label className="FormLabel" htmlFor="name">
                              Referencia
                            </label>
                            <input
                              className="InputForm"
                              type="text"
                              name="referencia"
                              onChange={handleEditChoferChange}
                              value={choferToEdit?.referencia}
                            ></input>
                          </div>

                          <div className="dialogEditGroup_elements">

                            <label className="FormLabel" htmlFor="name">
                              País
                            </label>
                            <select
                              className="SelectTrigger"
                              name="pais"
                              onChange={handleEditChoferChange}
                              value={choferToEdit?.pais}
                            >
                              <option value="">Seleccion un país</option>
                              <option value="MEX">México</option>
                              <option value="USA">Estados Unidos</option>

                            </select>
                          </div>

                          <div className="dialogEditGroup_elements">
                            <label className="FormLabel" htmlFor="name">
                              Estado
                            </label>
                            <select
                              className="SelectTrigger"
                              value={choferToEdit?.estado}
                              onChange={handleEditChoferChange}
                              name="estado"
                            >
                              <option value="">Selecciona un Estado</option>
                              {
                                estados.length > 0 && estados ?
                                  estados.map((estado, index) => (
                                    <option key={index} value={estado.Value}>{estado.Name}</option>
                                  )) : (<option value="">No hay opciones disponibles</option>)
                              }


                            </select>
                          </div>


                          <div className="dialogEditGroup_elements">
                            <label className="FormLabel" htmlFor="name">
                              Municipio
                            </label>
                            <select
                              className="SelectTrigger"
                              name="municipio"
                              onChange={handleEditChoferChange}
                              value={choferToEdit?.municipio}
                            >
                              <option value="">Selecciona un Municipio</option>
                              {
                                municipios.length > 0 && municipios ?
                                  municipios.map((municipio, index) => (
                                    <option key={index} value={municipio.Value}>{municipio.Name}</option>
                                  )) : (<option>No hay municipios disponibles</option>)
                              }
                            </select>

                          </div>
                          <div className="dialogEditGroup_elements">

                            <label className="FormLabel" htmlFor="name">
                              Código Postal
                            </label>
                            <input
                              className="InputForm"
                              type="text"
                              name="codigoPostal"
                              onChange={handleEditChoferChange}
                              required
                              value={choferToEdit?.codigoPostal}
                            ></input>
                          </div>

                          <div className="dialogEditGroup_elements">

                            <label className="FormLabel" htmlFor="name">
                              Colonia
                            </label>
                            <select
                              className="SelectTrigger"
                              name="colonia"
                              onChange={handleEditChoferChange}
                              value={choferToEdit?.colonia}
                            >
                              <option value="">Selecciona una colonia</option>
                              {
                                colonias.length > 0 && colonias ?
                                  colonias.map((colonia, index) => (
                                    <option key={index} value={colonia.Value} >{colonia.Name}</option>
                                  )) : (<option value="">No hay colonias disponibles</option>)
                              }

                            </select>

                          </div>
                        </div>

                        <div className="btn__container">

                          <button className="btn__login" style={{ marginTop: "2rem", width: "250px", display: "flex", justifyContent: "center", alignItems: "center" }} type="submit" disabled={isLoading}>
                            {
                              isLoading ? (
                                <SyncLoader
                                  color="white"
                                  loading={isLoading}
                                  size={20}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                  speedMultiplier={.7}
                                />

                              ) : "Actualizar Operador"

                            }

                          </button>
                        </div>


                      </form>
                      <Dialog.Close asChild></Dialog.Close>
                      <Dialog.Close asChild>
                        <button className="IconButton" aria-label="Close">
                          <Cross2Icon />
                        </button>
                      </Dialog.Close>
                    </Dialog.Content>
                  </Dialog.Portal>
                </Dialog.Root>

              </div>
            </Tabs.Content>
          </Tabs.Root>
        </div>
      </section>
    </div>
  );
}

export default AdminChoferes;
