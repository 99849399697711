import React, { createContext, useContext, useState, useEffect } from "react";
import { io } from "socket.io-client";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  /*const socket = io("https://datt-tracking.com:444", {
    transports: ["websocket"],
  });
  //https://datt-tracking.com:444
  //http://localhost:444
  useEffect(() => {
    socket.on("notification", (message) => {
      setNotifications((prevNotifications) => [...prevNotifications, message]);
    });

    return () => {
      socket.disconnect();
    };
  }, []);
  */
  const sendNotification = (message) => {
    //socket.emit("notification", message);
  };

  return (
    <NotificationContext.Provider value={{ notifications, sendNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification debe ser utilizado dentro de NotificationProvider"
    );
  }
  return context;
};
