import React, { useState, useEffect, useRef } from "react";
import "@radix-ui/themes/styles.css";
import "../../styles/adminchof.css";
import * as Tabs from "@radix-ui/react-tabs";
import "../../styles/select.css";
import "../../styles/tabs.css";
import jwtDecode from "jwt-decode";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../../index.css";
import { toast } from "react-toastify";
import { API_URL } from "../../constants";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useNotification } from "../../pages/NotificationContext";
import { PageInfo } from "../PageInfo";
import SyncLoader from "react-spinners/ClipLoader";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { IoDocuments } from "react-icons/io5";
import { FaRegFileWord } from "react-icons/fa";
import { EyeNoneIcon, EyeOpenIcon, CrossCircledIcon } from "@radix-ui/react-icons"


function RegistrarClientes() {
  const dt = useRef(null);
  const [name, setNombre] = useState("");
  const [permisos, setPermisos] = useState([]);
  const [empresa, setEmpresa] = useState("");

  const [email, setEmail] = useState("");
  const [emailAlternativo, setEmailAlternativo] = useState("");
  const { sendNotification } = useNotification();
  const [isDialogEditOpen, setIsDialogEditOpen] = useState(false);
  const [isDialogDeleteFileOpen, setIsDialogDeleteFileOpen] = useState(false);
  const cols = [
    { field: "name", header: "Nombre" },
    { field: "razonSocial", header: "Razón Social" },
    { field: "email", header: "Email" },
    { field: "phone", header: "Teléfono" },
    { field: "facturacion", header: "Facturación" },
    { field: "credito", header: "Crédito" },
    { field: "estatus", header: "Estatus" },
  ];
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    razonSocial: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    phone: { value: null, matchMode: FilterMatchMode.IN },
    facturacion: { value: null, matchMode: FilterMatchMode.EQUALS },
    credito: { value: null, matchMode: FilterMatchMode.EQUALS },
    estatus: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [clientes, setClientes] = useState([]);
  const [phone, setPhone] = useState("");
  const [phonePagos, setPhonePagos] = useState("");
  const [nombrePagos, setNombrePagos] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [facturacion, setFacturacion] = useState("");
  const [docs, setDocs] = useState("");
  const [credito, setCredito] = useState("");
  const [detalles, setDetalles] = useState("");
  const [loggedName, setloggedName] = useState("");
  const [RFC, setRFC] = useState("");
  const [regimenFiscal, setRegimenFiscal] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  const [driverToEdit, setDriverToEdit] = useState([]);
  const [regimenesFiscales, setRegimenesFiscales] = useState([]);
  const [isDocsDialogOpen, setIsDocsDialogOpen] = useState(false);
  const [clientDocsData, setClientDocsData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [documentOriginalName, setDocumentOriginalName] = useState("");

  let filterClientesObj = {
    RFC: "",
    razonSocial: "",
    estatus: "",
    empresa: "",
    regimenFiscal: "",
  }

  const [filterClientes, setFilterClientes] = useState(filterClientesObj);



  useEffect(() => {
    // fetchCustomersData();
    const token = localStorage.getItem("token");

    let perms = JSON.parse(localStorage.getItem("permisos"))
    setPermisos(perms);

    if (perms.length <= 1) {
      setEmpresa(perms[0]);

      setFilterClientes({
        ...filterClientes,
        empresa: perms[0]
      })

    }

    if (token) {
      const decodedUser = jwtDecode(token);
      setloggedName(decodedUser.name);
      fetchRegimenFiscal();
    }
  }, []);

  //register customer
  async function registerCustomer(event) {
    setIsLoading(true);
    event.preventDefault();
    let uid = Math.floor(Math.random() * Date.now());
    const formData = new FormData();

    formData.append("uid", uid);
    formData.append("empresa", empresa);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("emailAlternativo", emailAlternativo);
    formData.append("phone", phone);
    formData.append("razonSocial", razonSocial);
    formData.append("facturacion", facturacion);
    formData.append("telefonoPagos", phonePagos);
    formData.append("nombrePagos", nombrePagos);
    formData.append("RFC", RFC);
    formData.append("regimenFiscal", regimenFiscal);
    formData.append("codigoPostal", codigoPostal);
    for (let i = 0; i < docs.length; i++) {
      formData.append("docs", docs[i]);
    }
    formData.append("credito", credito);
    formData.append("detalles", detalles);
    try {

      const response = await fetch(`${API_URL}/api/register-customer`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();

      console.log("DAta", data);
      if (data.status === "ok") {
        notifyInfo("¡El cliente está en revisión!");
        setRegimenFiscal("");
        document.getElementById("formCustomer").reset();
        const currentTime = new Date();
        const time = currentTime.toISOString();
        const user = `${loggedName} ha registrado un cliente.`;
        sendNotification({
          user: user,
          razonSocial: razonSocial,
          time: formatDateTime(time),
        });
        fetchCustomersData(event);
        setIsLoading(false);
      } else {
        notifyError(data.message);
        setIsLoading(false);
      }
    } catch (error) {
      notifyError("El cliente no se puede registrar");
      setIsLoading(false);
    }
  }

  function formatDateTime(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const formattedDate = dateTime.toLocaleDateString();
    const formattedTime = dateTime.toLocaleTimeString();

    return `${formattedDate} ${formattedTime}`;
  }

  //FETCH customer
  const fetchCustomersData = async (e) => {

    e.preventDefault();
    try {
      setIsLoadingSearch(true);
      let queryString = "?";
      Object.entries(filterClientes).forEach(([key, value], index) => {

        if (value !== "") {
          queryString += `${key}=${value}&`
        }
      })
      if (!queryString.includes("empresa")) {
        queryString += `empresa=${permisos}&`
      }
      queryString = queryString.substring(0, queryString.length - 1);


      const response = await fetch(`${API_URL}/api/customerUpdate${queryString}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log("Data", data);
      setIsLoadingSearch(false);

      if (data.status === "Ok") {
        setClientes(data.customers);
        return data;

      } else {
        notifyWarning(data.message);
        setClientes([]);
        setIsLoadingSearch(false);
      }

    } catch (error) {
      notifyError(error);
      setIsLoadingSearch(false);

    }

  };

  const notifySuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  const fetchRegimenFiscal = async () => {
    try {
      let response = await fetch(`${API_URL}/api/catalagos/regimenFiscal`);
      let data = await response.json();
      setRegimenesFiscales(data);
    } catch (error) {
      notifyError("No se pueden obtener los regimenes fiscales")
    }
  }

  const notifyInfo = (message) => {
    toast.info(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };
  const notifyWarning = (message) => {
    toast.warning(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  const notifyError = (message) => {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(clientes);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "clientes");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const dialogClientDocs = (data) => {
    setIsDocsDialogOpen(true);
    setClientDocsData(data.docs);
    setCustomerData(data);
  }

  const getDocType = (docName) => {
    // let docType = docName.includes(".docx") ? "word" : "pdf";
    return docName.includes(".docx") ? "word" : "pdf";

  };

  const displayClientFile = (options) => {
    return (
      <IoDocuments
        className="documentosInicio"
        onClick={() => dialogClientDocs(options)}

      />
      // <React.Fragment>
      //   <Button
      //     className="pi pi-file-pdf btn__pi"
      //     rounded
      //     outlined
      //     severity="primary"
      //     placeholder="Mostrar archivo"
      //     // onClick={(e) => {
      //     //   window.open(`${API_URL}${options.docs[0].path}`);
      //     // }}

      //     onClick={()=>setIsDocsDialogOpen(true)}
      //     style={{ color: "#ad0b00" }}
      //   />
      // </React.Fragment>
    );
  }

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        className="Input"
      />
    );
  };
  const fileEditor = (options) => {
    return (
      <input
        type="file"
        className="Input"
        placeholder={options.rowData.docs[0].filename}
      />

    );
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          style={{ fontSize: "1.5rem", color: "red" }}
        />
      </React.Fragment>
    );
  };

  function getStatusColor(estatus) {
    switch (estatus) {
      case "En revisión":
        return "blue";
      case "Aprobado":
        return "green";
      case "Rechazado":
        return "red";
      default:
        return "gray";
    }
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            style={{ marginLeft: "30px" }}
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar"
          />
        </span>
      </div>
    );
  };

  const onRowEditComplete = async (row) => {

    let body = row.newData;
    try {
      let response = await fetch(`${API_URL}/api/customerUpdate/${row.data._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
      });
      let data = await response.json();


      if (data.status === "Ok") {
        notifySuccess("Datos actualizados correctamente");
        // fetchCustomersData();
      } else {
        notifyError("No se pudo realizar la actualización")

      }


    } catch (error) {
      console.error(error);
      notifyError("No se pudo realizar la actualización")
    }

  }

  const handleChangeFilter = (e) => {
    setFilterClientes({
      ...filterClientes,
      [e.target.name]: e.target.value
    })

  }

  const handleSearchClientes = async (e) => {
    e.preventDefault();
    console.log("HandleSearchclientes");

  }

  const handleEditCliente = async (e) => {
    e.preventDefault();
    console.log("DriverToEdit",driverToEdit);
    let formData = new FormData();
    let uid = driverToEdit.uid ? driverToEdit.uid : Math.floor(Math.random() * Date.now());

    formData.append("uid", uid);
    formData.append("nombre", driverToEdit.nombre)
    formData.append("nombrePagos", driverToEdit.nombrePagos);
    formData.append("email", driverToEdit.email);
    formData.append("emailAlternativo", driverToEdit.emailAlternativo);

    formData.append("razonSocial", driverToEdit.razonSocial);
    formData.append("telefono", driverToEdit.telefono);
    formData.append("telefonoPagos", driverToEdit.telefonoPagos)
    formData.append("facturacion", driverToEdit.facturacion);
    formData.append("docs", JSON.stringify(driverToEdit.docs));
    if(driverToEdit.nuevosDocumentos){
      for (let i = 0; i < driverToEdit.nuevosDocumentos.length; i++) {
        formData.append("nuevosDocumentos", driverToEdit.nuevosDocumentos[i]);
      }
    }
    formData.append("credito", driverToEdit.credito);
    formData.append("detalles", driverToEdit.detalles);
    formData.append("estatus", driverToEdit.estatus);
    formData.append("RFC", driverToEdit.RFC);

    formData.append("regimenFiscal", driverToEdit.regimenFiscal);
    formData.append("codigoPostal", driverToEdit.codigoPostal);
    formData.append("razon", driverToEdit.razon);

    try {
      setIsLoading(true);
      let response = await fetch(`${API_URL}/api/customerUpdate/${driverToEdit._id}`, {
        // headers: {
        //   'Content-Type': 'application/json'
        // },
        method: 'PUT',
        body: formData
      });
      let data = await response.json();
      setIsLoading(false);
      if (data.status === "Ok") {
        notifySuccess(data.message)
        fetchCustomersData(e);
        setIsDialogEditOpen(false);
      } else {
        notifyError("No se pudo realizar la actualización de datos");
      }

    } catch (error) {
      setIsLoading(false);
      notifyError("No se pudo realizar la actualización de datos");
    }
  }

  const bodyEditChofer = (data) => {

    return (
      <Button
        className="pi pi-pencil btn__pi"
        onClick={() => { setIsDialogEditOpen(true); setDriverToEdit(data) }}
      />
    )
  }
  const handleEditChange = (e) => {
    if (e.target.name === "docs") {
      setDriverToEdit({
        ...driverToEdit,
        nuevosDocumentos: e.target.files
      })
    } else {
      setDriverToEdit({
        ...driverToEdit,
        [e.target.name]: e.target.value
      });
    }
  }

  const handleEditFile = async (e) => {
    try {

      if (e.target.files.length > 0) {
        let newFormData = new FormData();
        newFormData.append("uid", customerData?.uid);

        newFormData.append("filename", documentOriginalName);
        newFormData.append("razonSocial", customerData?.razonSocial);
        newFormData.append("docs", JSON.stringify(customerData.docs));

        for (let i = 0; i < e.target.files.length; i++) {
          newFormData.append("doc", e.target.files[i]);
        }
        let response = await fetch(`${API_URL}/api/customerUpdate/updateFile/${customerData._id}`, {
          method: 'PUT',
          body: newFormData
        });

        let data = await response.json();
        if (data.status === "Ok") {
          setClientDocsData(data.docs);
          let customerResponse = await fetchCustomersData(e);
          let dataCust = customerResponse.find((customer) => customer._id === customerData._id);
          setCustomerData(dataCust);
          notifySuccess(data.message);
          setDocumentOriginalName("");
        } else if (data.error) {
          notifyError(data.error);
        }
      }
    } catch (error) {
      console.error("error ", error);
      notifyError("No se pudo realizar la actualización del archivo");
    }
  }

  const handleDeleteFile = async (e) => {
    e.preventDefault();
    let body = {
      docs: clientDocsData,
      documentOriginalName
    }
    try {
      let response = await fetch(`${API_URL}/api/customerUpdate/deleteFile/${customerData._id}`, {
        headers: {
          "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify(body)
      });

      let data = await response.json();
      console.log("DATA", data);

      if (data.status === "Ok") {
        notifySuccess(data.message);
        setClientDocsData(data.docs);
        setIsDialogDeleteFileOpen(false);
        fetchCustomersData(e);
      }
    } catch (error) {
      notifyError(error);
    }

  }

  return (
    <div>
      <div className="content-wrapper container__block" >
        <PageInfo heading="DATT - Tracking | Administrar Clientes" />
        <section className="">
          <div className="container-fluid">
            <Tabs.Root className="TabsRoot" defaultValue="tab1">
              <Tabs.List className="TabsList" aria-label="Registra un chofer">
                <Tabs.Trigger className="TabsTrigger" value="tab1">
                  Registrar Cliente
                </Tabs.Trigger>
                <Tabs.Trigger className="TabsTrigger" value="tab2">
                  Ver Clientes
                </Tabs.Trigger>
              </Tabs.List>

              <Tabs.Content
                className="TabsContent"
                value="tab1"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="container__width_70 client__container" style={{ flex: 1 }}>
                  {/* Register customer form */}

                  <form
                    encType="multipart/form-data"
                    onSubmit={(e) => registerCustomer(e)}
                    id="formCustomer"
                  >
                    <div className="data__group">
                      <h4>Datos del Cliente</h4>
                      <div className="data__group_form">
                        <div className="form__group_form-select">
                          {
                            permisos && permisos.length > 1 ? (
                              <div className="form__group form__group_selectField">
                                {/*Form input nombre*/}
                                <label style={{ margin: 0 }} className="FormLabel" >
                                  Empresa
                                </label>
                                <select
                                  className="SelectTrigger boxSizing__bb"
                                  type="text"
                                  required
                                  style={{ width: "100%" }}
                                  onChange={(e) => setEmpresa(e.target.value)}
                                >
                                  <option>Selecciona una empresa</option>
                                  {
                                    permisos.map((permiso, index) => (
                                      <option key={index} value={permiso}>{permiso}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            ) : ""
                          }

                        </div>


                        <div className="form__group_form-select">
                          <div className="form__group form__group_selectField">
                            {/*Form input nombre*/}
                            <label style={{ margin: 0 }} className="FormLabel" >
                              Nombre de contacto Facturación *
                            </label>
                            <input
                              className="Input boxSizing__bb"
                              type="text"
                              required
                              placeholder="Nombre"
                              onChange={(e) => { setNombre(e.target.value) }}
                            />
                          </div>

                          <div className="form__group form__group_selectField">
                            {/*Form input nombre*/}
                            <label style={{ margin: 0 }} className="FormLabel">
                              Nombre de contacto Operaciones
                            </label>
                            <input
                              className="Input boxSizing__bb"
                              type="text"
                              placeholder="Nombre"
                              onChange={(e) => setNombrePagos(e.target.value)}
                            />

                          </div>
                        </div>
                        <div className="form__group_form-select">

                          <div className="form__group form__group_selectField">

                            {/*Phone form field */}
                            <label style={{ margin: 0 }} className="FormLabel">Teléfono Facturación</label>
                            <input
                              className="Input boxSizing__bb"
                              type="number"
                              placeholder="+00 00 00 00"
                              onChange={(e) => setPhonePagos(e.target.value)}
                            />
                          </div>

                          <div className="form__group form__group_selectField">
                            {/*Phone form field */}
                            <label style={{ margin: 0 }} className="FormLabel">
                              Teléfono Operaciones
                            </label>
                            <input
                              className="Input boxSizing__bb"
                              type="phone"
                              placeholder="+00 00 00 00"
                              onChange={(e) => setPhone(e.target.value)}
                            />

                          </div>

                        </div>

                        <div className="form__group_form-select">
                          <div className="form__group form__group_selectField">
                            {/*Lastnames form field */}
                            <label style={{ margin: 0 }} className="FormLabel">Razón social *</label>
                            <input
                              className="Input boxSizing__bb"
                              type="text"
                              required
                              placeholder="Razón social"
                              onChange={(e) => setRazonSocial(e.target.value)}
                            />

                          </div>

                          <div className="form__group form__group_selectField">

                            <label style={{ margin: 0 }} className="FormLabel">RFC *</label>
                            <input
                              className="Input boxSizing__bb"
                              type="text"
                              required
                              placeholder="RFC"
                              onChange={(e) => setRFC(e.target.value)}
                            />

                          </div>

                        </div>
                        <div className="form__group_form-select">
                          <div className="form__group form__group_selectField">

                            <label style={{ margin: 0 }} className="FormLabel">Regimen Fiscal *</label>
                            <br></br>
                            <select
                              onChange={(e) => setRegimenFiscal(e.target.value)}
                              className="SelectTrigger boxSizing__bb"
                              value={regimenFiscal}
                              style={{ width: "100%", padding: "0 .5rem", margin: 0, marginTop: "8px" }}
                              required
                            >
                              <option value="">Escoge una opción</option>
                              {
                                regimenesFiscales.length > 0 ?
                                  regimenesFiscales.map((regimenFiscal) => (
                                    <option key={regimenFiscal.Clave} value={regimenFiscal.Clave} >{regimenFiscal.regimenFiscal}</option>
                                  )) : ""
                              }

                              {/* <option value="601">
                                General de Ley Personas Morales
                              </option>
                              <option value="603">
                                Personas Morales con Fines no Lucrativos
                              </option>
                              <option value="605">
                                Sueldos y Salarios e Ingresos Asimilados a Salarios
                              </option>
                              <option value="606">Arrendamiento</option>
                              <option value="608">Demás ingresos</option>
                              <option value="610">
                                Residentes en el Extranjero sin Establecimiento
                                Permanente en México
                              </option>
                              <option value="611">
                                Ingresos por Dividendos (socios y accionistas)
                              </option>
                              <option value="612">
                                Personas Físicas con Actividades Empresariales y
                                Profesionales
                              </option>
                              <option value="614">Ingresos por intereses</option>
                              <option value="616">Sin obligaciones fiscales</option>
                              <option value="620">
                                Sociedades Cooperativas de Producción que optan por
                                diferir sus ingresos
                              </option>
                              <option value="621">Incorporación Fiscal</option>
                              <option value="622">
                                Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras
                              </option>
                              <option value="623">
                                Opcional para Grupos de Sociedades
                              </option>
                              <option value="624">Coordinados</option>
                              <option value="628">Hidrocarburos</option>
                              <option value="607">
                                Régimen de Enajenación o Adquisición de Bienes
                              </option>
                              <option value="629">
                                De los Regímenes Fiscales Preferentes y de las Empresas
                                Multinacionales
                              </option>
                              <option value="630">
                                Enajenación de acciones en bolsa de valores
                              </option>
                              <option value="615">
                                Régimen de los ingresos por obtención de premios
                              </option>
                              <option value="625">
                                Régimen de las Actividades Empresariales con ingresos a
                                través de Plataformas Tecnológicas
                              </option> */}
                            </select>


                          </div>
                          <div className="form__group form__group_selectField">

                            <label style={{ margin: 0 }} className="FormLabel">Código Postal *</label>
                            <input
                              className="Input boxSizing__bb"
                              type="text"
                              required
                              placeholder="Código Postal"
                              onChange={(e) => setCodigoPostal(e.target.value)}
                            />
                          </div>
                        </div>


                        <div className="form__group_form-select">
                          <div className="form__group form__group_selectField">

                            {/*Email form field */}
                            <label style={{ margin: 0 }} className="FormLabel">Correo electrónico *</label>
                            <input
                              className="Input boxSizing__bb"
                              type="email"
                              required
                              placeholder="Correo electrónico"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>

                          <div className="form__group form__group_selectField">
                            <label style={{ margin: 0 }} className="FormLabel">
                              Correo electrónico alternativo
                            </label>
                            <input
                              className="Input boxSizing__bb"
                              type="email"
                              placeholder="Correo electrónico"
                              onChange={(e) => setEmailAlternativo(e.target.value)}
                            />
                          </div>




                        </div>

                        <div className="form__group_form-select">

                          <div className="form__group form__group_selectField">
                            {/*Factura form field */}
                            <label style={{ margin: 0 }} className="FormLabel">
                              Requisitos de facturación *
                            </label>
                            <input
                              className="Input boxSizing__bb"
                              type="text"
                              required
                              placeholder="Facturación"
                              onChange={(e) => setFacturacion(e.target.value)}
                              style={{ flex: "none" }}
                            />

                          </div>

                          <div className="form__group form__group_selectField">
                            {/*Credito form field */}
                            <label style={{ margin: 0 }} className="FormLabel">Línea de crédito *</label>
                            <input
                              className="Input boxSizing__bb"
                              type="number"
                              required
                              placeholder="Cantidad en días"
                              onChange={(e) => setCredito(e.target.value)}
                              style={{ flex: "none" }}

                            />
                          </div>

                        </div>


                        {/*Detalles form field */}
                        <label style={{ margin: 0 }} className="FormLabel">Detalles</label>
                        <input
                          className="Input boxSizing__bb"
                          type="text"
                          placeholder="Más detalles"
                          onChange={(e) => setDetalles(e.target.value)}
                          style={{ flex: "none" }}

                        />
                        {/*Docs form field */}
                        <label className="FormLabel">Cargar documentos *</label>
                        <input
                          className="Input boxSizing__bb"
                          type="file"
                          required
                          multiple
                          placeholder="Selecciona un archivo"
                          onChange={(e) => setDocs(e.target.files)}
                          style={{ flex: "none" }}
                        />

                        <div className="btn__container" style={{ marginTop: "2rem" }}>
                          {/*Register customer btn in BD*/}
                          <button
                            type="submit"
                            className="btn__login"
                            disabled={isLoading}
                            style={{ marginTop: 10, width: "230px", display: "flex", justifyContent: "center", alignItems: "center" }}
                          >
                            {
                              isLoading ? (
                                <SyncLoader
                                  color="white"
                                  loading={isLoading}
                                  size={20}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                  speedMultiplier={.7}
                                />

                              ) : "Registrar Cliente"
                            }

                          </button>

                        </div>
                      </div>
                    </div>

                    <br></br>


                  </form>
                </div>
              </Tabs.Content>

              <Tabs.Content className="TabsContent" value="tab2">
                {/* Drivers registered in a table */}
                <h3>Clientes registrados</h3>
                <section className=''>
                  <div className='cartaPorte__group'>
                    <div className="cartaPorte__group_elements">
                      <label className='FormLabel'>RFC del Cliente</label>
                      <input
                        className='InputForm'
                        name="RFC"
                        value={filterClientes.RFC}
                        onChange={handleChangeFilter}
                      >

                      </input>
                    </div>

                    <div className="cartaPorte__group_elements">
                      <label className='FormLabel'>Razón Social</label>
                      <input
                        className='InputForm'
                        name="razonSocial"
                        value={filterClientes.razonSocial}
                        onChange={handleChangeFilter}
                      >
                      </input>
                    </div>


                    {/* <div className="cartaPorte__group_elements">
                      <label className='FormLabel'>Cliente</label>
                      <select
                        className='SelectTrigger'
                        name="clientName"
                        value={filterClientes.Cliente}
                        onChange={handleChangeFilter}
                      >
                        <option value="">Selecciona un Cliente</option>
                        {
                          customersData && customersData.length > 0 ?
                            customersData.map((customer) => (
                              <option key={customer._id} value={customer.razonSocial}>{customer.razonSocial}</option>
                            )) : ""
                        }
                      </select>
                    </div> */}

                    {
                      permisos && permisos.length > 1 ?
                        (
                          <div className="cartaPorte__group_elements">
                            <label className="FormLabel">Empresa</label>
                            <select
                              className="SelectTrigger"
                              required
                              name='empresa'
                              style={{ width: "100%" }}
                              onChange={handleChangeFilter}
                              value={filterClientes.empresa}
                            >
                              <option value="">Selecciona una Empresa</option>
                              {
                                permisos.map((permiso, index) => (
                                  <option key={index} value={permiso}>{permiso}</option>
                                ))
                              }
                            </select>
                          </div>
                        ) : ""
                    }

                    <div className="cartaPorte__group_elements">
                      <label className='FormLabel'>Estatus</label>
                      <select
                        className='SelectTrigger'
                        name="estatus"
                        onChange={handleChangeFilter}
                        value={filterClientes.estatus}
                      >
                        <option value="">Selecciona un Estatus</option>
                        <option value="En revisión">En revisión</option>
                        <option value="Aprobado">Aprobado</option>
                        <option value="Rechazado">Rechazado</option>
                      </select>
                    </div>

                    <div className="cartaPorte__group_elements">
                      <label className='FormLabel'>Regimen Fiscal</label>
                      <select
                        className='SelectTrigger'
                        name="regimenFiscal"
                        onChange={handleChangeFilter}
                        value={filterClientes.regimenFiscal}
                      >
                        <option value="">Selecciona un Regimen Fiscal</option>
                        {
                          regimenesFiscales && regimenesFiscales.length > 0 ?
                            regimenesFiscales.map((regimen) => (
                              <option key={regimen._id} value={regimen.Clave}>{regimen.regimenFiscal}</option>
                            )) : ""
                        }

                      </select>
                    </div>

                    <div className="cartaPorte__group_elements" style={{ justifyContent: "flex-end" }} >
                      <div className='btn__container_search'>
                        <button
                          type="submit"
                          disabled={isLoadingSearch}
                          className="btn__login"
                          style={{ marginTop: 10, width: "240px", display: "flex", justifyContent: "center", alignItems: "center" }}
                          onClick={fetchCustomersData}
                        >
                          {
                            isLoadingSearch ? (
                              <SyncLoader
                                color="white"
                                loading={isLoadingSearch}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                speedMultiplier={.7}
                              />

                            ) : "Buscar Clientes"
                            // <FaSearch />
                          }

                        </button>
                      </div>

                    </div>


                  </div>

                </section>
                <header
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Button
                    type="button"
                    icon="pi pi-file-excel"
                    severity="success"
                    rounded
                    onClick={exportExcel}
                    data-pr-tooltip="XLS"
                    style={{ fontSize: "1.5rem", color: "green" }}
                  />
                </header>
                <div className="card">
                  <DataTable
                    value={clientes}
                    header={renderHeader}
                    filters={filters}
                    paginator
                    ref={dt}
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: "50rem" }}
                    onRowEditComplete={onRowEditComplete}

                    editMode="row"
                    dataKey="_id"
                  >
                    <Column field="_id" header="ID" hidden />
                    <Column
                      field="_id"
                      header="id"
                      hidden
                      editor={(options) => textEditor(options)}
                    />

                    <Column
                      field="nombre"
                      header="Nombre de Contacto Facturación"
                      sortable
                      editor={(options) => textEditor(options)}
                    />
                    <Column
                      field="nombrePagos"
                      header="Nombre de Contacto Operaciones"
                      sortable
                      editor={(options) => textEditor(options)}
                    />
                    <Column
                      field="telefonoPagos"
                      header="Teléfono Facturación"
                      sortable
                      editor={(options) => textEditor(options)}
                    />
                    <Column
                      field="telefono"
                      header="Teléfono Operaciones"
                      sortable
                      editor={(options) => textEditor(options)}
                    />

                    <Column
                      field="razonSocial"
                      header="Razón Social"
                      sortable
                      editor={(options) => textEditor(options)}
                    />
                    <Column
                      field="RFC"
                      header="RFC"
                      sortable
                      editor={(options) => textEditor(options)}
                    />
                    <Column
                      field="regimenFiscal"
                      header="Régimen Fiscal"
                      sortable
                      editor={(options) => textEditor(options)}
                    />
                    <Column
                      field="codigoPostal"
                      header="Código Postal"
                      sortable
                      editor={(options) => textEditor(options)}
                    />
                    <Column
                      field="email"
                      header="Correo"
                      sortable
                      editor={(options) => textEditor(options)}
                    />
                    <Column
                      field="emailAlternativo"
                      header="Correo Alternativo"
                      sortable
                      editor={(options) => textEditor(options)}
                    />

                    <Column
                      field="facturacion"
                      header="Facturación"
                      sortable
                      editor={(options) => textEditor(options)}
                    />

                    <Column
                      field="credito"
                      header="Crédito"
                      sortable
                      editor={(options) => textEditor(options)}
                    />
                    <Column
                      field="detalles"
                      header="Detalles"
                      sortable
                      editor={(options) => textEditor(options)}
                    />

                    <Column
                      header="Documentos"
                      style={{ minWidth: "11rem", marginLeft: "5px" }}
                      // editor={(options)=>fileEditor(options)}
                      body={displayClientFile}
                    >

                    </Column>
                    <Column
                      field="estatus"
                      header="Estatus"
                      sortable
                      // editor={(options) => textEditor(options)}
                      body={(rowData) => (
                        <div
                          style={{
                            background: getStatusColor(rowData.estatus),
                            padding: "6px",
                            borderRadius: "5px",
                            color: "white",
                          }}
                        >
                          {rowData.estatus}

                        </div>
                      )}
                    />

                    <Column
                      field="razon"
                      header="Observaciones"
                      sortable
                      body={(rowData) => {
                        if (rowData.estatus === "Rechazado") {
                          return (
                            <div
                              style={{
                                background: getStatusColor(rowData.estatus),
                                padding: "6px",
                                borderRadius: "5px",
                                color: "white",
                                // boxShadow: "0 0 5px rgba(0, 0, 0, 0.8)",
                              }}
                            >
                              {rowData.razon}
                            </div>
                          )
                        }

                      }}
                    >
                    </Column>
                    <Column
                      headerStyle={{ width: "20%", minWidth: "8rem" }}
                      bodyStyle={{ textAlign: "center" }}
                      body={bodyEditChofer}
                    ></Column>
                  </DataTable>

                  <Dialog.Root
                    open={isDialogEditOpen}
                    onOpenChange={() => { setIsDialogEditOpen(false) }}
                  >
                    <Dialog.Portal>
                      <Dialog.Overlay className="DialogOverlay" />
                      <Dialog.Content className="DialogContentCP dialogEdit" style={{ backgroundColor: "white" }}>
                        <Dialog.Title className="DialogTitle">
                          Editar Cliente
                        </Dialog.Title>
                        <form onSubmit={(e) => handleEditCliente(e)}>
                          <div className="dialogEditGroup">
                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="name">
                                Nombre de contacto Facturación *
                              </label>
                              <input
                                className="InputForm"
                                type="text"
                                name="nombre"
                                onChange={handleEditChange}
                                required
                                value={driverToEdit?.nombre}
                              ></input>
                            </div>
                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="name">
                                Nombre de contacto Operaciones
                              </label>
                              <input
                                className="InputForm"
                                type="text"
                                name="nombrePagos"
                                onChange={handleEditChange}
                                value={driverToEdit?.nombrePagos}
                              ></input>
                            </div>

                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="telefonoPagos">
                                Teléfono Facturación
                              </label>
                              <input
                                className="InputForm"
                                type="phone"
                                name="telefonoPagos"
                                onChange={handleEditChange}
                                required
                                value={driverToEdit?.telefonoPagos}
                              ></input>
                            </div>
                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="telefono">
                                Teléfono Operaciones
                              </label>
                              <input
                                className="InputForm"
                                type="phone"
                                name="telefono"
                                onChange={handleEditChange}
                                value={driverToEdit?.telefono}
                              ></input>
                            </div>

                            <div className="dialogEditGroup_elements">

                              <label className="FormLabel" htmlFor="name">
                                Razón Social
                              </label>
                              <input
                                className="InputForm"
                                type="text"
                                name="razonSocial"
                                onChange={handleEditChange}
                                required
                                value={driverToEdit?.razonSocial}
                              ></input>
                            </div>

                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="name">
                                RFC
                              </label>
                              <input
                                className="InputForm"
                                type="text"
                                name="RFC"
                                onChange={handleEditChange}
                                required
                                value={driverToEdit?.RFC}
                              ></input>
                            </div>

                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="name">
                                Regimen Fiscal
                              </label>
                              <select
                                onChange={handleEditChange}
                                className="SelectTrigger boxSizing__bb"
                                name="regimenFiscal"
                                value={driverToEdit?.regimenFiscal}
                                style={{ width: "100%", padding: "0 .5rem", margin: 0 }}
                                required
                              >
                                <option value="">Escoge una opción</option>
                                {
                                  regimenesFiscales.length > 0 ?
                                    regimenesFiscales.map((regimenFiscal) => (
                                      <option key={regimenFiscal._id} value={regimenFiscal.Clave} >{regimenFiscal.regimenFiscal}</option>
                                    )) : ""
                                }

                                {/* <option value="601">
                                  General de Ley Personas Morales
                                </option>
                                <option value="603">
                                  Personas Morales con Fines no Lucrativos
                                </option>
                                <option value="605">
                                  Sueldos y Salarios e Ingresos Asimilados a Salarios
                                </option>
                                <option value="606">Arrendamiento</option>
                                <option value="608">Demás ingresos</option>
                                <option value="610">
                                  Residentes en el Extranjero sin Establecimiento
                                  Permanente en México
                                </option>
                                <option value="611">
                                  Ingresos por Dividendos (socios y accionistas)
                                </option>
                                <option value="612">
                                  Personas Físicas con Actividades Empresariales y
                                  Profesionales
                                </option>
                                <option value="614">Ingresos por intereses</option>
                                <option value="616">Sin obligaciones fiscales</option>
                                <option value="620">
                                  Sociedades Cooperativas de Producción que optan por
                                  diferir sus ingresos
                                </option>
                                <option value="621">Incorporación Fiscal</option>
                                <option value="622">
                                  Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras
                                </option>
                                <option value="623">
                                  Opcional para Grupos de Sociedades
                                </option>
                                <option value="624">Coordinados</option>
                                <option value="628">Hidrocarburos</option>
                                <option value="607">
                                  Régimen de Enajenación o Adquisición de Bienes
                                </option>
                                <option value="629">
                                  De los Regímenes Fiscales Preferentes y de las Empresas
                                  Multinacionales
                                </option>
                                <option value="630">
                                  Enajenación de acciones en bolsa de valores
                                </option>
                                <option value="615">
                                  Régimen de los ingresos por obtención de premios
                                </option>
                                <option value="625">
                                  Régimen de las Actividades Empresariales con ingresos a
                                  través de Plataformas Tecnológicas
                                </option> */}
                              </select>

                            </div>

                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="name">
                                Código Postal
                              </label>
                              <input
                                className="InputForm"
                                type="text"
                                maxLength={50}
                                name="codigoPostal"
                                onChange={handleEditChange}
                                required
                                value={driverToEdit?.codigoPostal}
                              ></input>
                            </div>

                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="name">
                                Correo electrónico
                              </label>
                              <input
                                className="InputForm"
                                type="email"
                                maxLength={50}
                                name="email"
                                onChange={handleEditChange}
                                required
                                value={driverToEdit?.email}
                              ></input>
                            </div>
                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="name">
                                Correo electrónico alternativo
                              </label>
                              <input
                                className="InputForm"
                                type="email"
                                maxLength={50}
                                name="emailAlternativo"
                                onChange={handleEditChange}
                                required
                                value={driverToEdit?.emailAlternativo}
                              ></input>
                            </div>

                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="facturacion">
                                Requisitos de Facturación
                              </label>
                              <input
                                className="InputForm"
                                type="text"
                                maxLength={50}
                                name="facturacion"
                                onChange={handleEditChange}
                                required
                                value={driverToEdit?.facturacion}
                              ></input>
                            </div>

                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="credito">
                                Línea de Crédito
                              </label>
                              <input
                                className="InputForm"
                                type="text"
                                maxLength={50}
                                name="credito"
                                onChange={handleEditChange}
                                required
                                value={driverToEdit?.credito}
                              ></input>
                            </div>

                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="detalles">
                                Detalles
                              </label>
                              <input
                                className="InputForm"
                                type="text"
                                maxLength={50}
                                name="detalles"
                                onChange={handleEditChange}
                                value={driverToEdit?.detalles}
                              ></input>
                            </div>

                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel">Cargar documentos *</label>
                              <input
                                className="InputForm"
                                type="file"
                                multiple
                                placeholder="Selecciona un archivo"
                                name="docs"
                                onChange={handleEditChange}
                                // onChange={(e) => setDocs(e.target.files)}
                                style={{ flex: "none" }}
                              />
                            </div>
                          </div>
                          <div className="btn__container">


                            <button className="btn__login" style={{ marginTop: "2rem", width: "250px", display: "flex", justifyContent: "center", alignItems: "center" }} type="submit" disabled={isLoading}>
                              {
                                isLoading ? (
                                  <SyncLoader
                                    color="white"
                                    loading={isLoading}
                                    size={20}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                    speedMultiplier={.7}
                                  />

                                ) : "Editar Cliente"
                              }
                            </button>
                          </div>
                        </form>
                        <Dialog.Close asChild></Dialog.Close>
                        <Dialog.Close asChild>
                          <button className="IconButton" aria-label="Close">
                            <Cross2Icon />
                          </button>
                        </Dialog.Close>
                      </Dialog.Content>
                    </Dialog.Portal>
                  </Dialog.Root>
                  <Dialog.Root
                    open={isDocsDialogOpen}
                    onOpenChange={() => setIsDocsDialogOpen(false)}
                  >
                    <Dialog.Portal>
                      <Dialog.Overlay className="DialogOverlay" />
                      <Dialog.Content className="DialogContentFactura dialogContentViaje">
                        <Dialog.Title className="DialogTitle">
                          Documentos del Cliente
                        </Dialog.Title>
                        <Dialog.Description className="DialogDescription">
                          Abajo se muestran los documentos que se anexaron por
                          cliente.
                        </Dialog.Description>

                        {
                          clientDocsData.length > 0 && clientDocsData ?
                            clientDocsData.map((doc) => {
                              let docType = getDocType(doc.originalname);
                              return (
                                <div className="docs__container__parent">
                                  <div className="docs__container" onClick={() => { window.open(`${API_URL}${doc.path}`); }}>
                                    {
                                      docType === "word" ? (<FaRegFileWord style={{ color: "#073b8d", fontSize: "1.3rem" }} />) : (<i className="pi pi-file-pdf btn__pi"></i>)
                                    }
                                    <div className="doc__element_p">
                                      <p >
                                        {doc.originalname}
                                      </p>


                                    </div>
                                  </div>
                                  <div className="docs__control">
                                    <label className="editFile__btn" htmlFor="editFile" onClick={(e) => setDocumentOriginalName(doc.originalname)}>
                                      <i className="pi pi-pencil btn__pi"></i>
                                    </label>
                                    <label className="editFile__btn" onClick={(e) => { setDocumentOriginalName(doc.originalname); setIsDialogDeleteFileOpen(true) }}>
                                      <i className="pi pi-trash btn__pi" style={{ color: "red" }}></i>
                                    </label>

                                    <input
                                      type="file"
                                      name={`${doc.originalname}`}
                                      id="editFile"
                                      className={`editFile ${doc.originalname}`}

                                      onChange={handleEditFile}
                                    >
                                    </input>
                                  </div>

                                </div>
                              )
                            }) : ""
                        }
                        {/* {viajeToSave && (
                          <div style={{ margin: "1rem 0" }}>
                            {viajeToSave.documentos.map((documento, index) => (
                              <button
                                key={documento._id}
                                className="btnArchivos"
                                onClick={() => showPdf(index)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {documento.originalname}
                              </button>
                            ))}
                          </div>
                        )} */}


                      </Dialog.Content>
                    </Dialog.Portal>
                  </Dialog.Root>
                  <Dialog.Root
                    open={isDialogDeleteFileOpen}
                    onOpenChange={() => setIsDialogDeleteFileOpen(false)}
                  >
                    <Dialog.Portal>
                      <Dialog.Overlay className="DialogOverlay" />
                      <Dialog.Content
                        className="DialogContent"
                        style={{
                          height: "350px",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          width: "500px",
                          backgroundColor: "white"
                        }}>
                        <Dialog.Title className="DialogTitle" style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center"
                        }}>
                          <CrossCircledIcon
                            style={{
                              width: "78px",
                              height: "90px",
                              color: "red"
                            }}
                          />
                          Eliminar archivo
                        </Dialog.Title>
                        <Dialog.Description className="DialogDescription" style={{ textAlign: "center", width: "100%", wordWrap: "break-word" }}>
                          ¿Estás seguro de que deseas eliminar a <b>{documentOriginalName}</b>?
                        </Dialog.Description>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "20px",
                            marginTop: "14px"

                          }}
                        >
                          <Dialog.Close asChild>
                            <Button
                              className="EliminarButton"
                              onClick={handleDeleteFile}
                            >
                              Eliminar
                            </Button>
                          </Dialog.Close>
                          <Dialog.Close asChild>
                            <Button
                              aria-label="Close"
                              className="CancelarButton"
                              style={{
                                backgroundColor: "gray"
                              }}
                            >
                              Cancelar
                            </Button>
                          </Dialog.Close>
                        </div>
                      </Dialog.Content>
                    </Dialog.Portal>
                  </Dialog.Root>
                  {/* <label className="editFile__btn" htmlFor="editFile" onClick={(e)=>setDocumentOriginalName(doc.originalname)}>
                                      <i className="pi pi-pencil btn__pi"></i>
                                    </label> */}


                </div>
              </Tabs.Content>
            </Tabs.Root>
          </div>
        </section>
      </div>
    </div>
  );
}

export default RegistrarClientes;
