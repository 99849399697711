import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ExitIcon,
  RowsIcon,
  BellIcon,
  EnvelopeClosedIcon,
} from "@radix-ui/react-icons";
import { useNotification } from "../pages/NotificationContext";

function Header() {
  const { notifications } = useNotification();

  useEffect(() => {}, [notifications]);

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("permisos");
    localStorage.removeItem("token");
    console.log("LOGOUT");
    navigate("/", { replace: true });
  };

  return (
    <div>
      <nav
        className="main-header navbar navbar-expand navbar-white navbar-light"
        style={{ zIndex: 999 }}
      >
        <ul className="navbar-nav">
          <a className="nav-link" data-widget="pushmenu">
            <RowsIcon />
          </a>
          <li className="nav-item d-sm-inline-block">
            <a href="/dashboard" className="nav-link">
              Inicio
            </a>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <BellIcon />
              <span className="badge badge-danger navbar-badge">
                {notifications.length}
              </span>
            </a>
            {notifications.length > 0 ? (
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                {notifications.map((notification, index) => (
                  <a href="#" className="dropdown-item" key={index}>
                    <div className="media">
                      <div className="media-body">
                        <h3 className="dropdown-item-title">
                          {notification.user}
                          <span className="float-right text-sm">
                            <EnvelopeClosedIcon />
                          </span>
                        </h3>
                        {notification.razonSocial && (
                          <p className="text-sm">
                            Razón Social: {notification.razonSocial}
                          </p>
                        )}
                        <p className="text-sm text-muted">
                          <i className="far fa-clock mr-1"></i>{" "}
                          {notification.time}
                        </p>
                      </div>
                    </div>
                  </a>
                ))}
                <div className="dropdown-divider"></div>
              </div>
            ) : (
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <p className="dropdown-item">Sin notificaciones</p>
              </div>
            )}
          </li>
          <li className="nav-item d-sm-inline-block">
            <a href="" className="nav-link" onClick={handleLogout}>
              Cerrar sesión <ExitIcon />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
export default Header;
