import * as Form from "@radix-ui/react-form";
import React, { useState, useEffect, useRef } from "react";
import "@radix-ui/themes/styles.css";
import "../../styles/adminchof.css";

import * as Tabs from "@radix-ui/react-tabs";
import "../../styles/select.css";
import "../../styles/tabs.css";
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css"; // Elige un tema que prefieras
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../../index.css";
import * as Dialog from "@radix-ui/react-dialog";
import { API_URL } from "../../constants";
import { FilterMatchMode } from "primereact/api";
import { PageInfo } from "../PageInfo";
import { CrossCircledIcon, CheckIcon } from "@radix-ui/react-icons"
import * as Checkbox from "@radix-ui/react-checkbox";
import SyncLoader from "react-spinners/ClipLoader";
import { Cross2Icon } from "@radix-ui/react-icons";



export const AdminRemolques = () => {
    //transports data in a datatable

    const [transports, setTransportData] = useState("");
    const dt = useRef(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [transportToDelete, setTransportToDelete] = useState(null);
    const [transportToEdit, setTransportToEdit] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        company: { value: null, matchMode: FilterMatchMode.IN },
        phone: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });

    let remolquesObj = {
        empresa: "",
        placaRemolque: "",
        subTipoRem: ""
    }

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [remolques, setRemolques] = useState(remolquesObj);
    const [filterRemolques, setFilterRemolques] = useState(remolquesObj)
    const [remolquesData, setRemolquesData] = useState([]);
    const [remolqueToEdit, setRemolqueToEdit] = useState([]);
    const [remolqueToDelete, setRemolqueToDelete] = useState([])
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [permisos, setPermisos] = useState([]);


    useEffect(() => {
        let perms = JSON.parse(localStorage.getItem("permisos"));

        setPermisos(perms);

        if (perms.length <= 1) {
            setFilterRemolques({
                ...filterRemolques,
                empresa: perms[0]
            })
        }

    }, [])


    const notifySuccess = (message) => {
        toast.success(message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    };

    const notifyWarning = (message) => {
        toast.warning(message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }

    const notifyError = (message) => {
        toast.warning(message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    };

    const notifyUpdate = () => {
        toast.success("¡Datos actualizados!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    };
    // //DELETE driver
    const confirmDeleteRemolque = (rowData) => {
        setRemolqueToDelete(rowData);
        setIsDeleteDialogOpen(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    rounded
                    className="pi pi-trash btn__pi"
                    outlined
                    severity="danger"
                    onClick={() => confirmDeleteRemolque(rowData)}
                    style={{ color: "red" }}
                />
            </React.Fragment>
        );
    };
    const bodyEditRemolque = (data) => {
        return (
            <Button
                className="pi pi-pencil btn__pi"
                onClick={() => { setIsEditDialogOpen(true); setRemolqueToEdit(data) }}
            />
        )
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };



    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        style={{ marginLeft: "30px" }}
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        placeholder="Buscar"
                    />
                </span>
            </div>
        );
    };

    const handleInputChange = (e) => {
        setRemolques({
            ...remolques,
            [e.target.name]: e.target.value
        })
        console.log("Remolques", remolques);
    }

    const handleChangeFilter = (e) => {

        setFilterRemolques({
            ...filterRemolques,
            [e.target.name]: e.target.value
        });
    }
    const handleSearchRemolques = async (e) => {
        e.preventDefault();
        setIsLoadingSearch(true);
        let queryString = "?";

        Object.entries(filterRemolques).forEach(([key, value], index) => {
            if (value !== "") {
                queryString += `${key}=${value}&`
            }
        });

        if (!queryString.includes("empresa")) {
            queryString += `empresa=${permisos}&`
        }

        console.log("QueryString",queryString);
        queryString = queryString.substring(0, queryString.length - 1);

        try {
            let response = await fetch(`${API_URL}/api/catalagos/remolques${queryString}`);
            let data = await response.json();
            console.log("data recibida", data);

            setIsLoadingSearch(false);
            if (data.status === "Ok") {
                setRemolquesData(data.remolques);
            } else {
                notifyError(data.message);
                setRemolquesData([]);
            }
        } catch (error) {
            setIsLoadingSearch(false);
            setRemolquesData([]);
            notifyWarning(error);
        }
    }

    const exportExcel = () => {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(remolquesData);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });

            saveAsExcelFile(excelBuffer, "facturas");
        });
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE =
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                let EXCEL_EXTENSION = ".xlsx";
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE,
                });

                module.default.saveAs(
                    data,
                    fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
                );
            }
        });
    };

    const handleEditRemolqueChange = (e) => {
        setRemolqueToEdit({
            ...remolqueToEdit,
            [e.target.name]: e.target.value
        });
    }

    const handleDeleteRemolque = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const response = await fetch(`${API_URL}/api/catalagos/remolques/${remolqueToDelete._id}`, {
                method: "DELETE",
            });
            let data = await response.json();
            setIsLoading(false);
            if (data.status === "Ok") {
                notifySuccess(data.message)
                handleSearchRemolques(e);
                setIsDeleteDialogOpen(false);
            } else {
                notifyError(data.message);
            }
        } catch (error) {
            setIsLoading(false);
            notifyError("No se pudo eliminar");
        }

    }

    const handleEditRemolque = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const response = await fetch(`${API_URL}/api/catalagos/remolques/${remolqueToEdit._id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(remolqueToEdit),
            });
            let data = await response.json();
            setIsLoading(false);
            if (data.status === "Ok") {
                notifySuccess(data.message)
                handleSearchRemolques(e);
                setIsEditDialogOpen(false);
            } else {
                notifyError(data.message);
            }
        } catch (error) {
            setIsLoading(false);
            notifyError("No se pudo realizar la actualización");
        }
    }

    const registerRemolque = async (e) => {
        e.preventDefault();

        if (remolques.empresa === "") {
            console.log("La empresa es requerida");
            notifyWarning("La empresa es requerida");
            return false;
        }

        if (remolques.subTipoRem.trim() === "") {
            console.log("La empresa es requerida");

            notifyWarning("El tipo de remolque es requerido");
            return false;
        }
        if (remolques.placaRemolque.trim() === "") {
            notifyWarning("La placa del remolque es requerido");
            return false;
        }

        setIsLoading(true);

        try {
            console.log("Remolques to send", remolques);
            let response = await fetch(`${API_URL}/api/catalagos/remolques`, {
                headers: {
                    "Content-Type": "application/json"
                },
                method: "POST",
                body: JSON.stringify(remolques)
            });
            let data = await response.json();
            setIsLoading(false);
            if (data.status === "Ok") {
                setRemolques(remolquesObj);
                notifySuccess(data.message);
            } else {
                notifyError(data.message);
            }
        } catch (error) {
            setIsLoading(false);
            notifyError(error);
        }
    }

    return (
        <div className="content-wrapper container__block" style={{ paddingBottom: "1.4rem" }}>
            <PageInfo heading="DATT - Tracking | Administrar Remolques" />

            <section className="content">
                <div className="container-fluid">
                    <Tabs.Root className="TabsRoot" defaultValue="tab1">
                        <Tabs.List className="TabsList" aria-label="Registra un remolque">
                            <Tabs.Trigger className="TabsTrigger" value="tab1">
                                Registrar Remolque
                            </Tabs.Trigger>
                            <Tabs.Trigger className="TabsTrigger" value="tab2">
                                Ver Remolques
                            </Tabs.Trigger>
                        </Tabs.List>

                        <Tabs.Content
                            className="TabsContent"
                            value="tab1"
                            style={{
                                display: "flex", justifyContent: "center"
                            }}
                        >
                            <div
                                className="container__width_70"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "20px",
                                }}
                            >
                                <div style={{ flex: 1 }}>
                                    <Form.Root
                                        className="FormRoot"
                                        onSubmit={registerRemolque}
                                        id="DriverForm"
                                    >
                                        <div className="data__group">
                                            <h4 style={{ marginBottom: "2rem" }}>Datos del Remolque</h4>
                                            <div className="data__group_form">
                                                <div className="form__group_form-select">
                                                    <div className="form__group form__group_selectField">

                                                        <label className='FormLabel'>Empresa</label>
                                                        <select
                                                            className='SelectTrigger'
                                                            name="empresa"
                                                            value={remolques.empresa}
                                                            style={{ width: "100%" }}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option value="">Selecciona una empresa</option>
                                                            {
                                                                permisos.map((permiso, index) => (
                                                                    <option key={index} value={permiso}>{permiso}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form__group_form-select">

                                                    <div className="form__group form__group_selectField">
                                                        <label className='FormLabel'>Tipo de Remolque</label>
                                                        <select
                                                            className="SelectTrigger"
                                                            value={remolques.subTipoRem}
                                                            name="subTipoRem"
                                                            onChange={handleInputChange}
                                                            style={{ width: "100%" }}
                                                        >
                                                            <option value="">
                                                                Escoge una opción
                                                            </option>
                                                            <option value="CTR001">CTR001 Caballete</option>
                                                            <option value="CTR002">CTR002 Caja</option>
                                                            <option value="CTR003">
                                                                CTR003 Caja Abierta
                                                            </option>
                                                            <option value="CTR004">
                                                                CTR004 Caja Cerrada
                                                            </option>
                                                            <option value="CTR005">
                                                                CTR005 Caja De Recolección Con Cargador Frontal
                                                            </option>
                                                            <option value="CTR006">
                                                                CTR006 Caja Refrigerada
                                                            </option>
                                                            <option value="CTR007">CTR007 Caja Seca</option>
                                                            <option value="CTR008">
                                                                CTR008 Caja Transferencia
                                                            </option>
                                                            <option value="CTR009">
                                                                CTR009 Cama Baja o Cuello Ganso
                                                            </option>
                                                            <option value="CTR010">
                                                                CTR010 Chasis Portacontenedor
                                                            </option>
                                                            <option value="CTR011">
                                                                CTR011 Convencional De Chasis
                                                            </option>
                                                            <option value="CTR012">
                                                                CTR012 Equipo Especial
                                                            </option>
                                                            <option value="CTR013">CTR013 Estacas</option>
                                                            <option value="CTR014">
                                                                CTR014 Góndola Madrina
                                                            </option>
                                                            <option value="CTR015">
                                                                CTR015 Grúa Industrial
                                                            </option>
                                                            <option value="CTR016">CTR016 Grúa</option>
                                                            <option value="CTR017">CTR017 Integral</option>
                                                            <option value="CTR018">CTR018 Jaula</option>
                                                            <option value="CTR019">
                                                                CTR019 Media Redila
                                                            </option>
                                                            <option value="CTR020">
                                                                CTR020 Pallet o Celdillas
                                                            </option>
                                                            <option value="CTR021">CTR021 Plataforma</option>
                                                            <option value="CTR022">
                                                                CTR022 Plataforma Con Grúa
                                                            </option>
                                                            <option value="CTR023">
                                                                CTR023 Plataforma Encortinada
                                                            </option>
                                                            <option value="CTR024">CTR024 Redilas</option>
                                                            <option value="CTR025">
                                                                CTR025 Refrigerador
                                                            </option>
                                                            <option value="CTR026">CTR026 Revolvedora</option>
                                                            <option value="CTR027">CTR027 Semicaja</option>
                                                            <option value="CTR028">CTR028 Tanque</option>
                                                            <option value="CTR029">CTR029 Tolva</option>
                                                            <option value="CTR031">CTR031 Volteo</option>
                                                            <option value="CTR032">
                                                                CTR032 Volteo Desmontable
                                                            </option>

                                                        </select>
                                                    </div>
                                                    <div className="form__group form__group_selectField">

                                                        <label className='FormLabel'>Empresa</label>
                                                        <input
                                                            className="Input boxSizing__bb"
                                                            maxLength={7}
                                                            minLength={5}
                                                            name="placaRemolque"
                                                            value={remolques.placaRemolque}
                                                            type="text"
                                                            placeholder="Placa del Remolque"
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="btn__container">
                                                    {/*Register driver btn in BD*/}

                                                    <Form.Submit asChild>
                                                        <button
                                                            type="submit"
                                                            disabled={isLoading}
                                                            className="btn__login"
                                                            style={{ marginTop: 10, width: "230px", display: "flex", justifyContent: "center", alignItems: "center" }}
                                                        // onClick={registerTransport}
                                                        >
                                                            {
                                                                isLoading ? (
                                                                    <SyncLoader
                                                                        color="white"

                                                                        loading={isLoading}
                                                                        size={20}
                                                                        aria-label="Loading Spinner"
                                                                        data-testid="loader"
                                                                        speedMultiplier={.7}
                                                                    />

                                                                ) : "Registrar Remolque"
                                                            }

                                                        </button>
                                                    </Form.Submit>
                                                </div>
                                            </div>
                                        </div>

                                    </Form.Root>
                                </div>
                            </div>
                        </Tabs.Content>

                        <Tabs.Content
                            className="TabsContent"
                            value="tab2">
                            {/* Transports registered in a table */}
                            <h3>Remolques registrados</h3>
                            <section className=''>
                                <div className='cartaPorte__group'>

                                    {
                                        permisos && permisos.length > 1 ?
                                            (
                                                <div className="cartaPorte__group_elements">
                                                    <label className='FormLabel'>Empresa</label>
                                                    <select
                                                        className='SelectTrigger'
                                                        name="empresa"
                                                        value={filterRemolques.empresa}
                                                        onChange={handleChangeFilter}
                                                    >
                                                        <option value="">Selecciona una empresa</option>
                                                        {
                                                            permisos.map((permiso, index) => (
                                                                <option key={index} value={permiso}>{permiso}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>

                                            ) : ""

                                    }

                                    <div className="cartaPorte__group_elements">
                                        <label className='FormLabel'>Placa del Remolque</label>
                                        <input
                                            className='InputForm'
                                            name="placaRemolque"
                                            value={filterRemolques.placaRemolque}
                                            onChange={handleChangeFilter}
                                        >

                                        </input>
                                    </div>
                                    <div className="cartaPorte__group_elements">
                                        <label className='FormLabel'>Tipo de Remolque</label>
                                        <select
                                            className='SelectTrigger'
                                            name="subTipoRem"
                                            value={filterRemolques.subTipoRem}
                                            onChange={handleChangeFilter}
                                        >
                                            <option value="">Selecciona un Tipo de Remolque</option>
                                            <option value="CTR001">CTR001 Caballete</option>
                                            <option value="CTR002">CTR002 Caja</option>
                                            <option value="CTR003">
                                                CTR003 Caja Abierta
                                            </option>
                                            <option value="CTR004">
                                                CTR004 Caja Cerrada
                                            </option>
                                            <option value="CTR005">
                                                CTR005 Caja De Recolección Con Cargador Frontal
                                            </option>
                                            <option value="CTR006">
                                                CTR006 Caja Refrigerada
                                            </option>
                                            <option value="CTR007">CTR007 Caja Seca</option>
                                            <option value="CTR008">
                                                CTR008 Caja Transferencia
                                            </option>
                                            <option value="CTR009">
                                                CTR009 Cama Baja o Cuello Ganso
                                            </option>
                                            <option value="CTR010">
                                                CTR010 Chasis Portacontenedor
                                            </option>
                                            <option value="CTR011">
                                                CTR011 Convencional De Chasis
                                            </option>
                                            <option value="CTR012">
                                                CTR012 Equipo Especial
                                            </option>
                                            <option value="CTR013">CTR013 Estacas</option>
                                            <option value="CTR014">
                                                CTR014 Góndola Madrina
                                            </option>
                                            <option value="CTR015">
                                                CTR015 Grúa Industrial
                                            </option>
                                            <option value="CTR016">CTR016 Grúa</option>
                                            <option value="CTR017">CTR017 Integral</option>
                                            <option value="CTR018">CTR018 Jaula</option>
                                            <option value="CTR019">
                                                CTR019 Media Redila
                                            </option>
                                            <option value="CTR020">
                                                CTR020 Pallet o Celdillas
                                            </option>
                                            <option value="CTR021">CTR021 Plataforma</option>
                                            <option value="CTR022">
                                                CTR022 Plataforma Con Grúa
                                            </option>
                                            <option value="CTR023">
                                                CTR023 Plataforma Encortinada
                                            </option>
                                            <option value="CTR024">CTR024 Redilas</option>
                                            <option value="CTR025">
                                                CTR025 Refrigerador
                                            </option>
                                            <option value="CTR026">CTR026 Revolvedora</option>
                                            <option value="CTR027">CTR027 Semicaja</option>
                                            <option value="CTR028">CTR028 Tanque</option>
                                            <option value="CTR029">CTR029 Tolva</option>
                                            <option value="CTR031">CTR031 Volteo</option>
                                            <option value="CTR032">
                                                CTR032 Volteo Desmontable
                                            </option>

                                        </select>
                                    </div>



                                    <div className="cartaPorte__group_elements" style={{ justifyContent: "flex-end" }} >
                                        <div className='btn__container_search'>
                                            <button
                                                type="submit"
                                                disabled={isLoadingSearch}
                                                className="btn__login"
                                                style={{ marginTop: 10, width: "240px", display: "flex", justifyContent: "center", alignItems: "center" }}
                                                onClick={handleSearchRemolques}
                                            >
                                                {
                                                    isLoadingSearch ? (
                                                        <SyncLoader
                                                            color="white"
                                                            loading={isLoadingSearch}
                                                            size={20}
                                                            aria-label="Loading Spinner"
                                                            data-testid="loader"
                                                            speedMultiplier={.7}
                                                        />

                                                    ) : "Buscar Remolques"
                                                    // <FaSearch />
                                                }

                                            </button>
                                        </div>

                                    </div>


                                </div>

                            </section>
                            <header
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    gap: "1rem",
                                }}
                            >
                                <Button
                                    type="button"
                                    icon="pi pi-file-excel"
                                    severity="success"
                                    rounded
                                    onClick={exportExcel}
                                    data-pr-tooltip="XLS"
                                    style={{ fontSize: "1.5rem", color: "green" }}
                                />

                            </header>
                            <div className="card">
                                <DataTable
                                    value={remolquesData}
                                    paginator
                                    header={renderHeader}
                                    ref={dt}
                                    filters={filters}
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    tableStyle={{ minWidth: "50rem" }}
                                    editMode="row"
                                    dataKey="_id"
                                // onRowEditComplete={onRowEditComplete}
                                >
                                    <Column field="_id" header="ID" hidden />
                                    <Column
                                        field="empresa"
                                        header="Empresa"
                                        sortable
                                    />
                                    <Column
                                        field="placaRemolque"
                                        header="Placa del Remolque"
                                        sortable
                                    />
                                    <Column
                                        field="subTipoRem"
                                        header="Tipo de Remolque"
                                        sortable
                                    />
                                    {/*Edit btn */}
                                    <Column

                                        headerStyle={{ width: "10%", minWidth: "8rem" }}
                                        bodyStyle={{ textAlign: "center" }}
                                        body={bodyEditRemolque}
                                    ></Column>
                                    {/*Delete btn */}
                                    <Column
                                        body={actionBodyTemplate}
                                        exportable={false}
                                        style={{ minWidth: "12rem" }}
                                    ></Column>
                                </DataTable>
                                <Dialog.Root
                                    open={isEditDialogOpen}
                                    onOpenChange={() => { setIsEditDialogOpen(false) }}
                                >
                                    <Dialog.Portal>
                                        <Dialog.Overlay className="DialogOverlay" />
                                        <Dialog.Content className="DialogContentCP dialogEdit" style={{ backgroundColor: "white" }}>
                                            <Dialog.Title className="DialogTitle">
                                                Editar Remolque
                                            </Dialog.Title>
                                            <form onSubmit={handleEditRemolque}>
                                                <div className="dialogEditGroup_elements">
                                                    <label className="FormLabel" htmlFor="name">
                                                        Empresa
                                                    </label>
                                                    <select
                                                        className="SelectTrigger"
                                                        name="empresa"
                                                        onChange={handleEditRemolqueChange}
                                                        required
                                                        value={remolqueToEdit?.empresa}
                                                    >
                                                        <option value="">Selecciona una empresa</option>
                                                        {
                                                            permisos.map((permiso, index) => (
                                                                <option key={index} value={permiso}>{permiso}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="dialogEditGroup">
                                                    <div className="dialogEditGroup_elements">
                                                        <label className="FormLabel" htmlFor="name">
                                                            Placa del Remolque
                                                        </label>
                                                        <input
                                                            className="InputForm"
                                                            type="text"
                                                            minLength={5}
                                                            maxLength={7}
                                                            name="placaRemolque"
                                                            onChange={handleEditRemolqueChange}
                                                            placeholder="Placa del Remolque"
                                                            required
                                                            value={remolqueToEdit?.placaRemolque}
                                                        ></input>
                                                    </div>

                                                    <div className="dialogEditGroup_elements">
                                                        <label className="FormLabel" htmlFor="name">
                                                            Configuración Vehicular
                                                        </label>
                                                        <select
                                                            className="SelectTrigger"
                                                            required
                                                            value={remolqueToEdit?.subTipoRem}
                                                            name="subTipoRem"
                                                            onChange={handleEditRemolqueChange}
                                                            style={{ width: "100%", padding: ".5rem", margin: 0, height: "100%" }}
                                                        >
                                                            <option value="">
                                                                Escoge una opción
                                                            </option>
                                                            <option value="CTR001">CTR001 Caballete</option>
                                                            <option value="CTR002">CTR002 Caja</option>
                                                            <option value="CTR003">
                                                                CTR003 Caja Abierta
                                                            </option>
                                                            <option value="CTR004">
                                                                CTR004 Caja Cerrada
                                                            </option>
                                                            <option value="CTR005">
                                                                CTR005 Caja De Recolección Con Cargador Frontal
                                                            </option>
                                                            <option value="CTR006">
                                                                CTR006 Caja Refrigerada
                                                            </option>
                                                            <option value="CTR007">CTR007 Caja Seca</option>
                                                            <option value="CTR008">
                                                                CTR008 Caja Transferencia
                                                            </option>
                                                            <option value="CTR009">
                                                                CTR009 Cama Baja o Cuello Ganso
                                                            </option>
                                                            <option value="CTR010">
                                                                CTR010 Chasis Portacontenedor
                                                            </option>
                                                            <option value="CTR011">
                                                                CTR011 Convencional De Chasis
                                                            </option>
                                                            <option value="CTR012">
                                                                CTR012 Equipo Especial
                                                            </option>
                                                            <option value="CTR013">CTR013 Estacas</option>
                                                            <option value="CTR014">
                                                                CTR014 Góndola Madrina
                                                            </option>
                                                            <option value="CTR015">
                                                                CTR015 Grúa Industrial
                                                            </option>
                                                            <option value="CTR016">CTR016 Grúa</option>
                                                            <option value="CTR017">CTR017 Integral</option>
                                                            <option value="CTR018">CTR018 Jaula</option>
                                                            <option value="CTR019">
                                                                CTR019 Media Redila
                                                            </option>
                                                            <option value="CTR020">
                                                                CTR020 Pallet o Celdillas
                                                            </option>
                                                            <option value="CTR021">CTR021 Plataforma</option>
                                                            <option value="CTR022">
                                                                CTR022 Plataforma Con Grúa
                                                            </option>
                                                            <option value="CTR023">
                                                                CTR023 Plataforma Encortinada
                                                            </option>
                                                            <option value="CTR024">CTR024 Redilas</option>
                                                            <option value="CTR025">
                                                                CTR025 Refrigerador
                                                            </option>
                                                            <option value="CTR026">CTR026 Revolvedora</option>
                                                            <option value="CTR027">CTR027 Semicaja</option>
                                                            <option value="CTR028">CTR028 Tanque</option>
                                                            <option value="CTR029">CTR029 Tolva</option>
                                                            <option value="CTR031">CTR031 Volteo</option>
                                                            <option value="CTR032">
                                                                CTR032 Volteo Desmontable
                                                            </option>
                                                        </select>
                                                    </div>

                                                </div>
                                                <div className="btn__container" >
                                                    <button className="btn__login" style={{ marginTop: "2rem", width: "240px", display: "flex", justifyContent: "center", alignItems: "center" }} type="submit" disabled={isLoading}>
                                                        {
                                                            isLoading ? (
                                                                <SyncLoader
                                                                    color="white"
                                                                    loading={isLoading}
                                                                    size={20}
                                                                    aria-label="Loading Spinner"
                                                                    data-testid="loader"
                                                                    speedMultiplier={.7}
                                                                />

                                                            ) : "Actualizar Remolque"

                                                        }

                                                    </button>
                                                </div>

                                            </form>
                                            <Dialog.Close asChild></Dialog.Close>
                                            <Dialog.Close asChild>
                                                <button className="IconButton" aria-label="Close">
                                                    <Cross2Icon />
                                                </button>
                                            </Dialog.Close>
                                        </Dialog.Content>
                                    </Dialog.Portal>
                                </Dialog.Root>
                                <Dialog.Root
                                    open={isDeleteDialogOpen}
                                    onOpenChange={() => setIsDeleteDialogOpen(false)}
                                >
                                    <Dialog.Portal>
                                        <Dialog.Overlay className="DialogOverlay" />
                                        <Dialog.Content
                                            className="DialogContent"
                                            style={{
                                                height: "290px",
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                width: "400px",
                                                backgroundColor: "white"
                                            }}>
                                            <Dialog.Title className="DialogTitle" style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",

                                            }}>
                                                <CrossCircledIcon
                                                    style={{
                                                        width: "78px",
                                                        height: "90px",
                                                        color: "red",
                                                        marginBottom: "10px"
                                                    }}
                                                />
                                                Eliminar Remolque
                                            </Dialog.Title>
                                            <Dialog.Description className="DialogDescription">
                                                ¿Estás seguro de que deseas eliminar el remolque con placa: <b>{remolqueToDelete?.placaRemolque}</b>?
                                            </Dialog.Description>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    gap: "20px",
                                                    marginTop: "14px"
                                                }}
                                            >
                                                <Dialog.Close asChild>
                                                    <Button
                                                        className="EliminarButton"
                                                        onClick={handleDeleteRemolque}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </Dialog.Close>
                                                <Dialog.Close asChild>
                                                    <Button
                                                        aria-label="Close"
                                                        className="CancelarButton"
                                                    >
                                                        Cancelar
                                                    </Button>
                                                </Dialog.Close>
                                            </div>
                                        </Dialog.Content>
                                    </Dialog.Portal>
                                </Dialog.Root>

                            </div>
                        </Tabs.Content>
                    </Tabs.Root>
                </div>
            </section>
        </div>
    )
}
