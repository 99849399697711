import React, { Component, useState } from "react";
import * as Form from "@radix-ui/react-form";
import { loadGoogleMapsScript } from "./GoogleMapsAPI/AutocompleteLoad";
import { FaMinus } from "react-icons/fa";

class AutocompleteJS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "",
      waypointValues: [],
      waypointTotal: 0
    };
  }
  componentDidMount() {
    loadGoogleMapsScript(this.handleScriptLoad);
  }

  handleScriptLoadCallback = () => {
    this.handleScriptLoad();
  };

  handleScriptLoad = () => {
    const google = window.google;
    if (google) {
      const autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("inputOrigin")
      );

      const autocompleteDest = new google.maps.places.Autocomplete(
        document.getElementById("inputDest")
      );
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const originValue = place.formatted_address;
        const originAddress = place.address_components;
        this.setState({ originValue });
        this.props.onValueChange("origin", originValue);
        this.props.onValueChange("originAddress",originAddress);
      });
      autocompleteDest.addListener("place_changed", () => {
        const place = autocompleteDest.getPlace();
        const destinationValue = place.formatted_address;
        const destinationAddress = place.address_components;

        this.setState({ destinationValue });
        this.props.onValueChange("destination", destinationValue);
        this.props.onValueChange("destinationAddress",destinationAddress);
      });
    } else {
      console.error("La API de Google Maps no se ha cargado correctamente");
    }
  };

  handleRemoveWaypoint = (e) => {
    let parentElement = e.target.parentElement;

    let firstChildElement =parentElement.firstChild;

    let firstChildValue = firstChildElement.value;

    this.props.onDeleteValue("waypoint",firstChildValue);

    e.target.parentElement.remove();
    this.setState({...this.state,waypointTotal:this.state.waypointTotal - 1});
  }

  handleAddWaypoint = () => {

    const newDiv = document.createElement("div");
    const newInput = document.createElement("input");
    newInput.className = "Input";
    newInput.type = "text";
    newInput.required = false;
    newInput.placeholder = "Punto intermedio";
    newInput.style.width = "300px";
    newInput.style.marginTop = "1rem";
    newInput.style.marginBottom = "1rem";
    newInput.style.marginRight = "1rem";
    newInput.id = `inputWaypoint-${this.state.waypointTotal}`;
    const minusButton = document.createElement("i");
    // <i className="fa fa-truck" style={{ fontSize: "20px" }} />
    minusButton.classList.add("fas", "fa-minus","deleteWaypoint");
    minusButton.id=`inputWaypoint-${this.state.waypointTotal}`;
    
    minusButton.addEventListener('click',this.handleRemoveWaypoint);


    this.setState({...this.state,waypointTotal:this.state.waypointTotal + 1});

    newDiv.appendChild(newInput);
    newDiv.appendChild(minusButton);

    document.getElementById("waypointsContainer").appendChild(newDiv);
    // document.getElementById("waypointsContainer").appendChild(minusButton);

    // <FaMinus onClick={(e) => handleDeleteMercancia(index)/>
    const newAutocomplete = new window.google.maps.places.Autocomplete(
      newInput
    );
    newAutocomplete.addListener("place_changed", () => {
      const place = newAutocomplete.getPlace();
      const waypointValue = place.formatted_address;
      this.setState((prevState) => ({
        waypointValues: [...prevState.waypointValues, waypointValue],
      }));
      this.props.onValueChange("waypoint", waypointValue);
    });
  };
  render() {
    return (
      <div>

        <input
          className="Input boxSizing__bb"
          type="text"
          required
          placeholder="Origen"
          style={{ width: "100%" }}
          id="inputOrigin"
        />

        <div className="intermediate__location" style={{ margin: "1.3rem 0" }}>
          <Form.Label className="FormLabel" style={{ margin: 0, marginBottom: ".5rem" }}>
            Ingresa una ubicación intermedia
          </Form.Label>
          <div id="waypointsContainer" ></div>

          <button className="Button btn__form" onClick={this.handleAddWaypoint}>
            Agregar Punto Intermedio
          </button>

        </div>


        <Form.Label className="FormLabel" style={{ margin: 0, marginTop: "1.3rem", marginBottom: ".5rem" }}>
          Ingresa la ubicación de destino*
        </Form.Label>
        <input
          className="Input boxSizing__bb"
          type="text"
          required
          placeholder="Destino"
          style={{ width: "100%" }}
          id="inputDest"
        />
      </div>
    );
  }
}

export default AutocompleteJS;
