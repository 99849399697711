import { Loader } from "@googlemaps/js-api-loader";

export function loadGoogleMapsScript(callback) {
  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    version: "weekly",
    libraries: ["places"],
  });

  loader
    .load()
    .then(() => {
      callback();
    })
    .catch((error) => {
      console.error("Error loading Google Maps:", error);
    });
}
