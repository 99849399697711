import "../../styles/alert.css";
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { API_URL } from "../../constants";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import * as Dialog from "@radix-ui/react-dialog";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import { PageInfo } from "../PageInfo";

function RutasAsignadas({ onAsignarRutasClick }) {
  const [route, setRoute] = useState("");
  const [isDialogOpen3, setIsDialogOpen3] = useState(false);

  const cols = [
    { field: "username", header: "Usuario" },
    { field: "origin", header: "Origen" },
    { field: "intermedio", header: "Puntos Intermedios" },
    { field: "destination", header: "Destino" },
    { field: "distance", header: "Distancia" },
    { field: "duration", header: "Duración" },
    { field: "customerFlete", header: "Cliente Flete" },
    { field: "customerFactura", header: "Cliente Factura" },
    { field: "vehicle", header: "Vehículo" },
    { field: "diesel", header: "Gasto Diesel" },
    { field: "gastos", header: "Gastos Viaje" },
    { field: "precioVenta", header: "Precio de venta" },
    { field: "assigedDate", header: "Fecha asignado" },
    { field: "fechaInicio", header: "Fecha de inicio" },
    { field: "assigedDate", header: "Fecha asignado" },
    { field: "fechaFinal", header: "Fecha final" },
    { field: "status", header: "Estatus" },
  ];
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    username: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    origin: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    destination: { value: null, matchMode: FilterMatchMode.IN },
    distance: { value: null, matchMode: FilterMatchMode.EQUALS },
    duration: { value: null, matchMode: FilterMatchMode.EQUALS },
    customerFlete: { value: null, matchMode: FilterMatchMode.EQUALS },
    customerFactura: { value: null, matchMode: FilterMatchMode.EQUALS },
    vehicle: { value: null, matchMode: FilterMatchMode.EQUALS },
    diesel: { value: null, matchMode: FilterMatchMode.EQUALS },
    assignedDate: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const dt = useRef(null);
  const [viajeToSave, setViajeToSave] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [finalizacion, setFinalizacion] = useState("");
  const [usernamesData, setUsernamesData] = useState([]);

  useEffect(() => {
    fetchRoutesData();
    fetchDriverData();
  }, []);

  //FETCH routes
  const fetchRoutesData = async () => {
    const response = await fetch(`${API_URL}/api/routes`);
    const data = await response.json();
    setRoute(data);
  };

  //FETCH drivers
  const fetchDriverData = async () => {
    try {
      const response = await fetch(`${API_URL}/api/routes`);
      const routesData = await response.json();

      // FETCH driver usernames
      const usernamesResponse = await fetch(`${API_URL}/api/drivers-username`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const usernamesData = await usernamesResponse.json();
      const usernames = usernamesData.map((item) => item.nombreAlternativo);
      setUsernamesData(usernamesData);
      const filteredUsernames = usernames.filter((username) => {
        return (
          !routesData.some((route) => route.usuario === username) ||
          !routesData.some(
            (route) =>
              route.usuario === username &&
              [
                "Pendiente Chofer",
                "Viaje en curso",
                "Pendiente Gastos Diesel",
                "Pendiente Gastos Viaje",
                "Pendiente Precio Venta",
                "Pendiente Vehículo",
                "Pendiente Referencia",
              ].includes(route.estatus)
          )
        );
      });

      setUsername(filteredUsernames);
    } catch (error) {
      // Handle any fetch errors here
      console.error(error);
    }
  };

  function getStatusColor(estatus) {
    switch (estatus) {
      case "Pendiente Chofer":
        return "orange";
      case "Pendiente Gastos Diesel":
        return "orange";
      case "Pendiente Gastos Viaje":
        return "orange";
      case "Pendiente Precio Venta":
        return "orange";
      case "Pendiente Vehículo":
        return "orange";
      case "Pendiente Referencia":
        return "orange";
      case "Viaje en curso":
        return "green";

      case "Pendiente Facturación":
        return "blue";
      case "Pendiente Pago":
        return "blue";
      case "Finalizado":
        return "green";
      default:
        return "gray";
    }
  }

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const headers = [
        "_id",
        "usuario",
        "origen",
        "intermedio",
        "destino",
        "duracion",
        "distancia",
        "distanciaRecorrida",
        "movimiento",
        "contenedor",
        "referencia",
        "clienteFlete",
        "clienteFactura",
        "documentos",
        "vehiculo",
        "estatus",
        "fechaInicio",
        "asignadoPor",
        "fechaAsignado",
        "fechaFinal",
      ];

      const trimmedRoute = route.map((row) => {
        const trimmedRow = {};
        headers.forEach((header) => {
          trimmedRow[header] = row[header];
        });
        return trimmedRow;
      });

      const worksheet = xlsx.utils.json_to_sheet(trimmedRoute);

      const headerStyle = {
        fill: {
          fgColor: { rgb: "FF0000" },
        },
        font: {
          color: { rgb: "FFFFFF" },
          bold: true,
        },
      };

      // Obtener rango de celdas de encabezado
      const headerRange = xlsx.utils.decode_range(worksheet["!ref"]);
      for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
        const headerCellRef = xlsx.utils.encode_cell({
          r: headerRange.s.r,
          c: col,
        });
        worksheet[headerCellRef].s = headerStyle;
      }

      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "viajes");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            style={{ marginLeft: "30px" }}
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar"
          />
        </span>
      </div>
    );
  };

  const showPdf = (index) => {
    const documento = viajeToSave.documentos[index];
    window.open(`${API_URL}${documento.path}`, "_blank");
  };

  const confirmDeleteDriver = (rowData) => {
    setViajeToSave(rowData);
    setIsDialogOpen(true);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          rounded
          outlined
          severity="primary"
          placeholder="Guardar"
          className="ButtonPDF"
          onClick={() => confirmDeleteDriver(rowData)}
          style={{ fontSize: "1.5rem", color: "black" }}
        />
      </React.Fragment>
    );
  };

  const notifyUpdate = () => {
    toast.success("¡Datos actualizados!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    fetchDriverData();
  };

  const notifyError = () => {
    toast.error("¡No se permiten cambios, viaje ya finalizado!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  //UPDATE route in BD
  async function updateRouteInDatabase(updatedRoute) {
    const selectedUserData = usernamesData.find(
      (user) => user.username === updatedRoute.usuario
    );
    //console.log(updatedRoute);
    const duration = updatedRoute.duracion;
    const diesel = updatedRoute.diesel;
    const tollsCost = updatedRoute.casetas;
    var nominaDia = 0;
    var camionDia = 0;
    var cajaDia = 0;
    if (selectedUserData) {
      nominaDia = selectedUserData.nominaDia || 0;
      camionDia = selectedUserData.camionDia || 0;
      cajaDia = selectedUserData.cajaDia || 0;
    }

    var viaticos = 0;
    if (duration <= 16) {
      viaticos = 700;
    } else {
      viaticos = 1400;
    }

    // Calculo nomina por dia y por mes
    const nominaHora = parseFloat((nominaDia / 24).toFixed(2));
    // Calculo camion por dia y mes
    const camionHora = parseFloat((camionDia / 24).toFixed(2));
    // Calculo caja por dia y mes
    const cajaHora = parseFloat((cajaDia / 24).toFixed(2));

    // Calculo nomina de viaje:
    const nominaViaje = parseFloat((nominaHora * duration).toFixed(2));
    // Calculo costo de unidad (camion) por viaje:
    const costoCamion = parseFloat((camionHora * duration).toFixed(2));
    // Calculo costo de caja por viaje:
    const costoCaja = parseFloat((cajaHora * duration).toFixed(2));
    // Calculo costo operaciones
    const operaciones = parseFloat((duration * 22.52).toFixed(2));
    // Calculo pension
    const pension = parseFloat((duration * 10).toFixed(2));
    // Calculo provision
    const provision = parseFloat((duration * 83).toFixed(2));
    // Calculo administracion
    const administracion = parseFloat((duration * 16).toFixed(2));
    // Costo diesel
    const costoDiesel = parseFloat(diesel);
    // Costo casetas
    const costoCasetas = parseFloat(tollsCost);
    // Costo total
    const costoTotal = parseFloat(
      nominaViaje +
      costoCaja +
      costoCamion +
      operaciones +
      pension +
      provision +
      administracion +
      costoDiesel +
      costoCasetas
    ).toFixed(2);
    // Precio de venta
    const totalWithVAT = costoTotal * 1.2;
    const precioVenta = parseFloat(totalWithVAT.toFixed(2));

    // Utilidad
    const utilidad = parseFloat((precioVenta - costoTotal).toFixed(2));
    const updatedRouteWithVariables = {
      ...updatedRoute,
      nominaDia,
      camionDia,
      cajaDia,
      viaticos,
      nominaViaje,
      costoCamion,
      costoCaja,
      operaciones,
      pension,
      provision,
      administracion,
      costoTotal,
      precioVenta,
      utilidad,
    };

    try {
      const response = await fetch(`${API_URL}/api/update-route`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedRouteWithVariables),
      });

      const data = await response.json();
      if (data.status === "ok") {
        notifyUpdate();
        fetchRoutesData();
      }
      if (response.status === 401) {
        notifyError();
      }
      return data;
    } catch (error) {
      console.error("Error al actualizar el conductor:", error);
      return {
        status: "error",
        error: "Ocurrió un error al actualizar el conductor.",
      };
    }
  }

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        className="Input"
      />
    );
  };

  const statusEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={username.map((username) => ({
          label: username,
          value: username,
        }))}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder="Escoge un chofer"
        optionLabel="label"
      />
    );
  };

  //Update Assigned route:
  const onRowEditComplete = (e) => {
    let _route = [...route];
    let { newData, index } = e;

    _route[index] = newData;

    setRoute(_route);
    updateRouteInDatabase(newData);
  };

  const showPdfComprobante = (factura) => {
    const urlFile = factura;
    window.open(`${API_URL}/files/hoja-finalizacion/${urlFile}`);
  };

  const actionBodyTemplate3 = (rowData) => {
    if (rowData.estatus != "Viaje en curso") {
      return null;
    }
    return (
      <React.Fragment>
        <Button
          icon="pi pi-save"
          rounded
          outlined
          severity="primary"
          placeholder="Guardar"
          className="ButtonPDF"
          onClick={() => confirmDeleteDriver3(rowData)}
          style={{ fontSize: "1.5rem", color: "black" }}
        />
      </React.Fragment>
    );
  };

  const confirmDeleteDriver3 = (rowData) => {
    setViajeToSave(rowData);
    setIsDialogOpen3(true);
  };

  const handleSaveFinalizacion = (e) => {
    e.preventDefault();
    if (route) {
      handleFinalizacionUpload(route._id);
    }
    setIsDialogOpen3(false);
  };

  const handleFinalizacionUpload = async () => {
    const formData = new FormData();
    formData.append("finalizacion", finalizacion);
    formData.append("_id", viajeToSave._id);
    try {
      const response = await fetch(`${API_URL}/api/uploadfinalizacion`, {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        fetchRoutesData();
      } else {
        console.error("Failed to upload final doc");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="content-wrapper container__block">
      <PageInfo heading={"DATT - Tracking | Asignar Viajes"} />
      <section className="content">
        <div className="container-fluid">
          <h3>Viajes asignados</h3>
          <header
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Button
              type="button"
              icon="pi pi-file-excel"
              severity="success"
              rounded
              onClick={exportExcel}
              data-pr-tooltip="XLS"
              style={{ fontSize: "1.5rem", color: "green" }}
            />
          </header>
          <div className="card">
            <DataTable
              value={route}
              paginator
              header={renderHeader}
              filters={filters}
              ref={dt}
              rows={10}
              rowsPerPageOptions={[5, 10, 25, 50]}
              tableStyle={{ minWidth: "50rem" }}
              editMode="row"
              dataKey="_id"
              onRowEditComplete={onRowEditComplete}
            >
              <Column field="_id" header="ID" hidden />
              <Column
                field="usuario"
                header="Usuario"
                sortable
                style={{ width: "25%" }}
                editor={(options) => statusEditor(options)}
                columnProps={{ username: username }}
              />
              <Column
                field="origen"
                header="Origen"
                sortable
                style={{ width: "25%" }}
              />
              <Column
                field="intermedio"
                header="Puntos Intermedios"
                sortable
                style={{ width: "25%" }}
              />
              <Column
                field="destino"
                header="Destino"
                sortable
                style={{ width: "25%" }}
              />
              <Column
                field="distancia"
                header="Distancia (km)"
                sortable
                style={{ width: "25%" }}
              />
              <Column
                field="referencia"
                header="Referencia"
                sortable
                style={{ width: "25%" }}
                editor={(options) => textEditor(options)}
              />
              <Column
                field="duracion"
                header="Duración (horas)"
                sortable
                style={{ width: "25%" }}
              />
              <Column
                field="clienteFactura"
                header="Cliente Factura"
                sortable
                style={{ width: "25%" }}
              />
              <Column
                field="clienteFlete"
                header="Cliente Flete"
                sortable
                style={{ width: "25%" }}
              />
              <Column
                field="vehiculo"
                header="Vehículo"
                sortable
                style={{ width: "25%" }}
                editor={(options) => textEditor(options)}
              />
              <Column
                field="fechaAsignado"
                header="Fecha asignado"
                sortable
                style={{ width: "25%" }}
                editor={(options) => textEditor(options)}
              />
              <Column
                field="fechaInicio"
                header="Fecha Inicio"
                sortable
                style={{ width: "25%" }}
                editor={(options) => textEditor(options)}
              />
              <Column
                header="Documentos de inicio"
                body={actionBodyTemplate}
                exportable={false}
                style={{ minWidth: "12rem" }}
              />
              <Column
                field="hojaFinalizacion"
                header="Hoja de finalización"
                body={(rowData) => (
                  <button
                    onClick={() => {
                      if (rowData.documentoFinalizado) {
                        showPdfComprobante(rowData.documentoFinalizado);
                      }
                    }}
                    className="ButtonPDF"
                    disabled={!rowData.documentoFinalizado}
                  >
                    {rowData.documentoFinalizado ? "Ver PDF" : "No hay archivo"}
                  </button>
                )}
              />

              <Column
                header="Subir hoja finalización"
                style={{ minWidth: "12rem", marginLeft: "5px" }}
                body={actionBodyTemplate3}
              />
              <Column
                field="estatus"
                header="Estatus"
                sortable
                style={{ width: "25%" }}
                body={(rowData) => (
                  <div
                    style={{
                      background: getStatusColor(rowData.estatus),
                      padding: "5px",
                      borderRadius: "5px",
                      color: "white",
                      boxShadow: "0 0 5px rgba(0, 0, 0, 0.8)",
                    }}
                  >
                    {rowData.estatus}
                  </div>
                )}
              />
              {/*Edit btn */}
              <Column
                rowEditor
                headerStyle={{ width: "10%", minWidth: "8rem" }}
                bodyStyle={{ textAlign: "center" }}
              ></Column>

              <Column exportable={false} style={{ minWidth: "12rem" }}></Column>
            </DataTable>
            <Dialog.Root
              open={isDialogOpen}
              onOpenChange={() => setIsDialogOpen(false)}
            >
              <Dialog.Portal>
                <Dialog.Overlay className="DialogOverlay" />
                <Dialog.Content className="DialogContentFactura">
                  <Dialog.Title className="DialogTitle">
                    Documentos
                  </Dialog.Title>
                  <Dialog.Description className="DialogDescription">
                    Abajo se muestran los documentos que se anexaron por
                    cliente.
                  </Dialog.Description>

                  {viajeToSave && (
                    <div>
                      <h3>Documentos de Viaje</h3>
                      {viajeToSave.documentos.map((documento, index) => (
                        <button
                          key={documento._id}
                          className="btnArchivos"
                          onClick={() => showPdf(index)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {documento.originalname}
                        </button>
                      ))}
                    </div>
                  )}

                  <Dialog.Close asChild>
                    <Button aria-label="Close" className="EliminarButton">
                      Cancelar
                    </Button>
                  </Dialog.Close>
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
            <Dialog.Root
              open={isDialogOpen3}
              onOpenChange={() => setIsDialogOpen3(false)}
            >
              <Dialog.Portal>
                <Dialog.Overlay className="DialogOverlay" />
                <Dialog.Content className="DialogContentFactura">
                  <Dialog.Title className="DialogTitle">
                    Subir hoja de finalización
                  </Dialog.Title>
                  <Dialog.Description className="DialogDescription">
                    Selecciona el archivo PDF de la hoja.
                  </Dialog.Description>

                  <form
                    id="formRoute"
                    encType="multipart/form-data"
                    onSubmit={(e) => handleSaveFinalizacion(e)}
                  >
                    <label className="FormLabel">Selecciona el archivo</label>
                    <input
                      className="Input"
                      type="file"
                      required
                      accept=".pdf"
                      name="finalizacion"
                      onChange={(e) => setFinalizacion(e.target.files[0])}
                    />
                    <div style={{ margin: "30px" }}>
                      <input
                        className="CancelarButton"
                        type="submit"
                        value="Subir archivo"
                      />
                      <Dialog.Close asChild>
                        <Button aria-label="Close" className="EliminarButton">
                          Cancelar
                        </Button>
                      </Dialog.Close>
                    </div>
                  </form>
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RutasAsignadas;
