import React, { useEffect, useState } from "react";

import { Card } from "primereact/card";
import { API_URL } from "../constants";
import { Badge } from "primereact/badge";

import { CardTravel } from "./CardTravel";

function Dashboard() {
  const [route, setRoute] = useState("");
  const [permisos,setPermisos] = useState([]);

  useEffect(() => {
    let perms = JSON.parse(localStorage.getItem("permisos"));
    setPermisos(perms);
    fetchRoutesData(perms);
  }, []);

  //FETCH routes
  const fetchRoutesData = async (empresa) => {

    console.log("empresa recibida",empresa);
    const response = await fetch(`${API_URL}/api/routes?empresa=${empresa}&limit=10`);

    const data = await response.json();
    console.log("data fetchroutes",data);

    let dataDrivers = await fetchDriversByCompany(empresa);
    data.routes.map((route)=>{
      let foundOperador = dataDrivers.find((drivers) => route.usuario === drivers._id)
      route.usuario = foundOperador.nombreAlternativo
    });

    setRoute(data.routes);
  };

  const fetchDriversByCompany = async (empresa) => {
    try {
      if (empresa === "") {
        empresa = permisos;
      }

      let response = await fetch(`${API_URL}/api/drivers?company=${empresa}`);
      let data = await response.json();
      if (data.status === "Ok") {
        return data.drivers;
      } else {
        // notifyWarning(data.message);
      }
    } catch (error) {
      console.error(error);
    }

  };


  const getBadgeSeverity = (estatus) => {
    switch (estatus) {
      case "Pendiente Chofer":
        return "warning";
      case "Pendiente Gastos Diesel":
        return "warning";
      case "Pendiente Gastos Viaje":
        return "warning";
      case "Pendiente Precio Venta":
        return "warning";
      case "Pendiente Vehículo":
        return "warning";
      case "Pendiente Referencia":
        return "warning";
      case "Pendiente Facturación":
        return "info";
      case "Pendiente Pago":
        return "info";
      case "Viaje en curso":
        return "success";
      default:
        return "info";
    }
  };

  return (
    <div>
      <div className="content-wrapper" style={{ padding: "2rem" }}>

        <section className="content" style={{padding:"20px"}}>
          <div className="card__container">
            {Array.isArray(route) &&
              route.map(
                (routeItem, index) => (
                  routeItem.estatus !== "Finalizado" && (
                    <CardTravel key={index} data={routeItem} />)
                )
              )}
          </div>
        </section>
      </div>
    </div>
  );
}
export default Dashboard;
