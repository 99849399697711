import React, { useState, useEffect, useRef } from "react";
import "@radix-ui/themes/styles.css";
import "../../styles/adminchof.css";
import "../../styles/select.css";
import "../../styles/tabs.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { IoCloseSharp } from "react-icons/io5";
import { IoDocuments } from "react-icons/io5";
import { Cross2Icon } from "@radix-ui/react-icons";
import jwtDecode from "jwt-decode";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../../index.css";
import { toast } from "react-toastify";
import { API_URL } from "../../constants";
import * as Dialog from "@radix-ui/react-dialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useNotification } from "../../pages/NotificationContext";
import { PageInfo } from "../PageInfo";
import { FaRegFileWord } from "react-icons/fa";

function RegistrarClientes() {
  const dt = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [name, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const cols = [
    { field: "nombre", header: "Nombre" },
    { field: "email", header: "Correo" },
    { field: "razonSocial", header: "Razón Social" },
    { field: "telefono", header: "Teléfono" },
    { field: "facturacion", header: "Facturación" },
    { field: "credito", header: "Crédito" },
    { field: "estatus", header: "Estatus" },
  ];
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    nombre: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    razonSocial: { value: null, matchMode: FilterMatchMode.IN },
    telefono: { value: null, matchMode: FilterMatchMode.EQUALS },
    facturacion: { value: null, matchMode: FilterMatchMode.EQUALS },
    credito: { value: null, matchMode: FilterMatchMode.EQUALS },
    estatus: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [clientes, setClientes] = useState("");
  const [phone, setPhone] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [facturacion, setFacturacion] = useState("");
  const [docs, setDocs] = useState([]);
  const [credito, setCredito] = useState("");
  const [clienteToSave, setClienteToSave] = useState(null);
  const [razon, setRazon] = useState("");
  const [loggedName, setloggedName] = useState("");
  const { sendNotification } = useNotification();
  const [isDialogRejectOpen, setIsDialogRejectOpen] = useState(false);

  useEffect(() => {
    fetchCustomersData();
    const token = localStorage.getItem("token");
    if (token) {
      const decodedUser = jwtDecode(token);
      setloggedName(decodedUser.name);
    }
  }, []);

  //register customer
  async function registerCustomer(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("razonSocial", razonSocial);
    formData.append("facturacion", facturacion);
    for (let i = 0; i < docs.length; i++) {
      formData.append("docs", docs[i]);
    }
    formData.append("credito", credito);
    try {
      const response = await fetch(`${API_URL}/api/register-customer`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.status === "ok") {
        notifySuccess();
        document.getElementById("formCustomer").reset();
      }
    } catch (error) { }
  }

  //FETCH customer
  const fetchCustomersData = async () => {
    const response = await fetch(`${API_URL}/api/customers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    setClientes(data);
  };

  //UPDATE customers in BD
  async function handleAprobar(customerId) {
    try {
      const response = await fetch(`${API_URL}/api/approve-customer`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ customerId }),
      });

      const data = await response.json();
      if (data.status === "ok") {
        fetchCustomersData();
      }
      const currentTime = new Date();
      const time = currentTime.toISOString();
      const user = `${loggedName} ha aceptado a un cliente.`;
      sendNotification({
        user: user,
        razonSocial: data.updatedCustomer.razonSocial,
        time: formatDateTime(time),
      });
      return data;
    } catch (error) {
      console.error(error);
      return {
        status: "error",
        error: "Ocurrió un error al actualizar el conductor.",
      };
    }
  }

  function formatDateTime(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const formattedDate = dateTime.toLocaleDateString();
    const formattedTime = dateTime.toLocaleTimeString();

    return `${formattedDate} ${formattedTime}`;
  }

  async function handleRechazar(customerId) {
    if (!razon) {
      notifyRazon();
      return;
    } else {
      try {
        const response = await fetch(`${API_URL}/api/decline-customer`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ customerId, razon }),
        });

        const data = await response.json();
        if (data.status === "ok") {
          fetchCustomersData();
          handleCloseDialog();
        }
        const currentTime = new Date();
        const time = currentTime.toISOString();
        const user = `${loggedName} ha rechazado a un cliente.`;
        sendNotification({
          user: user,
          razonSocial: data.updatedCustomer.razonSocial,
          time: formatDateTime(time),
        });
        return data;
      } catch (error) {
        console.error("Error al actualizar el conductor:", error);
        return {
          status: "error",
          error: "Ocurrió un error al actualizar el conductor.",
        };
      }
    }
  }

  const notifySuccess = () => {
    toast.info("¡El cliente está en revisión!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const notifyRazon = () => {
    toast.error("¡Falta una justificación!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, clientes);
        doc.save("clientes.pdf");
      });
    });
  };

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const clientesCopy = clientes.map((cliente) => ({ ...cliente }));

      clientesCopy.forEach((cliente) => {
        delete cliente[Object.keys(cliente)[6]];
      });
      const worksheet = xlsx.utils.json_to_sheet(clientes);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "clientes");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        className="Input"
      />
    );
  };

  const confirmDeleteDriver = (rowData) => {
    setClienteToSave(rowData);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSaveConfirmation = () => {
    if (clienteToSave) {
      handleFileUpload(clienteToSave._id);
    }
    setIsDialogOpen(false);
  };

  // Upload factura on route assigned:
  const handleFileUpload = async () => {
    const formData = new FormData();
    formData.append("_id", clienteToSave);
    try {
      const response = await fetch(`${API_URL}/api/uploadcliente`, {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        fetchCustomersData();
      } else {
        console.error("Failed to upload factura");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <IoDocuments
        className="documentosInicio"
        onClick={() => confirmDeleteDriver(rowData)}

      />
      // <React.Fragment>
      //   <Button
      //     rounded
      //     outlined
      //     severity="primary"
      //     placeholder="Guardar"
      //     className="pi pi-save btn__pi"
      //     style={{ fontSize: "1.5rem", color: "black" }}
      //   />
      // </React.Fragment>
    );
  };
  const getDocType = (docName) => {
    // let docType = docName.includes(".docx") ? "word" : "pdf";
    return docName.includes(".docx") ? "word" : "pdf";

  };

  function getStatusColor(estatus) {
    switch (estatus) {
      case "En revisión":
        return "blue";
      case "Aprobado":
        return "green";
      case "Rechazado":
        return "red";
      default:
        return "gray";
    }
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            style={{ marginLeft: "30px" }}
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar"
          />
        </span>
      </div>
    );
  };

  return (
    <div>
      <div className="content-wrapper container__block">
        <PageInfo heading={"DATT - Tracking | Solicitudes de Clientes"} />
        <section className="content">
          <div className="container-fluid">
            {/* Drivers registered in a table */}
            <h3>Clientes registrados</h3>
            <header
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Button
                type="button"
                icon="pi pi-file"
                rounded
                onClick={() => exportCSV(false)}
                data-pr-tooltip="CSV"
                style={{ fontSize: "1.5rem", color: "blue" }}
              />
              <Button
                type="button"
                icon="pi pi-file-excel"
                severity="success"
                rounded
                onClick={exportExcel}
                data-pr-tooltip="XLS"
                style={{ fontSize: "1.5rem", color: "green" }}
              />
              <Button
                type="button"
                icon="pi pi-file-pdf"
                severity="warning"
                rounded
                onClick={exportPdf}
                data-pr-tooltip="PDF"
                style={{ fontSize: "1.5rem", color: "red" }}
              />
            </header>
            <div className="card">
              <DataTable
                value={clientes}
                header={renderHeader}
                filters={filters}
                paginator
                ref={dt}
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: "50rem" }}
                editMode="row"
                dataKey="_id"
              >
                <Column field="_id" header="ID" hidden />
                <Column
                  field="nombre"
                  header="Nombre de contacto (Facturas)"
                  editor={(options) => textEditor(options)}
                />
                <Column
                  field="nombrePagos"
                  header="Nombre de Operaciones"
                  editor={(options) => textEditor(options)}
                />
                <Column
                  field="razonSocial"
                  header="Razón Social"
                  sortable
                  editor={(options) => textEditor(options)}
                />
                <Column
                  field="email"
                  header="Correo"
                  sortable
                  editor={(options) => textEditor(options)}
                />
                <Column
                  field="emailAlternativo"
                  header="Correo Alternativo"
                  sortable
                  editor={(options) => textEditor(options)}
                />
                <Column
                  field="telefonoPagos"
                  header="Teléfono Facturas"
                  sortable
                  editor={(options) => textEditor(options)}
                />
                <Column
                  field="telefono"
                  header="Teléfono Operaciones"
                  sortable
                  editor={(options) => textEditor(options)}
                />
                <Column
                  field="codigoPostal"
                  header="Código Postal"
                  sortable
                  editor={(options) => textEditor(options)}
                />
                <Column
                  field="facturacion"
                  header="Facturación"
                  sortable
                  editor={(options) => textEditor(options)}
                />
                <Column
                  field="credito"
                  header="Crédito"
                  sortable
                  editor={(options) => textEditor(options)}
                />
                {/* <Column
                  field="razon"
                  header="Observaciones"
                  sortable
                  editor={(options) => textEditor(options)}
                /> */}


                <Column
                  header="Documentos"
                  body={actionBodyTemplate}
                  exportable={false}
                  style={{ minWidth: "12rem" }}
                />



                <Column
                  field="estatus"
                  header="Estátus"
                  sortable
                  editor={(options) => textEditor(options)}
                  body={(rowData) => (
                    <div
                      style={{
                        background: getStatusColor(rowData.estatus),
                        padding: "6px",
                        borderRadius: "5px",
                        color: "white",
                        // boxShadow: "0 0 5px rgba(0, 0, 0, 0.8)",
                      }}
                    >
                      {rowData.estatus}

                    </div>
                  )}
                />

                <Column
                  field="razon"
                  header="Observaciones"
                  sortable
                  body={(rowData) => {
                    if (rowData.estatus === "Rechazado") {
                      return (
                        <div
                          style={{
                            background: getStatusColor(rowData.estatus),
                            padding: "6px",
                            borderRadius: "5px",
                            color: "white",
                            // boxShadow: "0 0 5px rgba(0, 0, 0, 0.8)",
                          }}
                        >
                          {rowData.razon}
                        </div>
                      )
                    }

                  }}
                >
                </Column>
              </DataTable>
              <Dialog.Root
                open={isDialogOpen}
                onOpenChange={() => setIsDialogOpen(false)}
              >
                <Dialog.Portal>
                  <Dialog.Overlay className="DialogOverlay"
                  />
                  <Dialog.Content className="DialogContentFactura dialogEdit"
                    style={{ width: "37rem" }}
                  >
                    <Dialog.Title className="DialogTitle">
                      Documentos
                      <IoCloseSharp onClick={(e) => setIsDialogOpen(false)} className="closeDialogIcon" style={{ position: "absolute", right: "20px", top: "10px" }} />
                    </Dialog.Title>
                    <Dialog.Description className="DialogDescription">
                      Abajo se muestran los documentos que se anexaron por
                      cliente.
                    </Dialog.Description>

                    {clienteToSave && (
                      <div>
                        <h3 style={{marginBottom:"2rem"}}>Documentos de {clienteToSave.razonSocial}</h3>
                        {clienteToSave.docs.map((documento) => {
                          let docType = getDocType(documento.originalname);
                          return (
                            <div className="docs__container__parent">
                              <div className="docs__container" onClick={() => { window.open(`${API_URL}${documento.path}`); }}>
                                {
                                  docType === "word" ? (<FaRegFileWord style={{ color: "#073b8d", fontSize: "1.3rem" }} />) : (<i className="pi pi-file-pdf btn__pi"></i>)
                                }

                                <p >
                                  {documento.originalname}
                                </p>

                              </div>

                              </div>
                          )
                        })}
                      </div>
                    )}
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div style={{
                        marginTop: "30px",
                        marginBottom: "10px",
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "row-reverse",
                        alignItems: "center"
                      }}>
                        <input
                          className="AprobarButton"
                          type="submit"
                          value="Aprobar"
                          onClick={() => {
                            handleAprobar(clienteToSave._id);
                            handleCloseDialog();
                          }}
                        />
                        <input
                          className="EliminarButton"
                          type="submit"
                          value="Rechazar"
                          style={{ marginRight: "20px" }}
                          onClick={() => {
                            setIsDialogRejectOpen(true);
                          }}
                        />

                      </div>

                    </form>
                  </Dialog.Content>
                </Dialog.Portal>
              </Dialog.Root>
              <Dialog.Root
                open={isDialogRejectOpen}
                onOpenChange={() => setIsDialogRejectOpen(false)}
              >
                <Dialog.Portal>
                  <Dialog.Overlay className="DialogOverlay"
                  />
                  <Dialog.Content className="DialogContentFactura"
                    style={{ width: "32rem", backgroundColor: "white" }}
                  >
                    <Dialog.Title className="DialogTitle">
                      ¿Estás seguro que deseas rechazar a {clienteToSave?.razonSocial}?
                      <IoCloseSharp onClick={(e) => setIsDialogRejectOpen(false)} className="closeDialogIcon" style={{ position: "absolute", right: "20px", top: "10px" }} />
                    </Dialog.Title>
                    <Dialog.Description className="DialogDescription">
                      Describe el motivo del por qué su solicitud fue rechazada
                    </Dialog.Description>

                    <textarea
                      type="text"
                      className="InputJustificacion"
                      placeholder="Justificación"
                      onChange={(e) => setRazon(e.target.value)}
                      style={{ width: "100%", height: "5rem", padding: ".7rem 1rem" }}
                    />
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div style={{
                        marginTop: "22px",
                        marginBottom: "15px",

                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "row-reverse",
                        alignItems: "center"
                      }}>

                        <input
                          className="EliminarButton"
                          type="submit"
                          value="Rechazar"
                          onClick={() => {
                            handleRechazar(clienteToSave._id);
                            setRazon("");
                            setIsDialogRejectOpen(false);
                          }}
                        />
                        <input
                          className="CancelarButton"
                          type="submit"
                          value="Cancelar"
                          onClick={() => {
                            setIsDialogRejectOpen(false)
                          }}
                        />

                      </div>

                    </form>
                  </Dialog.Content>
                </Dialog.Portal>
              </Dialog.Root>
            </div>
          </div>
        </section >
      </div >
    </div >
  );
}

export default RegistrarClientes;