import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BellIcon,
  EnvelopeClosedIcon,
  ExitIcon,
  RowsIcon,
} from "@radix-ui/react-icons";
import { useNotification } from "../pages/NotificationContext";
import jwtDecode from "jwt-decode";

function Header() {
  const { notifications } = useNotification();
  const navigate = useNavigate();
  const [rol,setRol] = useState("");

  useEffect(() => {
    localStorage.setItem("notifications", JSON.stringify(notifications));
    let token = localStorage.getItem("token");

    // Mostrar notificación si hay notificaciones disponibles

    if (token) {
      const decodedUser = jwtDecode(token);
  
      console.log("DecodedUser",decodedUser);
      if(decodedUser.rol === 1){
        setRol("finanzas")
      }
      if(decodedUser.rol === 2){
        setRol("dashboard");
      }
      if(decodedUser.rol===3){
        setRol("admin");
      }
    }

    if (notifications.length > 0) {
      showNotification();
    }
  }, [notifications]);

  const showNotification = () => {
    if ("Notification" in window) {
      if (Notification.permission !== "granted") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            createNotification();
          }
        });
      } else {
        createNotification();
      }
    }
  };

  const createNotification = () => {
    const notification = new Notification("Nueva notificación", {
      body: "Un nuevo viaje ha sido registrado.",
    });

    setTimeout(() => {
      notification.close();
    }, 2000);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("permisos");
    navigate("/", { replace: true });
  };

  return (
    <div>
      <nav
        className="main-header navbar navbar-expand navbar-white navbar-light"
        style={{ zIndex: 999 }}
      >
        <ul className="navbar-nav">
          <a className="nav-link" data-widget="pushmenu">
            <RowsIcon />
          </a>
          <li className="nav-item d-sm-inline-block">
            <a href={`/${rol}`} className="nav-link">
              Inicio
            </a>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <BellIcon />
              <span className="badge badge-danger navbar-badge">
                {notifications.length}
              </span>
            </a>
            {notifications.length > 0 ? (
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                {notifications.map((notification, index) => (
                  <a href="#" className="dropdown-item" key={index}>
                    <div className="media">
                      <div className="media-body">
                        <h3 className="dropdown-item-title">
                          {notification.user}
                          <span className="float-right text-sm">
                            <EnvelopeClosedIcon />
                          </span>
                        </h3>
                        {notification.origin && notification.destination && (
                          <p className="text-sm">
                            Origen: {notification.origin} Destino:{" "}
                            {notification.destination}
                          </p>
                        )}
                        {notification.razonSocial && (
                          <p className="text-sm">
                            Razon Social: {notification.razonSocial}
                          </p>
                        )}
                        <p className="text-sm text-muted">
                          <i className="far fa-clock mr-1"></i>{" "}
                          {notification.time}
                        </p>
                      </div>
                    </div>
                  </a>
                ))}
                <div className="dropdown-divider"></div>
              </div>
            ) : (
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <p className="dropdown-item">Sin notificaciones</p>
              </div>
            )}
          </li>

          <li className="nav-item d-sm-inline-block">
            <a href="" className="nav-link" onClick={handleLogout}>
              Cerrar sesión <ExitIcon />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
export default Header;
