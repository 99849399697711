/* global google */
import React, { useState, useEffect, useRef } from "react";
import * as Form from "@radix-ui/react-form";
import * as Checkbox from "@radix-ui/react-checkbox";
import "../../styles/label.css";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import * as Select from "@radix-ui/react-select";
import "../../styles/select.css";
import * as Tabs from "@radix-ui/react-tabs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { FaTrash } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { InputText } from "primereact/inputtext";

import "../../styles/alert.css";
import "../../styles/dialogMaps.css";
import classnames from "classnames";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import AutocompleteJS from "./AutoComplete";
import { API_URL, RFC_Orig } from "../../constants";
import { useNotification } from "../../pages/NotificationContext";
import { PageInfo } from "../PageInfo";
import { Mercancias } from "../Mercancias";
import { IoCloseSharp } from "react-icons/io5";
import SyncLoader from "react-spinners/ClipLoader";
import { IoDocuments } from "react-icons/io5";
import { FaRegFileWord } from "react-icons/fa";


function AsignarRuta() {
  const dt = useRef(null);

  const [permisos, setPermisos] = useState([]);

  const [username, setUsername] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedTransport, setSelectedTransport] = useState("");

  const [remolqueRequerido, setRemolqueRequerido] = useState(false);

  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [movement, setMovement] = useState("");
  const [container, setContainer] = useState("");
  const [reference, setReference] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [customerFlete, setCustomerFlete] = useState("");
  const [customerFactura, setCustomerFactura] = useState("");
  const [diesel, setDiesel] = useState("");
  const [gastos, setGastos] = useState("");
  const [confirmacion, setConfirmacion] = useState("");
  const [customerData, setCustomerData] = useState("");
  const [inicio, setInicio] = useState("");
  //google maps API
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  //loged username
  const [loggedName, setloggedName] = useState("");
  const [originValue, setOriginValue] = useState("");
  const [originAddress, setOriginAddress] = useState("");

  const [destinationValue, setDestinationValue] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");
  const [waypointValue, setWaypointValue] = useState([]);
  const { sendNotification } = useNotification();
  const [selectedRoute, setSelectedRoute] = useState(0);
  const [tollsCosts, setTollsCosts] = useState(null);
  const [costoCasetas, setCostoCasetas] = useState("");
  const [nominaDia, setNominaDia] = useState("");
  const [camionDia, setCamionDia] = useState("");
  const [cajaDia, setCajaDia] = useState("");
  const [usernamesData, setUsernamesData] = useState([]);
  //Calculos
  const [viaticos, setViaticos] = useState("");
  const [nominaViaje, setNominaViaje] = useState("");
  const [costoCamion, setCostoCamion] = useState("");
  const [costoCaja, setCostoCaja] = useState("");
  const [operaciones, setOperaciones] = useState("");
  const [pension, setPension] = useState("");
  const [provision, setProvision] = useState("");
  const [administracion, setAdmon] = useState("");
  const [costoTotal, setCostoTotal] = useState("");
  const [precioVenta, setPrecioVenta] = useState("");
  const [precioVentaFinal, setPrecioVentaFinal] = useState("");
  const [utilidad, setUtilidad] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [tipoRemolque, setTipoRemolque] = useState("");
  const [modalAbierto, setModalAbierto] = useState(false);

  //Mercancias
  const [logInversa, setLogInversa] = useState("Sí");
  const [fraccAranc, setFraccAranc] = useState("");
  const [impoVucem, setImpoVucem] = useState("");
  const [permImpo, setPermImpo] = useState("");
  const [sectorCofepris, setSectorCofepris] = useState("");
  const [tipoMateria, setTipoMateria] = useState("");
  const [descMateria, setDescMateria] = useState("");
  const [bienesTransp, setBienesTransp] = useState("");
  const [despMaterial, setDespMaterial] = useState("");
  const [cantMaterial, setCantMaterial] = useState("");
  const [claveMaterial, setClaveMaterial] = useState("");
  const [matPeligroso, setMatPeligroso] = useState("No");
  const [pesoMateria, setPesoMateria] = useState("");
  const [tipoDoc, setTipoDoc] = useState("");
  const [numPedimento, setNumPedimento] = useState("");
  const [RFCImportador, setRFCImportador] = useState("");
  const [cantidadMaterial, setCantidadMaterial] = useState("");
  const [transpInt, setTranspInt] = useState("No");
  const [entradaSalida, setEntradaSalida] = useState("");
  const [arrMercancias, setArrMerc] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const [customersByCompany, setCustomersByCompany] = useState([]);
  const [remolquesByCompany,setRemolquesByCompany] = useState([]);


  let mercanciaObj = {
    transpInt: transpInt || "No",
    entradaSalida: "",
    fraccAranc: "",
    impoVucem: "",
    permImpo: "",
    sectorCofepris: "",
    denominacionGenProd: "",
    denominacionDistProd: "",
    fabricante: "",
    fechaCaducidad: "",
    loteMed: "",
    formaFarmaceutica: "",
    condTransporte: "",
    registroSanitario: "",
    ingActivo: "",
    nomQuimico: "",
    numCAS: "",
    regSanPlag: "",
    datosFabricante: "",
    datosFormulador: "",
    datosMaquilador: "",
    usoAutorizado: "",
    razonSocialEmpImp: "",
    tipoMateria: "",
    descripcion: "",
    bienesTransp: "",
    descripcionMateria: "",
    cantidad: "",
    claveUnidad: "",
    matPeligroso: matPeligroso || "",
    cveMatPeligroso: "",
    embalaje: "",
    pesoMateria: "",
    tipoDoc: "",
    numPedimento: "",
    cantidadMaterialTransp: "",
    RFCImportador: "",
    identDocAduanero: ""
  }
  const [mercancias, setMercancias] = useState([mercanciaObj]);

  const [transportsData, setTransportsData] = useState();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    username: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    origin: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    destination: { value: null, matchMode: FilterMatchMode.IN },
    distance: { value: null, matchMode: FilterMatchMode.EQUALS },
    duration: { value: null, matchMode: FilterMatchMode.EQUALS },
    customerFlete: { value: null, matchMode: FilterMatchMode.EQUALS },
    customerFactura: { value: null, matchMode: FilterMatchMode.EQUALS },
    vehicle: { value: null, matchMode: FilterMatchMode.EQUALS },
    diesel: { value: null, matchMode: FilterMatchMode.EQUALS },
    assignedDate: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [route, setRoute] = useState("");
  const [isDialogOpen3, setIsDialogOpen3] = useState(false);
  const [viajeToSave, setViajeToSave] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [finalizacion, setFinalizacion] = useState("");

  const [formaFarmaceutica, setFormaFarmaceutica] = useState([]);
  const [condicionesEspeciales, setCondicionesEspeciales] = useState([]);
  const [embalajeData, setEmbalajeData] = useState("");

  const [driversData, setDriversData] = useState([]);
  const [transportsByCompany, setTranportsByCompany] = useState([]);
  const [empresa, setEmpresa] = useState("");
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  const filterRoutesObj = {
    usuario: "",
    clienteFactura: "",
    empresa: "",
    estatus: "",
    fechaInicio: "",
    vehiculo: "",
    usuario: "",
    idFactura: "",
  }

  const [filterRoutes, setFilterRoutes] = useState(filterRoutesObj);
  const [status, setStatus] = useState([]);

  useEffect(() => {
    // fetchRoutesData();
    fetchRouteStatus();
    // fetchRoutesRData(perms);
    // fetchCustomerRazonSocial();
    // fetchDriverData()
    // fetchFormaFarmaceutica()
    // fetchCondicionesEspeciales()
    // Get username from logged user
    // getAllTransports();
    const token = localStorage.getItem("token");
    let perms = JSON.parse(localStorage.getItem("permisos"));

    setPermisos(perms);

    if (perms.length <= 1) {
      setEmpresa(perms[0]);
      handleSingleCompany(perms[0]);
    }

    if (token) {
      const decodedUser = jwtDecode(token);
      setloggedName(decodedUser.name);
    }

  }, []);

  const handleSingleCompany = async (empresa) => {
    let drivers = await fetchDriversByCompany(empresa);
    setDriversData(drivers);

    let transports = await fetchTransportsByCompany(empresa);
    setTranportsByCompany(transports);

    let customers = await fetchCustomersByCompany(empresa);
    setCustomersByCompany(customers);

  }

  const handleChangeFilter = async (e) => {

    console.log("filterRoutes", filterRoutes)
    if (e.target.name === "empresa") {
      setFilterRoutes({
        ...filterRoutes,
        vehiculo: "",
        clienteFactura: "",
        [e.target.name]: e.target.value
      })

      if (e.target.value !== "") {
        let transports = await fetchTransportsByCompany(e.target.value);
        setTranportsByCompany(transports);
        let customers = await fetchCustomersByCompany(e.target.value);
        setCustomersByCompany(customers);
        let drivers = await fetchDriversByCompany(e.target.value);
        setDriversData(drivers);
      }
    } else {
      setFilterRoutes({
        ...filterRoutes,
        [e.target.name]: e.target.value
      });
    }
  }


  const onRowEditComplete = (e) => {
    let _route = [...route];
    let { newData, index } = e;

    _route[index] = newData;

    setRoute(_route);
    updateRouteInDatabase(newData);
  };



  //Routes router
  const fetchRoutesRData = async () => {
    setIsLoadingSearch(true);

    try {
      let queryString = "?";

      Object.entries(filterRoutes).forEach(([key, value], index) => {

        if (value !== "") {
          queryString += `${key}=${value}&`
        }
      })

      if (!queryString.includes("empresa")) {
        queryString += `empresa=${permisos}&`
      }
      queryString = queryString.substring(0, queryString.length - 1);

      console.log("QueryString", queryString);

      const response = await fetch(`${API_URL}/api/routes${queryString}`);
      const data = await response.json();


      setIsLoadingSearch(false);
      if (data.status === "Ok") {
        console.log("Data en fetchRoutes", data);

        let routes = await getRouteDriver(data.routes);
        routes = await getRouteCustomers(routes);
        routes = await getRouteVehicle(routes);
        console.log("Routes", routes);

        setRoute(routes);
        return routes;
      } else {
        setRoute([]);
        notifyWarning(data.message);
      }
    } catch (error) {
      setIsLoadingSearch(false);
      notifyError(error);
    }
  }


  const getRouteDriver = async (routes) => {
    try {
      let driversData = await fetchDriversByCompany("");
      console.log("getRouteDriver", routes);

      routes.map((route) => {

        if (route.usuario.trim() !== "") {
          let usuario = driversData.find((driver) => driver._id === route.usuario);
          console.log("Usuario ", usuario);
          route.usuario = usuario.nombreAlternativo;
        }

      });

      return routes;
    } catch (error) {
      notifyError(error);
    }
  }

  const getRouteCustomers = async (routes) => {
    try {
      let customers = await fetchCustomersByCompany("");


      routes.map((route) => {
        let customerFactura = customers.find((customer) => customer._id === route.clienteFactura);
        let customerFlete = customers.find((customer) => customer._id === route.clienteFlete);

        route.clienteFactura = customerFactura.razonSocial;
        route.clienteFlete = customerFlete.razonSocial;
      });

      return routes;

    } catch (error) {
      console.error(error);
      notifyError(error);
    }

  }

  const getRouteVehicle = async (routes) => {
    try {
      let transports = await fetchTransportsByCompany("");

      routes.map((route) => {
        let vehicle = transports.find((transport) => transport._id === route.vehiculo);
        console.log("Vehicle", vehicle);
        route.vehiculo = vehicle.numUnidad
      })

      return routes;
    } catch (error) {
      notifyError(error);
    }
  }

  const fetchRouteStatus = async () => {
    try {
      let response = await fetch(`${API_URL}/api/routes/status`);

      let data = await response.json();

      if (data.status === "Ok") {
        setStatus(data.response);
      } else {
        notifyWarning(data.message);
      }

    } catch (error) {
      notifyError(error);
    }

  }


  const fetchFormaFarmaceutica = async () => {
    const response = await fetch(`${API_URL}/api/catalagos/formaFarmaceutica`);
    const data = await response.json();
    setFormaFarmaceutica(data.response);
  }

  const fetchCondicionesEspeciales = async () => {
    const response = await fetch(`${API_URL}/api/catalagos/condicionesEspeciales`);
    const data = await response.json();
    setCondicionesEspeciales(data.response);
  }

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            style={{ marginLeft: "30px" }}
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar"
          />
        </span>
      </div>
    );
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const statusEditor = (options) => {
    return (
      <select
        className="SelectTrigger"
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
      >
        {
          usernamesData.length > 0 ? usernamesData.map((driver) => (
            <option key={driver._id} value={driver.nombreAlternativo}>{driver.nombreAlternativo}</option>
          )) : ""
        }
      </select>
    );
  };
  const getDocType = (docName) => {
    // let docType = docName.includes(".docx") ? "word" : "pdf";
    return docName.includes(".docx") ? "word" : "pdf";

  };
  const getStatusColor = (status) => {
    let colors = [
      {
        status: "Pendiente Chofer",
        color: "#cf9b00"
      },

      {
        status: "Pendiente Gastos Diesel",
        color: "#cf9b00"
      },

      {
        status: "Pendiente Gastos Viaje",
        color: "#cf9b00"
      },
      {
        status: "Pendiente Precio Venta",
        color: "#cf9b00"
      },
      {
        status: "Pendiente Vehículo",
        color: "#cf9b00"
      },
      {
        status: "Pendiente Referencia",
        color: "#cf9b00"
      },
      {
        status: "Pendiente Facturación",
        color: "#17a2b8"
      },
      {
        status: "Pendiente Pago",
        color: "#cf9b00"
        // color: "#ffc107"
      },
      {
        status: "Viaje en curso",
        color: "#28a745"
      },
      {
        status: "Finalizado",
        color: "#28a745"
      }];

    let color = colors.find(value => {
      if (value.status == status) return value.color;
    })
    return color ? color.color : "#dc3545";

  }


  const actionBodyTemplate = (rowData) => {

    return (
      <IoDocuments
        className="documentosInicio"
        onClick={() => confirmDeleteDriver(rowData)}

      />
    )

    // return (
    //   <React.Fragment>
    //     <Button
    //       icon="pi pi-eye"
    //       rounded
    //       outlined
    //       severity="primary"
    //       placeholder="Guardar"
    //       className="ButtonPDF"
    //       onClick={() => confirmDeleteDriver(rowData)}
    //       style={{ fontSize: "1.5rem", color: "black" }}
    //     />
    //   </React.Fragment>
    // );
  };

  const confirmDeleteDriver = (rowData) => {
    setViajeToSave(rowData);
    setIsDialogOpen(true);
  };

  const notifyUpdate = () => {
    toast.success("¡Datos actualizados!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    fetchDriverData();
  };

  const getAllTransports = async () => {
    try {

      const response = await fetch(`${API_URL}/api/transports`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      setTransportsData(data);

    } catch (err) {
      console.error(err);
    }
  }

  const fetchCustomersByCompany = async (empresa) => {
    try {
      if (empresa === "") {
        empresa = permisos;
      }
      let response = await fetch(`${API_URL}/api/customerUpdate?empresa=${empresa}`);
      let data = await response.json();

      if (data.status === "Ok") {
        return data.customers;
      } else {
        setCustomersByCompany([]);
        notifyWarning(data.message);

      }

    } catch (error) {

      notifyError(error);
    }
  }

  const fetchDriversByCompany = async (empresa) => {
    try {

      if (empresa === "") {
        empresa = permisos;
      }

      let response = await fetch(`${API_URL}/api/drivers?company=${empresa}`);
      let data = await response.json();

      if (data.status === "Ok") {
        return data.drivers;
      } else {
        setDriversData([]);
        notifyWarning(data.message);
      }
    } catch (error) {
      console.error(error);
    }

  };

  const fetchTransportsByCompany = async (empresa) => {
    try {
      if (empresa === "") {
        empresa = permisos;
      }
      let response = await fetch(`${API_URL}/api/transports?empresa=${empresa}`);
      let data = await response.json();

      if (data.status === "Ok") {
        return data.transports;
      } else {
        setTranportsByCompany([]);
        notifyWarning(data.error);
      }

    } catch (error) {
      notifyError(error);
    }

  }

  const fetchRemolquesByCompany = async(empresa)=>{
    try{
      let response = await fetch(`${API_URL}/api/catalagos/remolques?empresa=${empresa}`);
      let data = await response.json();

      console.log("Data remolques",data);
      if(data.status==="Ok"){
        return data.remolques;
      }else{
        notifyWarning(data.message);
      }

    }catch(err){
      notifyWarning(err);
    }
  }

  //FETCH drivers
  const fetchDriverData = async () => {
    try {
      const response = await fetch(`${API_URL}/api/routes`);
      const routesData = await response.json();

      // FETCH driver usernames
      const usernamesResponse = await fetch(`${API_URL}/api/drivers-username`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const usernamesData = await usernamesResponse.json();
      const usernames = usernamesData.map((item) => item.nombreAlternativo);
      setUsernamesData(usernamesData);
      const filteredUsernames = usernames.filter((username) => {
        return (
          !routesData.some((route) => route.usuario === username) ||
          !routesData.some(
            (route) =>
              route.usuario === username &&
              [
                "Pendiente Chofer",
                "Viaje en curso",
                "Pendiente Gastos Diesel",
                "Pendiente Gastos Viaje",
                "Pendiente Precio Venta",
                "Pendiente Vehículo",
                "Pendiente Referencia",
              ].includes(route.estatus)
          )
        );
      });

      setUsername(filteredUsernames);
    } catch (error) {
      // Handle any fetch errors here
      console.error(error);
    }
  };

  //UPDATE route in BD
  async function updateRouteInDatabase(updatedRoute) {
    const selectedUserData = usernamesData.find(
      (user) => user.username === updatedRoute.usuario
    );
    const duration = updatedRoute.duracion;
    const diesel = updatedRoute.diesel;
    const tollsCost = updatedRoute.casetas;
    var nominaDia = 0;
    var camionDia = 0;
    var cajaDia = 0;
    if (selectedUserData) {
      nominaDia = selectedUserData.nominaDia || 0;
      camionDia = selectedUserData.camionDia || 0;
      cajaDia = selectedUserData.cajaDia || 0;
    }

    var viaticos = 0;
    if (duration <= 16) {
      viaticos = 700;
    } else {
      viaticos = 1400;
    }

    // Calculo nomina por dia y por mes
    const nominaHora = parseFloat((nominaDia / 24).toFixed(2));
    // Calculo camion por dia y mes
    const camionHora = parseFloat((camionDia / 24).toFixed(2));
    // Calculo caja por dia y mes
    const cajaHora = parseFloat((cajaDia / 24).toFixed(2));

    // Calculo nomina de viaje:
    const nominaViaje = parseFloat((nominaHora * duration).toFixed(2));
    // Calculo costo de unidad (camion) por viaje:
    const costoCamion = parseFloat((camionHora * duration).toFixed(2));
    // Calculo costo de caja por viaje:
    const costoCaja = parseFloat((cajaHora * duration).toFixed(2));
    // Calculo costo operaciones
    const operaciones = parseFloat((duration * 22.52).toFixed(2));
    // Calculo pension
    const pension = parseFloat((duration * 10).toFixed(2));
    // Calculo provision
    const provision = parseFloat((duration * 83).toFixed(2));
    // Calculo administracion
    const administracion = parseFloat((duration * 16).toFixed(2));
    // Costo diesel
    const costoDiesel = parseFloat(diesel);
    // Costo casetas
    const costoCasetas = parseFloat(tollsCost);
    // Costo total
    const costoTotal = parseFloat(
      nominaViaje +
      costoCaja +
      costoCamion +
      operaciones +
      pension +
      provision +
      administracion +
      costoDiesel +
      costoCasetas
    ).toFixed(2);
    // Precio de venta
    const totalWithVAT = costoTotal * 1.2;
    const precioVenta = parseFloat(totalWithVAT.toFixed(2));

    // Utilidad
    const utilidad = parseFloat((precioVenta - costoTotal).toFixed(2));
    const updatedRouteWithVariables = {
      ...updatedRoute,
      nominaDia,
      camionDia,
      cajaDia,
      viaticos,
      nominaViaje,
      costoCamion,
      costoCaja,
      operaciones,
      pension,
      provision,
      administracion,
      costoTotal,
      precioVenta,
      utilidad,
    };

    try {
      const response = await fetch(`${API_URL}/api/update-route`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedRouteWithVariables),
      });

      const data = await response.json();
      if (data.status === "ok") {
        notifyUpdate();
        // fetchRoutesData();
        fetchRoutesRData();
      }
      if (response.status === 401) {
        notifyError(data.error);
      }
      return data;
    } catch (error) {
      console.error("Error al actualizar el conductor:", error);
      return {
        status: "error",
        error: "Ocurrió un error al actualizar el conductor.",
      };
    }
  }

  const dateEditor = (options) => {
    return (
      <input
        className="Input boxSizing__bb"
        type="datetime-local"
        required
        value={options.value}
        placeholder=""
        style={{ color: "black" }}
      ></input>
    )
  }

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        className="Input"
      />
    );
  };

  const showPdf = (index) => {
    const documento = viajeToSave.documentos[index];
    window.open(`${API_URL}${documento.path}`, "_blank");
  };
  const showPdfComprobante = (factura) => {
    const urlFile = factura;
    window.open(`${API_URL}/files/hoja-finalizacion/${urlFile}`);
  };

  const actionBodyTemplate3 = (rowData) => {
    const statusPermitidos = ["Viaje en curso", "Pendiente Referencia"];
    if (!statusPermitidos.includes(rowData.estatus)) {
      return null;
    }

    return (
      <React.Fragment>
        <Button
          // icon="pi pi-save"
          rounded
          outlined
          severity="primary"
          placeholder="Guardar"
          className="pi pi-save btn__pi"
          style={{ color: "#5c6f80" }}
          onClick={() => confirmDeleteDriver3(rowData)}
        />
      </React.Fragment>
    );
  };
  const confirmDeleteDriver3 = (rowData) => {
    setViajeToSave(rowData);
    setIsDialogOpen3(true);
  };

  // function getStatusColor(estatus) {
  //   switch (estatus) {
  //     case "Pendiente Chofer":
  //       return "orange";
  //     case "Pendiente Gastos Diesel":
  //       return "orange";
  //     case "Pendiente Gastos Viaje":
  //       return "orange";
  //     case "Pendiente Precio Venta":
  //       return "orange";
  //     case "Pendiente Vehículo":
  //       return "orange";
  //     case "Pendiente Referencia":
  //       return "orange";
  //     case "Viaje en curso":
  //       return "green";

  //     case "Pendiente Facturación":
  //       return "blue";
  //     case "Pendiente Pago":
  //       return "blue";
  //     case "Finalizado":
  //       return "green";
  //     default:
  //       return "gray";
  //   }
  // }

  const handleSaveFinalizacion = (e) => {
    e.preventDefault();
    if (route) {
      handleFinalizacionUpload(route._id);
    }
    setIsDialogOpen3(false);
  };

  const handleFinalizacionUpload = async () => {
    const formData = new FormData();
    formData.append("finalizacion", finalizacion);
    formData.append("_id", viajeToSave._id);
    try {
      const response = await fetch(`${API_URL}/api/uploadfinalizacion`, {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        fetchRoutesRData();
      } else {
        console.error("Failed to upload final doc");
      }
    } catch (error) {
      console.error(error);
    }
  };


  // Fetch driver usernames
  const fetchCustomerRazonSocial = async () => {
    try {
      const response = await fetch(`${API_URL}/api/customers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      const approvedCustomers = data.filter(
        (item) => item.estatus === "Aprobado"
      );
      console.log("Customers", approvedCustomers);
      const customerData = approvedCustomers.map((item) => item);
      console.log("customerData,", customerData);
      setCustomerData(customerData);
    } catch (error) {
      console.error(error);
    }
  };

  // FETCH routes to get busy drivers
  const fetchRoutesData = async () => {
    try {
      const response = await fetch(`${API_URL}/api/routes`);
      const routesData = await response.json();
      console.log("RoutesData", routesData);


      // FETCH driver usernames
      const usernamesResponse = await fetch(`${API_URL}/api/drivers-username`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const usernamesData = await usernamesResponse.json();
      const usernames = usernamesData.map((item) => item.nombreAlternativo);

      setUsername(usernames);
    } catch (error) {
      // Handle any fetch errors here
      console.error(error);
    }
  };

  const fetchUnidad = async (e) => {
    const usernamesResponse = await fetch(`${API_URL}/api/drivers-username`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const usernamesData = await usernamesResponse.json();
    const choferData = usernamesData.find((item) => item.nombreAlternativo === e);
    if (choferData) {
      // Si se encuentra el chofer, asignar numUnidad a setVehicle
      setVehicle(choferData.numUnidad);
    } else {
      console.error(`No se encontró el chofer correspondiente a ${e}`);
    }
  }

  const handleAddMerch = () => {
    setMercancias([...mercancias, mercanciaObj]);
  };

  const handleTransportChange = (e) => {

    if (e.target.value !== "") {
      let findTransportByUnit = transportsData.find((el) => el.numUnidad === e.target.value);
      setRemolqueRequerido(findTransportByUnit.remolqueRequerido);
      setVehicle(e.target.value);
      setSelectedTransport(findTransportByUnit);
    }
  }

  const validationNumPedimento = (numPedimento, index) => {
    let claveAduana = ["01", "02", "05", "06", "07", "08", "11", "12", "14", "16", "17", "18", "19", "20", "22", "23", "24", "25", "26", "27", "28", "30", "31", "33", "34", "37", "38", "39", "40", "42", "43", "44", "46", "47", "48", "50", "51", "52", "53", "64", "65", "67", "73", "75", "80", "81", "82", "83", "84", "85"]

    let yearLastTwoChar = new Date().getFullYear().toString().substring(2, 4);
    let firsTwoNumPed = numPedimento.substring(0, 2);
    let clavAduanaPedimento = numPedimento.substring(6, 8);

    if (Number(firsTwoNumPed) > Number(yearLastTwoChar)) {
      notifyError(`Los primeros dos digitos del num de pedimento no pueden ser mayores a los dos ultimos digitos de la fecha actual ${index}`);
      return false;
    }

    if (!claveAduana.includes(clavAduanaPedimento)) {
      notifyError(`La posicion 5 y 6 no concuerdan con ninguna clave de aduana en el numero de pedimento de la Mercancia ${index}`);
      return false;
    }

  }

  const validateMercancias = () => {

    let validationRes = mercancias.map((mercancia, index) => {

      if (mercancia.bienesTransp.trim() == "" || mercancia.bienesTransp.trim().length > 8) {
        notifyError(`Bienes transportados no puede estar vacío ni puede ser mayor o menor a 8 digitos en Mercancia ${index + 1}`);
        return false;
      }

      if (mercancia.descripcion.trim() == "") {
        notifyError(`Descripción no puede estar vacío en Mercancía ${index + 1}`);
        return false;
      }

      if (mercancia.cantidad <= 0 || mercancia.cantidad == "") {
        notifyError(`La cantidad no debe ser igual a 0 ni estar vacío en Mercancía ${index + 1}`);
        return false;
      }

      if (mercancia.claveUnidad.trim() == "") {
        notifyError(`Clave unidad no puede estar vacío en Mercancía ${index + 1}`);
        return false;
      }

      if (mercancia.pesoMateria <= 0 || mercancia.pesoMateria == "") {
        notifyError(`Peso en kilogramos no puede ser igual a 0 ni estat vacío en Mercancía ${index + 1}`)
        return false;
      }

      if (transpInt == "Sí") {
        if (mercancia.fraccAranc.trim() == "") {
          notifyError(`Fracción Arancelaria no puede estar vacío en Mercancía ${index + 1}`);
          return false;
        }

        if (mercancia.fraccAranc.trim().length < 8) {
          notifyError(`La Fracción Arancelaria no ser menor a 8 digitos en Mercancía ${index + 1}`);
          return false;
        }

        if (mercancia.sectorCofepris === "01" ||
          mercancia.sectorCofepris === "02" ||
          mercancia.sectorCofepris === "04" ||
          mercancia.sectorCofepris === "05") {
          if (mercancia.impoVucem.trim() == "") {
            notifyError(`El folio impo Vucem no puede estar vacío en Mercancía ${index + 1}`);
            return false;
          }
        }


        if (mercancia.sectorCofepris === "01" || mercancia.sectorCofepris === "02" || mercancia.sectorCofepris === "03") {

          if (mercancia.sectorCofepris !== "02") {
            if (mercancia.denominacionGenProd.trim() === "" || mercancia.denominacionGenProd.trim().length > 50) {
              notifyError(`El nombre genérico del producto no puede estar vacío ni ser mayor a 50 caracteres en Mercancia ${index + 1}`);
              return false;
            }

            if (mercancia.denominacionDistProd.trim() === "" || mercancia.denominacionDistProd.trim().length > 50) {
              notifyError(`La marca del producto no puede estar vacía ni ser mayor a 50 caracteres en Mercancia ${index + 1}`);
              return false;
            }

            if (mercancia.registroSanitario.trim() === "" || mercancia.registroSanitario.trim().length > 15) {
              notifyError(`El registro sanitario no puede estar vacío ni ser mayor a 15 caracteres en Mercancia ${index + 1}`);
              return false;
            }
          }

          if (mercancia.fabricante.trim() === "") {
            notifyError(`El nombre del fabricante no puede estar vacío en Mercancía ${index + 1}`);
            return false;
          }

          if (mercancia.fechaCaducidad.trim() === "") {
            notifyError(`La Fecha de caducidad no puede estar vacía en Mercancía ${index + 1}`);
            return false;
          }

          if (mercancia.loteMed.trim() === "" || mercancia.loteMed.trim().length > 10) {
            notifyError(`El lote del producto no puede estar vacío ni ser mayor a 10 caracteres en Mercancía ${index + 1}`);
            return false;
          }

          if (mercancia.formaFarmaceutica.trim() === "") {
            notifyError(`La forma farmaceutica no puede estar vacía en Mercancía ${index + 1}`);
            return false;
          }

          if (mercancia.condTransporte.trim() === "") {
            notifyError(`Las condiciones de transporte no puede estar vacío en Mercancía ${index + 1}`);
            return false;
          }

          if (mercancia.permImpo.trim() === "" || mercancia.permImpo.trim().length > 6) {
            notifyError(`El permiso de importación no puede estar vacío ni ser mayor a 6 caracteres en Mercancia ${index + 1}`);
            return false;
          }

        }

        if (mercancia.sectorCofepris === "02" || mercancia.sectorCofepris === "05") {
          if (mercancia.ingActivo.trim() === "") {
            notifyError(`El ingrediente activo no puede estar vacío en Mercancía ${index + 1}`);
            return false;
          }
        }

        if (mercancia.sectorCofepris === "04") {
          if (mercancia.nomQuimico.trim() === "") {
            notifyError(`El nombre químico no puede estar vacío en Mercancía ${index + 1}`);
            return false;
          }

          if (mercancia.numCAS.trim() === "" || mercancia.numCAS.trim().length > 15) {
            notifyError(`El número de CAS no puede estar vacío ni ser mayor a 15 caracteres en Mercancía ${index + 1}`);
            return false;
          }
        }

        if (mercancia.sectorCofepris === "05") {
          if (mercancia.regSanPlag.trim() === "" || mercancia.regSanPlag.trim().length > 60) {
            notifyError(`El Registro Sanitario de Plaguicidas no puede estar vacío ni ser mayor a 60 caracteres en Mercancía ${index + 1}`);
            return false;
          }

          if (mercancia.datosFabricante.trim() === "") {
            notifyError(`Los Fatos del Fabricante no puede estar vacío en Mercancía ${index + 1}`);
            return false;
          }

          if (mercancia.datosFormulador.trim() === "") {
            notifyError(`Los Datos del Formulador no puede estar vacío en Mercancía ${index + 1}`);
            return false;
          }

          if (mercancia.datosMaquilador.trim() === "") {
            notifyError(`Los Datos del Maquilador no puede estar vacío en Mercancía ${index + 1}`);
            return false;
          }

          if (mercancia.usoAutorizado.trim() === "") {
            notifyError(`El Uso Autorizado no puede estar vacío en Mercancía ${index + 1}`);
            return false;
          }

        }
        if (mercancia.tipoMateria.trim() == "") {
          notifyError(`El tipo de materia no puede estar vacío en Mercancía ${index + 1}`)
          return false;
        }


        if (mercancia.tipoMateria == "05") {
          if (mercancia.descripcionMateria.trim() === "") {
            notifyError(`La descripción de la materia es obligatoria en Mercancía ${index + 1}`)
            return false;
          }
        }

        // validationNumPedimento(mercancia.numPedimento, index + 1);

        if (mercancia.tipoDoc === "01") {
          if (mercancia.RFCImportador.trim() == "") {
            notifyError(`El RFC del importador no puede estar vacío en Mercancia ${index + 1} cuando el tipo de documento es 01 Pedimento`)
            return false;
          }
          if (mercancia.numPedimento.trim() === "") {
            notifyError(`El Numero de pedimento no puede estar vacío en Mercancia ${index + 1} cuando el tipo de documento es 01 Pedimento`)
            return false;
          }
        }



        if (mercancia.tipoDoc.trim() == "") {
          notifyError(`El tipo de documento no puede estar vacío en Mercancia ${index + 1}`)
          return false;
        }

        if (mercancia.tipoDoc !== "01") {
          if (mercancia.identDocAduanero.trim() === "") {
            notifyError(`Documento Aduanero no puede estar vacío en Mercancia ${index + 1}`)
            return false;
          }
        }


        // if (mercancia.numPedimento.trim() == "") {
        //   let messageValidation = validationNumPedimento(mercancia.numPedimento); 

        //   notifyError(`El numero de Pedimento no puede estar vacío en Mercancia ${index + 1}`)
        //   return false;
        // }

        if (mercancia.cantidadMaterialTransp.trim() == "" || mercancia.cantidadMaterialTransp <= 0) {
          notifyError(`La cantidad que transporta no puede estar vacía ni ser igual a 0 en Mercancia ${index + 1}`)
          return false;
        }

      }



    })

    if (validationRes.includes(false)) {
      return false;
    }

    if (matPeligroso === "Sí") {
      notifyEmptyDir("Los transportes disponibles han sido actualizados debido al transporte de material peligroso");
    }

    setModalAbierto(false);
    notifySuccessMerc();
    const arrMercancias = {
      logInversa: logInversa || "",
      fraccAranc: fraccAranc || "",
      impoVucem: impoVucem || "",
      permImpo: permImpo || "",
      tipoMateria: tipoMateria || "",
      descMateria: descMateria || "",
      bienesTransp: bienesTransp || "",
      despMaterial: despMaterial || "",
      cantMaterial: cantMaterial || "",
      claveMaterial: claveMaterial || "",
      matPeligroso: matPeligroso || "",
      pesoMateria: pesoMateria || "",
      tipoDoc: tipoDoc || "",
      numPedimento: numPedimento || "",
      RFCImportador: RFCImportador || "",
      cantidadMaterial: cantidadMaterial || "",
      transpInt: transpInt || "",
      mercancias: mercancias || []
    };
    setArrMerc(mercancias);
    return true;
  };


  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const headers = [
        "_id",
        "usuario",
        "origen",
        "intermedio",
        "destino",
        "duracion",
        "distancia",
        "distanciaRecorrida",
        "movimiento",
        "contenedor",
        "referencia",
        "clienteFlete",
        "clienteFactura",
        "documentos",
        "vehiculo",
        "estatus",
        "fechaInicio",
        "asignadoPor",
        "fechaAsignado",
        "fechaFinal",
      ];

      const trimmedRoute = route.map((row) => {
        const trimmedRow = {};
        headers.forEach((header) => {
          trimmedRow[header] = row[header];
        });
        return trimmedRow;
      });

      const worksheet = xlsx.utils.json_to_sheet(trimmedRoute);

      const headerStyle = {
        fill: {
          fgColor: { rgb: "FF0000" },
        },
        font: {
          color: { rgb: "FFFFFF" },
          bold: true,
        },
      };

      // Obtener rango de celdas de encabezado
      const headerRange = xlsx.utils.decode_range(worksheet["!ref"]);
      for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
        const headerCellRef = xlsx.utils.encode_cell({
          r: headerRange.s.r,
          c: col,
        });
        worksheet[headerCellRef].s = headerStyle;
      }

      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "viajes");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };
  const obtenerRutas = async () => {
    setIsButtonDisabled(true);
    const waypoints = waypointValue.map((location) => ({ address: location }));
    const datosDeSolicitud = {
      from: {
        address: originValue,

      },
      to: {
        address: destinationValue,
      },
      waypoints: waypoints,
      serviceProvider: "here",
      vehicle: {
        type: "6AxlesTruck",
        weight: {
          value: 20000,
          unit: "pound",
        },
        height: {
          value: 7.5,
          unit: "meter",
        },
        length: {
          value: 7.5,
          unit: "meter",
        },
        axles: 6,
        emissionClass: "euro_5",
      },
      departure_time: 1609507347,
      fuelPrice: 24.16,
      fuelPriceCurrency: "MXN",
      fuelEfficiency: {
        city: 2,
        hwy: 2,
        units: "kmpl",
      },
      driver: {
        wage: 75,
        rounding: 60,
        valueOfTime: 0,
      },
      hos: {
        rule: 60,
        dutyHoursBeforeEndOfWorkDay: 11,
        dutyHoursBeforeRestBreak: 7,
        drivingHoursBeforeEndOfWorkDay: 11,
        timeRemaining: 60,
      },
      enableDurationInTraffic: true,
    };

    try {
      if (originValue == "" || destinationValue == "") {
        notifyEmptyDir();
        return;
      }
      const response = await fetch(`${API_URL}/api/toll`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datosDeSolicitud),
      });


      if (response.ok) {

        const tollsCosts = await response.json();

        if (tollsCosts.status === 403) {
          notifyError("No se pudo realizar el cálculo de las rutas");
        } else {
          setTollsCosts(tollsCosts);
          setIsButtonDisabled(false);
        }

      } else {
        console.error("Error en la solicitud:", response.statusText);
      }
    } catch (error) {
      // Handle any fetch errors here
      console.error(error);
      notifyError("No se pudo realizar el cálculo de las rutas")
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const calculateTotalCashCost = (tolls) => {
    const exchangeRateToMXN = 16.97;

    return tolls.reduce((sum, toll) => {
      const costInMXN =
        toll.currency === "USD"
          ? toll.cashCost * exchangeRateToMXN
          : toll.cashCost;
      return sum + costInMXN;
    }, 0);
  };

  const saveRouteData = () => {
    const selectedRouteData = tollsCosts.routes[selectedRoute];
    console.log("Distance", selectedRouteData.summary.distance);
    const distance = (
      selectedRouteData.summary.distance.value / 1000 +
      150
    ).toFixed(2);
    const duration = (selectedRouteData.summary.duration.value / 3600).toFixed(
      2
    );
    const diesel = ((distance / 2) * 24.16).toFixed(2);
    const tollsCost = calculateTotalCashCost(selectedRouteData.tolls).toFixed(
      2
    );


    // setDistance(distance);

    setDistance(Number(selectedRouteData.summary.distance.value / 1000).toFixed(2));

    setDiesel(diesel);

    //Calculo de viaticos:
    var viaticos = 0;
    if (duration <= 16) {
      viaticos = 700;
      setViaticos(viaticos);
    } else {
      viaticos = 1400;
      setViaticos(viaticos);
    }
    // Rutas cortas/largas
    var durationFinal;
    var parsedDuration = parseFloat(duration);

    if (!isNaN(parsedDuration)) {
      if (parsedDuration > 6) {
        durationFinal = (parsedDuration + 8).toFixed(2);
      } else {
        durationFinal = parsedDuration.toFixed(2);
      }
      setDuration(parseFloat(durationFinal));
    } else {
      console.error("Error al parsear duration a número.");
    }

    // Calculo nomina por dia y por mes
    const nominaHora = parseFloat((nominaDia / 24).toFixed(2));
    // Calculo camion por dia y mes
    const camionHora = parseFloat((camionDia / 24).toFixed(2));
    // Calculo caja por dia y mes
    const cajaHora = parseFloat((cajaDia / 24).toFixed(2));
    // Calculo nomina de viaje:
    const nominaViaje = parseFloat((nominaHora * durationFinal).toFixed(2));
    // Calculo costo de unidad (camion) por viaje:
    const costoCamion = parseFloat((camionHora * durationFinal).toFixed(2));
    // Calculo costo de caja por viaje:
    const costoCaja = parseFloat((cajaHora * durationFinal).toFixed(2));
    // Calculo costo operaciones
    const operaciones = parseFloat((durationFinal * 22.52).toFixed(2));
    // Calculo pension
    const pension = parseFloat((durationFinal * 10).toFixed(2));
    // Calculo provision
    const provision = parseFloat((durationFinal * 83).toFixed(2));
    // Calculo administracion
    const administracion = parseFloat((durationFinal * 16).toFixed(2));
    // Costo diesel
    const costoDiesel = parseFloat(diesel);
    // Costo casetas
    const costoCasetas = parseFloat(tollsCost);
    // Costo total
    const costoTotal = parseFloat(
      nominaViaje +
      costoCaja +
      costoCamion +
      operaciones +
      pension +
      provision +
      administracion +
      costoDiesel +
      costoCasetas +
      gastos
    ).toFixed(2);
    // Precio de venta
    const totalWithVAT = costoTotal * 1.2;
    const precioVenta = parseFloat(totalWithVAT.toFixed(2));

    // Utilidad
    const utilidad = parseFloat((precioVenta - costoTotal).toFixed(2));
    setDiesel(diesel);
    setCostoCasetas(costoCasetas);
    setNominaViaje(nominaViaje);
    setCostoCamion(costoCamion);
    setCostoCaja(costoCaja);
    setOperaciones(operaciones);
    setPension(pension);
    setProvision(provision);
    setAdmon(administracion);
    setCostoTotal(costoTotal);
    setPrecioVenta(precioVenta);
    setUtilidad(utilidad);
  };


  const selectVehiculoEditor = (options) => {


    let hasMatPeligroso = options.rowData.mercancias.map((el) => {
      if (el.matPeligroso === "Sí") return true;
    })

    let transportVehicle = [...transportsData];
    if (hasMatPeligroso.includes(true)) {
      transportVehicle = transportsData.filter((el) => el.medAmbiente === true);
    }

    return (
      <select
        className="SelectTrigger"
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
      >
        {
          transportVehicle.length > 0 ? (

            transportVehicle.map((vehicle) => (
              <option key={vehicle._id} value={vehicle.numUnidad}>
                {vehicle.numUnidad}
              </option>

            ))
          ) : ""
        }
      </select>
    );
  };


  //Route assignment
  async function routeAssignment(e) {
    e.preventDefault();

    if (remolqueRequerido) {
      let couldHaveContainer = ["VL", "OTROEVGP"];
      if (!couldHaveContainer.includes(selectedTransport.confVehicular)) {
        if (tipoRemolque.trim() === "") {
          notifyEmptyDir("El tipo de contenedor es requerido");
          return false;
        }

        if (container.trim() === "") {
          notifyEmptyDir("El contenedor no debe de estar vacío");
          return false;
        }
      }

    }

    if (selectedUser.trim() === "") {
      notifyEmptyDir("El operador es requerido");
      return false;
    }

    if (movement.trim() === "") {
      notifyEmptyDir("El tipo de movimiento es requerido");
      return false;
    }

    if (!originValue) {
      notifyEmptyDir("La ubicación de Origen está vacía");
      return false;
    }

    if (!destinationValue) {
      notifyEmptyDir("La ubicación de destino está vacía");
      return false;
    }
    if (!duration && !distance) {
      notifyEmptyDir("No se ha seleccionado la ruta");
      return false;
    }
    if (!customerFactura) {
      notifyEmptyDir("No se ha seleccionado el cliente de factura");
      return false;
    }
    if (!customerFlete) {
      notifyEmptyDir("No se ha seleccionado el cliente de flete");
      return false;
    }
    if (!vehicle) {
      notifyEmptyDir("El transporte no ha sido seleccionado");
      return false;
    }
    if (!precioVentaFinal) {
      notifyEmptyDir("El precio de venta final está vacío");
      return false;
    }
    if (!inicio) {
      notifyEmptyDir("La Fecha de Inicio está vacía");
      return false;
    }
    if (arrMercancias.length === 0) {
      notifyEmptyDir("Las mercancías no pueden estar vacías");
      return;
    }



    const formData = new FormData();
    formData.append("empresa", empresa);
    formData.append("selectedUser", selectedUser);
    formData.append("originValue", originValue);
    formData.append("originAddress", JSON.stringify(originAddress))
    formData.append("waypointValue", JSON.stringify(waypointValue));
    formData.append("destinationValue", destinationValue);
    formData.append("destinationAddress", JSON.stringify(destinationAddress))
    formData.append("duration", duration);
    formData.append("distance", distance);
    formData.append("movement", movement);
    formData.append("container", container);
    formData.append("reference", reference);
    formData.append("customerFlete", customerFlete);
    formData.append("customerFactura", customerFactura);
    formData.append("vehicle", vehicle);
    for (let i = 0; i < confirmacion.length; i++) {
      formData.append("confirmacion", confirmacion[i]);
    }
    formData.append("loggedName", loggedName);
    formData.append("inicio", inicio);
    //Calculos de costos por viaje
    formData.append("diesel", diesel);
    formData.append("casetas", costoCasetas);
    formData.append("viaticos", viaticos);
    formData.append("nominaViaje", nominaViaje);
    formData.append("costoCamion", costoCamion);
    formData.append("costoCaja", costoCaja);
    formData.append("operaciones", operaciones);
    formData.append("pension", pension);
    formData.append("provision", provision);
    formData.append("administracion", administracion);
    formData.append("costoTotal", costoTotal);
    formData.append("precio", precioVentaFinal);
    formData.append("utilidad", utilidad);
    formData.append("tipoRemolque", tipoRemolque);
    formData.append("arrMercancias", JSON.stringify(mercancias));

    try {
      setIsLoading(true);

      const response = await fetch(`${API_URL}/api/register-route`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      setIsLoading(false);

      if (data.status === "ok") {
        notifySuccess();
        setIsLoading(false);
        document.getElementById("formRoute").reset();
        setSelectedUser("");
        setDuration("");
        setDistance("");
        setPrecioVenta("");
        setVehicle("");
        setTipoRemolque("");
        setPrecioVenta("");
        setWaypointValue([]);
        // fetchRoutesData();
        // fetchRoutesRData();
        setPrecioVentaFinal("");
        setArrMerc([]);
        setTipoRemolque("");
        setFraccAranc("");
        setTipoMateria("");
        setBienesTransp("");
        setDescMateria("");
        setDespMaterial("");
        setCantMaterial("");
        setClaveMaterial("");
        setPesoMateria("");
        setTipoDoc("");
        setNumPedimento("");
        setRFCImportador("");
        setCustomerFlete("");
        setCustomerFactura("");
        setMercancias([mercanciaObj]);
        setMatPeligroso("No");
        const currentTime = new Date();
        const time = currentTime.toISOString();
        const user = `${loggedName} ha asignado un nuevo viaje.`;

        sendNotification({
          user: user,
          origin: originValue,
          destination: destinationValue,
          time: formatDateTime(time),
        });
        // fetchRoutesRData();
      } else {
        notifyError(data.message);

      }
    } catch (error) {
      notifyError(error);
      setIsLoading(false);
    }
  }

  function formatDateTime(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const formattedDate = dateTime.toLocaleDateString();
    const formattedTime = dateTime.toLocaleTimeString();

    return `${formattedDate} ${formattedTime}`;
  }

  const notifyWarning = (message) => {
    toast.warning(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };
  const notifyEmptyDir = (message) => {
    toast.warning(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const notifyError = (message) => {
    toast.error(`¡Error: ${message}!`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const notifySuccess = () => {
    toast.success("¡Viaje asignado correctamente!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };
  const notifySuccessMerc = () => {
    toast.success("¡Mercancias guardadas correctamente!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };
  const SelectItem = React.forwardRef(
    ({ children, className, ...props }, forwardedRef) => {
      return (
        <Select.Item
          className={classnames("SelectItem", className)}
          {...props}
          ref={forwardedRef}
        >
          <Select.ItemText>{children}</Select.ItemText>
          <Select.ItemIndicator className="SelectItemIndicator">
            <CheckIcon />
          </Select.ItemIndicator>
        </Select.Item>
      );
    }
  );

  const handleAutocompleteValueChange = (inputName, value) => {
    if (inputName === "origin") {
      setOriginValue(value);
    } else if (inputName === "destination") {
      setDestinationValue(value);
    } else if (inputName === "waypoint") {
      setWaypointValue([...waypointValue, value]);
    } else if (inputName === "originAddress") {
      setOriginAddress(value);
    } else if (inputName === "destinationAddress") {
      setDestinationAddress(value);
    }
  };

  const validateNumPedimento = (numPedimento) => {

    if (numPedimento !== "") {
      let firstSet = false;
      let secondSet = false;
      let thirdSet = false;
      let fourthSet = false;


      let sp = Array.from(numPedimento).reduce((acc, t, i) => {


        if (i > 0 && i % 2 == 0 && !firstSet) {
          acc += " ";
          firstSet = true;
        }//esta condición es la que hace el truco, tiene que ser divisible por 4 y mayor que cero, esto último para no agregar un espacio al inicio ya que cero es 0/4 es cero

        if (i > 0 && i % 2 == 0 && !secondSet) {
          acc += " ";
          secondSet = true;
        }

        if (i > 0 && i % 4 == 0 && !thirdSet) {
          acc += " ";
          thirdSet = true;
        }

        if (i > 0 && i % 7 == 0 && !thirdSet) {
          acc += " ";
          fourthSet = true;
        }

        acc += t;
        return acc;
      });
    }

  }




  const handleInputChange = (target, index) => {

    let currentArray = mercancias[index];

    let valueInput = target.value;
    if (target.name == "sectorCofepris") {
      currentArray.denominacionGenProd = "";
      currentArray.denominacionDistProd = "";
      currentArray.fabricante = "";
      currentArray.fechaCaducidad = "";
      currentArray.loteMed = "";
      currentArray.formaFarmaceutica = "";
      currentArray.condTransporte = "";
      currentArray.registroSanitario = "";
      currentArray.ingActivo = "";
      currentArray.nomQuimico = "";
      currentArray.numCAS = "";
      currentArray.regSanPlag = "";
      currentArray.datosFabricante = "";
      currentArray.datosFormulador = "";
      currentArray.datosMaquilador = "";
      currentArray.usoAutorizado = "";
      currentArray.razonSocialEmpImp = "";
      if (valueInput == "01" || valueInput == "02" || valueInput == "03" && formaFarmaceutica.length <= 0) {
        fetchFormaFarmaceutica();
        fetchCondicionesEspeciales();
      }
    }


    let newObj = {
      ...currentArray,
      [target.name]: valueInput
    };

    let mercanciasArray = [...mercancias];
    mercanciasArray[index] = newObj;
    setMercancias([...mercanciasArray]);
  }

  const handleEntradaSalida = ({ target }) => {

    let valueInput = target.value;

    let currentArray = mercancias.map((element) => {
      element.entradaSalida = valueInput;
      return element;
    });

    setEntradaSalida(valueInput);

    setMercancias([...currentArray]);
  }

  const fetchEmbalajeData = async () => {

    const response = await fetch(`${API_URL}/api/catalagos/embalaje`);
    const data = await response.json();
    setEmbalajeData(data.response);
  }

  const handleMaterialPeligroso = (isChecked, index) => {
    // mercancias[1].matPeligroso = 
    let valueChecked = isChecked ? "Sí" : "No";
    let currentArray = mercancias[index];
    if (embalajeData === "") {
      fetchEmbalajeData();
    }
    if (isChecked) {
      notifyWarning("Material peligroso ha sido selecionado y los transportes han sido actualizados, revisa nuevamente");
    }

    setVehicle("");
    let newObj = {
      ...currentArray,
      matPeligroso: valueChecked
    };

    let mercanciasArray = [...mercancias];
    mercanciasArray[index] = newObj;
    setMatPeligroso(valueChecked);
    setMercancias([...mercanciasArray]);
  }

  const handleTranspInt = (isChecked) => {
    // mercancias[1].matPeligroso = 
    let valueChecked = isChecked ? "Sí" : "No";

    let currentArray = mercancias.map((element) => {
      element.transpInt = valueChecked
      return element;
    });

    setTranspInt(valueChecked);

    setMercancias([...currentArray]);
  }


  const handleDeleteMercancia = (index) => {
    let merchArray = [...mercancias];
    let merchArraySlice = merchArray.splice(index, 1);
    setMercancias([...merchArray]);
  }

  const handleOnDeleteValue = (inputName, value) => {
    if (inputName === "waypoint") {
      let currentWaypoints = [...waypointValue];

      // let newWaypoints = waypoints.filter((el)=> el !== value);
      let indexFound = currentWaypoints.findIndex((el) => el === value);
      let spliced = currentWaypoints.splice(indexFound, 1);

      setWaypointValue([...currentWaypoints]);
    }
  }
  const showPdfFinalizacion = (options) => {

    if (options.estatus != "Viaje en curso" && options.documentoFinalizado) {
      return (
        <React.Fragment>
          <Button
            className="pi pi-file-pdf btn__pi"
            rounded
            outlined
            severity="primary"
            placeholder="Mostrar archivo"
            onClick={(e) => {
              if (options.estatus != "Viaje en curso" && options.documentoFinalizado) {
                showPdfComprobante(options.documentoFinalizado);
              }
              // window.open(`${API_URL}${options.docs[0].path}`);
            }}
            style={{ color: "#ad0b00" }}
          />
        </React.Fragment>
      );
    }
  }

  return (
    <div>
      <div className="content-wrapper container__block" style={{ paddingBottom: "1.4rem" }}>
        <PageInfo heading={"DATT - Tracking | Asignar Viajes"} />

        <section className="">
          <div className="container-fluid">
            <Tabs.Root className="TabsRoot" defaultValue="tab1">
              <Tabs.List className="TabsList" aria-label="Registra un chofer">
                <Tabs.Trigger className="TabsTrigger" value="tab1">
                  Registrar Viaje
                </Tabs.Trigger>
                <Tabs.Trigger className="TabsTrigger" value="tab2">
                  Ver Viajes
                </Tabs.Trigger>
              </Tabs.List>
              <Tabs.Content
                className="TabsContent"
                value="tab1"
                style={{
                  display: "flex", justifyContent: "center"
                }}
              >
                <div
                  className="container__width_70"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px",
                    alignItems: "center",
                    backgroundColor: "white",

                  }}
                >
                  <div style={{ flex: 1 }}>
                    {/* Route asigned by admin */}

                    <Form.Root
                      className="FormRoot"
                      style={{ margin: "auto" }}
                      onSubmit={routeAssignment}
                      id="formRoute"
                      encType="multipart/form-data"
                    >

                      <div className="data__group">
                        <h4>Datos del Viaje</h4>
                        <div className="data__group_form" style={{ marginTop: "1rem" }}>

                          {
                            permisos && permisos.length > 1 ? (
                              <div className="form__group_form-select">
                                <div className="form__group form__group_selectField">
                                  <label className="FormLabel" style={{ margin: "0" }}>Empresa</label>
                                  <select
                                    className="SelectTrigger"
                                    required
                                    style={{ width: "100%", margin: "1rem 0" }}
                                    name="empresa"
                                    value={empresa}
                                    onChange={async (e) => {
                                      setEmpresa(e.target.value);
                                      if(e.target.value !== ""){
                                        let transports = await fetchTransportsByCompany(e.target.value);
                                        setTranportsByCompany(transports);
  
                                        let drivers = await fetchDriversByCompany(e.target.value);
                                        setDriversData(drivers);
  
                                        let customers = await fetchCustomersByCompany(e.target.value);
                                        setCustomersByCompany(customers);
                                        
                                      }else{
                                        setRemolqueRequerido(false);
                                        setContainer("");
                                        setTipoRemolque("");
                                        setTranportsByCompany([]);
                                        setDriversData([]);  
                                        setCustomersByCompany([]);
                                        setRemolquesByCompany([]);
                                      }

                                    }}
                                  >
                                    <option value="">Selecciona una Empresa</option>
                                    {
                                      permisos.map((permiso, index) => (
                                        <option key={index} value={permiso} >{permiso}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                            ) : ""

                          }


                          <div className="form__group_form-select">
                            <div className="form__group form__group_selectField">
                              <label className="FormLabel" style={{ margin: "0" }}>Operador</label>
                              <select
                                className="SelectTrigger"
                                required
                                style={{ width: "100%", margin: "1rem 0" }}
                                name="username"
                                value={selectedUser}
                                onChange={(e) => setSelectedUser(e.target.value)}
                              >
                                <option value="">Selecciona un Operador</option>
                                {
                                  driversData && driversData.length > 0 ?
                                    driversData.map((driver) => (
                                      <option key={driver._id} value={driver._id} >{driver.nombreAlternativo}</option>
                                    )) : (<option disabled>No hay operadores registrados</option>)
                                }
                              </select>
                            </div>

                            <div className="form__group form__group_selectField">
                              <label className="FormLabel" style={{ margin: "0" }}>Unidad de Transporte</label>
                              <select
                                className="SelectTrigger"
                                required
                                style={{ width: "100%", margin: "1rem 0" }}
                                name="vehicle"
                                value={vehicle}
                                onChange={async(e) => {
                                  setVehicle(e.target.value);
                                  let transport = transportsByCompany.find((trans) => trans._id === e.target.value);
                                  setRemolqueRequerido(transport.remolqueRequerido);
                                  console.log("Remolque requerido", transport);

                                  if(transport.remolqueRequerido){
                                    let remolques = await fetchRemolquesByCompany(empresa);
                                    setRemolquesByCompany(remolques);
                                  }
                                }}
                              >
                                <option value="">Selecciona una Unidad</option>
                                {
                                   transportsByCompany && transportsByCompany.length > 0 ?

                                    transportsByCompany.map((transport) => {
                                      if (matPeligroso === "Sí") {

                                        if (transport.medAmbiente) {
                                          return (
                                            <option key={transport._id} value={transport._id} >{transport.numUnidad}</option>
                                          );
                                        }
                                      } else {
                                        return (
                                          <option key={transport._id} value={transport._id} >{transport.numUnidad}</option>
                                        )
                                      }
                                    }
                                    ) : (<option disabled>No hay unidades registradas</option>)
                                }
                              </select>
                            </div>
                          </div>





                          {/* Select route */}
                          <Form.Field className="FormField" name="origin">
                            <div
                            >
                              <Form.Field
                                className="FormField"
                                name="origin"
                                onChange={(e) => setOrigin(e.target.value)}
                              >
                                <Form.Label style={{ margin: 0, marginBottom: ".5rem" }} className="FormLabel">
                                  Ingresa la ubicación de origen*
                                </Form.Label>

                                <AutocompleteJS
                                  onValueChange={handleAutocompleteValueChange}
                                  onDeleteValue={handleOnDeleteValue}
                                />
                              </Form.Field>
                            </div>

                            <Form.Field
                              className="FormField boxSizing__bb"
                              name="destination"
                              onChange={(e) => setDestination(e.target.value)}
                            ></Form.Field>

                            <Dialog.Root className="Dialog">
                              <Dialog.Trigger asChild>
                                <button
                                  className="Button btn__form"
                                  onClick={obtenerRutas}
                                >
                                  Escoger ruta
                                </button>
                              </Dialog.Trigger>

                              <Dialog.Portal>
                                <Dialog.Overlay className="DialogOverlay" />

                                <Dialog.Content className="DialogContentFactura">
                                  <Dialog.Title className="DialogTitle">
                                    Rutas disponibles
                                  </Dialog.Title>
                                  <Dialog.Description className="DialogDescription">
                                    Selecciona una ruta.
                                  </Dialog.Description>
                                  {/* Show Routes and choose 1 */}
                                  <div>
                                    {tollsCosts ? (
                                      <div>
                                        {/* Tabla de resultados */}
                                        <table>
                                          <thead>
                                            <tr>
                                              <th>Ruta</th>
                                              <th>Distancia (km)</th>
                                              <th>Duración (horas)</th>
                                              <th>Costo de Diesel</th>
                                              <th>Costo de Peajes</th>
                                              <th>Costo Total</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {tollsCosts?.routes && tollsCosts.routes.map((route, index) => (
                                              <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                  {(
                                                    route.summary.distance.value /
                                                    1000 +
                                                    150
                                                  ).toFixed(2)}
                                                </td>
                                                <td>
                                                  {(route.summary.duration.value /
                                                    3600 >
                                                    6
                                                    ? route.summary.duration.value /
                                                    3600 +
                                                    8
                                                    : route.summary.duration.value /
                                                    3600
                                                  ).toFixed(2)}
                                                </td>

                                                <td>
                                                  ${" "}
                                                  {(
                                                    ((route.summary.distance.value /
                                                      1000 +
                                                      150) /
                                                      2) *
                                                    24.16
                                                  )
                                                    .toFixed(2)
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )}
                                                </td>
                                                <td>
                                                  ${" "}
                                                  {calculateTotalCashCost(route.tolls)
                                                    .toFixed(2)
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )}
                                                </td>
                                                <td>
                                                  ${" "}
                                                  {(
                                                    (route.summary.distance.value /
                                                      1000 /
                                                      2) *
                                                    24.16 +
                                                    calculateTotalCashCost(route.tolls)
                                                  )
                                                    .toFixed(2)
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )}
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>

                                        {/* Selector para elegir ruta */}
                                        <div>
                                          <label>Seleccionar ruta:</label>
                                          <select
                                            value={selectedRoute}
                                            onChange={(e) =>
                                              setSelectedRoute(Number(e.target.value))
                                            }
                                            className="Input"
                                            style={{
                                              width: "auto",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            {tollsCosts?.routes ? tollsCosts.routes.map((_, index) => (
                                              <option key={index} value={index}>
                                                Ruta {index + 1}
                                              </option>
                                            )) : (
                                              <option value="">
                                                No hay rutas
                                              </option>
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                    ) : (
                                      <p>Cargando resultados...</p>
                                    )}
                                  </div>

                                  <Dialog.Close asChild>
                                    <button
                                      className="Button green"
                                      aria-label="Close"
                                      onClick={saveRouteData}
                                      disabled={isButtonDisabled}
                                      id="btnguardarruta"
                                    >
                                      Guardar ruta
                                    </button>
                                  </Dialog.Close>
                                  <Dialog.Close asChild>
                                    <button className="IconButton" aria-label="Close">
                                      <Cross2Icon />
                                    </button>
                                  </Dialog.Close>
                                </Dialog.Content>
                              </Dialog.Portal>
                            </Dialog.Root>
                          </Form.Field>


                          <div className="form__group_form-select">

                            {/* Estimated time to arrive */}
                            <Form.Field
                              className="FormField form__group_selectField"
                              name="time"
                              onChange={(e) => setDuration(e.target.value)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Form.Label style={{ margin: 0, marginBottom: ".5rem" }} className="FormLabel">
                                  Tiempo estimado de viaje :
                                </Form.Label>
                              </div>
                              <Form.Control asChild>
                                <input
                                  className="Input boxSizing__bb"
                                  type="text"
                                  placeholder="0:00hrs"
                                  disabled
                                  value={`${duration} hrs`}
                                  id="inputDuration"
                                />
                              </Form.Control>
                            </Form.Field>

                            {/* Estimated distance between */}
                            <Form.Field
                              className="FormField form__group_selectField"
                              name="distance"
                              onChange={(e) => setDistance(e.target.value)}
                            >

                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                  justifyContent: "space-between",
                                }}
                              >

                                <Form.Label style={{ margin: 0, marginBottom: ".5rem" }} className="FormLabel">
                                  Distancia total:
                                </Form.Label>
                              </div>
                              <Form.Control asChild>
                                <input
                                  className="Input boxSizing__bb"
                                  type="text"
                                  disabled
                                  placeholder="0km"
                                  value={`${distance} Km`}
                                  id="inputDistance"
                                />
                              </Form.Control>
                            </Form.Field>
                          </div>

                          <div className="form__group_form-select">
                            <Form.Field
                              className="FormField form__group_selectField box"
                              name="movement"
                              onChange={(e) => {
                                setMovement(e.target.value);
                                // if (e.target.value == "Exportacion") {
                                //   setPrecioVentaFinal("");
                                // } else {
                                //   setPrecioVentaFinal(precioVenta)
                                // }
                              }}
                            >
                              <Select.Root size="3">
                                <Form.Label style={{ margin: 0, marginBottom: ".5rem" }} className="FormLabel">
                                  Tipo de movimiento: *
                                </Form.Label>
                                <br></br>
                                <Select.Trigger
                                  className="SelectTrigger boxSizing__bb"

                                  style={{ width: "100%" }}
                                  aria-label="Food"
                                  required
                                >
                                  <Select.Value placeholder="Tipo de movimiento" />
                                  <Select.Icon className="SelectIcon">
                                    <ChevronDownIcon />
                                  </Select.Icon>
                                </Select.Trigger>
                                <Select.Portal>
                                  <Select.Content className="SelectContent">
                                    <Select.ScrollUpButton className="SelectScrollButton">
                                      <ChevronUpIcon />
                                    </Select.ScrollUpButton>
                                    <Select.Viewport className="SelectViewport">
                                      <Select.Group>
                                        <SelectItem value="Importacion">
                                          Importación
                                        </SelectItem>
                                        <SelectItem value="Exportacion">
                                          Exportación
                                        </SelectItem>
                                      </Select.Group>
                                    </Select.Viewport>
                                    <Select.ScrollDownButton className="SelectScrollButton">
                                      <ChevronDownIcon />
                                    </Select.ScrollDownButton>
                                  </Select.Content>
                                </Select.Portal>
                              </Select.Root>
                            </Form.Field>

                          </div>
                          {
                            transportsByCompany && remolqueRequerido ? (
                              <>
                                <div className="form__group_form-select" style={{ marginTop: "1rem" }}>
                                  <div className="form__group form__group_selectField">
                                    <label className="FormLabel" style={{ margin: "1rem 0" }}>Remolque</label>
                                    <select
                                      className="SelectTrigger"
                                      required
                                      // value={customerValue}
                                      style={{ width: "100%" }}
                                      onChange={(e)=>{
                                        console.log("e",e);
                                        let remolque = remolquesByCompany.find((remolque)=>remolque._id === e.target.value);
                                        setContainer(remolque.placaRemolque);
                                        setTipoRemolque(remolque.subTipoRem)
                                      }}
                                    >
                                      <option value="">Selecciona un remolque</option>
                                      {
                                        remolquesByCompany && remolquesByCompany.length > 0  ?
                                          remolquesByCompany.map((remolque) => (
                                            <option key={remolque._id} value={remolque._id}>{remolque.placaRemolque}</option>
                                          )) : (<option>No hay remolques disponibles</option>)
                                      }
                                    </select>
                                  </div>
                                </div>

                                <div className="form__group_form-select" style={{ marginTop: "1rem" }}>
                                  <div className="form__group form__group_selectField">
                                    <Form.Field
                                      className="FormField"
                                      name="container"
                                      onChange={(e) => setContainer(e.target.value)}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                          justifyContent: "space-between",

                                        }}
                                      >
                                        <Form.Label style={{ margin: 0, marginBottom: ".5rem" }} className="FormLabel">
                                          Placa de la caja *
                                        </Form.Label>

                                      </div>
                                      <Form.Control asChild>
                                        <input
                                          className="Input boxSizing__bb"
                                          type="text"
                                          required
                                      value={container}

                                          placeholder="Placa de la caja"
                                        ></input>
                                      </Form.Control>
                                    </Form.Field>

                                  </div>

                                  <div className="form__group boxSizing__bb selectTriggerCustom form__group_selectField">
                                    <label className="FormLabel" style={{ margin: 0, marginBottom: ".5rem" }}>Tipo de Contenedor *</label>
                                    <br></br>
                                    <select
                                      className="SelectTrigger boxSizing__bb"
                                      required
                                      value={tipoRemolque}
                                      onChange={(e) => setTipoRemolque(e.target.value)}
                                      style={{ width: "100%", padding: "0 .5rem", margin: 0, marginTop: "9px", border: "none" }}
                                    >
                                      <option value="" style={{ textAlign: "center" }} disabled>
                                        Selecciona un contenedor
                                      </option>
                                      <option value="CTR001">CTR001 Caballete</option>
                                      <option value="CTR002">CTR002 Caja</option>
                                      <option value="CTR003">
                                        CTR003 Caja Abierta
                                      </option>
                                      <option value="CTR004">
                                        CTR004 Caja Cerrada
                                      </option>
                                      <option value="CTR005">
                                        CTR005 Caja De Recolección Con Cargador Frontal
                                      </option>
                                      <option value="CTR006">
                                        CTR006 Caja Refrigerada
                                      </option>
                                      <option value="CTR007">CTR007 Caja Seca</option>
                                      <option value="CTR008">
                                        CTR008 Caja Transferencia
                                      </option>
                                      <option value="CTR009">
                                        CTR009 Cama Baja o Cuello Ganso
                                      </option>
                                      <option value="CTR010">
                                        CTR010 Chasis Portacontenedor
                                      </option>
                                      <option value="CTR011">
                                        CTR011 Convencional De Chasis
                                      </option>
                                      <option value="CTR012">
                                        CTR012 Equipo Especial
                                      </option>
                                      <option value="CTR013">CTR013 Estacas</option>
                                      <option value="CTR014">
                                        CTR014 Góndola Madrina
                                      </option>
                                      <option value="CTR015">
                                        CTR015 Grúa Industrial
                                      </option>
                                      <option value="CTR016">CTR016 Grúa</option>
                                      <option value="CTR017">CTR017 Integral</option>
                                      <option value="CTR018">CTR018 Jaula</option>
                                      <option value="CTR019">
                                        CTR019 Media Redila
                                      </option>
                                      <option value="CTR020">
                                        CTR020 Pallet o Celdillas
                                      </option>
                                      <option value="CTR021">CTR021 Plataforma</option>
                                      <option value="CTR022">
                                        CTR022 Plataforma Con Grúa
                                      </option>
                                      <option value="CTR023">
                                        CTR023 Plataforma Encortinada
                                      </option>
                                      <option value="CTR024">CTR024 Redilas</option>
                                      <option value="CTR025">
                                        CTR025 Refrigerador
                                      </option>
                                      <option value="CTR026">CTR026 Revolvedora</option>
                                      <option value="CTR027">CTR027 Semicaja</option>
                                      <option value="CTR028">CTR028 Tanque</option>
                                      <option value="CTR029">CTR029 Tolva</option>
                                      <option value="CTR031">CTR031 Volteo</option>
                                      <option value="CTR032">
                                        CTR032 Volteo Desmontable
                                      </option>
                                    </select>
                                  </div>
                                </div>


                              </>

                            ) : ""


                          }



                          {/*Reference form field */}
                          <Form.Field
                            className="FormField"
                            name="reference"
                            onChange={(e) => setReference(e.target.value)}

                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                                justifyContent: "space-between",
                                marginTop: remolqueRequerido ? "0" : "1.3rem"
                              }}
                            >
                              <Form.Label style={{ margin: 0, marginBottom: ".5rem" }} className="FormLabel">Referencia</Form.Label>

                            </div>
                            <Form.Control asChild>
                              <input
                                className="Input boxSizing__bb"
                                type="text"
                                required
                                placeholder="Referencia"
                              ></input>
                            </Form.Control>
                          </Form.Field>

                          <div className="form__group_form-select">



                            {/*Customer form field */}
                            <Form.Field
                              className="FormField form__group_selectField"
                              name="customerFlete"
                              onChange={(e) => setCustomerFlete(e.target.value)}
                            >
                              <label style={{ margin: 0, marginBottom: ".5rem" }} className="FormLabel">
                                Cliente Flete *
                              </label>
                              <select
                                className="SelectTrigger boxSizing__bb"
                                aria-label="Food"
                                required
                                value={customerFlete}
                                onChange={(e) => setCustomerFlete(e.target.value)}
                                style={{ width: "100%", padding: "0 .5rem", margin: 0, marginTop: "9px" }}
                              >
                                <option value="">Selecciona un Cliente</option>

                                {customersByCompany.length > 0 && customersByCompany ?
                                  customersByCompany.map((customer) => (
                                    <option
                                      key={customer._id}
                                      value={customer._id}
                                    >
                                      {customer.razonSocial}
                                    </option>
                                  ))
                                  : (
                                    <option value="loading" disabled>
                                      No se encontraron clientes registrados
                                    </option>
                                  )}
                              </select>
                            </Form.Field>
                            {/*Customer factura form field */}
                            <Form.Field
                              className="FormField form__group_selectField"
                              name="customerFactura"
                              onChange={(e) => setCustomerFactura(e.target.value)}
                            >
                              <label style={{ margin: 0, marginBottom: ".5rem" }} className="FormLabel">
                                Cliente Factura *
                              </label>
                              <select
                                className="SelectTrigger boxSizing__bb"
                                aria-label="Food"
                                required
                                onChange={(e) => setCustomerFactura(e.target.value)}
                                style={{ width: "100%", padding: ".5rem", margin: 0, marginTop: "9px" }}
                              >
                                <option value="">Selecciona un Cliente</option>
                                {customersByCompany && customersByCompany.length > 0 ?
                                  customersByCompany.map((customer) => (
                                    <option
                                      key={customer._id}
                                      value={customer._id}
                                    >
                                      {customer.razonSocial}
                                    </option>
                                  ))
                                  : (
                                    <option value="loading" disabled>
                                      No se encontraron clientes registrados
                                    </option>
                                  )}
                              </select>
                            </Form.Field>
                          </div>


                          <div className="form__group_form-select">
                            {/* Vehicle form field */}
                            {/* <Form.Field
                              className="FormField form__group_selectField"
                              name="vehicle"
                              onChange={(e) => setVehicle(e.target.value)}
                              value={vehicle}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Form.Label style={{ margin: 0, marginBottom: ".5rem" }} className="FormLabel">
                                  Número de unidad *
                                </Form.Label>
                                <Form.Message
                                  className="FormMessage"
                                  match="valueMissing"
                                >
                                  Ingresa una unidad
                                </Form.Message>
                              </div>
                              <Form.Control asChild>
                                <input
                                  className="Input boxSizing__bb"
                                  type="text"
                                  required
                                  placeholder="Unidad"
                                  value={vehicle}
                                ></input>
                              </Form.Control>
                            </Form.Field> */}

                            {/* Sell cost */}
                            <Form.Field className="FormField form__group_selectField" name="distance">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Form.Label style={{ margin: 0, marginBottom: ".5rem" }} className="FormLabel">
                                  Precio de venta:
                                </Form.Label>
                              </div>
                              <Form.Control asChild>
                                <input
                                  className="Input boxSizing__bb"
                                  type="number"
                                  min={0}

                                  placeholder="Pesos mexicanos"
                                  onChange={(e) => {
                                    setPrecioVentaFinal(e.target.value);

                                    // if (movement == "Exportacion") {
                                    //   setPrecioVentaFinal(e.target.value)
                                    //   setUtilidad(e.target.value - costoTotal)
                                    // } else {
                                    //   setPrecioVentaFinal(precioVenta);
                                    // }
                                  }}
                                />
                              </Form.Control>
                            </Form.Field>


                            {/*PDF form field */}
                            <Form.Field
                              className="FormField  boxSizing__bb form__group_selectField"
                              name="cartaConfirmacion"
                              onChange={(e) => setConfirmacion(e.target.files)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Form.Label style={{ margin: 0, marginBottom: ".5rem" }} className="FormLabel">
                                  Documentos de inicio *
                                </Form.Label>
                                {/* <Form.Message
                                  className="FormMessage"
                                  match="valueMissing"
                                >
                                  Selecciona uno o más archivos
                                </Form.Message> */}
                              </div>
                              <Form.Control asChild>
                                <input
                                  className="Input boxSizing__bb"
                                  type="file"
                                  required
                                  multiple
                                  name="confirmacion"
                                ></input>
                              </Form.Control>
                            </Form.Field>
                          </div>
                          <div className="form__group_form-select">




                            {/*Viaje inicio form field */}
                            <Form.Field
                              className="FormField  boxSizing__bb form__group_selectField"
                              name="inicio"
                              onChange={(e) =>
                                setInicio(e.target.value.replace("T", " "))
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Form.Label style={{ margin: 0, marginBottom: ".5rem" }} className="FormLabel">
                                  Fecha de inicio *
                                </Form.Label>

                              </div>
                              <Form.Control asChild>
                                <input
                                  className="Input boxSizing__bb"
                                  type="datetime-local"
                                  required
                                  placeholder=""
                                  style={{ color: "black" }}
                                ></input>
                              </Form.Control>
                            </Form.Field>

                          </div>

                          {/*Save merchancy*/}
                          <Dialog.Root className="Dialog" open={modalAbierto} onClose={() => setModalAbierto(false)}>
                            <Dialog.Trigger asChild>
                              <button
                                className="Button btn__form"
                                style={{ marginTop: "1rem" }}
                                onClick={() => {

                                  if (arrMercancias.length > 0) {
                                    setMercancias(arrMercancias);
                                  }
                                  setModalAbierto(true);
                                }}
                              >
                                Mercancías a transportar
                              </button>

                            </Dialog.Trigger>

                            <Dialog.Portal>
                              <Dialog.Overlay className="DialogOverlay" />
                              <Dialog.Content className="DialogContentCP" style={{ backgroundColor: "white" }}>
                                <Dialog.Title className="DialogTitle">
                                  Mercancías
                                </Dialog.Title>
                                <Dialog.Description className="DialogDescription">
                                  Ingresa la información de las mercancias a transportar.<br></br>
                                  Los campos con * son los obligatorios para transportación nacional.
                                  <br></br>
                                  <a
                                    href="http://pys.sat.gob.mx/PyS/catPyS.aspx"
                                    target="_blank"
                                  >
                                    Click aquí para ver las Claves de Productos y
                                    Servicios del SAT
                                  </a>
                                  <br></br>

                                  Para conocer las claves de material peligroso y claves de unidad, ve el <br></br>
                                  <a
                                    href="http://omawww.sat.gob.mx/tramitesyservicios/Paginas/documentos/CatalogosCartaPorte30.xls"
                                    target="_blank"
                                  >
                                    Catálago de Carta Porte 3.0
                                  </a>

                                </Dialog.Description>
                                <hr></hr>
                                <div>
                                  <h3>Mercancias</h3>
                                  {/* <a
                                    href="https://www.sat.gob.mx/cs/Satellite?blobcol=urldata&blobkey=id&blobtable=MungoBlobs&blobwhere=1461173691660&ssbinary=true"
                                    target="_blank"
                                  >
                                    Catálogo de Fracciones Arancelarias SAT
                                  </a> */}
                                  <div className="catalagosSAT">
                                    <a
                                      href="http://omawww.sat.gob.mx/tramitesyservicios/Paginas/documentos/c_FraccionArancelaria_20231219.xls"
                                      target="_blank"
                                    >
                                      Catálogo de Fracciones Arancelarias SAT
                                    </a>
                                  </div>


                                  <div className="cartaPorte__group_elements_checkbox_parent"
                                  >
                                    <div className="cartaPorte__group_elements_checkbox">
                                      <Checkbox.Root
                                        className="CheckboxRoot"
                                        onCheckedChange={handleTranspInt}
                                        value={transpInt}
                                      >
                                        <Checkbox.Indicator className="CheckboxIndicator">
                                          <CheckIcon />
                                        </Checkbox.Indicator>
                                      </Checkbox.Root>

                                      <label className="FormLabel" htmlFor="c1">
                                        Transportación Internacional
                                      </label>
                                    </div>

                                    <div className="cartaPorte__group_elements_checkbox">

                                      <Checkbox.Root
                                        className="CheckboxRoot"
                                        onCheckedChange={(isChecked) => { setLogInversa(isChecked ? "Sí" : "No") }}

                                        checked={logInversa === "Sí" ? true : false}
                                      >
                                        <Checkbox.Indicator className="CheckboxIndicator">
                                          <CheckIcon />
                                        </Checkbox.Indicator>
                                      </Checkbox.Root>
                                      <label className="FormLabel" htmlFor="c1">
                                        Logística Inversa Recolección Devolución
                                      </label>
                                    </div>
                                  </div>


                                  {
                                    transpInt === "Sí" ? (
                                      <div className="cartaPorte__group_elements" style={{
                                        marginBottom: "2.3rem"
                                      }}>
                                        <label className="FormLabel" htmlFor="name">
                                          Entrada/Salida de Mercancía
                                        </label>
                                        <select
                                          className="SelectTrigger"
                                          onChange={handleEntradaSalida}
                                          value={entradaSalida}
                                        >
                                          <option value="" disabled>
                                            Escoge una opción
                                          </option>
                                          <option value="Entrada">Entrada</option>
                                          <option value="Salida">Salida</option>
                                        </select>
                                      </div>
                                    ) : ""
                                  }


                                  <div>


                                    {/* Renderiza las mercancias existentes */}
                                    {mercancias.map((mercancia, index) => (

                                      <div key={index}>
                                        {index > 0 ? (<hr></hr>) : ""}

                                        <h4 style={{ display: "flex", justifyContent: "space-between" }}> Mercancía {index + 1} {
                                          index > 0 ? (<FaMinus onClick={(e) => handleDeleteMercancia(index)} className="deleteMercancia" style={{ width: "15px" }} />) : ""
                                        }</h4>

                                        <Mercancias
                                          index={index}
                                          mercancia={mercancia}
                                          handleInputChange={handleInputChange}
                                          handleMaterialPeligroso={handleMaterialPeligroso}
                                          isTransInt={transpInt}
                                          entradaSalida={entradaSalida}
                                          formaFarmaceutica={formaFarmaceutica}
                                          condicionesEspeciales={condicionesEspeciales}
                                          embalajeData={embalajeData}
                                        >
                                        </Mercancias>
                                      </div>
                                    ))}

                                    {/* Botón para agregar más mercancías */}
                                    <button style={{ margin: ".6rem 0" }} className="Button " onClick={handleAddMerch}>
                                      Agregar mercancía
                                    </button>

                                  </div>
                                </div>
                                <hr></hr>
                                <div>

                                </div>
                                <button
                                  className="Button green"
                                  onClick={validateMercancias}
                                  style={{ margin: 0, padding: ".6rem 0", width: "200px", textAlign: "center" }}
                                >
                                  Guardar mercancias
                                </button>

                                <Dialog.Close asChild>
                                  <button className="IconButton" aria-label="Close" onClick={() => setModalAbierto(false)}>
                                    <Cross2Icon />
                                  </button>
                                </Dialog.Close>
                              </Dialog.Content>
                            </Dialog.Portal>
                          </Dialog.Root>
                          <hr></hr>
                          <h5 style={{ marginTop: "15px" }}>
                            {arrMercancias && (arrMercancias?.bienesTransp || arrMercancias?.cantidad || arrMercancias.length > 0) ? 'Mercancías cargadas' : 'No hay mercancías cargadas'}
                          </h5>

                          <ul style={{ paddingLeft: "0" }}>

                            {/* Mostrar las mercancías restantes si existen */}
                            {arrMercancias.length > 0 && (
                              arrMercancias.map((mercancia, index) => (
                                <div style={{ marginBottom: "1rem" }}>
                                  <label style={{ marginBottom: ".5rem" }}>Mercancia {index + 1}</label>
                                  <div className="mercancia__group" >
                                    <div className="mercancia__element">
                                      <label>Bienes Transportados</label>
                                      <span>{mercancia.bienesTransp}</span>
                                    </div>

                                    <div className="mercancia__element">
                                      <label>Descripcion</label>
                                      <span>{mercancia.descripcion}</span>
                                    </div>

                                    <div className="mercancia__element">
                                      <label>Cantidad</label>
                                      <span>{mercancia.cantidad}</span>
                                    </div>

                                    <div className="mercancia__element">
                                      <label>Peso</label>
                                      <span>{mercancia.pesoMateria}Kg</span>
                                    </div>


                                  </div>

                                  {/* <li key={index}>
                                    <strong>Mercancia {index + 1}:</strong><br></br>
                                    <strong>Bienes:</strong> {mercancia.bienesTransp} <br></br>
                                    <strong>Cantidad:</strong> {mercancia.cantidad}
                                  </li> */}
                                </div>
                              ))
                            )}

                            {/* Mostrar mensaje si no hay mercancías */}
                            {!arrMercancias && (
                              <p>No hay mercancías</p>
                            )}
                          </ul>
                          {/*Routes asigned btn*/}

                          <div className="btn__container">
                            <Form.Submit asChild onClick={routeAssignment}>
                              <button className="btn__login" style={{ marginTop: 20, width: "230px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {
                                  isLoading ? (
                                    <SyncLoader
                                      color="white"
                                      loading={isLoading}
                                      size={20}
                                      aria-label="Loading Spinner"
                                      data-testid="loader"
                                      speedMultiplier={.7}
                                    />

                                  ) : "Asignar viaje"
                                }

                              </button>
                            </Form.Submit>
                          </div>
                        </div>


                      </div>

                    </Form.Root>
                  </div>
                </div>
              </Tabs.Content>
              <Tabs.Content
                className="TabsContent"
                value="tab2"
                style={{
                  display: "flex", justifyContent: "center", flexDirection: "column"
                }}>
                <section className=''>
                  <div className='cartaPorte__group'>
                    {/* <div className="cartaPorte__group_elements">
                      <label className='FormLabel'>Id de Factura</label>
                      <input
                        className='InputForm'
                        name="idFactura"
                        value={filterRoutes.idFactura}
                        onChange={handleChangeFilter}
                      >

                      </input>
                    </div> */}
                    {
                      permisos && permisos.length > 1 ?
                        (
                          <div className="cartaPorte__group_elements">
                            <label className="FormLabel">Empresa</label>
                            <select
                              className="SelectTrigger"
                              required
                              name='empresa'
                              placeholder="Empresa"
                              style={{ width: "100%" }}
                              onChange={handleChangeFilter}
                              value={filterRoutes.empresa}
                            >
                              <option value="">Selecciona un Empresa</option>
                              {
                                permisos.map((permiso, index) => (
                                  <option key={index} value={permiso}>{permiso}</option>
                                ))
                              }
                            </select>
                          </div>
                        ) : ""
                    }

                    {
                      filterRoutes.empresa !== "" || permisos.length <= 1 ? (
                        <>
                          <div className="cartaPorte__group_elements">
                            <label className='FormLabel'>Cliente</label>
                            <select
                              className='SelectTrigger'
                              name="clienteFactura"
                              onChange={handleChangeFilter}
                              value={filterRoutes.clienteFactura}
                            >
                              <option value="">Selecciona un cliente</option>
                              {
                                customersByCompany && customersByCompany.length > 0 ?
                                  customersByCompany.map((customer) => (
                                    <option key={customer._id} value={customer._id} >{customer.razonSocial}</option>
                                  )) : (<option disabled> No hay clientes registrados</option>)
                              }
                            </select>
                          </div>
                          <div className="cartaPorte__group_elements">
                            <label className='FormLabel'>Operador</label>
                            <select
                              className="SelectTrigger"
                              name="usuario"
                              value={filterRoutes.usuario}
                              onChange={handleChangeFilter}
                            >
                              <option value="">Selecciona un operador</option>
                              {
                                driversData && driversData.length > 0 ?
                                  driversData.map((driver) => (
                                    <option key={driver._id} value={driver._id}>{driver.nombreAlternativo}</option>
                                  )) : (<option disabled>No hay operadores registrados</option>)
                              }
                            </select>
                          </div>

                          <div className="cartaPorte__group_elements">
                            <label className='FormLabel'>Unidad</label>
                            <select
                              className="SelectTrigger"

                              name="vehiculo"
                              value={filterRoutes.vehiculo}
                              onChange={handleChangeFilter}
                            >
                              <option value="">Selecciona una unidad</option>
                              {
                                transportsByCompany && transportsByCompany.length > 0 ?
                                  transportsByCompany.map((transport) => (
                                    <option key={transport._id} value={transport._id}>{transport.numUnidad}</option>
                                  )) : (<option disabled>No hay unidades registradas</option>)
                              }
                            </select>
                          </div>
                        </>
                      ) : ""
                    }

                    <div className="cartaPorte__group_elements">
                      <label className='FormLabel'>Estatus</label>
                      <select
                        className='SelectTrigger'
                        name="estatus"
                        onChange={handleChangeFilter}
                        value={filterRoutes.estatus}
                      >
                        <option value="">Selecciona un Estatus</option>
                        {
                          status && status.length > 0 ?
                            status.map((status, index) => (
                              <option key={index} value={status}>{status}</option>
                            )) : ""
                        }

                      </select>
                    </div>



                    <div className="cartaPorte__group_elements">
                      <label className='FormLabel'>Fecha de Inicio de Viaje</label>
                      <input
                        name="fechaInicio"
                        type="date"
                        onChange={handleChangeFilter}
                        value={filterRoutes.fechaInicio}
                      >
                      </input>
                    </div>



                    <div className="cartaPorte__group_elements" style={{ justifyContent: "flex-end" }} >
                      <div className='btn__container_search'>
                        <button
                          type="submit"
                          disabled={isLoadingSearch}
                          className="btn__login"
                          style={{ marginTop: 10, width: "240px", display: "flex", justifyContent: "center", alignItems: "center" }}
                          onClick={fetchRoutesRData}
                        >
                          {
                            isLoadingSearch ? (
                              <SyncLoader
                                color="white"
                                loading={isLoadingSearch}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                speedMultiplier={.7}
                              />

                            ) : "Buscar Viajes"
                            // <FaSearch />
                          }

                        </button>
                      </div>

                    </div>


                  </div>

                </section>
                <Button
                  type="button"
                  icon="pi pi-file-excel"
                  severity="success"
                  rounded
                  onClick={exportExcel}
                  data-pr-tooltip="XLS"
                  style={{ fontSize: "1.5rem", color: "green", textAlign: "right" }}
                />
                <div className="card">
                  <DataTable
                    value={route}
                    paginator
                    header={renderHeader}
                    filters={filters}
                    ref={dt}
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: "50rem" }}
                    editMode="row"
                    dataKey="_id"
                    onRowEditComplete={onRowEditComplete}
                  >
                    <Column field="_id" header="ID" hidden />
                    <Column
                      field="empresa"
                      header="Empresa"
                      sortable
                      style={{ width: "25%" }}
                    />

                    <Column
                      field="usuario"
                      header="Usuario"
                      sortable
                      style={{ width: "25%" }}
                      editor={(options) => statusEditor(options)}
                      columnProps={{ username: username }}
                    />
                    <Column
                      field="origen"
                      header="Origen"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="intermedio"
                      header="Puntos Intermedios"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="destino"
                      header="Destino"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="distancia"
                      header="Distancia (km)"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="referencia"
                      header="Referencia"
                      sortable
                      style={{ width: "25%" }}
                      editor={(options) => textEditor(options)}
                    />
                    <Column
                      field="duracion"
                      header="Duración (horas)"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="clienteFactura"
                      header="Cliente Factura"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="clienteFlete"
                      header="Cliente Flete"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="vehiculo"
                      header="Vehículo"
                      sortable
                      style={{ width: "25%" }}
                      editor={(options) => selectVehiculoEditor(options)}
                    />
                    <Column
                      field="fechaAsignado"
                      header="Fecha asignado"
                      sortable
                      style={{ width: "25%" }}
                      editor={(options) => dateEditor(options)}
                    />
                    <Column
                      field="fechaInicio"
                      header="Fecha Inicio"
                      sortable
                      style={{ width: "25%" }}
                      editor={(options) => dateEditor(options)}
                    />
                    <Column
                      header="Documentos de inicio"
                      body={actionBodyTemplate}
                      exportable={false}
                      style={{ minWidth: "12rem" }}
                    />
                    <Column
                      field="hojaFinalizacion"
                      header="Hoja de finalización"
                      body={showPdfFinalizacion}
                    // body={(rowData) => {
                    //   return (
                    //     <button
                    //       onClick={() => {
                    //         if (rowData.documentoFinalizado) {
                    //           showPdfComprobante(rowData.documentoFinalizado);
                    //         }
                    //       }}
                    //       className="ButtonPDF"
                    //       disabled={!rowData.documentoFinalizado}
                    //     >
                    //       {rowData.documentoFinalizado ? "Ver PDF" : "No hay archivo"}
                    //     </button>
                    //   )
                    // }}
                    />

                    <Column
                      header="Subir hoja finalización"
                      style={{ minWidth: "12rem", marginLeft: "5px" }}
                      body={actionBodyTemplate3}
                    />
                    <Column
                      field="estatus"
                      header="Estatus"
                      sortable
                      style={{ width: "25%" }}
                      body={(rowData) => (
                        <div
                          style={{
                            background: getStatusColor(rowData.estatus),
                            padding: "5px",
                            borderRadius: "5px",
                            color: "white",
                          }}
                        >
                          {rowData.estatus}
                        </div>
                      )}
                    />
                    {/*Edit btn */}
                    {/* <Column
                      rowEditor
                      headerStyle={{ width: "10%", minWidth: "8rem" }}
                      bodyStyle={{ textAlign: "center" }}
                    ></Column> */}

                    {/* <Column exportable={false} style={{ minWidth: "12rem" }}></Column> */}
                  </DataTable>
                  <Dialog.Root
                    open={isDialogOpen}
                    onOpenChange={() => setIsDialogOpen(false)}
                  >
                    <Dialog.Portal>
                      <Dialog.Overlay className="DialogOverlay" />
                      <Dialog.Content className="DialogContentFactura dialogContentViaje">
                        <Dialog.Title className="DialogTitle">
                          Documentos del Viaje
                          <IoCloseSharp onClick={(e) => setIsDialogOpen(false)} className="closeDialogIcon" style={{ position: "absolute", right: "20px", top: "10px" }} />

                        </Dialog.Title>
                        <Dialog.Description className="DialogDescription">
                          Abajo se muestran los documentos que se anexaron por
                          cliente.
                        </Dialog.Description>

                        {viajeToSave && (
                          <div style={{ margin: "1rem 0" }}>
                            {viajeToSave.documentos.map((documento, index) => {
                              let docType = getDocType(documento.originalname);
                              return (
                                <div className="docs__container__parent">
                                  <div className="docs__container" onClick={() => { window.open(`${API_URL}${documento.path}`); }}>
                                    {
                                      docType === "word" ? (<FaRegFileWord style={{ color: "#073b8d", fontSize: "1.3rem" }} />) : (<i className="pi pi-file-pdf btn__pi"></i>)
                                    }
                                    <p >
                                      {documento.originalname}
                                    </p>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        )}


                      </Dialog.Content>
                    </Dialog.Portal>
                  </Dialog.Root>
                  <Dialog.Root
                    open={isDialogOpen3}
                    onOpenChange={() => setIsDialogOpen3(false)}
                  >
                    <Dialog.Portal>
                      <Dialog.Overlay className="DialogOverlay" />
                      <Dialog.Content className="DialogContentFactura" style={{ width: "35rem", backgroundColor: "white" }}>
                        <Dialog.Title className="DialogTitle">
                          Subir hoja de finalización
                        </Dialog.Title>
                        <Dialog.Description className="DialogDescription">
                          Selecciona el archivo PDF de la hoja.
                        </Dialog.Description>

                        <form
                          id="formRoute"
                          encType="multipart/form-data"
                          onSubmit={(e) => handleSaveFinalizacion(e)}
                        >
                          <label className="FormLabel" style={{ margin: 0 }}>Selecciona el archivo</label>
                          <input
                            className="Input"
                            type="file"
                            required
                            accept=".pdf"
                            name="finalizacion"
                            onChange={(e) => setFinalizacion(e.target.files[0])}
                          />
                          <div style={{ marginTop: "1.3rem", gap: "20px", display: "flex", alignItems: "flex-end", flexDirection: "row-reverse", marginRight: "0" }}>
                            <input
                              className="AprobarButton"
                              type="submit"
                              value="Subir archivo"
                            />
                            <Dialog.Close asChild>
                              <Button aria-label="Close" className="EliminarButton"
                              >
                                Cancelar
                              </Button>
                            </Dialog.Close>
                          </div>
                        </form>
                      </Dialog.Content>
                    </Dialog.Portal>
                  </Dialog.Root>
                </div>
              </Tabs.Content>

            </Tabs.Root>
          </div>

        </section >
      </div >
    </div >
  );
}

export default AsignarRuta;
