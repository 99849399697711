/* global google */
import React, { useState, useEffect } from "react";
import "../../styles/label.css";
import * as Form from "@radix-ui/react-form";
import { toast } from "react-toastify";
import * as Select from "@radix-ui/react-select";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";

import { API_URL } from "../../constants";
import classnames from "classnames";
import { PageInfo } from "../PageInfo";

function Costos() {
  const [selectedUser, setSelectedUser] = useState("");
  const [usernamesData, setUsernamesData] = useState([]);
  const [username, setUsername] = useState("");
  const [nominaDia, setNominaDia] = useState("");
  const [camionDia, setCamionDia] = useState("");
  const [cajaDia, setCajaDia] = useState("");

  useEffect(() => {
    // FETCH drivers
    const fetchRoutesData = async () => {
      try {
        // FETCH driver usernames
        const usernamesResponse = await fetch(
          `${API_URL}/api/drivers-username`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const usernamesData = await usernamesResponse.json();
        const filteredUsernamesData = usernamesData.filter(
          (item) => item.company !== "Power-Only"
        );

        setUsernamesData(filteredUsernamesData);

        const usernames = filteredUsernamesData.map((item) => item.username);
        setUsername(usernames);
      } catch (error) {
        // Handle any fetch errors here
        console.error(error);
      }
    };

    fetchRoutesData();
  }, []);

  async function updateCosts(e) {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/api/costs-driver`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          selectedUser,
          nominaDia,
          camionDia,
          cajaDia,
        }),
      });
      const data = await response.json();
      if (data.status === "ok") {
        notifyRegisterSuccess();
        document.getElementById("formCosts").reset();
      }
    } catch (error) { }
  }

  const SelectItem = React.forwardRef(
    ({ children, className, ...props }, forwardedRef) => {
      return (
        <Select.Item
          className={classnames("SelectItem", className)}
          {...props}
          ref={forwardedRef}
        >
          <Select.ItemText>{children}</Select.ItemText>
          <Select.ItemIndicator className="SelectItemIndicator">
            <CheckIcon />
          </Select.ItemIndicator>
        </Select.Item>
      );
    }
  );

  const notifyRegisterSuccess = () => {
    toast.success("¡Los datos fueron registrados correctamente!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="">
          <div className="container-fluid container__block">

          <PageInfo heading={"DATT - Tracking | Costos por Operador"}/>

            <section className="content ">
              <div className="container-fluid">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",

                    padding: "20px",
                    alignItems: "center",
                    backgroundColor: "white",
                    border: "none",
                    borderRadius: "5px",
                    boxShadow: "0 2px 10px var(--black-a4)"

                  }}
                >
                  <div style={{display:"flex",justifyContent:"center", flex: 1 }}>
                    <div className="data__group FormRoot container__width_70" style={{display:"flex",justifyContent:"center"}} >
                      {/* Costs form */}
                      <h4>Costos</h4>
                      <div className="data__group_form">

                        <Form.Root
                          className=""
                          
                          onSubmit={updateCosts}
                          id="formCosts"
                        >
                          <div className="form__group_form-select">
                            {/* Select user */}
                            <Form.Field
                              className="FormField form__group_selectField"
                              name="username"
                              onChange={(e) => {
                                setSelectedUser(e.target.value);
                                const selectedUserData = usernamesData.find(
                                  (user) => user.username === e.target.value
                                );
                                if (selectedUserData) {
                                  setNominaDia(selectedUserData.nominaDia || "");
                                  setCamionDia(selectedUserData.camionDia || "");
                                  setCajaDia(selectedUserData.cajaDia || "");
                                }
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Form.Label style={{ margin: 0 }} className="FormLabel">
                                  Selecciona el operador
                                </Form.Label>
                                <Form.Message
                                  className="FormMessage"
                                  match="valueMissing"
                                >
                                  Ingresa un operador
                                </Form.Message>
                              </div>
                              {/* Drivers usernames options */}
                              <Select.Root size="3" required>
                                <Select.Trigger
                                  className="SelectTrigger boxSizing__bb"
                                  aria-label="Food"
                                  style={{ width: "100%", padding: ".5rem", margin: 0, marginTop:"9px" }}
                                >
                                  <Select.Value placeholder="Selecciona un usuario" />
                                  <Select.Icon className="SelectIcon">
                                    <ChevronDownIcon />
                                  </Select.Icon>
                                </Select.Trigger>
                                <Select.Portal>
                                  <Select.Content className="SelectContent">
                                    <Select.ScrollUpButton className="SelectScrollButton">
                                      <ChevronUpIcon />
                                    </Select.ScrollUpButton>
                                    <Select.Viewport className="SelectViewport">
                                      <Select.Group>
                                        {username.length > 0 ? (
                                          username.map((user) => (
                                            <SelectItem key={user} value={user}>
                                              {user}
                                            </SelectItem>
                                          ))
                                        ) : (
                                          <SelectItem value="loading">
                                            Cargando usernames...
                                          </SelectItem>
                                        )}
                                      </Select.Group>
                                    </Select.Viewport>
                                    <Select.ScrollDownButton className="SelectScrollButton">
                                      <ChevronDownIcon />
                                    </Select.ScrollDownButton>
                                  </Select.Content>
                                </Select.Portal>
                              </Select.Root>
                            </Form.Field>

                            {/* Nomina por dia */}
                            <Form.Field
                              className="FormField form__group_selectField"
                              name="nominaDia"
                              onChange={(e) => setNominaDia(e.target.value)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Form.Label style={{ margin: 0 }} className="FormLabel">
                                  Nómina por día: *
                                </Form.Label>
                              </div>
                              <Form.Control asChild>
                                <input
                                  className="Input"
                                  type="number"
                                  placeholder="$"
                                  value={nominaDia}
                                  id="nominaDia"
                                />
                              </Form.Control>
                            </Form.Field>
                          </div>
                          <div className="form__group_form-select">


                            {/* Renta camion por dia */}
                            <Form.Field
                              className="FormField form__group_selectField"
                              name="nominaDia"
                              onChange={(e) => setCamionDia(e.target.value)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Form.Label style={{ margin: 0 }} className="FormLabel">
                                  Renta de camión por día: *
                                </Form.Label>
                              </div>
                              <Form.Control asChild>
                                <input
                                  className="Input"
                                  type="number"
                                  placeholder="$"
                                  value={camionDia}
                                  id="camionDia"
                                />
                              </Form.Control>
                            </Form.Field>

                            {/* Renta caja por dia */}
                            <Form.Field
                              className="FormField form__group_selectField"
                              name="cajaDia"
                              onChange={(e) => setCajaDia(e.target.value)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Form.Label style={{ margin: 0 }} className="FormLabel">
                                  Renta de caja por día: *
                                </Form.Label>
                              </div>
                              <Form.Control asChild>
                                <input
                                  className="Input"
                                  type="number"
                                  placeholder="$"
                                  value={cajaDia}
                                  id="cajaDia"
                                />
                              </Form.Control>
                            </Form.Field>
                          </div>
                          <div className="btn__container">
                            {/*Save btn*/}
                            <Form.Submit asChild>
                              <button className="Button" style={{ marginTop: 20 }}>
                                Guardar datos
                              </button>
                            </Form.Submit>

                          </div>
                        </Form.Root>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Costos;
