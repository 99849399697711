import React, { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Header from "../components/HeaderAdmin";
import Footer from "../components/Footer";
import MenuAdmin from "../components/MenuAdmin";
import Reporte from "../components/subcomponentsAdmin/Reporte";
import Dashboard from "../components/Dashboard";
import RegistroUsuarios from "../components/subcomponentsAdmin/RegistroUsuarios";
import RegistrarClientesAdmin from "../components/subcomponentsAdmin/RegistrarCliente";
import RegistrarClientes from "../components/subcomponents/RegistrarCliente";
import PowerOnly from "../components/subcomponentsAdmin/PowerOnly";
import Costos from "../components/subcomponentsAdmin/Costos";
import { NotificationProvider } from "./NotificationContext";
import { AdminTransportes } from "../components/subcomponents/AdminTransportes";
import AdminChoferes from "../components/subcomponents/AdminChoferes";
import AsignarRuta from "../components/subcomponents/AsignarRuta";
import { CuentasPorCobrar } from "../components/subcomponents/CuentasPorCobrar";
import { AdminRemolques } from "../components/subcomponents/AdminRemolques";

function Main() {
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();
  const [showAdminReporte, setShowAdminReporte] = useState(false);
  const [showRegistroUsuarios, setShowRegistro] = useState(false);
  const [showRegistroClientes, setShowRegistroClientes] = useState(false);
  const [showPowerOnly, setPowerOnly] = useState(false);
  const [showCostos, setCostos] = useState(false);
  const [showAdminTransp, setAdminTransp] = useState(false);
  const [showOperadores,setShowOperadores] = useState(false);
  const [showAsignarRutas,setShowAsignarRutas]= useState(false);
  const [showRegistrarClientes,setShowRegistrarClientes] = useState(false);
  const [showCuentasPorCobrar,setShowCuentasPorCobrar] = useState(false);
  const [showRemolques,setShowRemolques] = useState(false);


  const handleAdminReporteClick = () => {
    setShowAdminReporte(true);
    setShowRegistro(false);
    setShowRegistroClientes(false);
    setPowerOnly(false);
    setCostos(false);
    setAdminTransp(false)
    setShowOperadores(false);
    setShowAsignarRutas(false)
    setShowRegistrarClientes(false);
    setShowCuentasPorCobrar(false);
    setShowRemolques(false);
  };

  const handleRegistroUsers = () => {
    setShowRegistro(true);
    setShowAdminReporte(false);
    setShowRegistroClientes(false);
    setPowerOnly(false);
    setCostos(false);
    setAdminTransp(false)
    setShowRemolques(false);
    setShowOperadores(false);
    setShowAsignarRutas(false)
    setShowRegistrarClientes(false);
    setShowCuentasPorCobrar(false)
  };

  const handleRegistroClientes = () => {
    setShowRegistro(false);
    setShowAdminReporte(false);
    setShowRegistroClientes(true);
    setPowerOnly(false);
    setCostos(false);
    setAdminTransp(false)
    setShowRemolques(false);

    setShowOperadores(false);
    setShowAsignarRutas(false)
    setShowRegistrarClientes(false);
    setShowCuentasPorCobrar(false)
  };

  const handlePowerOnly = () => {
    setShowRegistro(false);
    setShowAdminReporte(false);
    setShowRegistroClientes(false);
    setPowerOnly(true);
    setCostos(false);
    setAdminTransp(false)
    setShowRemolques(false);

    setShowOperadores(false);
    setShowAsignarRutas(false)
    setShowRegistrarClientes(false);
    setShowCuentasPorCobrar(false)
  };

  const handleCostos = () => {
    setShowRegistro(false);
    setShowAdminReporte(false);
    setShowRegistroClientes(false);
    setPowerOnly(false);
    setCostos(true);
    setAdminTransp(false)
    setShowRemolques(false);

    setShowOperadores(false);
    setShowAsignarRutas(false)
    setShowRegistrarClientes(false);
    setShowCuentasPorCobrar(false)

  };
  const handleAdminTranspClientes = () => {
    setShowRegistro(false);
    setShowAdminReporte(false);
    setShowRegistroClientes(false);
    setPowerOnly(false);
    setCostos(false);
    setAdminTransp(true)
    setShowRemolques(false);

    setShowOperadores(false);
    setShowAsignarRutas(false)
    setShowRegistrarClientes(false);
    setShowCuentasPorCobrar(false)

  };

  const handleOperadores = ()=>{
    setShowRegistro(false);
    setShowAdminReporte(false);
    setShowRegistroClientes(false);
    setPowerOnly(false);
    setCostos(false);
    setAdminTransp(false)
    setShowRemolques(false);

    setShowOperadores(true);
    setShowAsignarRutas(false)
    setShowRegistrarClientes(false);
    setShowCuentasPorCobrar(false)

  }
  const handleAsignarRutas = ()=>{
    setShowRegistro(false);
    setShowAdminReporte(false);
    setShowRegistroClientes(false);
    setPowerOnly(false);
    setCostos(false);
    setAdminTransp(false)
    setShowRemolques(false);

    setShowOperadores(false);
    setShowAsignarRutas(true)
    setShowRegistrarClientes(false);
    setShowCuentasPorCobrar(false)

  }

  const handleRegistrarClientes = ()=>{
    setShowRegistro(false);
    setShowAdminReporte(false);
    setShowRegistroClientes(false);
    setPowerOnly(false);
    setCostos(false);
    setAdminTransp(false)
    setShowRemolques(false);

    setShowOperadores(false);
    setShowAsignarRutas(false)
    setShowRegistrarClientes(true);
    setShowCuentasPorCobrar(false)

  }
  const handleCuentasPorCobrar = ()=>{
    setShowRegistro(false);
    setShowAdminReporte(false);
    setShowRegistroClientes(false);
    setPowerOnly(false);
    setCostos(false);
    setAdminTransp(false)
    setShowRemolques(false);

    setShowOperadores(false);
    setShowAsignarRutas(false)
    setShowRegistrarClientes(false);
    setShowCuentasPorCobrar(true)

  }
  const handleShowRemolques = ()=>{
    setShowRegistro(false);
    setShowAdminReporte(false);
    setShowRegistroClientes(false);
    setPowerOnly(false);
    setCostos(false);
    setAdminTransp(false)
    
    setShowOperadores(false);
    setShowAsignarRutas(false)
    setShowRegistrarClientes(false);
    setShowCuentasPorCobrar(false)
    setShowRemolques(true);

  }


  const notify = () => {
    toast.success("¡Inicio de sesión exitoso!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const previousToken = localStorage.getItem("previousToken");
    if (!token) {
      // No hay token, redirigir a la página de inicio de sesión
      navigate("/", { replace: true });
    } else {
      const user = jwtDecode(token);
      const decodedUser = jwtDecode(token);
      if (decodedUser) {
        setUserName(decodedUser.name);
      }
      // Verificar si el token es diferente al anterior
      if (token !== previousToken) {
        notify();
        localStorage.setItem("previousToken", token); // Actualizar el token anterior
      }

      if (!user) {
        localStorage.removeItem("token");
        navigate("/", { replace: true });
      } else {
      }
    }
  }, [navigate, notify]);

  const isTokenExpired = (token) => {
    if (!token) {
      return true;
    }

    const decodedToken = jwtDecode(token);

    if (!decodedToken.exp) {
      return true;
    }

    const expirationTime = decodedToken.exp * 1000;
    const currentTime = Date.now();

    return currentTime > expirationTime;
  };

  const token = localStorage.getItem("token");
  const isExpired = isTokenExpired(token);
  if (isExpired) {
    localStorage.removeItem("token");
  } else {
  }

  return (
    <NotificationProvider>
      <div>
        <Header />
        <MenuAdmin
          onAdminReporteClick={handleAdminReporteClick}
          onRegistroClick={handleRegistroUsers}
          onRegistroClienteClick={handleRegistroClientes}
          onPowerOnlyClick={handlePowerOnly}
          onCostosClick={handleCostos}
          onAdminTranspClienteClick={handleAdminTranspClientes}
          onAdminOperadoresClick={handleOperadores}
          onAsignarRutasClick={handleAsignarRutas}
          onRegistrarClientesClick={handleRegistrarClientes}
          onCuentasPorCobrarClick={handleCuentasPorCobrar}
          onAdminRemolquesClick={handleShowRemolques}

        />
        {showAdminReporte ? <Reporte /> : null}
        {showRegistroUsuarios ? <RegistroUsuarios /> : null}
        {showRegistroClientes ? <RegistrarClientesAdmin /> : null}
        {showPowerOnly ? <PowerOnly /> : null}
        {showCostos ? <Costos /> : null}
        {showAdminTransp ? <AdminTransportes /> : null}
        {showOperadores ? <AdminChoferes/> : null}
        {showAsignarRutas ? <AsignarRuta/> : null}
        {showRegistrarClientes ? <RegistrarClientes/> : null}
        {showCuentasPorCobrar ? <CuentasPorCobrar/> : null}
        {showRemolques ? <AdminRemolques/> : null}
        {!showAdminReporte &&
          !showRegistroUsuarios &&
          !showRegistroClientes &&
          !showPowerOnly &&
          !showCostos &&
          !showAdminTransp &&
          !showOperadores &&
          !showAsignarRutas &&
          !showRegistrarClientes &&
          !showCuentasPorCobrar &&
          !showRemolques 
          ? (
            <Dashboard />
          ) : null}
        <Footer />
        <ToastContainer />
      </div>
    </NotificationProvider>
  );
}
export default Main;
