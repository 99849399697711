import React, { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Header from "../components/HeaderAdmin";
import Footer from "../components/Footer";
import MenuFinanzas from "../components/MenuFinanzas";
import Reporte from "../components/subcomponentsAdmin/Reporte";
import Dashboard from "../components/Dashboard";
import RegistroUsuarios from "../components/subcomponentsAdmin/RegistroUsuarios";
// import RegistrarClientes from "../components/subcomponentsAdmin/RegistrarCliente";
import RegistrarClientes from "../components/subcomponents/RegistrarCliente";
import PowerOnly from "../components/subcomponentsAdmin/PowerOnly";
import { NotificationProvider } from "./NotificationContext";
import { CuentasPorCobrar } from "../components/subcomponents/CuentasPorCobrar";

function Main() {
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();
  const [showAdminReporte, setShowAdminReporte] = useState(false);
  const [showRegistroUsuarios, setShowRegistro] = useState(false);
  const [showRegistroClientes, setShowRegistroClientes] = useState(false);
  const [showPowerOnly, setPowerOnly] = useState(false);
  const [showCuentasPorCobrar,setShowCuentasPorCobrar] = useState(false);

  const handleAdminReporteClick = () => {
    setShowAdminReporte(true);
    setShowRegistro(false);
    setShowRegistroClientes(false);
    setPowerOnly(false);
    setShowCuentasPorCobrar(false);
  };

  const handleRegistroUsers = () => {
    setShowRegistro(true);
    setShowAdminReporte(false);
    setShowRegistroClientes(false);
    setPowerOnly(false);
    setShowCuentasPorCobrar(false);

  };

  const handleRegistroClientes = () => {
    setShowRegistro(false);
    setShowAdminReporte(false);
    setShowRegistroClientes(true);
    setPowerOnly(false);
    setShowCuentasPorCobrar(false);
    
  };

  const handlePowerOnly = () => {
    setShowRegistro(false);
    setShowAdminReporte(false);
    setShowRegistroClientes(false);
    setPowerOnly(true);
    setShowCuentasPorCobrar(false);

  };

  const handleCuentasPorCobrar = () => {
    setShowRegistro(false);
    setShowAdminReporte(false);
    setShowRegistroClientes(false);
    setPowerOnly(false);
    setShowCuentasPorCobrar(true);

  };
  

  const notify = () => {
    toast.success("¡Inicio de sesión exitoso!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const previousToken = localStorage.getItem("previousToken");
    if (!token) {
      // No hay token, redirigir a la página de inicio de sesión
      navigate("/", { replace: true });
    } else {
      const user = jwtDecode(token);
      const decodedUser = jwtDecode(token);
      if (decodedUser) {
        setUserName(decodedUser.name);
      }
      // Verificar si el token es diferente al anterior
      if (token !== previousToken) {
        notify();
        localStorage.setItem("previousToken", token); // Actualizar el token anterior
      }

      if (!user) {
        localStorage.removeItem("token");
        navigate("/", { replace: true });
      } else {
      }
    }
  }, [navigate, notify]);

  const isTokenExpired = (token) => {
    if (!token) {
      return true;
    }

    const decodedToken = jwtDecode(token);

    if (!decodedToken.exp) {
      return true;
    }

    const expirationTime = decodedToken.exp * 1000;
    const currentTime = Date.now();

    return currentTime > expirationTime;
  };

  const token = localStorage.getItem("token");
  const isExpired = isTokenExpired(token);
  if (isExpired) {
    localStorage.removeItem("token");
  } else {
  }

  return (
    <NotificationProvider>
      <div>
        <Header />
        <MenuFinanzas
          onAdminReporteClick={handleAdminReporteClick}
          onRegistroClick={handleRegistroUsers}
          onRegistroClienteClick={handleRegistroClientes}
          onPowerOnlyClick={handlePowerOnly}
          onCuentasPorCobrarClick={handleCuentasPorCobrar}
        />
        {showAdminReporte ? <Reporte /> : null}
        {showRegistroUsuarios ? <RegistroUsuarios /> : null}
        {showRegistroClientes ? <RegistrarClientes /> : null}
        {showPowerOnly ? <PowerOnly /> : null}
        {showCuentasPorCobrar ? <CuentasPorCobrar/>: null}
        {!showAdminReporte &&
        !showRegistroUsuarios &&
        !showRegistroClientes &&
        !showPowerOnly &&
        !showCuentasPorCobrar ? (
          <Dashboard />
        ) : null}
        <Footer />
        <ToastContainer />
      </div>
    </NotificationProvider>
  );
}
export default Main;
