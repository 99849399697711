import React, { Component, useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import DATTMXLogo from '../assets/DATTMXLogo.png'
import 'react-toastify/dist/ReactToastify.css'
function Menu({
  onAdminChoferesClick,
  onAsignarRutasClick,
  onRutasAsignadasClick,
  onRegistroClienteClick,
  onAdminTranspClienteClick,
  onAdminRemolquesClick
}) {
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      // No hay token, redirigir a la página de inicio de sesión
      navigate("/", { replace: true });
    } else {
      const user = jwtDecode(token);
      const decodedUser = jwtDecode(token);
      if (decodedUser) {
        setUserName(decodedUser.name);
      }
      if (!user) {
        localStorage.removeItem("token");
        navigate("/", { replace: true });
      } else {
      }
    }
  }, [navigate]);

  return (
    <aside
      className="main-sidebar sidebar-dark-primary elevation-4"
      style={{ zIndex: 999 }}
    >
     
      <img
        src={DATTMXLogo}
        // className="brand-image  elevation-3"
        style={{
          // opacity: "0.8",
          width: "230px",
          margin: "10px",
          padding: "5px",
        }}
      />
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src="../images/user.png"
              className="img-circle elevation-2"
              alt="User Image"
            />
          </div>
          <div className="info">
            <a href="#" className="d-block">
              {userName}
            </a>
          </div>
        </div>
        <div className="form-inline"></div>
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item" style={{ marginLeft: "10px" }}>
              <a href="#" className="nav-link" onClick={onAdminChoferesClick}>
                <i className="fa fa-users" style={{ fontSize: "20px" }} />
                <p style={{ marginLeft: "10px" }}>Administrar operadores</p>
              </a>
            </li>

            <li className="nav-item" style={{ marginLeft: "10px" }}>
              <a href="#" className="nav-link" onClick={onAsignarRutasClick}>
                <i className="fa  fa-map" style={{ fontSize: "20px" }} />
                <p style={{ marginLeft: "13px" }}>Asignar viajes</p>
              </a>
            </li>
{/* 
            <li className="nav-item" style={{ marginLeft: "10px" }}>
              <a href="#" className="nav-link" onClick={onRutasAsignadasClick}>
                <i className="fa fa-map" style={{ fontSize: "20px" }} />
                <p style={{ marginLeft: "10px" }}>Viajes asignados</p>
              </a>
            </li> */}

            <li className="nav-item" style={{ marginLeft: "10px" }}>
              <a href="#" className="nav-link" onClick={onRegistroClienteClick}>
                <i className="fa fa-users" style={{ fontSize: "20px" }} />
                <p style={{ marginLeft: "10px" }}>Registro de clientes</p>
              </a>
            </li>

            <li className="nav-item" style={{ marginLeft: "10px" }}>
              <a href="#" className="nav-link" onClick={onAdminTranspClienteClick}>
                <i className="fa fa-truck" style={{ fontSize: "20px" }} />
                <p style={{ marginLeft: "10px" }}>Administrar transportes</p>
              </a>
            </li>
            <li className="nav-item" style={{ marginLeft: "10px" }}>
              <a href="#" className="nav-link" onClick={onAdminRemolquesClick}>
                {/* <i className="fa fa-truck" style={{ fontSize: "20px" }} />
                <i class="fa-solid fa-truck-ramp-box"/ */}
                <i class="fas fa-truck-loading" style={{ fontSize: "20px" }}></i>
                <p style={{ marginLeft: "10px" }}>Administrar remolques</p>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}
export default Menu;
